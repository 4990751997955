@media only screen and (min-width: 1420px) {
  .wrap--container {
    max-width: calc(100vw - 5rem);
  }

  ul,
  ol,
  dl {
    font-size: rem-calc(17);
  }

  .list__title--sub-nav,
  .sub-nav__link,
  .main-nav__link,
  .utility-nav__link,
  .btn--tab,
  .btn--dropdown,
  .btn--section-toggle,
  .btn--ko-dropdown,
  #iverson-page .pop-over__title,
  #iverson-page .alert,
  #iverson-page .indicator--saved,
  .list__item--shipping-options,
  .list--unselected,
  .list--label-success,
  .list__header--modal-sb {
    font-size: rem-calc(15);
  }

  .wrap--free-callout p,
  #iverson-page input[type="text"],
  #iverson-page input[type="number"],
  #iverson-page input[type="password"],
  #iverson-page input[type="date"],
  .btn--pagination,
  .btn--pagination:focus,
  .btn--sm,
  .wrap--callout-trial .label__callout--trial,
  .btn--sm:focus,
  #iverson-page label,
  #iverson-page .select,
  #iverson-page .input__unit,
  #iverson-page .alert--savings,
  .list--addr-msg,
  .list--addr-callout,
  .list__item--modal-sb,
  .btn--filter,
  btn--presets,
  .btn--action,
  .btn--action:focus {
    font-size: rem-calc(14);
  }

  .btn--filter,
  .btn--presets {
    vertical-align: middle;
  }

  .wrap--list-header .btn--filter {
    vertical-align: baseline;
    /* or unset */
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"] {
    &.input--xx-sm {
      font-size: rem-calc(13);
    }
  }


  .alert--form-callout,
  .list__item--dropdown,
  .label--selectable,
  .list__link--dropdown,
  input[type="number"],
  .tooltip:after,
  [data-hint]:after,
  .pop-over__body,
  .list--subfilters,
  .list--order-data,
  .main-nav__count,
  .main-nav__count:hover,
  .list__title--order-data,
  .list__title--tracking-data,
  .list__title--line-detail,
  .list__item--line-detail {
    font-size: rem-calc(13);
  }

  .main-nav__link {
    padding-right: .75rem;
    padding-left: .75rem;
  }

  // Typography Modular Scale

  .fs-n2 {
    font-size: rem-calc(12);
  }

  .fs-n1 {
    font-size: rem-calc(13);
  }

  .fs-n0 {
    font-size: rem-calc(14);
  }

  .fs-00 {
    font-size: rem-calc(15);
  }

  .fs-01 {
    font-size: rem-calc(18);
  }

  .fs-02 {
    font-size: rem-calc(24);
  }

  .fs-03 {
    font-size: rem-calc(28);
  }

  .fs-04 {
    font-size: rem-calc(38);
  }

  .fs-05 {
    font-size: rem-calc(45);
  }

  .fs-06 {
    font-size: rem-calc(60);
  }

  .fs-07 {
    font-size: rem-calc(72);
  }

  .fs-08 {
    font-size: rem-calc(96);
  }

  .wrap--tag-buttons.w-25 {
    width: 30%;
  }


}

@media only screen and (min-width: 1660px) {
  .wrap--container {
    max-width: calc(100vw - 10rem);
  }

  .list__item--shipping-options-rs {
    flex-basis: 43%;

    .btn__text--no-overflow {
      max-width: 11rem;
    }

  }

  .wrap--rate-shipper-pkg-info {
    max-width: 21rem;
  }

}

@media only screen and (min-width: 1460px) and (max-width: 1660px) {
  .btn__text--no-overflow {
    max-width: 21rem;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1460px) {
  .btn__text--no-overflow {
    max-width: 18rem;
  }
}

@media only screen and (max-width: 1060px) {
  .outer-wrap--callout-trial {
    display: block;
  }

  .wrap--callout-trial {
    display: block;

    .label__callout--trial {
      font-size: rem-calc(12);
      border-radius: 0;
      margin-top: rem-calc(5);
    }

    .btn--primary-demo {
      font-size: rem-calc(12);
      width: 100%;
      margin-bottom: rem-calc(5);
    }

  }

  .wrap--rate-shipper-pkg-info,
  .btn__text--no-overflow {
    max-width: 11rem;
  }

}


@media only screen and (max-width: 990px) {
  @-moz-document url-prefix() {
    .wrap--sub-nav {
      justify-content: unset;
    }

    .list--sub-nav {
      margin-right: 1.25rem;
    }
  }


}

@media only screen and (max-width: 950px) {

  .main-nav__link {
    padding-right: .5rem;
    padding-left: .5rem;

    &.wrap--logo {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .utility-nav__link {
    padding-right: .3rem;
    padding-left: .3rem;
  }
}

@media only screen and (max-width: 850px) {

  .wrap--main-nav-links,
  .utility-nav__item {
    display: none;
  }

  .utility-nav,
  .main-nav__link.wrap--logo {
    margin-right: 0;
  }

  .main-nav__link.wrap--logo {
    margin-left: 1.1rem;
    line-height: 1.2;
  }

  .btn--nav-toggle,
  .wrap--activities {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    .utility-nav__link:hover {
      background: transparent;
    }
  }

  .open-nav {
    .wrap--nav-sm {
      display: block;
    }
  }

  .list--activity-dropdown {
    margin-top: 3rem;
  }

  .wrap--container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 650px) {

  .wrap--free-callout,
  .outer-wrap--callout-trial {
    display: none;
  }

  .img--product-popover {
    max-width: 70vw;
  }
}

@media only screen and (max-height: 550px) {

  #iverson-page .inner-wrap__panel--actions,
  .list--content-blocks,
  #iverson-page .meta-labelconfigform-shipper-ups .inner-wrap__panel--actions,
  .zuck #iverson-page .inner-wrap__panel--actions,
  .zuck #iverson-page .meta-labelconfigform-shipper-ups .inner-wrap__panel--actions,
  .zuck .list--content-blocks,
  .zuck #iverson-page .panel--actions .wrap--batch-info-panel .inner-wrap__panel--actions,
  .zuck #iverson-page .wrap--batch-info-panel .inner-wrap__panel--actions {
    overflow: visible;
    max-height: 100%;
  }

  .wrap--action-panel {
    margin-bottom: 2rem;
  }

}

/* Barcode scanning responsive styling */

@media only screen and (min-width: 320px) and (max-width: 800px) {

  .list--content-blocks,
  .zuck .list--content-blocks {
    max-height: 100%;
  }

  .wrap--list-header {

    .tooltip--top:hover {

      &:before,
      &:after {
        display: none;
      }
    }

    .i-refresh,
    .i--expand,
    .i-barcode-05 {
      font-size: rem-calc(27);
      padding-right: rem-calc(2);
      padding-left: rem-calc(2);
    }

    .list-items-count {
      font-size: rem-calc(17);
    }
  }

  .table--order-summary tr {

    td {
      display: block;
      padding: 0;
      margin-left: 2rem;
      width: auto;

      > :first-child {
        margin-top: .75rem;
      }

      .fs-n1 {
        font-size: rem-calc(14);
      }

      .list__title--order-header {
        font-size: rem-calc(17);
      }

      &:last-of-type {
        &:last-child {
          margin-bottom: .75rem;
        }
      }
    }

    .table__td--order-checkbox {
      float: left;
      margin-left: 0.25rem;
    }

  }

  #iverson-page .table__td--order-checkbox input[type="checkbox"] {
    margin-top: .75rem;
  }

  #iverson-page input[type="text"].input--main-search,
  #iverson-page .input--main-search {
    width: 100%;
  }

  #iverson-page .wrap--main-search {
    width: 100%;
    margin-top: .5rem;
  }

  .wrap--modal {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal--scan-mode,
  .meta-packing-mode-modal {
    min-width: auto;
    max-height: auto;
    width: 100%;
    height: 100%;
    margin: 0;

    .i--close {
      display: block;
      font-size: rem-calc(23);
    }

    .btn--force-verify,
    .table__td--verified:hover .btn--force-verify {
      opacity: .5;
      right: 0;

      .i-check-circle {
        font-size: rem-calc(23);
      }
    }

    .modal__header {
      padding: .875rem .875rem .875rem 0;
    }

    .modal__content {
      margin: 0;
      max-height: 100%;
    }

    .modal__footer {
      padding: 1rem 0;
    }

    .wrap--scan-success {
      top: .5rem;
    }
  }

  .wrap--scan-alert {
    min-height: auto;
    padding: 1rem 0;
  }

  .wrap--order-timeline-pop-over {
    display: none;
  }

  .wrap--dashboard-stats {

    .flex--reset-sm {
      display: block;

      .divider--right {
        border-right: 0;
        margin-bottom: rem-calc(25);
      }

      .wrap--line-graph {
        margin-top: rem-calc(25);
      }

    }

    .wrap--meta-data {
      margin-top: rem-calc(25);
    }

    .wrap--graph {
      border-bottom: 0;
      padding-bottom: 0;
    }

  }

  .outer-wrap--dashboard-stats {
    border-right: 0;
  }

  .outer-wrap--action-items {
    padding-left: 0.9375rem !important
  }


}
