// Colors Setting Overrides

$mainColor: #6EB43A;
$mainColorMed: #5e9438;
$mainColorDark: #243b14;
$secondaryColor: #e9e9e9;
$alertColor: #c60f13;
$successColor: #5da423;
$txtColor: #444;
$txtGreen: #3E992C;
$lightestGrey: #f2f2f2;
$lightGrey: #eee;
$lightMediumGrey: #ccc;
$mediumGrey: #999;
$mediumDarkGrey: #666;
$ordoroOrange: #f58026;
$ordoroBlue: #34a0cd;
$linkColor: $ordoroOrange;
$linkHoverColor: $ordoroBlue;
$errorColor: #ff0000;
$yellow: #ffff00;
$black: #000;
$white: #fff;

// Custom Color Settings

$white90: rgba(255, 255, 255, 0.9);
$white85: rgba(255, 255, 255, 0.85);
$white80: rgba(255, 255, 255, 0.8);
$white70: rgba(255, 255, 255, 0.7);
$white60: rgba(255, 255, 255, 0.6);
$white50: rgba(255, 255, 255, 0.5);
$white40: rgba(255, 255, 255, 0.4);
$white30: rgba(255, 255, 255, 0.3);
$white20: rgba(255, 255, 255, 0.2);
$white10: rgba(255, 255, 255, 0.1);

$black90: rgba(0, 0, 0, 0.9);
$black85: rgba(0, 0, 0, 0.85);
$black80: rgba(0, 0, 0, 0.8);
$black70: rgba(0, 0, 0, 0.7);
$black60: rgba(0, 0, 0, 0.6);
$black50: rgba(0, 0, 0, 0.5);
$black40: rgba(0, 0, 0, 0.4);
$black30: rgba(0, 0, 0, 0.3);
$black20: rgba(0, 0, 0, 0.2);
$black15: rgba(0, 0, 0, 0.15);
$black10: rgba(0, 0, 0, 0.1);
$black07: rgba(0, 0, 0, 0.07);

$toolTipBlue: rgba(42, 141, 183, 0.9);
