//
// Datepicker overrides
// --------------------

.react-datepicker {
  font-family: $font-family;
  border: 2px solid #444;
  box-shadow: rgba(0, 0, 0, .25) 0 rem-calc(5) rem-calc(40);
}

.wrap--modal-date-picker .react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__header {}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: rem-calc(27);
  border-bottom-color: #444;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  margin-top: rem-calc(-6);
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-top .react-datepicker__triangle:before {
  border-bottom-color: #444;
}

.react-datepicker__current-month {
  font-family: $font-family-headings;
  font-weight: normal;
}

.react-datepicker__navigation--previous {
  border-right-color: #999;

  &:hover {
    border-right-color: #666;
  }
}

.react-datepicker__navigation--next {
  border-left-color: #999;

  &:hover {
    border-left-color: #666;
  }
}

.react--datepicker__navigation--custom {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 16px;

  &:hover, &:active, &:focus {
    background-color: transparent;
  }
}

.wrap--react-datepicker__header {
  margin: .3rem .5rem .75rem .5rem;
}

.react-datepicker__month {}

.react-datepicker__week {}

.react-datepicker__day {
  color: #666;

  &:hover {
    background: #999;
    color: #fff;
  }
}

.react-datepicker__day--today {
  font-family: $font-family-headings;
  font-weight: normal;
  color: #111;
}

.react-datepicker__day--in-range {
  font-family: $font-family-headings;
  font-weight: normal;
  color: #666;
  background: #ccc;

  &:hover {
    background: #999;
    color: #fff;
  }
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #eee;
  color: #666;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background: $ordoro-blue;
  font-family: $font-family-headings;
  font-weight: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;

  &:hover {
    background: $ordoro-blue;
    cursor: default;
    color: #fff;
  }
}

.react-datepicker__day--disabled,
.react-datepicker__day--disabled:hover {
  color: #ddd;
  background: transparent;
  cursor: default;
}

.dropdown--datepicker {
  .react-datepicker__month-container {
    clear: both;
  }

  .list--dropdown {
    min-width: unset;
  }

  .react-datepicker {
    border: none;
    border-radius: none;
    box-shadow: none;
  }
}
