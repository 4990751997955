//Utility Nav
.user-utility-wrap {
    position: absolute;
    z-index: 2;
    right: 0;
    padding-top: 3px;
    .user-utility {
        background: transparent;
        li {
            list-style: none;
            color: $black;
            display: inline-block;
            font-size: rem-calc(11);
        }
        .dropdown-wrap .dropdown-menu li {
            display: block;
        }
        .user-name {
            padding: 8px 10px 0 0;
            a {
                color: $medium-grey;
            }
            a:hover, a:focus {
                color: $anchor-font-color-hover;
            }
        }
        .activity {
            margin-top: -1px;
        }
        .sync {
            position: relative;
            vertical-align: middle;
        }
        .utility-nav-button {
            border-left: 1px solid $light-grey;
            border-right: 1px solid transparent;
        }
    }
}

.user-utility {
    .syncing {
        .dropdown-toggle {
            color: $txt-green;
            .icon {
                color: $txt-green;
            }
        }
        .dropdown-toggle:hover {
            color: $anchor-font-color-hover;
            .icon {
                color: $anchor-font-color-hover;
            }
        }
    }
    .open.syncing {
        .dropdown-toggle {
            color: #fff;
            .icon {
                color: #fff;
            }
        }
    }
}

//Main Nav
.main-nav-wrap {
    background: $ordoro-green;
    border-top: 2px solid #5d9935;
    border-bottom: 1px solid #66a73a;
    padding: 0;
    .top-bar-section .main-nav {
        background: transparent;
        font-size: rem-calc(15);
        li {
            list-style: none;
            float: left;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            @include text-shadow-standard;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            background: $ordoro-green;
            a {
                font-weight: normal;
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
                font-size: 1em;
                @include transition-all-standard;
                position: relative;
                padding: 0 1.5em;
                height: 55px;
                border-left: 1px solid #66a73a;
            }
            a:not(.button) {
                background: transparent;
                line-height: 55px;
            }
            a:hover:not(.button), a:focus:not(.button) {
                background: #66a73a;
            }
            .selected, .selected:not(.button), .selected:hover, .selected:hover:not(.button) {
                background: #5d9935;
                border-left: 1px solid transparent;
            }
            .selected:after, .selected:after:not(.button) {
                @include triangle-pointing-up(0.9em, #fff);
                position: absolute;
                bottom: -3px;
                left: 50%;
                transform: translate(-50%,0);

            }
        }
        li > a {
            background: transparent;
        }
        li:last-of-type a {
            border-right: 1px solid #66a73a;
        }
        li:last-of-type .selected {
            border-right: 1px solid transparent;
        }
        .right-arrow {
            display: none;
        }
        #shipments_main_nav {
            .right-arrow {
                display: inline-block;
                margin-right: -1.75em;
                margin-left: .5em;
            }
            .right-arrow:before {
                top: -.5px;
            }
        }
    }
}

.count, .count:hover {
    padding: 3px 4px;
    font-family: Helvetica, sans-serif;
    font-size: .7em;
    line-height: normal;
    min-width: 2em;
    border-radius: 3px;
    border: 2px solid #dc6b00;
    background: $ordoro-orange;
    color: #fff;
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-align: center;
    @include text-shadow-standard;
    -webkit-font-smoothing: antialiased;
    line-height: 1;
    vertical-align: top;
    position: absolute;
    right: 10px;
    top: -1.05em;
}

.activity_count {}

//Subnav
.list-navigation-rule {
    border: 0;
    margin: 0 0.9375em 1.5em !important;
    border-bottom: 1px solid $txt-green;
}

.subnav {
    @include inline-list;
    margin-left: 0;
    li {
        font-size: rem-calc(14);
        line-height: 2.6;
        margin-left: 0;
        position: relative;
        a {
            color: $body-font-color;
            border-left: 1px solid $light-grey;
            margin-left: 0;
            padding: 1em;
            line-height: 1;
        }
        a:hover, a:focus {
            color: $anchor-font-color-hover;
        }
        .first {
            border-left: 0;
            margin-left: 0;
            padding-left: 0;
        }
        .selected, .selected:hover, .selected:focus {
            color: $txt-green;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
        }
        .selected:after {
            @include triangle-pointing-up(5px, $txt-green);
            bottom: 0;
            left: 50%;
            transform: translate(-50%,0);
        }
    }
}

//Breadcrumbs
.breadcrumbs {
    margin: 1em 0 0 0;
    font-size: rem-calc(12);
    li {
        padding: 0;
    }
    > *:before {
        color: $lightMediumGrey;
        margin: 0 0.75em;
        top: 0;
    }
    > *:first-child:before {
        margin: 0;
    }
    a {
        color: $anchor-font-color;
    }
    a:hover {
        color: $anchor-font-color-hover;
    }
}

//Modal Subnav
.modal-subnav {
    margin-bottom: 0;
    margin-top: 1em;
    li {
        margin-left: .25em;
        color: $light-grey;
    }
    .selected {
        color: $txt-green;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;

        .modal-subnav-button {
            color: $txt-green;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            vertical-align: baseline;
        }
    }
    .step {
        font-size: emCalc(13px);
        margin-left: .3em;
    }
    .first {
        margin-left: 0;
    }
}

.modal-subnav-rule {
    border: 0;
    margin: 0 0.9375em !important;
    border-bottom: 1px solid $light-grey;
}

//Tab navigation
.tab-subnav, .row .tab-subnav {
    margin-left: 0;
    border-bottom: 1px solid #aaa;
    margin-bottom: 1.5em;
    dt {
        font-size: 0.75em;
    }
    dd {
        float: left;
        margin: 0;
    }
    .tab-toggle {
        border: 1px solid #aaa;
        border-right: 0;
        border-bottom: 0;
        background: #f7f7f7;
        color: $body-font-color;
        display: block;
        position: relative;
        padding: .75em 1.5em;
        @include transition-all-standard;
        opacity: .8;
        top: 1px;
        height: 3em;
    }
    .tab-toggle:hover, .tab-toggle:focus {
        border-color: #aaa;
        background-color: #ddd;
        opacity: 1;
    }
    .tab-toggle.active, .tab-toggle.active:hover {
        background-color: #fff;
        border-bottom: 1px solid #fff;
        opacity: 1;
        height: 3.1em;
        color: #000;
    }
    .last {
        .tab-toggle {
            border-right: 1px solid #aaa;
        }
    }
}


//Side Nav

.wrap--side-nav {
    border-right: 1px solid $light-grey;
    margin-bottom: 2em;
}

.side-nav {}

.side-nav__title {
    border-bottom: 1px solid $lighter-grey;
    line-height: 1.2;
    margin: .75em 0 0 0;
    padding: 0 0 .5em .5em;
    color: $txt-green;
}

.side-nav__item, .side-nav .side-nav__item { /* Refactor this later */
    font-family: $aktiv-grotesk-regular;
    font-weight: normal;
    line-height: 3;
    font-size: .75em;
    margin-bottom: 0;
    border-bottom: 1px solid $lighter-grey;
    font-weight: normal;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    position: relative;
}

.side-nav__link, .side-nav .side-nav__link {
    color: $medium-grey;
    @include transition-all-standard;
    padding-left: .75em;

    &:hover {
        color: $ordoro-blue;
        color: $ordoro-blue;
        background: $lightest-grey;
    }

    &.disabled, &.disabled:hover, &.disabled:focus {
        color: #ddd;
        cursor: default;
        background: transparent;
    }
}

.side-nav__item--active, .side-nav .side-nav__item--active {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;

    .side-nav__link, .side-nav__link:hover, .side-nav__link:focus {
        color: #000;
        font-weight: normal;
        background: transparent;
        cursor: default;
    }

    .side-nav__link:after, .side-nav__link:hover:after {
        @include triangle-pointing-left(10px, $light-grey);
        top: 30%;
        right: -1px;
        z-index: 9;
        font-size: 0;
    }
}

.side-nav li a:not(.button) {
    line-height: 1.3;
    padding: 1rem .875rem;
}
