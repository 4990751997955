//
// RESETS for Foundation
// ---------------------

// Forms

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], textarea {
    margin: 0;
    background-color: initial;
    border-radius: 0;
    border-color: initial;
    box-sizing: border-box;
    height: initial;
    font-size: initial;
    padding: initial;
    transition: none;

    &:focus {
        background-color: initial;
        border-color: initial;
    }
}

input[type="file"], input[type="checkbox"], input[type="radio"], select {
    margin: 0;
    box-sizing: border-box;

    &:focus {
        border-color: initial;
        box-shadow: none;
    }
}
