//
// Modals
// ------

html.no-scroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.wrap--modal {
  background: rgba(0, 0, 0, .5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 665;
}

.modal {
  background: #fff;
  margin: 5vh auto 0 auto;
  width: rem-calc(600);
  min-width: rem-calc(600);
  box-shadow: rgba(0, 0, 0, .6) 0 rem-calc(1) rem-calc(5);

  .table__tbody--lines tr:last-of-type {
    .table__td {
      border-bottom: 0;
    }
  }

  .table__tbody--lines .tr--error:last-of-type {
    .table__td {
      border-bottom: 1px solid #ff0000;
    }
  }
}

.error {
  color: $error-color;
}

.modal--x-sm {
  width: rem-calc(320);
  min-width: rem-calc(320);
}

.modal--sm {
  width: rem-calc(480);
  min-width: rem-calc(480);
}

.modal--sm-md {
  width: rem-calc(666);
  min-width: rem-calc(666);
}

.modal--md {
  width: calc(100vw - 16rem);
  max-width: rem-calc(810);
}

.modal--lg {
  width: calc(100vw - 16rem);
  max-width: rem-calc(900);
}

.modal--x-lg {
  width: calc(100vw - 10rem);
  max-width: rem-calc(1100);
}

.modal--xx-lg {
  width: calc(100vw - 6rem);
  max-width: unset;

  .row {
    max-width: unset;
  }

}

.modal__header {
  background: #ddd;
  border-bottom: 1px solid #ccc;
  padding: .875rem;

}

.modal__content {
  background: #fff;
  margin: 1.2rem .875rem;
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
  @include modal__scrollbar;

  .inner-wrap__panel--actions {
    max-height: 100%;
    overflow-x: auto;
    padding-right: 0;
    margin-right: 0;
  }

  &.modal__prevent-inner-scroll {
    overflow-y: initial;
  }
}

.modal__has-errors .modal__content {
  max-height: calc(100vh - 20rem);
}

.modal__has-errors.modal--quick-ship {
  .modal__content {
    overflow-x: hidden;
  }
}

.modal__errors {
  background: #fff;
  margin: 1.2rem .875rem;


  .alert {
    max-height: 4rem;
    overflow-y: auto;
    @include modal__scrollbar;

  }

  pre {
    white-space: pre-wrap;
  }
}

.modal__footer {
  background: #fff;
  padding: 1rem .875rem;
  border-top: 1px solid #ddd;
}

.modal__footer--empty {
  border-top: 0;
  padding: .01rem;
}

// Modal Utility Sidebar

.list--modal-sb {
  padding: 0;
  margin: 0;
  background: #222;
  color: $medium-grey;
  -webkit-font-smoothing: antialiased;
}

.list__header--modal-sb {
  color: #fff;
  font-size: rem-calc(14);
  padding: .75rem 0;
  background: #444;
  text-align: center;
  margin-bottom: 0;
}

.list__item--modal-sb {
  font-size: rem-calc(13);
  margin-bottom: 0;
}

.list--addr-msg,
.list--addr-callout {
  font-size: rem-calc(13);
  line-height: 1.3;
  margin-bottom: 0;
  padding: .75rem;

  &.warning {
    color: $warning-txt-color;
  }

  &.error {
    color: $error-color;
  }

  dd:last-of-type {
    margin-bottom: 0;
  }
}

.list__item--usps-callout {
  background: #666;
  color: #fff;
  padding: .5rem;

  a {
    color: yellow;

    &:hover {
      text-decoration: underline;
    }
  }
}

.list__title--addr-msg {
  border-bottom: 1px solid #444;
  text-transform: uppercase;
}

.list__item--addr-callout {
  border: 1px dashed $medium-dark-grey;
  color: #fff;
  margin-bottom: 0;
  padding: .5rem;
}

// Print view modal overrides

.meta-modal-name-label-view-modal {

  .list__title--label-success {
    margin-top: 0;
  }

  .select.w-100 {
    width: auto;
    min-width: 12rem;
  }

  .fieldset--shipping-options {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px dotted #ccc;

    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }
  }

  .wrap--error-wb {
    white-space: normal;
  }
}

// Scan/Verify aka Pack Modal

.modal--scan-mode,
.meta-packing-mode-modal {
  position: relative;

  .i--close {
    display: none;
  }
}

.wrap--scan-success {
  position: absolute;
  top: .85rem;
  right: 1.25rem;
  text-align: center;
  color: $ordoro-blue;
  padding: .5rem .85rem;
  background: #fff;
  border: 2px solid $ordoro-blue;
  opacity: 0;
  @include transition-all-standard;

  &.in {
    opacity: 1;
  }
}

.tr--scan-success,
table .tr--scan-success,
table .tr--scan-success:nth-of-type(even) {
  background: #fff;
  @include transition-all-standard;

  .verified-quantity {
    @include transition-all-faster;
    color: #444;
  }

  &.in {
    background: #f0f8fb;

    .verified-quantity {
      font-size: rem-calc(37);
      color: $ordoro-blue;
    }
  }

  &.tr--child-sku.in {
    .verified-quantity {
      font-size: rem-calc(24);
    }
  }
}

.success-fade-exit,
table .success-fade-exit,
table .success-fade-exit:nth-of-type(even) {
  opacity: 1;
  background: #f0f8fb;

  .verified-quantity {
    font-size: rem-calc(37);
    color: $ordoro-blue;
  }

  &.tr--child-sku {
    .verified-quantity {
      font-size: rem-calc(24);
    }
  }
}

.success-fade-exit.success-fade-exit-active,
table .success-fade-exit.success-fade-exit-active,
table .success-fade-exit.success-fade-exit-active:nth-of-type(even) {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

/* Kit component styling */

.table__tbody--lines .tr--has-children {
  .table__td {
    padding-bottom: .75rem;
  }
}

.table__tbody--lines .tr--child-sku {
  .table__td {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 1rem;
  }
}

.list--parent-sku {
  .list__title {
    font-size: rem-calc(17);
    line-height: 1.35;
  }

  .list__item {
    color: #666;
    font-size: rem-calc(14);
    line-height: 1.35;
    margin-top: rem-calc(5);
    margin-bottom: 0;
  }
}

.list--child-sku {
  padding-left: .75rem;
  border-left: 1px dotted #ccc;

  .list__title {
    font-size: rem-calc(14);
    line-height: 1.35;
  }

  .list__item {
    color: #666;
    font-size: rem-calc(12);
    line-height: 1.35;
    margin-top: rem-calc(3);
    margin-bottom: 0;
  }
}

// MODAL styling overrides
.list__item--form.modal-weight-input,
.list__item--form.modal-dimensions-input {
  margin-bottom: rem-calc(5);

  .input__unit.inline-block {
    margin-left: rem-calc(5);
    margin-right: rem-calc(5);
  }
}

.meta-modal-name-create-rule-modal .meta-editing-rule {
  width: 100%;
}

// Insurance modal overrides

.modal .label__text--insurance-toggle {
  font-size: rem-calc(17);
}

.modal .wrap--bulk-update-shipping-config .label__text--insurance-toggle {
  font-size: rem-calc(14);
}

.modal .wrap--bulk-update-profile label {
  display: none;
}


// Global Error Modal

.modal--global-error {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  margin: 1.5rem auto;
  border: 3px solid #ff0000;

  .modal__header {
    border-bottom: 3px solid #ff0000;
    color: #ff0000;
    background: #ffeaeb;

    h3 {
      color: #ff0000;
    }
  }
}

.wrap--global-error-raw {
  background: #ffeaeb;
  color: darken(#ff0000, 8%);
  border: 1px dashed #ccc;
  padding: .4rem;
  max-height: 5rem;
  overflow-y: scroll;
  word-break: break-word;

  &::-webkit-scrollbar {
    height: .45em;
    width: rem-calc(12);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .3);
    border-right: rem-calc(3) solid #ffeaeb;
    border-left: rem-calc(3) solid #ffeaeb;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #ffeaeb;
    border-right: rem-calc(3) solid #ffeaeb;
    border-left: rem-calc(3) solid #ffeaeb;
    margin-top: rem-calc(3);
    margin-bottom: rem-calc(3);
  }
}

.btn--copy-pasta,
.btn--copy-pasta:focus {
  width: 100%;
  padding: .35rem;
  text-align: center;
  border: 1px solid #ccc;
}


// Sync Modal

.wrap--locked-element {

  label,
  label:hover {
    opacity: .5;
    cursor: default;
  }
}



// Filters Modal
.wrap--modal-filters {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.75rem;
  padding-bottom: 1rem;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }

}

.list__item--modal-filters,
.modal .list__item--modal-filters {
  width: 32%;
  margin-bottom: 1.25rem;
  padding-right: rem-calc(10);

  .btn--filter-reverse {
    margin-left: .5rem;
  }

  &:hover {
    .btn--filter-reverse {
      opacity: 1;
      display: inline-block;

    }
  }
}

.list__item--modal-filters-no-results,
.modal .list__item--modal-filters-no-results {
  width: 100%;
  padding: 2rem 0;
  text-align: center;
}

.wrap--filter-expand-btn {
  margin-bottom: 1rem;
}

.wrap--filter-operators {
  border: 1px dashed #444;
  background: #fff;
  padding: .75rem 1rem;
  display: inline-block;
}

.list--modal-filters {
  input[type="text"].input--w-prefix {
    width: 7rem;
    height: 2.15rem;
  }

  .icon-prefix {
    -webkit-font-smoothing: antialiased;
    color: #999;
  }

}

.modal--order-list-filters {
  .wrap--right-modal-buttons {
    @include flexbox;
    @include flex-direction(column);
    @include flex-justify-sa;
  }
}

// Label Config in Modal Overrides

.modal__content .wrap--label-panel-in-modal {

  .inner-wrap__panel--actions {
    overflow: visible;
  }

  .wrap--dropdown-presets {
    text-align: left;
    margin-left: 0;
    margin-bottom: .75rem;

    .btn--dropdown {
      border: 1px solid #aaa;
      padding: .12rem .35rem;
      line-height: 1.15;
      border-radius: rem-calc(3);

      &:hover {
        border-color: #ccc;
      }
    }
  }

  #rate-select.h-auto,
  .select.h-auto {
    height: 1.6875rem;
  }

  .list__item--shipping-options,
  .meta-labelconfigform-form-additionaloptions {
    margin-top: 1.15rem;
  }

  .fieldset--shipping-summary .alert--warning {
    margin-bottom: 1rem !important;
  }

  .wrap--error-wb {
    white-space: pre-wrap;
  }

  .list__title--label-success {
    display: none;
  }

}

// Modal Timeline Overrides

.modal--quick-ship {

  .list--manual-address {

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="date"] {

      height: 2.0625rem;
    }
  }

  .list--timeline-point {
    margin-left: .75rem;
  }

  .list--timeline-point:first-of-type {
    padding-top: 0;
  }

  .list--timeline-point:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;

    .list__title--timeline-point {
      line-height: 1;
    }

  }

  .list--timeline-point-active {

    .list__title--timeline-point {
      color: #111;

      &:before {
        background-color: #333;
      }

    }
  }

  .list--timeline-point-completed {

    .list__title--timeline-point {
      color: $ordoro-green;

      &:before {
        background-color: #fff;
        content: "\21";
        color: $ordoro-green;
        font-family: "iverson-icons";
        font-weight: normal;
        font-size: 1rem;
        line-height: 1;
        top: -0.2rem;
        left: -1.9rem;
        width: auto;
        height: auto;
      }

    }
  }

}

// Hubspot calendar iframe overrides

.meetings-iframe-container-650-height iframe {
  height: 650px !important;
  max-height: 650px !important;
}

// Edit Preset Modal (style overrides for edit version of the label config form)

.wrap--edit-preset-list {

  .flex-wrap-margin-10 {
    margin: 0;

    li,
    .flex-item {
      margin-top: 0;
      margin-left: 0;
    }

    .btm-offset-7 {
      margin-bottom: 0;
    }
  }

  .flex-wrap-margin-15 {
    margin: 0;

    li,
    .flex-item {
      margin-top: 0;
      margin-left: 0;
      width: 100%;
    }

    .btm-offset-7 {
      margin-bottom: 0;
    }

  }

  .list,
  .open .list__title--accordion {
    margin-bottom: 0;
  }

  .list__item--shipping-options,
  .wrap--edit-preset-form-flex-item {
    border-bottom: 1px dotted #ccc;
    padding: 1rem 0;
    margin-bottom: 0;
    width: 100%;
    @include flexbox;
    @include flex-justify-sb;
    @include flex-wrap(wrap);
    flex-grow: unset;
    align-items: center;

    .wrap--edit-preset-form-input {
      width: 82%;

      &.wrap--edit-preset-form-input-md {
        width: 50%;
      }

      &:not(.wrap--edit-preset-prevent-disable):has(+ .wrap--edit-preset-switch input:not(:checked)) {
        opacity: .65;
        pointer-events: none;
      }
    }

    .wrap--edit-preset-switch {
      width: 10%;
    }

  }

  .wrap--edit-preset-disabled {
    opacity: .65;
    pointer-events: none;
  }

  .wrap--edit-preset-form-flex-item:first-of-type {
    padding-top: 0;
  }

  .wrap--edit-preset-form-flex-item:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .wrap--edit-preset-no-divider-bottom {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .label__text--insurance-toggle {
    font-size: rem-calc(14);
  }

  .list__item--shipping-options:has(.list:empty) {
    display: none;
  }

  .required,
  .wrap--edit-preset-modal-hide-item {
    display: none;
  }
}

.wrap--label-enable-mode {
  .list__item--shipping-options {
    border-bottom: 1px dotted #ccc;
    padding: 1rem 0;
    margin-bottom: 0;
    width: 100%;
    @include flexbox;
    @include flex-justify-sb;
    @include flex-wrap(wrap);
    flex-grow: unset;
    align-items: center;

    .wrap--edit-preset-form-input {
      width: 82%;

      &.wrap--edit-preset-form-input-md {
        width: 50%;
      }

      &:not(.wrap--edit-preset-prevent-disable):has(+ .wrap--edit-preset-switch input:not(:checked)) {
        opacity: .65;
        pointer-events: none;
      }
    }

    .wrap--edit-preset-switch {
      width: 10%;
    }
  }
}