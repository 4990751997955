//Setup the icon font
//Setup the icon font
.icon {
    vertical-align: baseline;
}

.icon:before {
    font-family: $iconFontFamily;
    speak: none;
    position: relative;
    border: 0;
    font-style: normal;
    font-weight: 300;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    white-space: nowrap;
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

.icon-block:before {
    font-family: $alternateIconFontFamily;
}

.icon-block-two:before {
    font-family: $tertiaryIconFontFamily;
}

//Icon sizes and vertical alignment
.icon.xxx-sm:before {
    top: 4px;
}

.additional-table-info .icon.xxx-sm:before {
    top: 2px;
}

.icon.xxx-sm {
    font-size: 10px !important;
    line-height: 0;
}

.icon.xx-sm:before {
    top: 4px;
}

.icon.xx-sm {
    font-size: 14px !important;
    line-height: 0;
}

.list__item--rule-criteria {
    .icon.xx-sm {
        font-size: 15px !important;
    }
}

.icon.x-sm:before {
    top: 4px;
}

.icon.x-sm {
    font-size: 18px !important;
    line-height: 0;
}

.icon.sm:before {
    top: 6px;
}

.icon.sm {
    font-size: 24px !important;
    line-height: 0;
    vertical-align: baseline;
}

.icon.md:before {
    top: 8px;
}

.icon.md {
    font-size: 30px !important;
    line-height: 0;
}

.icon.lg:before {}

.icon.lg {
    font-size: 48px !important;
    line-height: 0;
}

.icon.xl:before {}

.icon.xl {
    font-size: 71px !important;
    line-height: 0;
}

.icon.xxl:before {}

.icon.xxl {
    font-size: 96px !important;
    line-height: 0;
}

.icon.top-zero:before {
    top: 0;
}

.icon.top-one:before {
    top: 1px;
}

.icon.main-nav-icon {
    opacity: .9;
    margin-right: .3em;
    color: #fff;
    vertical-align: middle;

    &:before {
        top: 0;
        vertical-align: middle;
    }
}

.dashboard-icon {
    opacity: .7;
    @include text-shadow-white;
    margin-right: .3em;
    vertical-align: baseline;
}

.table-cell-status-icon, .table-cell-rule-icon {
    float: left;
    height: .5em;
    display: none;
}

.no-go, .warning, .unshippable, .partially-shippable, .overallocated, .partially-allocated, .allocated, .ok, .shippable {
    .table-cell-status-icon {
        display: block;
    }
}

.rule-triggered {
    .table-cell-rule-icon {
        display: block;
    }
}

//
//Icon characters
//

//Utility nav icons
.icon-settings:before {
    content: "\2699";
}
/* Cog */

.icon-support:before {
    content: "\2753";
}
/* Support question mark */

.icon-activity:before {
    content: "\E57A";
}
/* Activity graph */

.icon-news:before {
    content: "\E397";
}
/* Megaphone */

.icon-lightning:before {
    content: "\2607";
}
/* lightning */

//Main nav icons
#dashboard_main_nav .icon:before {
    content: "\F000";
}

/* dashboard gauge */
#orders_main_nav .icon:before, .icon-orders:before {
    content: "\E550";
}

/* order list */
#shipments_main_nav .icon:before, .icon-shipments:before, .shipped-list .status-icon:before, .line-shipped .status-icon:before {
    content: "\1F4E6";
}

/* shipping box */
.shipped-list .status-icon:before {
    color: $medium-grey;
}

#products_main_nav .icon:before, .icon-products:before {
    content: "\E100";
}
/* product tag */

#pos_main_nav .icon:before, #purchase_orders_main_nav .icon:before {
    content: "\1F4B5";
}

#analytics_main_nav .icon:before {
    content: "\1F4CA";
}
/* bank notes */

//Dashboard icons
.icon-revenue:before {
    content: "\1F4B0";
}

/* money bag */
.icon-heart:before {
    content: "\2665";
    color: #ff0000;
    top: 1px !important;
}

/* heart */

//List page utility icons
.icon-search:before {
    content: "\1F50D";
}
/* Magnifying glass */

.icon-create:before {
    content: "\EC01";
}
/* Rounded square with a + */

.icon-export:before {
    content: "\EE00";
}
/* Export arrow in box */

.icon-scanlist:before {
    content: "\E972";
}
/* Notepad/paper with list on it */

.icon-cart:before {
    content: "\E500";
}

/* Shopping cart */
.icon-import-tracking-number:before {
    content: "\EB02";
}
/* shipping box with down arrow */

.icon-comment:before {
    content: "\1F4AC" !important;
}
/* Speech bubble */

.icon-note:before {
    content: "\1F5D2" !important;
}
/* Notebook */

.icon-label:before, .icon-print:before {
    content: "\2399";
}
/* printer with label */

.icon-email:before {
    content: "\1F585";
}
/* envelope with right-pointing arrow */

.icon-received:before {
    content: "\1F4E9";
}
/* envelope with down-pointing arrow */

.icon-kit:before {
    content: "\1F4BC";
}
/* briefcase */

.icon-archived:before {
    content: "\E901";
}
/* storage box */

.icon-dropship:before {
    content: "\1F69A";
}
/* truck */

//General icons in app
.icon-refresh:before {
    content: "\21BB";
}
/* Refresh circle */

.icon-close:before {
    content: "\2421";
    font-family: $alternateIconFontFamily;
}
/* Circle with X */

.icon-trash:before {
    content: "\E0D0";
}
/* trashcan */

.icon-lock:before {
    content: "\1F512";
}
/* lock */

.icon-error:before, .no-go .status-icon:before, .unshippable .shipping-sneak-peek .status-icon:before, .unshippable .detail-shippability-wrap .status-icon:before, .unshippable .table-cell-status-icon.status-icon:before, .unshippable-list .status-icon:before, .line-unshippable .status-icon:before, .sync-failure .sync-status .status-icon:before, .address-info.error .icon:before, .wrap--callout.error .icon:before {
    content: "\1F6AB";
    color: $error-color;
}
/* Circle with slash through it */
.icon-warning:before, .warning .status-icon:before, .partially-shippable .detail-shippability-wrap .status-icon:before, .partially-shippable .shipping-sneak-peek .status-icon:before, .partially-shippable .table-cell-status-icon.status-icon:before, .line-partially-shippable .status-icon:before, .partially-shippable-list .status-icon:before, .not-configured .warehouse-status-icon:before, .address-info.warning .icon:before, .wrap--callout.warning .icon:before {
    content: "\26A0";
    color: $warning-txt-color;
}

/* Rounded triangle with exclamation */

.ok .status-icon:before, .shippable .detail-shippability-wrap .status-icon:before, .shippable .shipping-sneak-peek .status-icon:before, .shippable .table-cell-status-icon.status-icon:before, .shippable-list .status-icon:before, .line-shippable .status-icon:before, .synced .sync-status .status-icon:before {
    content: "\1F506";
    color: $txt-green;
    font-family: $alternateIconFontFamily;
}
/* Orb */

.icon-date:before {
    content: "\1F4C5";
}
/* Calendar */

.icon-up:before {
    content: "\2B06";
}
/* Up Arrow */

.icon-down:before {
    content: "\2B07";
}
/* Down Arrow */

.icon-left:before {
    content: "\25C5";
}
/* left arrow in circle */

.icon-right:before {
    content: "\25BB" !important;
}
/* Right Arrow */

.icon-success:before, .default .warehouse-status-icon:before {
    content: "\2713";
}
/* checkbox */


.icon-thumbs-up:before {
    content: "\1F44D";
}
/* thumbs up */

.icon-empty-circle:before {
    content: "\1F315";
}
/* empty circle */

.icon-filled-circle:before {
    content: "\1F311";
}
/* filled circle */

.icon-upgrade:before {
    content: "\1F451";
}
/* crown */

.icon-login:before {
    content: "\1F6AA";
}
/* doorway */

.icon-star:before {
    content: "\22C6";
}
/* star */

.icon-rows:before {
    content: "\E9A1";
}
/* rows -- must use PIKA icons */

.icon-info:before {
    content: "\2139";
}
/* info i in circle */

.icon-inbox:before {
    content: "\1F4E5";
}
/* inbox */

.icon-trophy:before {
    content: "\1F3C6";
}
/* trophy */

.icon-autosync:before {
    content: "\2940";
}
/* backup clock */

.needs-sync .sync-status .status-icon:before {
    content: "\21BB";
    font-family: $alternateIconFontFamily;
}

//Settings icons
.icon-general-settings:before {
    content: "\1F30E";
}
/* globe */

.icon-packing-list:before {
    content: "\EC00";
}
/* packing list */

.icon-packing-list-preview:before {
    content: "\EC05";
    top: 2px !important;
}
/* packing list with magnifying glass */

.icon-packing-list-preview.icon.lg {
    font-size: 60px !important;
    line-height: 1.15;
}

.icon-sales-channels:before {
    content: "\E500";
}
/* cart */

.icon-users:before {
    content: "\1F465";
}
/* users */

.icon-customers:before {
    content: "\E405";
}
/* customers */

.icon-top-sellers:before {
    content: "\1F3C6";
}
/* customers */

.icon-warehouses:before {
    content: "\E602";
}
/* warehouse */

.icon-suppliers:before {
    content: "\E5D8";
}
/* crate */

.icon-shippers:before {
    content: "\2708";
}

.icon-rules:before {
    content: "\E203";
}
/* magic wand */

.icon-billing:before {
    content: "\1F4B3";
}
/* credit card */

.icon-payments:before {
    content: "\E530";
}
/* credit card */


.icon-accounting:before {
    content: "\1F4B0";
}

.icon-postage:before {
    content: "\0024";
}

.icon-dataimport:before {
    content: "\1F308";
}

.icon-edit:before {
    content: "\270E";
}
/* pencil */

.icon-add:before, .all-collapsed .icon-expand-all:before {
    content: "\002B";
}
/* plus sign in a circle */

.icon-subtract:before, .all-expanded .icon-expand-all:before {
    content: "\002D";
}
/* minus sign in a circle */

.icon-upload:before {
    content: "\EB40";
}
/* upload to the cloud! */

.icon-tools:before {
    content: "\F031";
}
/* screwdriver and wrench */

.icon-history:before {
    content: "\2940";
}
/* counterclockwise clock */


//Order details icons
.icon-order-details:before {
    content: "\EC05";
}
/* packing list with magnifying glass */

.icon-order-address-info:before {
    content: "\1F4D1";
}
/* address book */

.icon-order-timeline:before {
    content: "\23F2";
}
/* clock */

.icon-sad-face:before {
    content: "\2639";
}
/* sad face */


.icon-presets:before {
    content: "\1F39A";
}

.icon-3PL:before {
    content: "\EB43";
}

.icon-scale:before {
    content: "\2696";
}

.icon-prefix {
    font-family: $alternateIconFontFamily;
}

//Misc icon shit
.utility-nav-toggle, .utility-nav-button {
    .icon {
        color: #aaa;
        position: relative;
        @include transition-all-standard;
    }
}
