//Print stylesheet stuff from html5 boilerplate. We can probably clean this up later, but it's not hurting anything right now.

@media print {
    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
    }
    a, a:visited {
        color: #444 !important;
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
        content: "";
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    
    tr, img {
        page-break-inside: avoid;
    }
    @page {
        margin: 0.5cm;
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
    //Packing List Overrides
    .page-break {
        page-break-after: always !important;
        width: 100%;
    }
    .packing-list-content {
        border: 0;
        padding-top: 0;
    }
    .packing-list-dl.make-smaller {
        dt, dd {
            font-size: 8pt;
        }
    }
    .packing-list-options {
        display: none;
    }
    .lead.pick-list-lead {
        padding-top: 0;
        border-top: 0;
    }
    .packing-list-table {
        thead, .thead {
            background: $light-grey !important;
        }
        th {
            border: 1px solid #000;
            padding: .25em .75em;
            line-height: 1.3;
            &:first-child {
                border: 1px solid #000;
            }
        }
        td {
            color: $medium-grey;
        }
    }
}
