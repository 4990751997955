@media screen and (min-width: 0\0) {
    select::-ms-expand {
        display: block;
        height: 1em;
        width: 1em;
        margin-right: .15em;
    }
    .select {
        height: auto;
        padding: .1em;
    }
    .pagination-data .number-per-page-wrap .select {
        margin-top: rem-calc(0);
    }
    .select.bigger-select, .select.full-width {
        padding: .1em;
    }
    .select.warehouse-select {
        height: 1.5em;
    }
}
