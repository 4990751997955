.list--pricing-summary {
  border-radius: rem-calc(5);
  background: #fff;
  color: #444;
  border: 3px solid transparent;

  .i-check {
    color: #d4ac60;
  }

  .btn--disabled .i-check,
  button[disabled] .i-check {
    color: #666;
  }

}

.list--pricing-summary-starter {
  border-color: darken($ordoro-green, 5%);
}

.list--pricing-summary-express {
  border-color: darken($ordoro-blue, 5%);
}

.list--pricing-summary-pro {
  border-color: darken(#444, 5%);
}

.list--pricing-summary-enterprise {
  border-color: darken(#bf9b30, 5%);
}

.list--pricing-summary.plan-selected {
  border: 5px solid #222;
  box-shadow: 0 rem-calc(10) rem-calc(10) rgba(0, 0, 0, .2);
  margin-top: -.5rem;
}

.list__title--pricing-summary {
  padding: .5rem .75rem;
  margin-bottom: 0;

  .header--pricing-summary {
    font-weight: 700;
    font-style: normal;
  }
}

.list__title--pricing-summary-starter {
  background: $ordoro-green;
  color: #fff;
}

.list__title--pricing-summary-express {
  background: $ordoro-blue;
  color: #fff;
}

.list__title--pricing-summary-pro {
  background: #444;
  color: #fff;
}

.list__title--pricing-summary-enterprise {
  background: #bf9b30;
  color: #fff;
}


.list__item--pricing-summary {
  padding: 0 .75rem;
  margin: .5rem 0 0 0;

  &:first-of-type {
    margin: 0.75rem .75rem 1rem .75rem;
    padding-bottom: .75rem;
    border-bottom: 1px solid #ddd;

  }

  &:last-of-type {
    margin-top: .5rem;
  }
}

// 2024 new pricing shit

.wrap--app-pricing-summary {
  border-radius: rem-calc(5);
  border: 4px solid #fff;
  background-color: transparent;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  width: 100%;
}

.label__callout--app {
  padding: 0 .35rem;
}

.wrap--app-pricing-summary-shipping {
  // border-color: #6EB439;
  border-color: #9bd085;

  &:not(.wrap--app-resource) {

    a {
      color: #9bd085;

      &:hover {
        color: #fff;
      }

    }
  }

  .label__callout--app {
    color: #fff;
    background-color: #6EB439;
  }

}

.label__callout--shipping-app {
  color: #fff;
  background-color: #6EB439;
}

.wrap--app-pricing-summary-inv {
  // border-color: #2e91ba;
  border-color: #78c2e0;

  &:not(.wrap--app-resource) {

    a {
      color: #78c2e0;

      &:hover {
        color: #fff;
      }

    }
  }

  .label__callout--app {
    color: #fff;
    background-color: #2e91ba;
  }

}

.wrap--app-pricing-summary-dropshipping {
  // border-color: #6e4ea0;
  border-color: #9b87bf;

  &:not(.wrap--app-resource) {

    a {
      color: #9b87bf;

      &:hover {
        color: #fff;
      }
    }
  }

  .label__callout--app {
    color: #fff;
    background-color: #6e4ea0;
  }
}

.wrap--app-pricing-summary-dropshipping .list__item--app-pricing-callout-icon {
  position: absolute;
  top: rem-calc(-28);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 2;

  .icon--app {
    width: rem-calc(60);
    height: rem-calc(52);
  }

}

// App Icons

.wrap--app-icon {
  .icon--app {
    width: rem-calc(60);
    height: rem-calc(52);
  }
}

.wrap--app-icon-pricing {
  margin: rem-calc(-67) auto 1.25rem auto;
  text-align: center;
}

.icon__hexagon--app,
.icon__name--app {
  fill: #fff;
}

.icon--app-shipping {

  .icon__hexagon--app,
  .icon__name--app {
    fill: #6EB439;
  }

  .icon__bg-fill--app {
    fill: #fff;
  }
}

.icon--app-inventory {

  .icon__hexagon--app,
  .icon__name--app {
    fill: #2e91ba;
  }

  .icon__bg-fill--app {
    fill: #fff;
  }
}

.icon--app-dropshipping {

  .icon__hexagon--app,
  .icon__name--app {
    fill: #6e4ea0;
  }

  .icon__bg-fill--app {
    fill: #fff;
  }
}

.wrap--app-pricing-summary-disabled {
  border: 2px dashed #ccc;

  .list__title {
    color: #666;
  }

  .icon--app-shipping {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #ccc;
    }

    .icon__bg-fill--app {
      fill: #fff;
    }
  }

  .icon--app-inventory {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #ccc;
    }

    .icon__bg-fill--app {
      fill: #fff;
    }
  }

  .icon--app-dropshipping {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #ccc;
    }

    .icon__bg-fill--app {
      fill: #fff;
    }
  }

  &.wrap--app-pricing-summary-shipping .btn--primary-ol {
    border-color: #6EB439;
    color: darken(#6EB439, 5%);
  }

  &.wrap--app-pricing-summary-inv .btn--primary-ol {
    border-color: #2e91ba;
    color: #2e91ba;
  }

  &.wrap--app-pricing-summary-dropshipping .btn--primary-ol {
    border-color: #6e4ea0;
    color: #6e4ea0;
  }

}

// 2024 Welcome Tab Shit

.wrap--app-resource {
  padding: 1.65rem;

  &:hover {
    background: #f7f7f7;
  }

  .wrap--app-icon-pricing {
    margin: -3.45rem auto .55rem auto;
  }

}

.wrap--pricing-summary {
  .list--pricing-summary {
    border-color: #bbb;
  }
}

.wrap--pricing-summary-shipping {
  .plan-selected {
    border: 6px solid #111;
  }
}

.wrap--pricing-summary-inv {
  .plan-selected {
    border: 6px solid #111;
  }
}

.wrap--pricing-summary-dropshipping {
  .plan-selected {
    border: 6px solid #111;
  }
}

.list__title--pricing-summary-shipping-essential {
  background: #DFEAD2;
  color: rgba(0, 0, 0, .8);
  @include text-shadow-white;
}

.list__title--pricing-summary-shipping-advanced {
  background: #A6CB88;
  color: rgba(0, 0, 0, .8);
  @include text-shadow-white;
}

.list__title--pricing-summary-shipping-premium {
  background: #6DB444;
  color: rgba(0, 0, 0, .9);
  @include text-shadow-white;
}

.list__title--pricing-summary-inventory-advanced {
  background: #8abedf;
  color: rgba(0, 0, 0, .8);
  @include text-shadow-white;
}

.list__title--pricing-summary-inventory-premium {
  background: #34a0cd;
  color: rgba(0, 0, 0, .9);
  @include text-shadow-white;
}

.list__title--pricing-summary-dropshipping-advanced {
  background: #9b87bf;
  color: rgba(0, 0, 0, .9);
}

.list__title--pricing-summary-dropshipping-premium {
  background: #6e4ea0;
  color: #fff;
  @include text-shadow-standard;
}

// BILLING PAGE 2024

.wrap--billing-app-callout {

  align-items: center;

  .wrap--app-icon {
    .icon--app {
      width: rem-calc(30);
      height: rem-calc(26);
    }
  }
}

.wrap--billing-app-shipping-callout {
  color: darken(#6EB439, 5%);
}

.wrap--billing-app-inv-callout {
  color: #2e91ba;
}

.wrap--billing-app-dropshipping-callout {
  color: #6e4ea0;
}