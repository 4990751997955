//
// Rules
// ------

.list--rule-group {
  margin-left: 0;
  margin-bottom: 1.25rem;

  &:first-of-type {
    margin-top: .5rem;
  }
}

.list__title--rule-condition {
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  text-align: right;
  font-family: $font-family;
  font-weight: normal;
  color: $medium-grey;
  vertical-align: top;
  display: inline-block;
  line-height: 1.3;
  width: 9%;
  margin-right: 3%;

  &.read-only {
    padding-bottom: 0;
    width: 3rem;
    margin-right: 1rem;
  }
}

.list__item--rule-criteria {
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  border-bottom: 1px dotted $light-grey;
  display: inline-block;
  vertical-align: top;
  line-height: 1.3;
  width: 88%;

  .select,
  .input[type="text"] {
    margin-right: .25em;
  }

  &.criteria--if {
    padding-bottom: .65rem;
  }
}

.wrap--rule-buttons {
  padding-left: 12%;
  margin-left: 0;
}

.list__item--multiple-subhead {
  border-bottom: 1px dotted #ddd;
  display: inline-block;
  vertical-align: top;
}

/* FF select sadness... Need to move this somewhere better */
@-moz-document url-prefix() {
  .list__item--rule-criteria .select {
    height: auto;
  }
}

.wrap--rule-statement {
  @include transition-all-standard;
  line-height: 1.2;
  opacity: .55;
  font-size: rem-calc(14);
  margin-bottom: 0;
  vertical-align: bottom;

  &.on {
    opacity: 1;
  }

  .tag-name.v-align-middle {
    vertical-align: initial;
  }
}

.rule-criteria {
  background: $lighter-grey;
  padding: .25rem .35rem;
  margin-left: .15rem;
  @include text-shadow-white;
  color: #111;
  vertical-align: bottom;
}

.wrap--rules-tag-dropdown {
  .btn--dropdown span:not(.error-message) {
    color: #111;
    font-family: $font-family-headings;

    &:hover {
      color: #999;
    }
  }
}

.wrap--rules-preset-dropdown {

  .wrap--dropdown-presets {
    text-align: left;
    margin-right: 0;
  }

  .wrap--inner-dropdown-right .list--dropdown {
    left: -.35rem;
    right: auto;
  }

  .wrap--inner-dropdown-right:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    right: auto;
    left: .75rem;
    top: -.15rem;
  }

  .btn--dropdown {
    font-size: rem-calc(14);

    &:not(.error-message) {
      color: #111;
      font-family: $font-family-headings;

      &:hover {
        color: #999;
      }
    }
  }
}

.wrap--rule {
  padding: 1.25rem 0;
}

.drag-handle {
  padding-right: 0;
  width: 2.5rem;
  cursor: move;
  text-align: center;
  opacity: 0;
  -webkit-font-smoothing: antialiased;

  .i-drag-handle,
  .i-three-bars {
    opacity: .25;
  }

  &:hover {

    .i-drag-handle,
    .i-three-bars {
      opacity: .4;
    }
  }
}

.draggable {
  background: #fff;
  @include transition-all-standard;

  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
}

.dragging {
  opacity: 0;
}

.wrap--modal-draggable-items {

  .drag-handle {
    opacity: .75;
  }
}

.wrap--auto-dropshipping-rule {
  padding: 1.5rem 2rem;
  margin-bottom: 1.5rem;
  border: 3px solid #eee;
  background: #fff;

  p {
    color: #aaa;
  }


  &.enabled {
    border: 3px solid lighten($ordoro-blue, 5%);
    background: lighten($ordoro-blue, 46%);

    p {
      color: #444;
    }
  }

}

.wrap--rule-notes {
  margin: 0 0 1.5rem .5rem;
  border: 1px dashed #bbb;
  padding: .85rem;

  &.editing {
    border-color: transparent;
    padding-top: 0;
  }

}