//CUSTOM MIXINS
@mixin bg-none {
  background: none;
}

.no-bg {
  @include bg-none;
}

//MARGINS AND PADDING
@mixin custom-margin($side, $size) {
  .margin-#{$side}-#{$size} {
    margin-#{$side}: $size * 1px !important;
  }

  @media #{$large} {
    .margin-#{$side}-#{$size} {
      margin-#{$side}: $size * 1px + 2px !important;
    }
  }
}

@include custom-margin(top, 0);

@include custom-margin(top, 1);

@include custom-margin(top, 2);

@include custom-margin(top, 3);

@include custom-margin(top, 4);

@include custom-margin(top, 5);

@include custom-margin(top, 7);

@include custom-margin(top, 10);

@include custom-margin(top, 15);

@include custom-margin(top, 20);

@include custom-margin(top, 25);

@include custom-margin(top, 30);

@include custom-margin(top, 40);

@include custom-margin(top, 43);

@include custom-margin(top, 45);

@include custom-margin(top, 50);

@include custom-margin(bottom, 0);

@include custom-margin(bottom, 1);

@include custom-margin(bottom, 3);

@include custom-margin(bottom, 5);

@include custom-margin(bottom, 7);

@include custom-margin(bottom, 10);

@include custom-margin(bottom, 15);

@include custom-margin(bottom, 20);

@include custom-margin(bottom, 25);

@include custom-margin(bottom, 30);

@include custom-margin(bottom, 40);

@include custom-margin(bottom, 50);

@include custom-margin(left, 0);

@include custom-margin(left, 1);

@include custom-margin(left, 2);

@include custom-margin(left, 3);

@include custom-margin(left, 4);

@include custom-margin(left, 5);

@include custom-margin(left, 7);

@include custom-margin(left, 10);

@include custom-margin(left, 15);

@include custom-margin(left, 20);

@include custom-margin(left, 25);

@include custom-margin(left, 30);

@include custom-margin(right, 0);

@include custom-margin(right, 1);

@include custom-margin(right, 2);

@include custom-margin(right, 3);

@include custom-margin(right, 4);

@include custom-margin(right, 5);

@include custom-margin(right, 7);

@include custom-margin(right, 10);

@include custom-margin(right, 15);

@include custom-margin(right, 20);

@include custom-margin(right, 25);

@include custom-margin(right, 30);

@mixin custom-padding($side, $size) {
  .padding-#{$side}-#{$size} {
    padding-#{$side}: $size * 1px !important;
  }

  @media #{$large} {
    .padding-#{$side}-#{$size} {
      padding-#{$side}: $size * 1px + 2px !important;
    }
  }
}

@include custom-padding(top, 0);

@include custom-padding(top, 5);

@include custom-padding(top, 10);

@include custom-padding(top, 15);

@include custom-padding(top, 20);

@include custom-padding(top, 25);

@include custom-padding(top, 30);

@include custom-padding(top, 35);

@include custom-padding(top, 40);

@include custom-padding(top, 45);

@include custom-padding(bottom, 0);

@include custom-padding(bottom, 10);

@include custom-padding(bottom, 15);

@include custom-padding(bottom, 20);

@include custom-padding(bottom, 25);

@include custom-padding(bottom, 30);

@include custom-padding(bottom, 35);

@include custom-padding(bottom, 40);

@include custom-padding(bottom, 45);

@include custom-padding(left, 0);

@include custom-padding(left, 3);

@include custom-padding(left, 5);

@include custom-padding(left, 7);

@include custom-padding(left, 10);

@include custom-padding(left, 15);

@include custom-padding(left, 20);
@include custom-padding(left, 25);
@include custom-padding(left, 30);

@include custom-padding(right, 0);

@include custom-padding(right, 3);

@include custom-padding(right, 5);

@include custom-padding(right, 7);

@include custom-padding(right, 10);

@include custom-padding(right, 15);

@include custom-padding(right, 20);

@include custom-padding(right, 25);

//TYPOGRAPHY MIXINS
@mixin main-nav-font {
  font-family: $Avant-Garde-Demi;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
}

@mixin primary-button-font {
  @include main-nav-font;
}

@mixin text-shadow-standard {
  text-shadow: rgba(0, 0, 0, 0.3) 0 1px 1px;
}

@mixin text-shadow-white {
  text-shadow: rgba(255, 255, 255, 0.7) 0 1px 1px;
}

@mixin text-shadow-reset {
  text-shadow: rgba(0, 0, 0, 0) 0 0 0;
}

//TRANSITION MIXINS
@mixin transition-all-fast {
  @include transition(all 0.1s ease-out 0s);
}

@mixin transition-all-faster {
  @include transition(all 0.2s ease-in-out 0s);
}

@mixin transition-all-standard {
  @include transition(all 0.3s ease-out 0s);
}

@mixin transition-all-slower {
  @include transition(all 0.6s ease-out 0s);
}

//TRIANGLES
@mixin triangle-pointing-up($border-width, $color) {
  border-bottom: $border-width solid $color;
  border-left: $border-width solid transparent;
  border-right: $border-width solid transparent;
  content: "&#9652;";
  text-indent: -9999px;
  position: absolute;
  font-size: 1em;
}

@mixin triangle-pointing-down($border-width, $color) {
  border-top: $border-width solid $color;
  border-left: $border-width solid transparent;
  border-right: $border-width solid transparent;
  content: "&#9652;";
  text-indent: -9999px;
  position: absolute;
  font-size: 1em;
}

@mixin triangle-pointing-left($border-width, $color) {
  border-right: $border-width solid $color;
  border-top: $border-width solid transparent;
  border-bottom: $border-width solid transparent;
  content: "&#9652;";
  text-indent: -9999px;
  position: absolute;
  font-size: 1em;
}

@mixin triangle-pointing-right($border-width, $color) {
  border-left: $border-width solid $color;
  border-top: $border-width solid transparent;
  border-bottom: $border-width solid transparent;
  content: "&#9652;";
  text-indent: -9999px;
  position: absolute;
  font-size: 1em;
}

@mixin dropdown-triangle {
  @include transition-all-standard;
  position: static;
  display: inline-block;
  vertical-align: top;
}

// WHERE-ARE-YOU MIXINS
@mixin main-nav-where-are-you($class) {

  .#{$class} .main-nav .nav-bar>.#{$class}>a,
  .#{$class} .main-nav .nav-bar>.#{$class}>a:hover {
    background: $black15;
    color: #fffbda;
    cursor: default;
  }

  .ie8 {

    .#{$class} .main-nav .nav-bar>.#{$class}>a,
    .#{$class} .main-nav .nav-bar>.#{$class}>a:hover {
      background: #4f7d2f;
      color: #fffbda;
      cursor: default;
    }
  }

  .#{$class} .main-nav .nav-bar>.#{$class}>a .nav-icon,
  .#{$class} .main-nav .nav-bar>.#{$class}>a:hover .nav-icon {
    @include opacity(0.6);
  }
}

@mixin subnav-where-are-you($class) {

  .#{$class} dl.sub-nav dd.#{$class} a,
  .#{$class} dl.sub-nav dd.#{$class} a:hover {
    color: $mainColor;
    background: none;
    font-family: $mainNavFontFamily;
    font-weight: normal;
  }

  .#{$class} dl.sub-nav dd.#{$class} a:after {
    position: absolute;
    bottom: 0;
    left: 45%;
    font-size: ms(1);
    font-family: $iconFontFamily;
    color: $mainColor;
    content: "&#9652;";
    text-indent: -9999px;
    @include triangle-pointing-up(6px, $mainColor);
  }

  @media only screen and (max-width: 825px) {
    .#{$class} dl.sub-nav dd.#{$class} a:after {
      display: none;
    }
  }
}

//CLEARING
@mixin clear($type) {
  .clear-#{$type} {
    clear: $type;
  }
}

@include clear(left);

@include clear(right);

@include clear(both);

@include clear(none);

//ANIMATIONS

@include keyframes(preloader-spin) {
  0% {
    @include transform(rotate(0deg));
  }

  100% {
    @include transform(rotate(360deg));
  }
}


@include keyframes(preloader-grow) {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}


@include keyframes(preloader-bg-dark) {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  50% {
    background-color: rgba(0, 0, 0, 0.1);
  }

  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}


@mixin spinner($border-width, $rgb-color, $opacity, $size) {
  border: $border-width solid rgba($rgb-color, 0.6);
  border-left: $border-width solid rgba($rgb-color, 1);
  opacity: $opacity;
  width: $size;
  height: $size;
  border-radius: 100%;
  top: 0;
  vertical-align: middle;
  @include animation(preloader-spin 0.55s linear infinite);
}

@include keyframes(candystripe) {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1.5em 1.5em;
  }
}

@mixin candystriper {
  @include animation(candystripe 2s linear infinite);
}


@mixin grower($color, $size) {
  width: $size;
  height: $size;
  background: $color;
  @include animation(preloader-grow 0.55s ease-out infinite);
}

@mixin floater {
  @include animation(floater 1.25s infinite);
}

@include keyframes(floater) {
  0% {
    @include transform(translateY(0%));
  }

  50% {
    @include transform(translateY(-10%));
  }

  0% {
    @include transform(translateY(0%));
  }
}

@mixin pulse {
  @include animation(pulse 1.5s infinite);
}

@include keyframes(pulse) {
  0% {
    background: transparent;
    opacity: .2;
  }

  50% {
    background: transparent;
    opacity: .4;
  }

  100% {
    background: transparent;
    opacity: .2;
  }
}

.pulse {
  @include pulse;
}

//BOX SHADOWS
@mixin popover-shadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0 3px 7px;
}

@mixin img-shadow-big {
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
}

@mixin button-submitting {
  box-shadow: rgba(0, 0, 0, 0) 0 1px 8px, inset rgba(0, 0, 0, 0.5) 0 0 15px;
}

@mixin dropdown-shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px;
}

@mixin tag-color-shadow-hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 8px, inset rgba(0, 0, 0, 0.1) 0 2px 25px;
}

@mixin tag-color-shadow-selected {
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 8px, inset rgba(0, 0, 0, 0.4) 0 2px 25px;
}

@mixin shadow-reset {
  box-shadow: rgba(0, 0, 0, 0) 0 0 0;
}