//Dropzone styles

.dropzone {
    padding: rem-calc(70) rem-calc(34);
    border: 2px dashed $light-grey;
    border-radius: .5em;
    @include transition-all-standard;
    .icon {
        @include transition-all-standard;
    }
    .dropzone-header {
        margin-top: .75em;
    }
    .icon:before {
        line-height: .8;
        opacity: .5;
    }
    &.uploading {
        background: $lightest-grey;
        border: 2px solid $light-grey;
        .dz-text {
            display: none;
        }
        .dropzone-header {
            margin-top: 0;
        }
    }
    &.error {
        color: $error-color;
        background: $error-bg-color;
        border: 2px dashed $error-color;
        .icon:before {
            content: "\2639";
            color: $error-color;
        }
        .dz-text {
            display: none;
        }
    }
    &.drag-hover {
        border: 2px solid $ordoro-blue;
        background: lighten(#34a0cd, 45%);
        color: $body-font-color;
        .icon {
            @include floater;
            color: $ordoro-blue;
        }
        .icon:before {
            content: "\1F600";
            opacity: 1;
        }
    }
}
