//
// Buttons
// ---------

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  overflow: visible;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: $transition-duration;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:active {}
}

.btn--primary,
.btn--primary:focus {
  background-color: getColor(base, primary);
  font-size: rem-calc(17);
  padding: .5em 2em;
  color: #fff;
  border-radius: rem-calc(4);
  border: 2px solid darken(getColor(base, primary), 12%);
  font-family: $font-family-headings;
  line-height: 1.35;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;

  &.fs-01 {
    font-size: rem-calc(17);
  }

  &:hover {
    color: lighten(yellow, 5%);
    border: 2px solid darken(getColor(base, primary), 14%);
    background-color: darken(getColor(base, primary), 5%);
  }
}

.btn--primary:focus {
  color: lighten(yellow, 5%);
  border: 2px solid darken(getColor(base, primary), 20%);
  background-color: darken(getColor(base, primary), 5%);
}

.btn--primary-w-dropdown,
.btn--primary-w-dropdown:focus,
.btn--primary-w-dropdown:hover,
.btn--primary-w-dropdown:focus:hover {
  border-radius: rem-calc(4) 0 0 rem-calc(4);
}

.btn--primary-dropdown,
.btn--primary-dropdown:focus {
  border-radius: 0 rem-calc(4) rem-calc(4) 0;
  border-left: 0;

  &:after {
    @include arrow(bottom, #fff, .35em);
    content: "";
    display: inline-block;
    vertical-align: middle;
  }

  &[disabled],
  &.btn--disabled,
  &[disabled]:hover,
  &.btn--disabled:hover {
    &:after {
      @include arrow(bottom, #bbb, .35em);
      content: "";
      display: inline-block;
      vertical-align: middle;
    }
  }

  &:hover,
  &:focus {
    color: lighten(yellow, 5%);
    border-left: 0;

    &:after {
      @include arrow(bottom, lighten(yellow, 5%), .35em);
      content: "";
      display: inline-block;
    }
  }
}

.btn--primary-alt,
.btn--primary-alt:focus {
  background: $ordoro-blue;
  border-color: #2a8db7;
}

.btn--primary-alt-grey,
.btn--primary-alt-grey:focus {
  background: #999;
  border-color: #666;
}

.btn--primary-alt-purp,
.btn--primary-alt-purp:focus {
  border-color: darken(#6e4ea0, 5%);
  background: #6e4ea0;
}

.btn--primary-alt-green,
.btn--primary-alt-green:focus {
  border-color: darken(#6EB439, 5%);
  background: #6EB439;
}


.btn--primary-ol,
.btn--primary-ol:focus {
  background: #fff;
  border-color: #999;
  color: #666;
}

.btn--primary-alt:hover,
.btn--primary-alt:focus:hover {
  background-color: darken($ordoro-blue, 5%);
  border-color: darken(#2a8db7, 5%);
  color: #fffbda;
}

.btn--primary-alt-grey:hover,
.btn--primary-alt-grey:focus:hover {
  background-color: darken(#999, 5%);
  border-color: darken(#666, 5%);
  color: #fffbda;
}

.btn--primary-alt-purp:hover,
.btn--primary-alt-purp:focus:hover {
  color: #fff;
  border-color: darken(#6e4ea0, 10%);
  background: darken(#6e4ea0, 6%);
}

.btn--primary-alt-green:hover,
.btn--primary-alt-green:focus:hover {
  color: #fff;
  border-color: darken(#6EB439, 10%);
  background: darken(#6EB439, 6%);
}

.btn--primary-ol:hover,
.btn--primary-ol:focus:hover {
  background: #f7f7f7;
  color: #444;
  border-color: #666;
}

.btn--primary-alt-ol,
.btn--primary-alt-ol:focus {
  background: transparent;
  border-color: #34a0cd;
  color: #fff;
}

.btn--primary-alt-ol:hover,
.btn--primary-alt-ol:focus:hover {
  background-color: #111;
  border-color: #d4ac60;
  color: #fff;
}

.btn--primary-warning-ol,
.btn--primary-warning-ol:focus {
  border-color: $warning-border-color;
  color: $warning-txt-color;
  background: transparent;
}

.btn--primary-warning-ol:hover,
.btn--primary-warning-ol:hover:focus {
  border-color: darken($warning-border-color, 5%);
  color: $warning-txt-color;
  background: transparent;
}



.btn--disabled,
.btn--disabled:hover,
.btn--disabled:focus,
.btn--disabled:focus:hover {
  background: #eee;
  border-color: #999;
  color: #bbb;
  opacity: .5;
  cursor: default;

  &.btn--disabled-warning {
    border-color: $warning-txt-color;
  }

  &.btn--disabled-error {
    border-color: #ff0000;
  }
}

.btn--selected,
.btn--selected:hover,
.btn--selected:focus,
.btn--selected:focus:hover {
  background: transparent;
  border-color: transparent;
  color: $ordoro-blue;
  cursor: default;
}

.btn__text--selected,
.btn--selected .btn__text--not-selected {
  display: none;
}

.btn__text--not-selected,
.btn--selected .btn__text--selected {
  display: inline-block;
}

.btn--secondary,
.btn--secondary:focus {
  font-size: rem-calc(17);
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  padding: .5rem 1rem;
  color: #666;
  line-height: 1.35;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;

  &:hover {
    background-color: transparent;
    color: #999;
    text-decoration: underline;
  }
}

// THANKS QUICKBOOKS

.btn--qb,
.btn--qb:focus {
  border: 0;
  padding: 0;
  background: url(https://storage.googleapis.com/ordoro-pappy-assets/public/qb-btn-default.svg) transparent no-repeat;
  text-indent: -9999px;
  width: 223px;
  height: 40px;

  &:hover,
  &:hover:focus {
    border: 0;
    padding: 0;
    background: url(https://storage.googleapis.com/ordoro-pappy-assets/public/qb-btn-hover.svg) transparent no-repeat;
  }
}



.btn--md,
.btn--md:focus {
  padding: .35rem .75rem;

  &.btn--secondary {
    padding: .35rem .65rem;
  }

  &.btn--primary-dropdown {
    padding: .35rem;
  }
}

.btn--xx-sm,
.btn--xx-sm:focus {
  font-size: rem-calc(11);
  padding: .1rem .25rem;

  &.btn--secondary {
    padding: .1rem .2rem;
  }
}


.btn--x-sm,
.btn--x-sm:focus {
  font-size: rem-calc(12);
  padding: .1rem .35rem;

  &.btn--secondary {
    padding: .1rem .2rem;
  }

  &.lh-sm {
    line-height: 1;
  }

}

.btn--sm,
.btn--sm:focus {
  font-size: rem-calc(13);
  padding: .25rem .85rem;

  &.btn--secondary {
    padding: .25rem .5rem;
  }

  &.fs-00,
  .fs-00:focus {
    font-size: rem-calc(14);
  }


}

.btn--std,
.btn--std:focus {
  padding: .5em 2em;
}

.btn--x-md,
.btn--x-md:focus {
  padding: .75rem .5rem;
  line-height: 1;
}

.btn--lg,
.btn--lg:focus {
  font-size: rem-calc(23);
  padding: .5rem 3rem;
}

.btn--xl,
.btn--xl:focus {
  font-size: rem-calc(23);
  padding: 1rem 3rem;
}

.btn--xxl,
.btn--xxl:focus {
  font-size: rem-calc(27);
  padding: 1.15rem 3.15rem;

}

.btn--xxxl,
.btn--xxxl:focus {
  font-size: rem-calc(37);
  padding: 1rem 1.5rem;
  border-width: 3px;
}

.btn--primary-demo,
.btn--primary-demo:focus {
  background-color: yellow;
  padding: .25rem .55rem;
  color: #355a24;
  border-color: #355a24;
  border-radius: 0 rem-calc(3) rem-calc(3) 0;
}

.btn--primary-demo:hover,
.btn--primary-demo:focus:hover {
  background-color: darken(yellow, 3%);
  padding: .25rem .55rem;
  color: darken(#355a24, 7%);
  border-color: #355a24;
}

.btn--secondary:only-child,
.btn--secondary:focus:only-child {
  padding-left: 0;
}

.list__item--no-api-key .btn--secondary {
  display: none;
}

.btn--refresh,
.btn--refresh:focus {
  padding: 0 .5rem;
  height: auto;

  .i-refresh {
    font-size: rem-calc(18);
    line-height: 1;
    vertical-align: middle;
    display: inline-block;
  }
}

.btn--multibox,
.btn--multibox:focus {
  margin-left: rem-calc(3);
  padding: 0 .35rem;
  height: auto;
  line-height: 0;
  font-family: $font-family;
}

.btn--multibox-rs,
.btn--multibox-rs:focus {
  margin-left: rem-calc(7);
  line-height: 1;
}

.btn--processing,
.btn--processing:hover,
.btn--processing:focus,
.btn--processing:focus:hover,
.btn--processing:disabled,
.btn--processing:hover:disabled {
  border-color: $medium-dark-grey;
  background: $medium-grey;
  color: $medium-grey;
  cursor: default;
  position: relative;
  opacity: 1;

  &:before {
    @include spinner(0.15rem, #fff, 0.85, 1rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }

  &.btn--nav-sync:before {
    @include spinner(0.15rem, #fff, 0.85, 0.85rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }
}

.btn--processing-xxl,
.btn--processing-xxl:hover,
.btn--processing-xxl:focus,
.btn--processing-xxl:focus:hover,
.btn--processing-xxl:disabled,
.btn--processing-xxl:hover:disabled {
  border-color: $medium-dark-grey;
  background: $medium-grey;
  color: $medium-grey;
  cursor: default;
  position: relative;
  opacity: 1;

  &:before {
    @include spinner(0.3rem, #fff, 0.85, 2.25rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }
}

.btn--progress,
.btn--progress:hover,
.btn--progress:focus,
.btn--progress:focus:hover,
.btn--progress:disabled,
.btn--progress:hover:disabled {
  color: #fff;
  border-color: $medium-dark-grey;
  background: $medium-grey;
  cursor: default;
  position: relative;
  padding-left: 2rem;
  opacity: 1;

  .btn--text {
    opacity: .85;
  }

  &:before {
    @include spinner(0.15rem, #fff, 0.85, 1rem);
    @include animate-spin;
    position: absolute;
    top: 0;
    bottom: 0;
    left: .65rem;
    margin: auto 0;
    content: "";
  }

}

.btn--dropdown,
.btn--section-toggle {
  font-size: rem-calc(14);
  color: getColor(gray, base);

  &:after {
    @include arrow(bottom, getColor(gray, base), .35em);
    content: "";
    display: inline-block;
    margin-left: rem-calc(3);
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: #999;

    &:after {
      @include arrow(bottom, #999, .35em);
      content: "";
      display: inline-block;
    }
  }
}

.btn--dropdown-darker {
  font-size: rem-calc(14);
  color: #444;

  &:after {
    @include arrow(bottom, #444, .35em);
    content: "";
    display: inline-block;
    margin-left: rem-calc(3);
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: #999;

    &:after {
      @include arrow(bottom, #999, .35em);
      content: "";
      display: inline-block;
    }
  }
}

.btn--ko-dropdown {
  font-size: rem-calc(14);
  color: #fff;
  font-family: $font-family;
  opacity: .9;

  &:after {
    @include arrow(bottom, #fff, .35em);
    content: "";
    display: inline-block;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }

  .plan {
    border-color: lighten(getColor(green, base), 30%);
    color: lighten(getColor(green, base), 30%);
  }
}

.btn__text--company {
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.btn--main-search {
  border-radius: 0 rem-calc(4) rem-calc(4) 0;
  border: 1px solid #999;
  border-left: 0;
  color: #fff;
  background: #bbb;
  padding: 0 .75rem;

  &:hover,
  &:focus {
    background: #999;
    color: #fff;
  }

  &:focus {
    border-color: #777;
  }
}

.input--main-search:focus+.btn--main-search {
  border-color: #666;
}

.btn--filter,
.btn--presets {
  font-size: rem-calc(13);
  vertical-align: baseline;

  &:after {
    vertical-align: middle;
  }

  &.btn--dropdown.error-message {
    color: $error-color;
  }
}

.btn--filter-w-border {
  border: 1px solid #666;
  border-radius: .25rem;
  padding: .5rem;
  text-align: left;

  &:after {
    position: absolute;
    right: .5rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }

}

.btn--expand-row {
  font-size: rem-calc(17);
  line-height: 0;
  margin-left: rem-calc(2);
  vertical-align: baseline;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: .25rem;
  right: .25rem;
  @include transition-all-faster;

  &:after {
    vertical-align: middle;
  }
}

.table--order-summary:hover {
  .btn--expand-row {
    opacity: 1;
    visibility: visible;
  }
}

.btn--expand-product-row {
  font-size: rem-calc(17);
  line-height: 0;
  vertical-align: baseline;
  opacity: 0;
  visibility: hidden;
  @include transition-all-faster;

  &:focus {
    line-height: 0;
  }

  &:after {
    vertical-align: middle;
  }
}

.table--zebra-list .table__tr:hover {
  .btn--expand-product-row {
    opacity: 1;
    line-height: 0;
    visibility: visible;

    &:focus {
      line-height: 0;
    }

  }
}

.btn--action,
.btn--action:focus {
  font-size: rem-calc(13);
  color: getColor(gray, base);
  background: transparent;

  &:hover {
    background: transparent;
    color: #999;
  }
}

.btn--section-toggle {
  font-family: $font-family-headings;
}

.btn--label-more {
  border: transparent;
  color: #fff;
  border-radius: 0;
  background: rgba(0, 0, 0, 0);

  &:hover,
  &:focus,
  &:active,
  &:hover:focus {
    background: rgba(0, 0, 0, .2);
    border: transparent;
    color: #fff;
    border-radius: 0;
  }

}

.btn--link,
.btn--link:focus {
  background: transparent;
  color: #999;
  text-align: left;
  white-space: normal;
  line-height: 1.2;

  &.no-padding {
    padding: 0;
  }

  &:hover {
    background: transparent;
    color: #666;
    text-decoration: underline;
    cursor: pointer;
  }

  &.darkest {
    color: #222;

    &:hover {
      color: #000;
    }
  }

  &.darker {
    color: #444;

    &:hover {
      color: #111;
    }
  }

  &.mid {
    color: #666;

    &:hover {
      color: #111;
    }
  }

  &.lighter {
    color: #bbb;

    &:hover {
      color: #666;
    }
  }

  &.lightest {
    color: #eee;

    &:hover {
      color: #fff;
    }
  }

  &.hyperlink {
    color: #f58026;

    &:hover {
      color: #34a0cd;
    }
  }

  &.revision-icon {
    color: #FFD324;

    &:hover {
      color: #f58026;
    }
  }

  &.btn--lh-x-md,
  .btn--lh-x-md:focus {
    line-height: 1.5;
  }

  &.no-underline {

    &:hover {
      text-decoration: none;
    }
  }

  &.border-underline {
    border-bottom: 1px dotted #999;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid #999;
    }

  }

  &.align-right {
    text-align: right;
  }

  &.align-center:focus {
    text-align: center;
  }

  &.lh-initial:focus {
    line-height: initial;
  }

  &[disabled],
  &[disabled]:hover {
    background: transparent;
    cursor: default;
    text-decoration: none;
    opacity: 1;
    color: #bbb;
  }
}

.btn--link.label__callout--grey:hover,
.btn--link.label__callout--grey:focus {
  background: #666;
  color: #fff;
  line-height: 1.5;
}

.btn--link.label__callout--amazon:hover,
.btn--link.label__callout--amazon:focus {
  background: darken(#f29d39, 12%);
  color: #fff;
  line-height: 1.5;
}

.btn--link-expand-warehouses {
  position: absolute;
  top: -1.75rem;
  left: 0;
}

.wrap--list-header .btn--link,
.wrap--list-header .btn--link:focus {
  &:focus {
    line-height: 1.2;
  }
}

.wrap--list-header .btn--link.lh-0,
.wrap--list-header .btn--link.lh-0:focus {
  &:focus {
    line-height: 0;
  }
}

.btn--link.lh-sm,
.btn--link.lh-sm:focus {
  line-height: 1;
}

.wrap--error {

  .btn--link,
  .btn--link:focus {
    color: #ff0000;

    &:hover {
      color: #ff0000;
    }
  }
}

.error-message {

  .btn--balance,
  .btn--balance:focus {
    color: #ff0000;
  }
}

.btn--close-banner-msg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: .5rem;
  margin: auto;
}

.btn--zuck,
.btn--reload-app {
  color: #fff;
  vertical-align: baseline;

  &:hover {
    color: yellow;
  }
}

.btn--pagination,
.btn--pagination:focus {
  background: transparent;
  border-radius: rem-calc(2);
  color: #666;
  font-size: rem-calc(13);
  padding: rem-calc(3) rem-calc(4);

  &:hover {
    background: #ddd;
  }

  &.active,
  .active:hover {
    background: transparent;
    color: #333;
    cursor: default;
    font-family: $aktiv-grotesk-bold;
  }
}


.btn--nav-misc {
  margin: .6rem 0;
}

.btn--nav-sync,
.btn--nav-sync:hover,
.btn--nav-sync:focus,
.btn--nav-sync:focus:hover {
  margin: .6rem 0;
  font-size: rem-calc(13);
  padding: .25rem .375rem;
}

.btn--nav-toggle,
.btn--nav-toggle:focus {
  background: transparent;
  border: 2px solid #fff;
  border-radius: rem-calc(4);
  color: #fff;
  font-size: rem-calc(11);
  line-height: 1.5;
  margin: .7rem 0;
  opacity: .8;
  padding: rem-calc(3) rem-calc(5);
  text-transform: uppercase;

  &:hover {
    color: #fff;
    opacity: 1;
  }
}

.btn--nav-toggle {
  display: none;
}

.button.btn--sync,
.button.btn--sync:focus {
  box-shadow: none;
  text-shadow: none;

  &:hover {
    background: darken(#34a0cd, 5%);
  }
}

.button.btn--working,
.button.btn--working:hover,
.button.btn--working:focus,
.button.btn--working:focus:hover,
.button.btn--working:active {
  box-shadow: none;
  text-shadow: none;
  border-color: #666;
  background: #999;
  cursor: default;
  color: #999;
  opacity: 1;
}

.btn--tab {
  background: #f4f4f4;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  font-size: rem-calc(14);
  padding: 1rem .35rem;
  text-align: center;
  color: #999;
  margin-top: -1px;
  width: 100%;

  &:hover,
  &:focus {
    background: #f4f4f4;
  }
}

/* Active styling in _lists.scss */

.btn--remove-tag,
.btn--remove-tag:focus {
  opacity: .35;
  vertical-align: middle;
  font-size: rem-calc(10);
  line-height: .85;
  background: transparent;
  margin: 0;
  padding: 0;

  .i--close:before {
    vertical-align: middle;
  }

  &[disabled],
  &.disabled {
    background-color: transparent;
    opacity: .75;
  }

}

.list__item--gen-filter {
  .btn--remove-tag {}
}

.list__item--tag:hover {

  .btn--remove-tag,
  .btn--remove-tag:focus {
    opacity: .55;

    &:hover {
      opacity: .75;
      background: transparent;
    }
  }
}

.plan {
  border: 2px solid #ccc;
  color: #fff;
  border-radius: rem-calc(3);
  padding: .25em .5em;
  margin-left: .35em;
  font-size: rem-calc(10);
  font-family: $font-family-headings;
}

.btn--clear-search {
  opacity: 0;
}

.btn--setup-guide-toggle {
  @include transform(rotate(-90deg));
  position: absolute;
  top: 2rem;
  right: -1.75rem;
  z-index: 3;
  opacity: 1;
  visibility: visible;
  @include transition-all-faster;
}

.wrap--suffix-group {}

.btn--suffix {
  border: 1px solid #666;
  color: #666;
  padding: 0 .4rem;
  text-align: center;
  background: #ddd;

  &:hover,
  &:focus {
    background: #ccc;
    color: #444;
  }
}

.btn--arrow-up {
  border-radius: 0 rem-calc(4) 0 0;

  &:after {
    @include arrow(top, #666, .2rem);
    @include center-everything;
    content: "";
  }

  &:hover:after {
    @include arrow(top, #444, .2rem);
    content: "";
  }
}

.btn--arrow-down {
  border-radius: 0 0 rem-calc(4) 0;
  border-top: 0;

  &:after {
    @include arrow(bottom, #666, .2rem);
    @include center-everything;
    content: "";
  }

  &:hover:after {
    @include arrow(bottom, #444, .2rem);
    content: "";
  }
}

.input--w-suffix:focus+.wrap--suffix-group .btn--suffix {
  border-color: #6EB43A;
}

.input--error+.wrap--suffix-group .btn--suffix,
.input--error:focus+.wrap--suffix-group .btn--suffix {
  border-color: #ff0000;
}


/* Force Verify Button in Scan/Verify Modal */

.btn--force-verify {
  position: absolute;
  opacity: 0;
  right: -1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  @include transition-all-faster;
}

.table__td--verified:hover,
.table__th--verified:hover {
  .btn--force-verify {
    opacity: 1;
    right: .85rem;
  }
}

.tr--child-sku {
  .btn--force-verify {
    top: -.85rem;
  }
}

/* Capture Weight Scale Button */

.btn--weighing {}

.btn__text--scale {
  margin-left: rem-calc(3);
}

.meta-labelconfigform-container-weight {

  .meta-usb-scale {
    margin-right: rem-calc(5);
  }
}


.btn--edit-product-name,
.btn--make-default-td,
.btn--edit-icon {
  opacity: 0;
  visibility: hidden;
}

.table__td:hover .btn--edit-product-name,
tr:hover .btn--make-default-td,
.wrap--btn--edit-icon:hover .btn--edit-icon {
  opacity: 1;
  visibility: visible;
}


.btn--similar-address {
  border: 2px solid #999;
  border-radius: rem-calc(2);
  padding: rem-calc(2);
  line-height: 1;
  position: relative;
  width: 1rem;
  height: 1rem;
}

.btn__count--similar-address {
  border: 2px solid #999;
  border-radius: rem-calc(2);
  padding: 0 rem-calc(2);
  line-height: 1;
  position: absolute;
  top: rem-calc(2);
  left: rem-calc(2);
  color: #666;
  font-size: rem-calc(11);
  background: #fff;
  box-shadow: -1px -1px 0 #fff;
}

// Button Reveal Styling

.btn--reveal {
  opacity: 0;
  visibility: hidden;
}

.wrap--reveal:hover .btn--reveal {
  opacity: 1;
  visibility: visible;
}

// Welcome tab buttons 2024

.btn--video-overlay {
  border: 3px solid #444;
  background-color: #ccc;
  position: relative;
  width: 100%;

  &:after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: '►';
    font-size: 6rem;
    color: #fff;
    opacity: .65;
  }

  &:hover {
    background: #aaa;
    border-color: #333;

    &:after {
      opacity: .95;
    }

  }

  img {
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .video-thumb--shipping {
    object-position: 10% 50%;
  }

  .video-thumb--inv {
    object-position: 10% 25%;
  }

  .video-thumb--dropshipping {
    object-position: 10% 40%;
  }

}
