.shake {
    animation: 500ms ease-in-out shake;
}

@keyframes shake {
    from, to {
        transform: translate3d(0, 0, 0);
    }

    10%, 30%, 50%, 70%, 90% {
        transform: translate3d(-5px, 0, 0);
    }

    20%, 40%, 60%, 80% {
        transform: translate3d(5px, 0, 0);
    }
}

.wrap--packship-page {
    .wrap--scan-alert {
        padding-top: 1rem;
        padding-bottom: 2.25rem;
    }

    .meta-verify-order-error {
        padding: 0;
    }

    .loading {
        padding: 2rem 0 2.5rem 0;
    }

    .form__header--edit {
        padding: .7rem 1.5rem;
    }
}

.modal--scan-mode {
    .loading {
        padding: 3rem 0 3.25rem 0;
    }
}

.wrap--compact-addr {
    .inline-block--compact-addr {
        display: inline-block;

        &.margin-right {
            margin-right: rem-calc(3);
        }
    }

    .hide--compact-addr {
        display: none;
    }

    .prefix--comma:before {
        display: inline-block;
        content: ',';
        padding-right: rem-calc(2);

    }
}

#iverson-page.wrap--packship-page, .zuck #iverson-page.wrap--packship-page {
    .inner-wrap__panel--actions, .inner-wrap__abode {
        max-height: initial;
    }
} 