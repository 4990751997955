//PRODUCT DETAIL PAGE

.inline-dt {
    color: #000;
    font-size: rem-calc(13);
    dt {
        font-weight: bold;
        display: inline;
        margin-right: 1px;
    }
    dd {
        display: inline;
        margin-left: 0;
    }
}

.resupply-option {
    label {
        font-size: 1em;
    }
    input[type="radio"] {
        margin: 0 .3em 0 0;
    }
}

.detail-page {
    input[type="text"]:focus, .searchbar input[type="text"]:focus, textarea:focus {
        background-color: #fff !important;
    }
    .error-message {
        font-size: rem-calc(11);
        font-family: $Helvetica;
        text-transform: none;
        display: block;
    }
    .inline-text-button {
        font-size: rem-calc(11);
        margin-bottom: 0;
        vertical-align: baseline;
    }
    .hyperlink {
        font-size: 1em;
    }
    .app-table {
        td {
            .bigger-select {
                font-size: rem-calc(12);
                line-height: 1.1;
                margin-bottom: 0;
            }
        }
        .zero-quantity {
            td, .on-hand-wrap {
                color: $light-grey;
            }
        }
        .search-wrap input[type="text"] {
            font-size: .75rem;
            padding-left: 1.65rem;
        }
        .search-results {
            width: 60%;
        }
        .input-wrap {
            .prefix {
                height: 1.8em;
                line-height: 1.8;
                background: $lighter-grey;
                color: $medium-dark-grey;
                border: 0;
                border-right: 1px solid $medium-grey;
            }
            input[type="text"] {
                padding-left: 2.25em;
            }
        }
    }
}

.product-basic-info-value, .product-basic-info-equation.edit-mode {
    input {
        width: 80%;
        margin-bottom: 0;
    }
    .input-wrap input {
        width: 72%;
        padding-left: 1.45em;
    }
    .input-wrap .with-postfix {
        width: 85%;
        padding-left: 4px;
    }
    .prefix, .postfix {
        font-family: $Helvetica;
        font-size: 17px;
        background: $lighter-grey;
        color: $medium-dark-grey;
        padding: 0 .5em;
        line-height: 1.85;
        height: 1.85em;
        width: auto;
        border: 0;
        border-right: 1px solid $medium-grey;
        top: 3px;
    }
    .postfix {
        border: 0;
        border-left: 1px solid $medium-grey;
        height: 1.95em;
        right: 2px;
        top: .4em;
    }
}

.product-detail-supplier-delete-cell {
    .icon-trash.xx-sm:before {
        top: 0;
    }
}

.product-detail-group, .row .product-detail-group {
    border-top: 1px solid $mediumGrey;
    padding-top: 3%;
    margin-top: 3%;
}

.product-basic-info {
    min-height: 6em;
    margin-bottom: 0;
    dd {
        margin-left: 0;
        text-rendering: optimizeLegibility;
    }
    .product-basic-info-label {
        font-family: $Avant-Garde-Book;
        text-transform: uppercase;
        font-size: emCalc(14px);
        line-height: 1;
        margin-bottom: .25em;
        color: #000;
    }
    .product-basic-info-value {
        font-family: $Helvetica;
        text-transform: uppercase;
        font-size: emCalc(37px);
        line-height: 1;
        margin-bottom: .1em;
        color: #000;
        input {
            font-family: $Helvetica;
            display: inline-block;
        }
    }
    .button-group {
        font-size: 12px;
    }
    .product-basic-info-equation {
        font-size: emCalc(17px);
        line-height: 1;
        margin-bottom: .3em;
        margin-left: .1em;
        color: $txtColor;
    }
    .product-basic-info-equation.edit-mode {
        width: 75%;
        input {
            width: 35%;
            vertical-align: bottom;
        }
        span {
            vertical-align: top;
            line-height: 2;
        }
        .prefix {
            left: .4em;
            top: -0.3em;
        }
        .button-group {
            margin-left: 10px;
        }
        .error-message {
            margin-left: 10px;
            padding-top: 0;
        }
    }
    .currency {
        position: relative;
        font-size: 23px;
        top: -.35em;
    }
    .span-wrap {
        margin-right: .2em;
    }
    .weight-unit {
        color: $body-font-color;
        font-weight: 300;
        text-transform: lowercase;
        margin-left: .05em;
    }
    .money {
        color: $txtGreen;
    }
}

.manual-order-price-wrap {
    .product-basic-info-value {
        .prefix {
            top: .4em;
        }
    }
}

.product-basic-info.middle {
    border-left: 1px solid $lightMediumGrey;
    border-right: 1px solid $lightMediumGrey;
}

//General things

.money {
    color: $txtGreen;
}

.sales-channel {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: .4em;
}

.kit-subhead {
    font-size: emCalc(20px);
    color: #000;
}
