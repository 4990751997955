//
// Panels
// ------

.wrap--action-panel {
  margin-top: .5rem;
}

.panel--actions {
  border: 1px solid #bbb;
  background: #fff;
  margin-bottom: 0;
}

.panel--unselected {
  border: 0;
  background: #f7f7f7;
  padding: 2rem 1rem;
  margin-top: .5rem;
}

.panel--error {
  border: 1px solid #ff0000;
  background: lighten(#ff0000, 45%);
  margin: 0;
}

.panel--warning {
  border: 1px solid $warning-txt-color;
  background: lighten($warning-txt-color, 45%);
  margin: 0;
}

.panel--form-wrap {
  border: 1px solid #bbb;
  background: #fff;
  margin: 0 0 1.875rem 0;
  padding: 0;
}

.panel__header {
  background: #ddd;
  border-bottom: 1px solid #bbb;
  padding: .875rem 0.9375rem;

  h3 {
    margin: 0;
    font-family: $aktiv-grotesk-bold;
    color: #000;
  }
}

.panel__body {
  padding: .875rem 0.9375rem;
}

.panel--sm {
  padding: .5rem;
}

.inner-wrap__panel--actions {
  max-height: calc(100vh - 29rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: .625rem;
  margin-right: .625rem;

  &.inner-wrap__panel--errors {
    max-height: 4.5rem;
    margin-right: 0;
  }

  &::-webkit-scrollbar {
    height: .5rem;
    width: .5rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.15);
  }
}


.wrap--batch-info-panel {
  border: 1px solid #666;
  padding: 1.5rem .25rem 1.5rem 1.5rem;
  border-radius: rem-calc(5);

  .inner-wrap__panel--actions {
    max-height: calc(100vh - 15rem);
  }

}

.panel--actions {
  .wrap--batch-info-panel {
    border: 0;
    padding: 1.25rem .15rem 1.25rem 1.25rem;
    border-radius: 0;

    .inner-wrap__panel--actions {
      max-height: calc(100vh - 23rem);
    }
  }
}

.list__item--batch-tab-docs {
  margin-top: rem-calc(12);

  &:last-of-type {
    margin-bottom: 5;
  }

}