.wrap--logo-img img {
  max-width: rem-calc(400);
  max-height: rem-calc(80);
}

.panel--tracking-page {
  border-radius: rem-calc(3);
  padding: 0;
  border: 1px solid #aaa;
  background: #fff;
}

.panel__header--tracking-page,
.panel--tracking-page .panel__header--tracking-page {
  background: $lighter-grey;
  color: $medium-grey;
  border-bottom: 1px solid #aaa;
}

.subheader--tracking-page,
.panel .subheader--tracking-page {
  color: $body-font-color;
  @include text-shadow-white;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.list__items--shipment-line {
  margin-bottom: 1.35rem;
  padding-bottom: 1.35rem;
  border-bottom: 1px dotted #ccc;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.carrier-logo--tracking-page {

  margin-bottom: 1rem;
  filter: grayscale(1);
  opacity: .6;

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {
    background: transparent url(../images/usps-mark.svg) top left no-repeat;
    width: rem-calc(79);
    height: rem-calc(50);
    background-size: contain;
    margin-right: rem-calc(15);
  }

  &.ups {
    background: transparent url(../images/ups.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(50);
    margin-right: rem-calc(7);
  }

  &.dhl,
  &.dhl_ecommerce {
    background: transparent url(../images/dhl.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(30);
    width: rem-calc(127);
    margin-right: rem-calc(15);
  }

  &.fedex {
    background: transparent url(../images/fedex.svg) top left no-repeat;
    min-height: rem-calc(40);
    width: rem-calc(114);
    margin-right: rem-calc(12);
    background-size: contain;
  }

  &.canada_post {
    background: transparent url(../images/canada-post-mark.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(50);
    margin-right: rem-calc(10);
  }

  &.amazon {
    background: transparent url(../images/amazon-buy-shipping-logo.png) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(30);
    width: rem-calc(127);
    margin-right: rem-calc(15);
  }

  &.amazon_shipper {
    background: transparent url(../images/amazon-shipping.png) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(105);
    margin-right: rem-calc(15);
  }

  &.pitney_cbds,
  &.newgistics {
    background: transparent url(../images/pitney-bowes-mark.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(50);
    margin-right: rem-calc(10);
  }

  &.australia_post {
    background: transparent url(../images/australia-post-mark.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(50);
    margin-right: rem-calc(10);
  }

  &.onelivex {
    background: transparent url(../images/x-delivery.svg) top left no-repeat;
    background-size: contain;
    min-height: rem-calc(50);
    width: rem-calc(50);
    margin-right: rem-calc(10);
  }

  &.sendle {
    background: transparent url(../images/sendle.svg) top left no-repeat;
    min-height: rem-calc(25);
    width: rem-calc(92);
    margin-right: rem-calc(10);
  }
}

.link--logo {
  width: 8rem;
}

.img--logo {
  max-height: 1.25rem;

  .logo-type {
    fill: #222222;
  }

  .mark {
    fill: #6eb43a;
  }
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.wrap--loading {
  margin: auto;
  position: absolute;
  text-align: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 15rem;
  height: 10rem;
}

.loading {
  border: 0.5rem solid rgba(0, 0, 0, 0.3);
  border-left: 0.5rem solid black;
  opacity: 1;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  vertical-align: middle;
  animation: spin 0.65s infinite linear;
  display: inline-block;
}

.text--loading {
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  margin-top: 1rem;
  font-family: sans-serif;
  color: #666;
}

.bg--white {
  background-color: #fff;
}