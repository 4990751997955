.non-pro {
    #purchase_orders_main_nav, #pos_main_nav {
        display: none;
    }

    #products_main_nav a {
        border-right: 1px solid #66a73a;
    }

    #products_main_nav .selected {
        border-right: 1px solid transparent;
    }

    #product_supplier_column, #product_on-hand_column, #product_total-committed_column {
        display: none;
    }
}

.non-pro #products-list, .non-pro #analytics-module {
    .hide-for-non-pro {
        display: none;
    }

    .list-utility {
        margin-top: -3.35em;
    }

    /* zebra-striping needs a huge refactor, but this'll do for now */
    .zebra {
        tbody .subdue-for-non-pro {
            background-color: #e5e5e5;
            border-bottom: 1px solid #c6c6c6;
            vertical-align: middle;
        }

        tbody:nth-child(2n+1) .subdue-for-non-pro {
            background-color: #d1d1d1;
            border-bottom: 1px solid #b7b7b7;
        }

        tbody:first-of-type {
            .cta-for-non-pro {
                display: block;
                text-align: center;
                font-size: rem-calc(13);
                margin-bottom: 0;
                color: $medium-dark-grey;
                opacity: .9;
            }
        }
        .collapsed:hover {
            td {
                cursor: default;
                background: #fff;
            }
            .subdue-for-non-pro {
                background-color: #e5e5e5;
            }

            .toggle-tip {
                opacity: 0;
            }
        }

        tbody:nth-child(2n+1).collapsed:hover {
            td {
                cursor: default;
                background-color: $secondary-color;
            }
             .subdue-for-non-pro {
                background-color: #d1d1d1;
            }
        }
    }



    #product_on-hand_column, #supplier_column, #product_total-committed_column, #product_reordered_column, #product_supplier_column, #product_available_column {
        background: #555;
        color: $medium-grey;

        .column-name {
            border-left: 1px solid $medium-dark-grey;
        }

        .sort-arrows {
            display: none;
        }
    }

}
