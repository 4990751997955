$rem-base: 16px !default;

//
// Strip Unit
// ----------

// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

//
// Convert to REM
// --------------

@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function data($attr) {
    @if $namespace {
        @return '[data-' + $namespace + '-' + $attr + ']';
    }

    @return '[data-' + $attr + ']';
}

//
// REM Calc
// --------

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

@function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);

    @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

    $remValues: ();
    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }
    @return $remValues;
}

//
// Margin and padding utility class generator
// ------------------------------------------

@mixin generate-box-model-values($property, $side) {
    .#{$property}-#{$side}-0 {
        #{$property}-#{$side}: rem-calc(0);
    }
    .#{$property}-#{$side}-1 {
        #{$property}-#{$side}: rem-calc(1);
    }
    .#{$property}-#{$side}-3 {
        #{$property}-#{$side}: rem-calc(3);
    }
    .#{$property}-#{$side}-5 {
        #{$property}-#{$side}: rem-calc(5);
    }

    .#{$property}-#{$side}-7 {
        #{$property}-#{$side}: rem-calc(7);
    }

    .#{$property}-#{$side}-10 {
        #{$property}-#{$side}: rem-calc(10);
    }
    .#{$property}-#{$side}-15 {
        #{$property}-#{$side}: rem-calc(15);
    }
    .#{$property}-#{$side}-20 {
        #{$property}-#{$side}: rem-calc(20);
    }
    .#{$property}-#{$side}-25 {
        #{$property}-#{$side}: rem-calc(25);
    }
    .#{$property}-#{$side}-30 {
        #{$property}-#{$side}: rem-calc(30);
    }
    .#{$property}-#{$side}-35 {
        #{$property}-#{$side}: rem-calc(35);
    }
    .#{$property}-#{$side}-40 {
        #{$property}-#{$side}: rem-calc(40);
    }
    .#{$property}-#{$side}-45 {
        #{$property}-#{$side}: rem-calc(45);
    }
    .#{$property}-#{$side}-50 {
        #{$property}-#{$side}: rem-calc(50);
    }

    .#{$property}-#{$side}-55 {
        #{$property}-#{$side}: rem-calc(55);
    }

    .#{$property}-#{$side}-60 {
        #{$property}-#{$side}: rem-calc(60);
    }
}

//
// Arrows
// ------

@mixin arrow($direction, $color, $size){
    display: block;
    height: 0;
    width: 0;

    @if $direction == 'top' {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    } @else if $direction == 'right' {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    } @else if $direction == 'bottom' {
        border-top: $size solid $color;
        border-right: $size solid transparent;
        border-left: $size solid transparent;
    } @else if $direction == 'left' {
        border-top: $size solid transparent;
        border-right: $size solid $color;
        border-bottom: $size solid transparent;
    } @else if $direction == 'top-left' {
        border-top: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == 'top-right' {
        border-top: $size solid $color;
        border-left: $size solid transparent;
    } @else if $direction == 'bottom-left' {
        border-bottom: $size solid $color;
        border-right: $size solid transparent;
    } @else if $direction == 'bottom-right' {
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    }
}

//
// Flexbox mixins (borrowed from Bourbon.io)
//

$prefix-for-webkit:    true;
$prefix-for-mozilla:   true;
$prefix-for-microsoft: true;
$prefix-for-opera:     true;
$prefix-for-spec:      true;

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      @if $prefix-for-webkit {
        -webkit-#{$property}: $value;
      }
    } @else if $prefix == moz {
      @if $prefix-for-mozilla {
        -moz-#{$property}: $value;
      }
    } @else if $prefix == ms {
      @if $prefix-for-microsoft {
        -ms-#{$property}: $value;
      }
    } @else if $prefix == o {
      @if $prefix-for-opera {
        -o-#{$property}: $value;
      }
    } @else if $prefix == spec {
      @if $prefix-for-spec {
        #{$property}: $value;
      }
    } @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin disable-prefix-for-all() {
  $prefix-for-webkit:    false !global;
  $prefix-for-mozilla:   false !global;
  $prefix-for-microsoft: false !global;
  $prefix-for-opera:     false !global;
  $prefix-for-spec:      false !global;
}

// CSS3 Flexible Box Model and property defaults

// Custom shorthand notation for flexbox
@mixin box($orient: inline-axis, $pack: start, $align: stretch) {
  @include display-box;
  @include box-orient($orient);
  @include box-pack($pack);
  @include box-align($align);
}

@mixin display-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox; // IE 10
  display: box;
}

@mixin box-orient($orient: inline-axis) {
// horizontal|vertical|inline-axis|block-axis|inherit
  @include prefixer(box-orient, $orient, webkit moz spec);
}

@mixin box-pack($pack: start) {
// start|end|center|justify
  @include prefixer(box-pack, $pack, webkit moz spec);
  -ms-flex-pack: $pack; // IE 10
}

@mixin box-align($align: stretch) {
// start|end|center|baseline|stretch
  @include prefixer(box-align, $align, webkit moz spec);
  -ms-flex-align: $align; // IE 10
}

@mixin box-direction($direction: normal) {
// normal|reverse|inherit
  @include prefixer(box-direction, $direction, webkit moz spec);
  -ms-flex-direction: $direction; // IE 10
}

@mixin box-lines($lines: single) {
// single|multiple
  @include prefixer(box-lines, $lines, webkit moz spec);
}

@mixin box-ordinal-group($int: 1) {
  @include prefixer(box-ordinal-group, $int, webkit moz spec);
  -ms-flex-order: $int; // IE 10
}

@mixin box-flex($value: 0) {
  @include prefixer(box-flex, $value, webkit moz spec);
  -ms-flex: $value; // IE 10
}

@mixin box-flex-group($int: 1) {
  @include prefixer(box-flex-group, $int, webkit moz spec);
}

// CSS3 Flexible Box Model and property defaults
// Unified attributes for 2009, 2011, and 2012 flavours.

// 2009 - display (box | inline-box)
// 2011 - display (flexbox | inline-flexbox)
// 2012 - display (flex | inline-flex)
@mixin display($value) {
// flex | inline-flex
  @if $value == "flex" {
    // 2009
    display: -webkit-box;
    display: -moz-box;
    display: box;

    // 2012
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox; // 2011 (IE 10)
    display: flex;
  } @else if $value == "inline-flex" {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;

    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  } @else {
    display: $value;
  }
}

// 2009 - box-flex (integer)
// 2011 - flex (decimal | width decimal)
// 2012 - flex (integer integer width)
@mixin flex($value) {

  // Grab flex-grow for older browsers.
  $flex-grow: nth($value, 1);

  // 2009
  @include prefixer(box-flex, $flex-grow, webkit moz spec);

  // 2011 (IE 10), 2012
  @include prefixer(flex, $value, webkit moz ms spec);
}

// 2009 - box-orient ( horizontal | vertical | inline-axis | block-axis)
//      - box-direction (normal | reverse)
// 2011 - flex-direction (row | row-reverse | column | column-reverse)
// 2012 - flex-direction (row | row-reverse | column | column-reverse)
@mixin flex-direction($value: row) {

  // Alt values.
  $value-2009: $value;
  $value-2011: $value;
  $direction: normal;

  @if $value == row {
    $value-2009: horizontal;
  } @else if $value == "row-reverse" {
    $value-2009: horizontal;
    $direction: reverse;
  } @else if $value == column {
    $value-2009: vertical;
  } @else if $value == "column-reverse" {
    $value-2009: vertical;
    $direction: reverse;
  }

  // 2009
  @include prefixer(box-orient, $value-2009, webkit moz spec);
  @include prefixer(box-direction, $direction, webkit moz spec);

  // 2012
  @include prefixer(flex-direction, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-direction: $value;
}

// 2009 - box-lines (single | multiple)
// 2011 - flex-wrap (nowrap | wrap | wrap-reverse)
// 2012 - flex-wrap (nowrap | wrap | wrap-reverse)
@mixin flex-wrap($value: nowrap) {
  // Alt values
  $alt-value: $value;
  @if $value == nowrap {
    $alt-value: single;
  } @else if $value == wrap {
    $alt-value: multiple;
  } @else if $value == "wrap-reverse" {
    $alt-value: multiple;
  }

  @include prefixer(box-lines, $alt-value, webkit moz spec);
  @include prefixer(flex-wrap, $value, webkit moz ms spec);
}

// 2009 - TODO: parse values into flex-direction/flex-wrap
// 2011 - TODO: parse values into flex-direction/flex-wrap
// 2012 - flex-flow (flex-direction || flex-wrap)
@mixin flex-flow($value) {
  @include prefixer(flex-flow, $value, webkit moz spec);
}

// 2009 - box-ordinal-group (integer)
// 2011 - flex-order (integer)
// 2012 - order (integer)
@mixin order($int: 0) {
  // 2009
  @include prefixer(box-ordinal-group, $int, webkit moz spec);

  // 2012
  @include prefixer(order, $int, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-order: $int;
}

// 2012 - flex-grow (number)
@mixin flex-grow($number: 0) {
  @include prefixer(flex-grow, $number, webkit moz spec);
  -ms-flex-positive: $number;
}

// 2012 - flex-shrink (number)
@mixin flex-shrink($number: 1) {
  @include prefixer(flex-shrink, $number, webkit moz spec);
  -ms-flex-negative: $number;
}

// 2012 - flex-basis (number)
@mixin flex-basis($width: auto) {
  @include prefixer(flex-basis, $width, webkit moz spec);
  -ms-flex-preferred-size: $width;
}

// 2009 - box-pack (start | end | center | justify)
// 2011 - flex-pack (start | end | center | justify)
// 2012 - justify-content (flex-start | flex-end | center | space-between | space-around)
@mixin justify-content($value: flex-start) {

  // Alt values.
  $alt-value: $value;
  @if $value == "flex-start" {
    $alt-value: start;
  } @else if $value == "flex-end" {
    $alt-value: end;
  } @else if $value == "space-between" {
    $alt-value: justify;
  } @else if $value == "space-around" {
    $alt-value: distribute;
  }

  // 2009
  @include prefixer(box-pack, $alt-value, webkit moz spec);

  // 2012
  @include prefixer(justify-content, $value, webkit moz ms o spec);

  // 2011 (IE 10)
  -ms-flex-pack: $alt-value;
}

// 2009 - box-align (start | end | center | baseline | stretch)
// 2011 - flex-align (start | end | center | baseline | stretch)
// 2012 - align-items (flex-start | flex-end | center | baseline | stretch)
@mixin align-items($value: stretch) {

  $alt-value: $value;

  @if $value == "flex-start" {
    $alt-value: start;
  } @else if $value == "flex-end" {
    $alt-value: end;
  }

  // 2009
  @include prefixer(box-align, $alt-value, webkit moz spec);

  // 2012
  @include prefixer(align-items, $value, webkit moz ms o spec);

  // 2011 (IE 10)
  -ms-flex-align: $alt-value;
}

// 2011 - flex-item-align (auto | start | end | center | baseline | stretch)
// 2012 - align-self (auto | flex-start | flex-end | center | baseline | stretch)
@mixin align-self($value: auto) {

  $value-2011: $value;
  @if $value == "flex-start" {
    $value-2011: start;
  } @else if $value == "flex-end" {
    $value-2011: end;
  }

  // 2012
  @include prefixer(align-self, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-item-align: $value-2011;
}

// 2011 - flex-line-pack (start | end | center | justify | distribute | stretch)
// 2012 - align-content (flex-start | flex-end | center | space-between | space-around | stretch)
@mixin align-content($value: stretch) {

  $value-2011: $value;
  @if $value == "flex-start" {
    $value-2011: start;
  } @else if $value == "flex-end" {
    $value-2011: end;
  } @else if $value == "space-between" {
    $value-2011: justify;
  } @else if $value == "space-around" {
    $value-2011: distribute;
  }

  // 2012
  @include prefixer(align-content, $value, webkit moz spec);

  // 2011 (IE 10)
  -ms-flex-line-pack: $value-2011;
}

@mixin spinner($border-width, $rgb-color, $opacity, $size) {
    border: $border-width solid rgba($rgb-color, 0.6);
    border-left: $border-width solid rgba($rgb-color, 1);
    opacity: $opacity;
    width: $size;
    height: $size;
    border-radius: 100%;
    vertical-align: middle;
}

.animate-spin {
    -moz-animation: spin .65s infinite linear;
    -o-animation: spin .65s infinite linear;
    -webkit-animation: spin .65s infinite linear;
    animation: spin .65s infinite linear;
    display: inline-block;
}

@mixin animate-spin {
    -moz-animation: spin .65s infinite linear;
    -o-animation: spin .65s infinite linear;
    -webkit-animation: spin .65s infinite linear;
    animation: spin .65s infinite linear;
    display: inline-block;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes opacity-in-out {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@mixin opacity-in-out {
    -moz-animation: opacity-in-out 3s 1 linear;
    -o-animation: opacity-in-out 3s 1 linear;
    -webkit-animation: opacity-in-out 3s 1 linear;
    animation: opacity-in-out 3s 1 linear;
}

