//
// Iverson Grid + Layout
// ---------------------

.wrap--container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  max-width: calc(100vw - .9375rem);
  width: 100%;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .wrap--container {
      position: relative;
      z-index: 1;
    }

    .header .wrap--container {
      position: initial;
    }

  }
}

.wrap--row {
  margin: 0;

  &:before,
  &:after {
    content: "";
    display: table;
  }
}

.wrap--row:after {
  clear: both;
}

/* override for settings form shit in modal */

.row .wrap--settings-form-modal .wrap--row {
  margin: 0 -0.9375rem;
  max-width: none;
  width: auto;
}

.wrap--settings-form-modal {
  .medium-6 .list--form .list__item--form:first-of-type {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .medium-6 .list--form .alert--zuck-mode:first-of-type {
    padding-top: .8rem;
  }
}


// wide gutters

.wrap--row-wide-gutters {

  .columns,
  .column {
    padding-left: 1.15rem;
    padding-right: 1.15rem;
  }
}