//
// Iverson
// =============================================================================

//
// Basics
// ------

hr {
    border-top: 1px solid #bbb;
}

.hr--order-header {
    border-top: 1px solid #ccc;
    margin: .7rem .7rem 0 1.25rem;
}

.hr--detail-page {
    border-top: 1px solid #aaa;
    margin: 1.75rem 0 1.5rem 0;
}

p, li, dd, dt, span {
    strong {
        font-family: $font-family-headings;
    }
}

.plan {
    border: 2px solid #ccc;
    color: #fff;
    border-radius: rem-calc(3);
    padding: .25em .5em;
    margin-left: .35em;
    font-size: rem-calc(10);
    font-family: $font-family-headings;
}

//
// Typography
// ----------

.header__logo {
    width: 2em;
    display: inline-block;
    opacity: .5;
    vertical-align: middle;
}

.header--main {
    background: getColor(green, base);
    padding: 0;
    margin-bottom: 1.5em;
    -webkit-font-smoothing: antialiased;
}

.header--pagination {
    font-size: rem-calc(23);
}

.page__title {
    font-size: rem-calc(37);
    line-height: 1;
    margin: 0;
    font-family: $font-family;
}

.page__title--inner {
    font-size: rem-calc(27);
}

.panel__header--actions {
    font-size: rem-calc(17);
    padding: .75rem 1rem;
    text-align: center;
    background: #eee;
}

//
// Links
// ------

.unshippable {
    .link--line-detail, .i--shippability, .line-detail {
        color: $alert-color;
    }
}

.partially-shippable {
    .link--line-detail, .i--shippability, .line-detail {
        color: $warning-txt-color;
    }
}

.shippable, .dropshippable {
    .link--line-detail, .i--shippability, .line-detail {
        color: $txt-green;
    }
}

.shipped {
    .link--line-detail, .i--shippability, .line-detail {
        color: #999;
    }
}

.link--line-detail {
    font-family: $font-family;
    &:hover {
        color: #666;
        text-decoration: underline;
    }
}

.wrap--order-shippability {
    margin: 0 rem-calc(5) 0 rem-calc(-19);

    &.compact {
        margin-left: 0;
        vertical-align: middle;

        .i--shippability {
            font-size: rem-calc(13);
        }
    }
}

//
// Forms
// -----

input[type="text"], input[type="number"], input[type="password"], input[type="date"] {
    background: #fff;
    border-radius: rem-calc(4);
    border-color: getColor(gray, base);
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px;
    height: rem-calc(27);
    min-height: rem-calc(27);
    padding: 0 .3rem;
    font-size: rem-calc(13);

    &:focus {
        background: #fff;
        border-color: #6EB43A;
    }

    &.input--xx-sm {
        height: rem-calc(23);
        min-height: rem-calc(23);
        padding: 0 .2rem;
        font-size: rem-calc(12);
        width: rem-calc(33);

        &.ext {
            width: 2.7rem;
        }
    }

    &.input--dim {
        width: 2.55rem;
    }

    &.input--x-sm {
        width: 2.75rem;
    }

    &.input--sm {
        width: 4rem;
    }

    &.input--md {
        width: 6rem;
    }

    &.input--lg {
        width: 10rem;
    }

    &.input--med-h {
        height: rem-calc(32);
    }

    &.input--tall {
        height: rem-calc(40);
    }

    &.input--error {
        border-color: $error-color;
        color: $error-color;
    }

    &.input--w-prefix {
      border-radius: 0 rem-calc(4) rem-calc(4) 0 ;
      border-left: 0;
    }

    &.input--w-suffix {
        border-radius: rem-calc(4) 0 0 rem-calc(4);
        border-right: 0;
    }

    &[disabled] {
        border-color: #999;
        background: #eee;
        opacity: .5;
    }
}

.wrap--weight-input input[type="text"].input--w-suffix {
    height: auto;
}

.wrap--datepicker input[type="text"] {
    height: rem-calc(32);
}

input[type="number"], input[type="date"] {
    padding-right: 0;
    text-align: left;
}

input[type="number"] {
    font-size: rem-calc(12);
}

input[type="text"].input--search, .input--search {
    width: 100%;
    height: rem-calc(33);
    padding-left: 1.75rem;
}

input[type="text"].input--main-search, .input--main-search {
    width: 13rem;
    height: auto;
    border-radius: rem-calc(4) 0 0 rem-calc(4);
    padding-left: .5rem;
    padding-right: 0;
    font-size: rem-calc(14);
    border-color: #999;
    box-shadow: none;
    border-right: 0;

    &:focus {
        border-color: #666;
    }
}

.wrap--main-search {
    height: rem-calc(33);
    margin-bottom: .5rem;
}

input[type="text"].input--lighter, .input--lighter {
    border-color: #999;

    &:focus {
        background: #fff;
        border-color: #444;
    }
}

input[type="text"].has-prefix, .has-prefix {
    padding-left: 2.5rem;
}

input[type="text"].has-prefix--sm, .has-prefix--sm {
    padding-left: 1.5rem;
}


input[type="text"].has-suffix, .has-suffix {
    padding-right: 2.5rem;
}

.textarea {
    border-color: getColor(gray, base);
    border-radius: rem-calc(4);
    padding: .3rem;
    font-size: rem-calc(13);
}

.textarea--in-cell {
    opacity: 1;
    width: 65%;

    &.order-detail {
        width: 80%;
        font-size: rem-calc(12);
        line-height: 1.3;
        height: 2rem;
        min-height: 0;
    }

    &:focus {
        opacity: 1;
    }
}

.textarea-h-2rem {
  height: 2rem;
  min-height: 0;
}

small.error {
    line-height: 1.3;
}

.fieldset--shipping-options, .fieldset--dropship {
    border: 0;
    border-radius: 0;
    border-top: 1px solid #ccc;
    padding: .75rem 1.25rem;
    margin: 0;

    &.no-border {
        border-top: 0;
    }
}

.fieldset--view-pdfs {
    &:first-of-type {
        border-top: 0;
        padding-top: 0;
    }
}

.fieldset--scroll-wrap {
    padding: 0 0 .75rem 1.25rem;
    border-top: 0;
}

.fieldset--shipping-summary {
    padding-bottom: 0;

    &.warning small, &.warning label {
        color: $warning-txt-color;
    }
}

.wrap--returns-btns {
  padding: 1.75rem 1.25rem;
}

.wrap--rates-error {
    color: $error-color;
    margin-left: .5rem;
}

.wrap--error-wb {
    white-space: pre-wrap;
    font-family: $aktiv-grotesk-regular;
}

.legend--shipping-options {
    font-weight: normal;
    padding: 0;
}

label {
    font-size: rem-calc(13);
}

.label--bold {
    font-family: $font-family-headings;
}

.label--normal, .label--normal label {
    font-family: $aktiv-grotesk-regular;
}

.label--input-prefix {
    font-family: $aktiv-grotesk-regular;
    color: #999;
    font-size: rem-calc(17);
    line-height: 1;
    position: relative;

    &:before {
        line-height: 1.85;
        position: absolute;
        left: rem-calc(7);
    }
}

.label--product-search {
    display: inline-block;
    color: #999;
    margin-right: .75rem;
}

.label--tag, .label--sm {
    font-size: rem-calc(12);

    label {
        font-size: rem-calc(12);
    }
}

.select {
    border-color: getColor(gray, base);
    font-size: rem-calc(13);
    height: rem-calc(27);

    &.h-auto {
        height: auto;
    }

    &[disabled] {
        border-color: #999;
        background: #eee;
        opacity: .5;
    }
}

input:focus, select:focus, textarea:focus {
    border-color: getColor(green, base);
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px;
}

.select--error, .select--error:focus, .select--error:disabled {
    border: 2px solid $error-color;
    color: $error-color;
    background: #fff;
    cursor: default;
}

.select--warning, .select--warning:focus, .select--warning:enabled {
    border: 2px solid $warning-txt-color;
    color: $warning-txt-color;
    background: #fff;
    cursor: default;
}

.select--loading, .select--loading:focus, .select--loading:disabled {
    opacity: .5;
    background: #fff;
    cursor: default;
}

.select--updated {
    border: 2px solid $ordoro-blue;
    background: #fff;
}

.input__unit {
    vertical-align: middle;
    font-size: rem-calc(13);
    line-height: 1.6875rem;
    margin: 0 rem-calc(4);
}
.error .input__unit {
    color: $error-color;
}

// FIXME: pls forgive, @matto
.wrap--error {
  color: $error-color;
}

.wrap--checkbox-all {
    padding: .25em .9em;
    margin-bottom: 0;
    border: 1px solid #aaa;
    background: #eee;
    border-radius: rem-calc(3);
    vertical-align: middle;

    // &:after {
    //     @include arrow(bottom, getColor(gray, base), .35em);
    //     content: "";
    //     display: inline-block;
    //     margin-left: rem-calc(3);
    //     vertical-align: middle;
    // }
}

.table__th--sm .wrap--checkbox-all {
    font-size: .75rem;
}

// Support UPS Ready legalese
.meta-labelconfigform-shipper-ups .inner-wrap__panel--actions {
    max-height: calc(100vh - 31rem);
}

@supports (-moz-appearance:none) and (hyphens:none) {
    .fieldset--scroll-wrap {
        padding-right: 1.25rem;
    }

    .wrap--dropdown-presets {
        margin-right: 0;
    }

    .inner-wrap__panel--actions {
        padding-right: 1rem;
        margin-right: -1rem;
    }
}

.inner-wrap__label-sizes {
    max-height: calc(100vh - 24.25rem);
}

.inner-wrap__dropship {
    max-height: calc(100vh - 22.6rem);
}

.inner-wrap__abode {
    max-height: calc(100vh - 20rem);
}

.panel--scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: calc(100vh - 20em);
}

.indicator--saved {
    opacity: 0;
    font-size: rem-calc(14);
    color: #999;

    &.saving {
        @include spinner(0.2rem, #444, 0.6, 1rem);
        @include animate-spin;
        position: relative;

        &:before {
            content: "";
        }
    }

    &.saved {
        @include opacity-in-out;
    }
}

//
// Tooltips
// --------

.tooltip:after, [data-hint]:after {
    font-size: rem-calc(12);
    font-family: $aktiv-grotesk-bold;
    -webkit-font-smoothing: antialiased;
    box-shadow: rgba(0, 0, 0, 0.25) 0 rem-calc(1) rem-calc(3);
}

.list__item--tag {

    &.tooltip--top:before, &.tooltip--top:after, &.list__item--tag[data-hint]:before, &.list__item--tag[data-hint]:after {
        visibility: hidden;
    }

    &.tooltip--top:before, &.tooltip--top:after {
        left: -.25rem;
    }

    &.tooltip--top:hover:after, &.tooltip--top:hover:before {
        transform: translateY(-8px);
    }

}

.wrap--summary-tags-table .list__item--tag {
    &.tooltip--top::before, &.tooltip--top:after, &.list__item--tag[data-hint]:before, &.list__item--tag[data-hint]:after {
        visibility: visible;
    }
}


.tooltip--top:hover:after, .tooltip--top:hover:before {
    transform: translateY(-5px);
}

.tooltip--top:before, .tooltip--top:after {
    left: 20%;
}

.unshippable, .overallocated, .error {
    .tooltip:after, [data-hint]:after {
        background: $alert-color;
    }
    .tooltip--top:before {
        border-top-color: $alert-color;
    }
}

.partially-shippable, .partially-allocated, .warning {
    .tooltip:after, [data-hint]:after {
        background: $warning-txt-color;
    }
    .tooltip--top:before {
        border-top-color: $warning-txt-color;
    }
}


.shippable, .allocated, .dropshippable {
    .tooltip:after, [data-hint]:after {
        background: $txt-green;
    }
    .tooltip--top:before {
        border-top-color: $txt-green;
    }
}

.shipped {
    .tooltip:after, [data-hint]:after {
        background: #666;
    }
    .tooltip--top:before {
        border-top-color: #666;
    }
}

//
// Pop-overs
// --------

.pop-over {
    display: none;
    border: 3px solid #444;
    margin: 0;
    position: absolute;
    width: rem-calc(300);
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.3) 0 rem-calc(3) rem-calc(15);
}

.pop-over--w-35vw {
    width: 35vw;
}

.pop-over--below {
    left: rem-calc(-150);
}

.pop-over--left {
    right: 1.25rem;
    top: -.25rem;
}

.pop-over--below-right {
    left: .1rem;
    top: 1rem;
}

.pop-over__title {
    font-size: rem-calc(14);
    padding: .5rem;
    margin: 0;
    background: #ddd;
    border-bottom: 1px solid #ccc;
}

.pop-over__body {
    padding: .75rem .5rem;
    font-size: rem-calc(12);
    margin: 0;
    background: #fff;
}

.pop-over--rates-error {
    bottom: 0;
    right: 1rem;
    border-color: $error-color;

    .pop-over__title {
        background-color: lighten($error-color, 45%);
        border: 0;
    }

    .pop-over__body {
        color: #666;
    }
}

.wrap--pop-over {
    position: relative;

    &:hover {
        .pop-over {
            display: block;
        }
    }
}

.wrap--order-comments, .wrap--order-notes {
    position: absolute;
    top: 0;
    right: 0.5rem;
    line-height: 0;
}
