body {
  background: #111;
  color: #eee;
}

::selection {
  background-color: #666;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
.list__title--label-success {
  color: #fff;
}

.header--main {
  background: #222;
  border-bottom: 1px solid #444;
}

.wrap--banner-msg {
  color: #111;
}

.wrap--free-callout {
  background-color: #444;
  border-bottom-color: #666;
}

.zuck-mode {
  background: #777;
  color: #eee;

  .btn--zuck {
    color: #fff;
  }

}

.progress-indicator__outer {
  background: #111;
}


.main-nav__link {
  opacity: 1;

  &.wrap--logo {

    .logo-mark__fill {
      fill: #6EB43A;
    }

    &:hover {
      background: transparent;

      .logo-mark__fill {
        fill: lighten(#6EB43A, 5%);
      }
    }
  }

}

.main-nav__link:hover,
.utility-nav__link:hover,
.open .utility-nav__link,
.open .utility-nav__link:hover,
.open .utility-nav__link:focus {
  background: #000;
}

.main-nav__link.active,
.main-nav__link.main-nav__link.active:hover {
  background: #333;
}

.main-nav__count,
.main-nav__count:hover {
  background: #fff;
  color: #111;
}

button.disabled,
button.disabled:hover,
button.disabled:focus,
button[disabled],
button[disabled]:hover,
button[disabled]:focus {
  background: #333;
  border-color: #666;
  color: #999;
}



.btn--processing,
.btn--processing:hover,
.btn--processing:focus,
.btn--processing:focus:hover,
.btn--processing:disabled,
.btn--processing:hover:disabled {
  border-color: $medium-dark-grey;
  background: $medium-grey;
  color: $medium-grey;
  cursor: default;
  position: relative;
  opacity: 1;

  &:before {
    @include spinner(0.15rem, #fff, 0.85, 1rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }

  &.btn--nav-sync:before {
    @include spinner(0.15rem, #fff, 0.85, 0.85rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }
}

.btn--processing-xxl,
.btn--processing-xxl:hover,
.btn--processing-xxl:focus,
.btn--processing-xxl:focus:hover,
.btn--processing-xxl:disabled,
.btn--processing-xxl:hover:disabled {
  border-color: $medium-dark-grey;
  background: $medium-grey;
  color: $medium-grey;
  cursor: default;
  position: relative;
  opacity: 1;

  &:before {
    @include spinner(0.3rem, #fff, 0.85, 2.25rem);
    @include animate-spin;
    @include center-everything;
    content: "";
  }
}


.btn--link,
.btn--link:focus,
.btn--action,
.btn--action:focus {
  color: #bbb;

  &.btn--edit-product-name,
  &.btn--product-qty-th {
    color: #999;
  }

  &.lighter {
    color: #ddd;
  }

  &.light {
    counter-reset: #999;
  }

  &.darkest {
    color: #eee;
  }

  &.label__callout {
    color: #fff;
  }

  &:hover {
    color: #eee;
  }
}

/* fine-tuning for dark modeg */

.i-batch-03 {
  margin-left: rem-calc(1);
}


.label__callout {
  text-shadow: rgba(0, 0, 0, .35) 0px 1px 0;
}

.wrap--callout-trial {
  .label__callout--trial {
    background-color: #222;
    border-color: yellow;
    color: #fff;
  }

  .btn--primary-demo {
    border-color: yellow;
    color: #222;
  }

}


.alert--warning,
.alert--warning-lt {
  background: #111;
  color: #FFD324;

  .btn--link {
    color: #eee;
  }
}

.btn--dropdown,
.btn--section-toggle {
  color: #bbb;

  &:after {
    border-top-color: #bbb;
  }

  &:hover {
    color: #eee;

    &:after {
      border-top-color: #eee;
    }

  }

}

.btn--filter-w-border {
  border-color: #999;
  background: #222;
  color: #eee;
}

.btn--secondary,
.btn--secondary:focus {
  color: #bbb;

  &:hover {
    color: #eee;
  }

}

.btn--primary-ol,
.btn--primary-ol:focus {
  background: #222;
  color: #bbb;
  border-color: #999;
}

.btn--primary-ol:hover,
.btn--primary-ol:focus:hover {
  color: #ddd;
  border-color: #ddd;
}

.btn--primary-alt-ol,
.btn--primary-alt-ol:focus {
  color: #fff;
}

.btn--primary-alt-ol:hover,
.btn--primary-alt-ol:focus:hover {
  background-color: #111;
  border-color: #d4ac60;
  color: #fff;
}

.btn--primary-warning-ol,
.btn--primary-warning-ol:focus {
  border-color: $warning-border-color;
  color: $warning-border-color;
  background: transparent;
}

.btn--primary-warning-ol:hover,
.btn--primary-warning-ol:hover:focus {
  border-color: lighten(#FFD324, 20%);
  color: lighten(#FFD324, 20%);
  background: transparent;
}

// OMNIBAR OVERRIDES

.omni-bar__control {
  background: #111;
  border-color: #666 !important;
}

.btn--omni-bar-search {
  border-color: #666;
  background-color: #777;

  &:hover {
    background-color: #444;
  }

}

.omni-bar__multi-value:not(.omni-bar_value-type_tag) {
  background-color: #666;
  color: #fff;

  .omni-bar__multi-value__remove {
    color: #bbb;

    &:hover {
      background-color: #999;
      color: #eee;
    }
  }

}

.omni-bar_value-type_search {
  background-color: #111 !important;
}

.omni-bar_value-type_search .omni-bar__multi-value__remove {
  background-color: #666;
}

.omni-bar__multi-value:before {
  background: #111;
  color: #bbb;
}


.omni-bar__input {
  color: #eee !important;
}

.wrap--list-loading {
  background: rgba(255, 225, 255, .15);
}

#iverson-page hr {
  border-top-color: #666;
}

.list__item--content-block {
  background: #333;
  border-top-color: #666;

  &:nth-child(2n+1) {
    background: #111;
  }

  &:hover {
    background-color: #000;

    .icon--pop-over {
      opacity: .75;
    }

  }

  .table {
    background: transparent;
  }
}

.list__item--order-revision,
.list__item--order-revision:nth-child(2n+1) {
  background: #222;

  &:hover {
    background: #333;
  }

  &.selected,
  &:nth-child(2n+1).selected {
    background: #333;

  }
}

.status-badge {
  background-color: #ccc;
  color: #111;
}

/* PICK PACK SHIT */
.tr--scan-success,
table .tr--scan-success,
table .tr--scan-success:nth-of-type(even) {
  background-color: #111;
}

.list--parent-sku .list__item {
  color: #aaa;
}

.tr--scan-success .verified-quantity,
table .tr--scan-success .verified-quantity,
table .tr--scan-success:nth-of-type(even) .verified-quantity {
  color: #aaa;
}

.modal--scan-mode .table__th {
  border-color: #999;

  &.border-left--light {
    border-left-color: #666;
  }
}

.form__header--edit,
.row .form__header--edit {
  background: #333;
}

// POPOVERS

#iverson-page {

  .pop-over {
    border-color: #999;
    color: #eee;
  }

  .pop-over__title {
    background-color: #444;
    color: #fff;
  }

  .pop-over__body {
    background: #111;
    color: #eee;
  }
}

// TIMELINES

.wrap--timeline-day {
  border-top-color: #666;

  &:first-of-type {
    border-top: 0;
  }

}

.list--timeline-point {
  border-left-color: #666;
}

.list__title--timeline-point {
  color: #ddd;

  &:before {
    border: 3px solid #111;
    background: #666;
  }
}

.attr--timeline-point {
  color: #777;
}

.wrap--tally-list {
  border-top: 1px dotted #666;
}

.modal--quick-ship {

  .list__title--timeline-point {
    color: #999;
  }

  .list--timeline-point-active {

    .list__title--timeline-point {
      color: #fff;

      &:before {
        background-color: #fff;
      }
    }
  }

  .list--timeline-point-completed {
    .list__title--timeline-point {
      color: #6EB43A;

      &:before {
        background-color: #111;
      }
    }
  }
}


// TOOLTIPS

#iverson-page {

  .tooltip:after,
  [data-hint]:after {
    background-color: #ddd;
    color: #222;
    box-shadow: rgba(0, 0, 0, .65) 0 rem-calc(1) rem-calc(5);
  }
}

.tooltip:after,
[data-hint]:after {
  background-color: #ddd;
  color: #222;
  box-shadow: rgba(0, 0, 0, .65) 0 rem-calc(1) rem-calc(5);
}

.tooltip--top:before {
  border-top-color: #ddd;
  margin-bottom: -13px;
}

.tooltip--right:before {
  border-right-color: #ddd;
  margin-left: -13px;
}

.tooltip--left:before {
  border-left-color: #ddd;
  margin-right: -13px;
}

.list__item--order-block:first-of-type .status-badge.tooltip--top:before {
  border-bottom-color: #ddd;
}

// TABLES

.table--product-list .wrap--checkbox-all-products {
  background: #333;
  border-color: #666;
}

.table {
  background: #111;

  tr td {
    color: #eee;
    border-color: #666;
  }

  .tr--account-locked {
    .table__td {
      color: #ff0000;
      background: rgba(255, 0, 0, 0.1);

      &:first-of-type {
        padding-left: .75rem;
      }

      .btn--link {
        color: #ff0000;
      }
    }
  }

  .border-left--light,
  .border-left--light:hover {
    border-left-color: #666;
  }

}

.table--order-summary tr td {
  color: #eee;

  .text--md-grey {
    color: #bbb;
  }

  .icon--pop-over.op-50 {
    opacity: .75;
  }

}

.table--zebra-list {

  .table__th {}

  tr td {

    background: #222;
    border-color: #666;
    color: #eee;
  }

  tr:nth-child(2n+1) td {
    background: #111;
  }

}

table tr.even,
table tr.alt,
table tr:nth-of-type(even) {
  background-color: #111;
}


.table__tbody--lines .table__td--product-search,
.table__tbody--warehouse-inventory .table__td--product-search,
.table__tbody--warehouse-inventory {
  background-color: #222;
  border-color: #666;
}

.table__tbody--lines .table__td--warehouse-inventory:first-of-type,
.table__tbody--warehouse-inventory .table__td--warehouse-inventory:first-of-type {
  border-right-color: #666;
}

.table__tr--merge-orders.selected,
.table__tr--merge-orders.selected:hover {
  background-color: #222;
}

.table__tbody--single-total .table__td {
  background-color: #333;
}


.list--content-blocks {

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .5);
    border-left: rem-calc(5) solid #111;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .25);
    border-left: rem-calc(5) solid #111;
  }

}

.table__tbody--totals .table__td {
  background-color: #444;
  border-color: #666;

}

.table__tbody--totals .table__td--grand-total {
  background-color: #666;
  border-color: #999;
}

.table-inner-scroll--header {
  border-right-color: #111;
}

.table__th--warehouse-inventory-value {
  background-color: #111;
  border-color: #666;
}

.th--inner-ellipsis {
  border-color: #444;
}

.wrap--product-addl-info-list {
  background-color: #111;
  border-color: #666;

  &:before {
    border-bottom-color: #666;
  }

  .list__item--line-qty {
    color: #bbb;
  }

}

.wrap--goods-receipt,
.wrap--bom-product {
  border-color: #666;
}

.wrap__inner--product-addl-info-list,
.list--line-details,
.list--kit-component,
.list--product-warehouse {
  border-bottom-color: #666;
}

.wrap--product-addl-info-item {
  border-top-color: #666;
}

.wrap--order-revision-callout {
  background-color: #FFD324;

  .btn--link,
  .btn--link:focus,
  .btn--action,
  .btn--action:focus {
    color: #111;
  }

}

.wrap--product-list-inner-scroll {

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .5);
    border-left-color: #111;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .25);
    border-left-color: #111;
  }
}

.zuck .wrap--product-list-inner-scroll {
  max-height: calc(100vh - 16rem);
}

.list--product-top-bar {
  .list__item--dropdown .label--selectable span:nth-of-type(2) {
    display: flex;
    align-items: center;
  }
}

.modal__content,
.wrap--eula-scroll {

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .5);
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .25);
  }

}

.btn--pagination,
.btn--pagination:focus {
  color: #bbb;

  &:hover {
    background-color: #444;
    color: #ddd;
  }

}

.btn--pagination.active,
.btn--pagination .active:hover,
.btn--pagination:focus.active,
.btn--pagination:focus .active:hover {
  color: #eee;
  background: transparent;
}

.link--order-header,
.link--order-header:hover {
  color: #fff !important;
}

.divider--top,
.divider--bottom,
.list--line-details,
.divider--right,
.divider--left {
  border-color: #666;
}

.border-right--light,
.border-right--light:hover,
.border-top--light,
.border-top--light:hover {
  border-color: #444;
}

.alert--feature-lock {
  background: #111;
  color: #fff;
}

.alert--standard {
  background: #222;

  h4 {
    color: #eee;
  }

}

.alert--neutral {
  background: #222;
  border-color: #666;
}

.panel--actions {
  background: #111;
  border-color: #666;

  label {
    color: #eee;
  }

}

.label-config__carrier-fine-print {
  color: #666;
}

.btn--tab {
  background: #444;
  color: #777;
  border-color: #666;

  &:hover {
    color: #bbb;
  }

}

.list__item--tab.active {
  border-right-color: #666;
  border-left-color: #666;

  &:first-of-type {
    border-left-color: transparent;
  }

  &:last-of-type {
    border-right-color: transparent;
  }

  .btn--tab {
    background: #111;
    color: #eee;
  }
}

.panel--unselected {
  background: #333;
  color: #fff;

  .op-50 {
    opacity: .8;
  }
}

.alert--tally {
  background-color: #111;

  &.alert--error {

    background: rgba(255, 0, 0, .1);
    border-color: #ff0000;
  }

  .error-block {
    background: rgba(255, 0, 0, .2);
  }

}

.error-block,
.error-block p {
  color: #111;
}

.inner-wrap__panel--actions {

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .3);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, .25);
  }
}


.modal .alert--error,
.alert--error {
  background: rgba(255, 0, 0, .1);
  border: 1px solid #ff0000;
}

.list--accordion,
.wrap__list--order-data,
.wrap__list--order-data:last-of-type {
  border-color: #666;
}

#iverson-page {

  .select,
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"] {
    background-color: #222;
    border-color: #999;
    color: #eee;

    &:focus {
      border-color: #3E992C;
      background-color: #111;
    }

  }

  .wrap--checkbox-all-items input[type="checkbox"] {
    margin: 0 auto;
  }

  .select--updated,
  .select--loading,
  .select--error,
  .select--warning {
    background-color: #222;
  }

}

.list--empty-content {
  color: #ccc;
}


.select,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
.textarea,
textarea {
  background-color: #222;
  border-color: #999;
  color: #eee;

  &:focus {
    border-color: #3E992C;
    background-color: #111;
  }

  &::selection {
    background-color: $txt-green;
    color: #fff;
  }

  &.input--error {
    border-color: #ff0000;
    color: #ff0000;
  }

}


#dropdown-root {

  .select,
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  .textarea,
  textarea {
    background-color: #222;
    border-color: #999;
    color: #eee;

    &:focus {
      border-color: #3E992C;
      background-color: #111;
    }

  }

  .list__item--dropdown .label--tag .flex--justify-nowrap span:nth-of-type(2) {
    display: flex;
    align-items: center;
  }

}

.wrap--input-prefix {
  background-color: #222;
  border-color: #999;
  color: #eee;
}

.btn--arrow-up:after {
  border-bottom-color: #eee;
}

.btn--arrow-down:after {
  border-top-color: #eee;
}

.btn--suffix {
  background-color: #666;
  border-color: #999;
  color: #eee;

  &:hover {
    background-color: #444;

    .btn--arrow-up:after {
      border-bottom-color: #fff;
    }

    .btn--arrow-down:after {
      border-top-color: #fff;
    }

  }
}

.wrap--inner-dropdown:before {
  border-bottom-color: #999;
}

.list--dropdown {
  background-color: #111;
  border-color: #999;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 70%);

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .5);
    border-left: rem-calc(3) solid #111;
    border-right: rem-calc(3) solid #111;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .25);
    border-left: rem-calc(3) solid #111;
    border-right: rem-calc(3) solid #111;
  }
}

.list__item--dropdown {
  border-color: #999;
  color: #eee;
}

.label--selectable,
.list__link--dropdown {

  color: #ddd;

  &.disabled {

    &:hover,
    &:focus:hover {
      color: #aaa;
    }
  }

  &.selected,
  &:hover,
  &:focus:hover,
  &:focus,
  &.list__link--dropdown-arrow-focus {
    background: #333;
    color: #fff;
    text-decoration: none;
  }

  &.list__link--dropdown-arrow-focus {
    outline: 2px dashed $ordoro-blue;
  }

}

.label--selectable-pt-pb-0.label--selectable-left-indent label {
  margin-left: .5rem;
  border-left-color: #666;
}

.list__item--dropdown-header {
  background-color: #444;
  color: #fff;
  border-bottom: 1px solid #999;

  label {
    color: #fff;
  }
}

.list__item--dropdown-header-pkg-type {

  .label--selectable,
  .list__link--dropdown {

    color: #ddd;

    &.selected,
    &:hover,
    &:focus:hover,
    &.list__link--dropdown-arrow-focus {
      background: #666;
      color: #fff;
      text-decoration: none;
    }

    &.list__link--dropdown-arrow-focus {
      outline: 2px dashed $ordoro-blue;

      label {
        color: #fff;
      }

    }

  }
}

.list__item--dropdown-last .list__link--dropdown,
.list__item--dropdown-last .list__link--dropdown:focus,
.list__link--batch-list,
.list__link-batch-list:focus {
  background-color: #444;
}

.wrap--support-pin {
  background-color: #777;
}

.wrap--activity-header {
  background-color: #444;
  color: #fff;
  border-bottom: 2px solid #999;
}

.list__item--activity {
  color: #eee;
}

.result-link,
.log-link {
  color: #999;
}

.list__link--preset-save,
.list__link--preset-save:focus,
.list__link--logout,
.list__link--logout:focus {
  background: #333;

  &:hover,
  &:focus:hover {
    color: #fff;
    background: #444;
  }
}

label,
.form-list .form-list-item label,
.inline-text-button.darker {
  color: #eee;
}

.label--fancy:hover {
  color: #fff;
}


// MODALS

.loading--hold-at-location {
  .spinner--md {
    @include spinner(0.3rem, #ccc, 0.6, 1.5rem);
    @include animate-spin;
  }
}

.wrap--modal {
  background: rgba(0, 0, 0, .75);
}

.modal {
  background: #111;
  border: 3px solid #999;

  label {
    color: #eee;
  }

}


.modal__header {
  background: #444;
  border-bottom: 3px solid #999;

  h3 {
    color: #fff;
  }

}

.modal__content,
.modal__footer {
  background: #111;
}

.modal__errors {
  background: #111;
}

.modal__footer {
  border-top: 3px solid #999;
}

.wrap--modal-filters {
  border-color: #666;
}

.wrap--filter-operators {
  background: #333;
  border-color: #222;
}

.wrap--eula-scroll {
  border-color: #666;
}

.draggable {
  background-color: #111;
}

.drag-handle {
  opacity: 1;
}

.drag-handle .i-drag-handle,
.drag-handle .i-three-bars {
  opacity: .5;
}

.drag-handle:hover .i-drag-handle,
.drag-handle:hover .i-three-bars {
  opacity: .8;
}

.label--fancy-untagged {
  background-color: #222;
}

input[type='checkbox'].checkbox--fancy,
input[type='radio'].radio--fancy {
  --active-inner: #fff;
  --focus: 3px rgba(255, 255, 255, 0.2);
  --border: #999;
  --border-hover: #bbb;
  --background: #111;
  --disabled: #444;
  --disabled-inner: #333;


  &:after {}

  &:checked {

    &+.label__text--fancy div .label__pre-text {
      color: #fff;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
}



input[type='checkbox'],
input[type='checkbox'].checkbox--fancy,
label:not(.wrap--checkbox-all-items) input[type='checkbox'] {

  --active: #34a0cd;
  --active-inner: #fff;
  --focus: 3px rgba(255, 255, 255, 0.2);
  --border: #999;
  --border-hover: #bbb;
  --background: #111;
  --disabled: #444;
  --disabled-inner: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem-calc(22);
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 rem-calc(6) 0 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  flex-shrink: 0;

  &.checkbox--multi-location {
    vertical-align: middle;
  }

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
      color: #fff;
    }
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }

  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &:not(.switch) {
    width: rem-calc(22);

    &:after {
      opacity: var(--o, 0);
    }

    &:checked {
      --o: 1;
    }
  }

  &:indeterminate {
    --b: #999;
    --bc: #fff;
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
      color: #ff0000;

      &:after {
        display: block;
        font-family: $aktiv-grotesk-regular;
        content: '(Excluded)';
        font-size: rem-calc(10);
        color: #ff0000;
      }

    }
  }

  &:not(.switch--fancy) {
    border-radius: 5px;

    &:after {
      width: 6px;
      height: 12px;
      border: 3px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }

    &:indeterminate {
      &:after {
        width: 12px;
        height: 3px;
        border: 0;
        left: 4px;
        top: 8px;
        transform: rotate(var(--r, 0deg));
        opacity: 1;
        background-color: #fff;
      }

      &:checked {
        --r: 0deg;
      }
    }
  }

}

input[type='checkbox'].checkbox--fancy,
input[type='checkbox'] {
  &:not(.switch--fancier) {
    border-radius: 5px;

    &:after {
      width: 6px;
      height: 12px;
      border: 3px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }
  }

  &.switch--fancy {
    width: 38px;
    border-radius: 11px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: .6;
        }
      }
    }
  }
}

// Preset Modal Switches

input[type=checkbox].switch--fancier {
  height: 0;
  width: 0;
  visibility: hidden;

  --active: unset;
  --active-inner: unset;
  --focus: unset;
  --border: unset;
  --border-hover: unset;
  --background: unset;
  --disabled: unset;
  --disabled-inner: unset;
  -webkit-appearance: none;
  -moz-appearance: none;

  outline: none;
  display: block;
  vertical-align: unset;
  position: unset;
  margin: 0;
  cursor: unset;
  border: unset;
  background: unset;
  transition: unset;
  flex-shrink: 0;

  &:after {
    display: none;
  }

}

.switch__background--fancier {
  background: #444;

}

input[type=checkbox].switch--fancier+.switch__background--fancier {
  margin: 0;
  vertical-align: unset;
  display: block;
}

.list__item--shipping-shipperIDs .switch__background--fancier:after {
  margin: 0;
  top: .25rem;
  bottom: unset;
  display: block;
}


input[type='radio'],
input[type='radio'].radio--fancy {
  border-radius: 50%;

  &:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }

  &:checked {
    --s: .65;
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
    }
  }

  &:not(.switch) {

    &:after {
      opacity: var(--o, 0);
      background-color: #34a0cd;
    }

    &:checked {
      background-color: #fff;
    }
  }
}

table input[type='checkbox'],
.wrap--checkbox-all-items input[type='checkbox'] {
  height: rem-calc(16);

  &:not(.switch) {
    width: rem-calc(16);
    border-radius: 3px;

    &:after {
      width: 5px;
      height: 10px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 5px;
      top: 1px;
    }

    &:indeterminate {
      &:after {
        width: 8px;
        height: 2px;
        border: 0;
        left: 3px;
        top: 6px;
        transform: rotate(var(--r, 0deg));
        opacity: 1;
        background-color: #fff;
      }

      &:checked {
        --r: 0deg;
      }
    }

  }

}

.sync-cart-list .sync-cart-list-item {
  .panel {
    background-color: #333;
    border-color: #666;

    dt,
    dd {
      color: #fff;
    }

    label {
      display: flex;
      align-items: center;
    }

    .alert {
      color: #fff;
    }
  }
}

// DATEPICKER

.react-datepicker__month-container,
.react-datepicker__month {
  background-color: #111;
  color: #ccc;
}

.react-datepicker__header {
  border-radius: 0;
  background-color: #333;
  color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff;
}

#dropdown-root .wrap--dropdown-list {
  border-color: #ddd;
  background-color: #111;
}

#dropdown-root .wrap--dropdown-list--left.wrap--dropdown-list--down:before,
#dropdown-root .wrap--dropdown-list--right.wrap--dropdown-list--down:before {
  border-bottom-color: #ddd;
}

#dropdown-root .wrap--dropdown-list--left.wrap--dropdown-list--up:after,
#dropdown-root .wrap--dropdown-list--right.wrap--dropdown-list--up:after {
  border-top-color: #ddd;
}


.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: #999;
}

.react-datepicker__day--in-range {
  background-color: #444;
}

.list__item--dropdown-empty {
  .spinner--sm {
    @include spinner(0.2rem, #ccc, 0.6, 1rem);
    @include animate-spin;
  }

  strong {
    color: #ccc;
  }
}


// DASHBOARD

.icon--setup-sales-channel {
  .cls-4 {
    fill: #eee;
  }

  .cls-5 {
    fill: #aaa;
  }

  .cls-10 {
    fill: #ddd;
  }
}

.icon--setup-carrier {

  .cls-1 {
    fill: #666;
  }

  .cls-2 {
    fill: #d7c6b3;
  }

  .cls-3 {
    fill: #fff;
  }

  .cls-4 {
    fill: #c0a78b;
  }

  .cls-6 {
    fill: #666;
  }

}

.icon--setup-warehouse {

  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: #666;
  }

  .cls-3 {
    fill: #c0a78b;
  }
}


.panel--analytics,
.panel--dashboard,
.panel--form-wrap {
  color: #fff;
  background: #111;
  border-color: #666;

  dl,
  p,
  li {
    color: #fff;
  }

  .list__item .btn--link {
    color: #eee;
  }

}

.panel__header--analytics,
.panel--form-wrap .panel__header {
  background-color: #444;
  color: #fff;
  text-shadow: none;
  border-bottom-color: #666;

}

.subheader--analytics,
.panel .subheader--analytics,
.panel--form-wrap .panel__header h3 {
  color: #eee;
  @include text-shadow-standard;

  &.text--lt-grey {
    color: #aaa;
  }
}

.text--md-grey,
.panel .text--md-grey {
  color: #999;
}

.text--md-dk-grey,
.panel .text--md-dk-grey {
  color: #ccc;
}

.text--black {
  color: #fff
}

.wrap--dashboard-stats .list--horiz-bar-graph-none {
  background-color: #111;
  border-color: #444;

  .list__title {
    color: #ccc;
  }

  .list__item {
    color: #ddd;
  }
}

.wrap--bar-graph {
  background-color: #444;
}

.panel--dashboard-action-items {
  border-color: darken(#34a0cd, 8%);

  .panel__header--dashboard {
    background: rgba(52, 160, 205, 0.7);
    border-color: darken(#34a0cd, 8%);

    .subheader {
      color: #fff;
      text-shadow: none;
    }

  }
}


.wrap--dashboard-content .panel--warning {
  border-color: #b29600;

  .panel__header--dashboard {
    background: rgba(255, 211, 36, 0.7);
    border-color: #b29600;

    .subheader {
      color: #fff;
      text-shadow: none;
    }
  }
}

.panel--dashboard-quickship,
.panel--setup-guide {

  .panel__header--dashboard,
  .panel__header--setup-guide {
    background: #222;
    border-color: #666;

    .subheader--dashboard {
      color: #fff;
      text-shadow: none;
    }

  }
}

.wrap--dashboard-stats .loading .graph-loading:after {
  content: "";
  @include spinner(0.35em, #ccc, 0.5, 2em);
}

.list-processing {
  content: "";
  @include spinner(0.3em, #ddd, 0.35, 2em);
}

.list--analytics-unconfigured {
  background-color: rgba(0, 0, 0, .75);

  .list__title,
  .list__item {
    color: #bbb;
  }
}

.value--grey-bar {
  color: #333;
  background-color: #333;
}

.wrap--graph {
  border-color: #666;
}

.list__item--graph-total,
.panel .list__item--graph-total {
  border-color: #666;
}

.list__item--graph-title {
  color: #eee;
}

.list__item--graph-value {
  color: #bbb;
}

.wrap--table-panel {

  .loading .wrap--graph {
    border-right: 1px solid #333;

    &:after {

      @include spinner(0.5em, #fff, 0.5, 3.5em);

    }
  }
}

.loading .graph-loading {

  &:after {
    @include spinner(0.35em, #fff, 0.5, 2em);
  }

}

// SETTINGS

.inner-wrap--side-nav {
  background-color: #222;
  border-color: #666;
}

.link--side-nav {
  color: #999;

  &:hover {
    color: #ccc;
    border-color: #777;
  }
}

.link--side-nav-active,
.link--side-nav-active:hover,
.link--side-nav-active:focus {
  color: #5DE542;
}

.settings-list .settings-list-item.archived,
.list--settings .list__item--settings.archived {
  background-color: #333;
}


.list--settings .list__item--settings.warning,
.settings-list .settings-list-item.warning {
  background-color: rgba(255, 211, 36, .1);

  .wrap--reauth-notice {
    color: #FFD324;
  }

}

.wrap--nested-form label {
  margin-bottom: .5rem;
}

.helper,
.helper-li {
  background: #222;
  border-color: #666;

  li {
    color: #fff;
  }
}

.alert--zuck-mode,
.alert--success {
  background-color: #222;
}

.settings-list-item-form-wrap li label {
  display: flex;
  align-items: center;
}

.inner-wrap--logo {
  background-color: #fff;
  padding: 1.5rem 0;
  text-align: center;
}

.wrap--auto-dropshipping-rule {

  border-color: #666;
  background: #111;

  p {
    color: #999;
  }


  &.enabled {
    border: 3px solid lighten($ordoro-blue, 5%);
    background: #333;

    p {
      color: #fff;
    }
  }

}

.wrap--rules-tag-dropdown .btn--dropdown span:not(.error-message) {
  color: #bbb;
}

// Product Detail Page

.sub-nav__link {
  color: #aaa;

  &:hover {
    color: #eee;
    border-color: #999;
  }

  &.active,
  .active:hover,
  .active:focus {
    color: #5DE542;

  }

}

// Data Import

.list--progress,
.progress-bar,
.dropzone.drag-hover {
  background-color: #222;
  color: #fff;
}

// Analytics

.list--analytics-filters {
  background-color: #222;
  border-color: #666;
  color: #eee;
}


.side-nav__item--active,
.side-nav .side-nav__item--active {

  .side-nav__link,
  .side-nav__link:hover,
  .side-nav__link:focus {
    color: #fff;
  }
}

.side-nav li a:not(.button) {
  color: #ccc;
  line-height: 1.3;
  padding: 1rem .875rem;

  &:hover {
    color: #eee;
    background-color: rgba(255, 255, 255, .1);
  }
}

.btn--filter-toggle {
  background-color: #ccc;
  color: #222;

  &:after {
    border-top-color: #222;
  }
}

.open .btn--filter-toggle:after {
  border-bottom-color: #222;
}

.list--filters {
  background-color: #ccc;
  left: 1px;
}

.list__item--filter-select-all {
  border-color: #222;
}

.label--filter {
  display: flex;
  align-items: center;
  color: #222;
}

.table--analytics {

  thead {
    background-color: #ddd;

    tr th {
      color: #111;
    }

  }

  td {
    background-color: #111;
    border-bottom-color: #444;
    color: #ccc;
  }
}

.table--revenue-statement tr:first-of-type td,
.table--revenue-statement tr:first-of-type strong {
  color: #fff;
}

.table--revenue-statement tr:last-of-type td {
  border-color: #999;
}

.q0-10 {
  fill: #111;
  stroke: #999;
}

.q1-10 {
  fill: rgba(52, 160, 205, .1);
  stroke: #999;
}

.q2-10 {
  fill: rgba(52, 160, 205, .2);
  stroke: #999;
}

.q3-10 {
  fill: rgba(52, 160, 205, .3);
  stroke: #999;
}

.q4-10 {
  fill: rgba(52, 160, 205, .4);
  stroke: #999;
}

.q5-10 {
  fill: rgba(52, 160, 205, .5);
  stroke: #999;
}

.q6-10 {
  fill: rgba(52, 160, 205, .6);
  stroke: #bbb;
}

.q7-10 {
  fill: rgba(52, 160, 205, .7);
  stroke: #bbb;
}

.q8-10 {
  fill: rgba(52, 160, 205, .8);
  stroke: #bbb;
}

.q9-10 {
  fill: rgba(52, 160, 205, .9);
  stroke: #ddd;
}

.q10-10 {
  fill: rgba(52, 160, 205, 1);
  stroke: #ddd;
}

.states {
  path {
    &:hover {
      fill: #666;
      stroke: #fff;
    }
  }
}

.popover-wrapper .popover .content {
  background-color: #222;
}

// Shipper/Settings Logo Sadness

.wrap--logo,
.label__logo {

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {
    background: #fff url(../images/usps.svg) center center no-repeat;
    height: 2.25rem;
    width: 10.5rem;
    background-size: 93%;
    border-radius: rem-calc(4);
  }

  &.ups {
    background: #fff url(../images/ups.svg) center center no-repeat;
    background-size: 87%;
    height: 4.75rem;
    width: 4.25rem;
    border-radius: rem-calc(4);
  }

  &.dhl,
  &.dhl_ecommerce {
    background: #fff url(../images/dhl.svg) center center no-repeat;
    background-size: 93%;
    height: 2.75rem;
    width: 9.5rem;
    border-radius: rem-calc(4);
  }

  &.fedex {
    background: #fff url(../images/fedex.svg) center center no-repeat;
    width: 9rem;
    height: 3rem;
    background-size: 93%;
    border-radius: rem-calc(4);
  }

  &.canada_post {
    background: #fff url(../images/canada-post.svg) center center no-repeat;
    background-size: 93%;
    height: 2.75rem;
    width: 9.5rem;
    border-radius: rem-calc(4);
  }

  &.amazon {
    background: #fff url(../images/amazon-buy-shipping-logo.png) center center no-repeat;
    background-size: 93%;
    height: 2.75rem;
    width: 9rem;
    border-radius: rem-calc(4);
  }

  &.amazon_shipper {
    background: #fff url(../images/amazon-shipping.png) center center no-repeat;
    background-size: 93%;
    height: 4.5rem;
    width: 9rem;
    border-radius: rem-calc(4);
  }

  &.pitney_cbds,
  &.newgistics {
    background: #fff url(../images/pitney-bowes.svg) center center no-repeat;
    background-size: 93%;
    height: 3rem;
    width: 10.5rem;
    border-radius: rem-calc(4);
  }

  &.quickbooks {
    background: transparent url(../images/quickbooks-white.svg) top left no-repeat;
  }

  &.stripe {
    background: #fff url(../images/stripe-blue.svg) center center no-repeat;
    background-size: 87%;
    height: 4rem;
    width: 10rem;
    border-radius: rem-calc(4);
  }

  &.australia_post {
    background: #fff url(../images/australia-post.svg) center center no-repeat;
    background-size: 93%;
    width: 10rem;
    height: 2.25rem;
    border-radius: rem-calc(4);
  }

  &.onelivex {
    background: #fff url(../images/x-delivery.svg) center center no-repeat;
    background-size: 88%;
    height: 3.85rem;
    width: 3.85rem;
    border-radius: rem-calc(4);
  }

  &.sendle {
    background: #fff url(../images/sendle.svg) center center no-repeat;
    background-size: 93%;
    height: 2.85rem;
    width: 9rem;
    border-radius: rem-calc(4);
  }
}

.wrap--selected-rate-service,
.label__carrier-logo,
.wrap-shipper-addl-options {
  &:before {
    border-radius: rem-calc(2);
  }

  &.fedex {
    &:before {
      background: #fff url(../images/fedex.svg) center center no-repeat;
      background-size: 92%;
      width: rem-calc(37);
      height: rem-calc(13);
    }
  }

  &.amazon {
    &:before {
      background: #fff url(../images/amazon-mark-01.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(17);
      height: rem-calc(15);
    }
  }

  // FIXME @matto: need to get a fucking SVG for this amazon shipping logo

  &.amazon_shipper {
    &:before {
      background: #fff url(../images/amazon-shipping.png) center center no-repeat;
      background-size: 93%;
      width: rem-calc(48);
      height: rem-calc(16);
    }
  }

  &.ups {
    &:before {
      background: #fff url(../images/ups.svg) center center no-repeat;
      background-size: 87%;
      width: rem-calc(19);
      height: rem-calc(19);
    }
  }

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {

    &:before {
      background: #fff url(../images/usps-mark.svg) center center no-repeat;
      background-size: 100%;
      width: rem-calc(22);
      height: rem-calc(14);
      border: 1px solid #fff;
    }
  }

  &.canada_post {
    &:before {
      background: #fff url(../images/canada-post-mark.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.australia_post {
    &:before {
      background: #fff url(../images/australia-post-mark.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.dhl,
  &.dhl_ecommerce {
    &:before {
      background: #fff url(../images/dhl.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(55);
      height: rem-calc(13);
    }
  }

  &.pitney_cbds,
  &.newgistics {
    &:before {
      background: #fff url(../images/pitney-bowes-mark.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(16);
      height: rem-calc(16);
    }
  }

  &.onelivex {
    &:before {
      background: #fff url(../images/x-delivery.svg) center center no-repeat;
      background-size: 88%;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.sendle {
    &:before {
      background: #fff url(../images/sendle.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(55);
      height: rem-calc(15);
    }
  }

}

.label__carrier-logo,
.wrap-shipper-addl-options {

  &.fedex {
    &:before {
      width: rem-calc(74);
      height: rem-calc(26);
    }
  }

  &.amazon {
    &:before {
      width: rem-calc(34);
      height: rem-calc(30);
    }
  }

  // FIXME @matto: need to get a fucking SVG for this amazon shipping logo

  &.amazon_shipper {
    &:before {
      width: rem-calc(78);
      height: rem-calc(26);
    }
  }

  &.ups {
    &:before {
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {

    &:before {
      width: rem-calc(44);
      height: rem-calc(28);
    }
  }

  &.canada_post {
    &:before {
      background: #fff url(../images/canada-post-mark.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.australia_post {
    &:before {
      background: #fff url(../images/australia-post-mark.svg) center center no-repeat;
      background-size: 93%;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.dhl,
  &.dhl_ecommerce {
    &:before {
      width: rem-calc(110);
      height: rem-calc(26);
    }
  }

  &.pitney_cbds,
  &.newgistics {
    &:before {
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.onelivex {
    &:before {
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.sendle {
    &:before {
      width: rem-calc(99);
      height: rem-calc(27);
    }
  }
}

.td__shipper-logo {
  width: 100%;

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {
    background: #fff url(../images/usps.svg) center center no-repeat;
    min-height: rem-calc(34);
    background-size: 93%;
    border-radius: rem-calc(4);
  }

  &.ups {
    background: #fff url(../images/ups.svg) center center no-repeat;
    background-size: 87%;
    margin-left: auto;
    margin-right: auto;
    min-height: rem-calc(38);
    width: rem-calc(34);
    border-radius: rem-calc(4);
  }

  &.dhl,
  &.dhl_ecommerce {
    background: #fff url(../images/dhl.svg) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(33);
    width: 100%;
    border-radius: rem-calc(4);
  }

  &.fedex {
    background: #fff url(../images/fedex.svg) center center no-repeat;
    min-height: rem-calc(33);
    background-size: 92%;
    border-radius: rem-calc(4);
  }

  &.canada_post {
    background: #fff url(../images/canada-post.svg) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(33);
    border-radius: rem-calc(4);
  }

  &.amazon {
    background: #fff url(../images/amazon-buy-shipping-logo.png) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(33);
    border-radius: rem-calc(4);
  }

  &.amazon_shipper {
    background: #fff url(../images/amazon-shipping.png) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(34);
    border-radius: rem-calc(4);
  }

  &.pitney_cbds,
  &.newgistics {
    background: #fff url(../images/pitney-bowes.svg) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(34);
    border-radius: rem-calc(4);
  }

  &.australia_post {
    background: #fff url(../images/australia-post.svg) center center no-repeat;
    background-size: 93%;
    margin-left: auto;
    margin-right: auto;
    height: rem-calc(34);
    width: rem-calc(34);
    border-radius: rem-calc(4);
  }

  &.onelivex {
    background: #fff url(../images/x-delivery.svg) center center no-repeat;
    background-size: 88%;
    height: rem-calc(34);
    width: 60%;
    margin: auto;
    border-radius: rem-calc(4);
  }

  &.sendle {
    background: #fff url(../images/sendle.svg) center center no-repeat;
    background-size: 93%;
    min-height: rem-calc(34);
    border-radius: rem-calc(4);
  }
}

.list__item--shipping-shipperIDs label {
  position: relative;

  &:after {
    display: none;
  }
}

.selected-shipper {
  display: none;
}

// Pricing Plan Overrides

.icon--app .icon__bg-fill--app {
  fill: #111;
}

.wrap--app-pricing-summary {
  color: #fff;
  background-color: #111;
}

.wrap--app-pricing-summary-disabled {
  border-color: #444;

  .list__title {
    color: #666;
  }

  .icon--app-shipping {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #444;
    }
  }

  .icon--app-inventory {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #444;
    }
  }

  .icon--app-dropshipping {

    .icon__hexagon--app,
    .icon__name--app {
      fill: #444;
    }
  }
}

.alert--app-disabled {
  border-color: #444;
  background-color: #222;
}

.list__item--pricing-summary {
  color: #eee;
}

.wrap--pricing-summary .list--pricing-summary {
  background: #111;
  border-color: #777;

  &.plan-selected {
    border-color: yellow;
  }

}

// WELCOME TAB

.wrap--app-resource {

  &:hover {
    background: #000;
  }
}

.list--app-resource {
  background: #222;
  border-color: #444;
}

.btn--video-overlay {
  background-color: #333;
  border-color: #eee;

  &:hover {
    background-color: #444;
    border-color: #fff;
  }
}

.list--app-resource-best {
  border-color: #eee;

  &:before {
    background: #eee;
    color: #111;
  }

}

// RATE SHOPPING

.zropdown__header--rs {
  background-color: #111;
}

.zropdown__header--rs-inner {
  color: #fff;
}

.table--rs {

  tr:not(.tr--has-error):hover {
    .table__td {
      background: #222;
    }
  }

  .tr--focus {
    outline: 2px dashed #48aad2;
  }

  .tr--rate-selected {
    td {
      background-color: #333;
    }
  }

}