@font-face {
    font-family: "AvantGardeGothicITCW01B 731069";
    src: url("../fonts/c68f0543-0caf-4988-b234-355520476b8c.woff") format("woff"), url("../fonts/80f98a03-905d-49e6-8614-cec7c32ca4f2.svg#80f98a03-905d-49e6-8614-cec7c32ca4f2") format("svg");
}


@font-face {
    font-family: "AvantGardeGothicITCW01D 731075";
    src: url("../fonts/c18aee57-7585-4a8b-9a1c-69d226301d73.woff") format("woff"), url("../fonts/f2de529c-11d2-43b7-be7c-05c5aaeaf133.svg#f2de529c-11d2-43b7-be7c-05c5aaeaf133") format("svg");
}


@font-face {
    font-family: "AvantGardeGothicITCW01M 731087";
    src: url("../fonts/43b723ac-a6f2-4d5d-9d72-c50aea85ecee.woff") format("woff"), url("../fonts/e3929a31-b148-4180-91be-4b490bdac87d.svg#e3929a31-b148-4180-91be-4b490bdac87d") format("svg");
}


@font-face {
    font-family: "ITCAvantGardeW01-CnBd";
    src: url("../fonts/30d9d074-824f-4957-a97e-90c6b45bab98.woff") format("woff"), url("../fonts/d209c71b-de66-4172-8488-c843b48ccf91.svg#d209c71b-de66-4172-8488-c843b48ccf91") format("svg");
}


@font-face {
    font-family: "ITCAvantGardeW01-CnBk";
    src: url("../fonts/c24e2d71-b63f-4e78-aef6-684261af637f.woff") format("woff"), url("../fonts/97fb8cd4-bd1f-4c24-81d4-0ade48c166ff.svg#97fb8cd4-bd1f-4c24-81d4-0ade48c166ff") format("svg");
}


@font-face {
    font-family: "ITCAvantGardeW01-CnDm";
    src: url("../fonts/492a4e64-4c6c-40b2-ade3-d1ebc5d93405.woff") format("woff"), url("../fonts/e1ee22b5-5f91-478c-88c3-ca2d903cf54d.svg#e1ee22b5-5f91-478c-88c3-ca2d903cf54d") format("svg");
}


@font-face {
    font-family: "ITCAvantGardeW01-CnMd";
    src: url("../fonts/f20fba45-d4bf-4a8a-a08c-1dec4f3af1ba.woff") format("woff"), url("../fonts/e2d317fa-40ef-4dc6-a126-89aff6207703.svg#e2d317fa-40ef-4dc6-a126-89aff6207703") format("svg");
}


@font-face {
    font-family: "Symbolicons-Line";
    src: url("../fonts/ss-symbolicons-line.woff") format("woff"), url("../fonts/ss-symbolicons-line.svg") format("svg");
}


@font-face {
    font-family: "Symbolicons-Block";
    src: url("../fonts/ss-symbolicons-block.woff") format("woff"), url("../fonts/ss-symbolicons-block.svg") format("svg");
}


@font-face {
    font-family: "SS-Pika";
    src: url("../fonts/ss-pika.woff") format("woff"), url("../fonts/ss-pika.svg") format("svg");
}

@font-face {
    font-family: "Aktiv-Grotesk-Regular";
    src: url("../fonts/aktiv--grotesk--regular.woff") format("woff"), url("../fonts/aktiv--grotesk--regular.svg#aktiv--grotesk--regular") format("svg");
}

@font-face {
    font-family: "Aktiv-Grotesk-Italic";
    src: url("../fonts/aktiv--grotesk--italic.woff") format("woff"), url("../fonts/aktiv--grotesk--italic.svg#aktiv--grotesk--italic") format("svg");
}

@font-face {
    font-family: "Aktiv-Grotesk-Bold";
    src: url("../fonts/aktiv--grotesk--bold.woff") format("woff"), url("../fonts/aktiv--grotesk--bold.svg#aktiv--grotesk--bold") format("svg");
}

@font-face {
    font-family: "Aktiv-Grotesk-Bold-Italic";
    src: url("../fonts/aktiv--grotesk--bold--italic.woff") format("woff"), url("../fonts/aktiv--grotesk--bold--italic.svg#aktiv--grotesk--bold--italic") format("svg");
}
