//
// Tags styling
// ------------

.list__item--tag {
    display: inline-block;
    font-size: rem-calc(10);
    border: 1px solid #999;
    padding: .1rem .1rem .15rem .2rem;
    margin-left: 0;
    margin-right: .4rem;
    margin-bottom: 0;
    line-height: 1;
    color: #444;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
}

.list--tags-lg .list__item--tag {
    font-size: rem-calc(14);
    padding: .2rem .3rem;
 }


.list__item--sfp {
    background: #000;
    color: #fff;
    padding: .1rem .35rem .15rem .35rem;
    border: 2px solid #ff9900;
}

.list__item--gen-filter {
    background: #666;
    border: 1px solid #222;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.wrap--tag-color {
    border-radius: 4px;
    border: 1px solid #666;
    box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);

    &.focused {
        border-color: #3E992C;
    }
}

.prefix--tag-color {
    background-color: #eee;
    border-radius: 4px 0 0 4px;
    border: 0;
    width: rem-calc(27);
}

input[type="text"].input--tag-color {
    background: transparent;
    border-radius: 0 4px 4px 0;
    border: 0;
    padding-left: .75rem;
    margin-bottom: 0;
    @include shadow-reset;

    &:focus {
        background: rgba(0,0,0,.0125);
    }
}

.wrap--color-picker {
    padding: 0.9375rem 0.9375rem;
    float: right;
}

.tag-color-block {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(7);
}

.wrap--product-title {

  .tooltip--top:before, .tooltip--top:after, .list__item--tag[data-hint]:before, .list__item--tag[data-hint]:after {
      visibility: hidden;
  }
}
