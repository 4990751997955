//General form customizations
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], textarea {
    border-radius: 4px;
    height: 1.8rem;
    font-size: 1em;

    &.input--error {
        border-color: $error-color;
    }
}

fieldset {
    border: 0;
    padding: 0;
}

.select {
    font-family: $aktiv-grotesk-regular;
    width: auto;
    font-size: .75em;
    color: $body-font-color;
    border: 1px solid $medium-dark-grey;
    height: 1.5em;
    padding: .3em;
    vertical-align: top;
    white-space: nowrap;
    @include appearance(menulist !important);
    background-image: none;
    border-radius: 0;
    &:hover {
        border-color: $medium-dark-grey;
    }
    &:focus {
        outline: none;
        border-color: $body-font-color;
    }
}

.select.taller {
    height: 2.25em;
    line-height: 1.8;
}

.select.bigger-select {
    height: 1.8em;
    line-height: 1.5;
    min-width: 10em;
}


.data-import-table {
    .select.bigger-select {
        font-size: 1em;
        height: 2.4em;
        width: 89%;
        margin-bottom: 0;
    }
}

.select.full-width {
    width: 100%;
    height: 2.3em;
    line-height: 1;
    @include box-sizing(border-box);
}

.th-select {
    width: 100%;
    height: 2.3em;
    font-size: rem-calc(12);
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0;
    line-height: 1;
    @include box-sizing(border-box);
    background: $body-font-color;
    color: #fff;
    border: 1px solid #555;
    @include appearance(menulist !important);
    @include text-shadow-standard;
    &:hover, &:focus {
        background: $medium-dark-grey;
        border: 1px solid $light-grey;
    }
}

.warehouse-label {
    font-size: rem-calc(11);
}

.label-helper {
    font-size: .8em;
    font-weight: normal;
}

.form-list {
    margin-left: 0;
    .form-list-item {
        list-style: none;
        label {
            color: $body-font-color;
            line-height: 1.2;

            &.error {
              color: $error-color;
            }
        }
        label.button {
            color: #fff;
            line-height: 1.35em;
        }
        input[type="text"], input[type="password"] {
            font-size: rem-calc(13);
            height: 2.2em;
            line-height: 1;
        }
        small {
            line-height: 1;
        }
        textarea {
            font-size: rem-calc(13);
        }

    }
    &.input-btm-margin {
        input[type="text"], input[type="password"] {
            margin-bottom: .3em;
        }
    }
    .bigger, &.bigger-list {
        label {
            margin-bottom: .2em;
        }
        input[type="text"], input[type="password"], input[type="number"] {
            font-size: 1em;
            height: 2.5em;
            margin-bottom: .65em;
        }
        .select {
            font-size: .85em;
            height: 2.5em;
        }
    }
    &.bigger-list {
        li {
            margin-bottom: rem-calc(14);
        }
    }
    &.smaller-list {
        li {
            margin-bottom: rem-calc(10);
        }
    }
    .smaller {
        font-size: .9em;
        line-height: 1.25;
    }
    .legal {
        font-size: .6em;
        color: $medium-grey;
    }
    .error {
        input[type="text"], input[type="password"], textarea {
            border-color: $error-color;
            margin-bottom: rem-calc(3);
        }
        label, .required {
            color: $error-color;
        }
    }
}

.required {
    color: $ordoro-orange;
}

p.required {
    font-size: rem-calc(12);
}

.warning-message {
    font-size: .9em;
    padding: 1em;
    background: $warning-bg-color;
    color: $warning-txt-color;
    border: 1px solid $warning-border-color;
    &.suppliers-warning {
        max-height: 6.75em;
        overflow-y: scroll;
    }
}

//Input widths
input[type="text"].input-x-sm {
    width: 10%;
}

input[type="text"].input-sm {
    width: 20%;
}

input[type="text"].input-sm-m {
    width: 25%;
}

input[type="text"].input-md {
    width: 50%;
}

input[type="text"].input-med {
    width: 4em;
}

input[type="text"].input-9em {
    width: 9em;
    height: 2.15em;
}

input[type="text"].input-lg {
    width: 75%;
}

input[type="text"].input-large {
    width: 15em;
}

input[type="text"].input-x-lg {
    width: 90%;
}

input[type="text"].w100 {
    width: 100%;
}


input[type="text"].customs-weight-input {
    width: 3em;
}

input[type="text"].bigger, .input[type="password"].bigger {
    height: 2.3em;
}
input[type="text"].width-auto, .input[type="password"].width-auto {
    width: auto;
}

//Textarea sizes
.textarea-x-sm {
    height: 2.3em;
    min-height: 2.3em;
    width: 95%;
    line-height: 1.1;
}

.textarea--addl-details {
    height: 3em;
    min-height: 3em;
    width: 97%;
    line-height: 1.3;
}

.textarea-sm {
    height: 5em;
    min-height: 5em;
    line-height: 1.1;
}

.textarea-md {
    height: 8em;
    min-height: 8em;
    line-height: 1.1;
}

.textarea-lg {
    height: 10em;
    min-height: 10em;
    line-height: 1.1;
}

.textarea-x-lg {
    height: 12em;
    min-height: 12em;
    line-height: 1.1;
}

//Custom File Upload Input (using only CSS)
.file-upload-wrapper {
    overflow: hidden;
    position: relative;
    [type=file] {
        cursor: inherit;
        display: block;
        opacity: 0;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
    }
}

//Search input
.search-wrap {
    border: 0;
    padding: 0;
    position: relative;
    .input-prefix {
        line-height: 1;
        position: absolute;
        left: .4rem;
        top: .4rem;
        z-index: 1;
        color: $medium-grey;
    }

    .input-suffix {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        padding: 0.1em .65em;
        margin-bottom: 0;
        border-radius: 0 rem-calc(3) rem-calc(3) 0;

        .icon:before {
            top: .15em;
        }

    }
    .input-prefix.with-label {
        top: 1.5em;
        color: $medium-grey;
        .icon-search:before {
            top: 0.6em !important;
        }
    }
    input[type="text"] {
        padding-left: 2em;
        font-size: .75em;

        &.with-suffix {
            padding: 0.5rem;
        }
    }
}

/* Search dropdown styling */
.search-results {
    position: absolute;
    border: 1px solid $txt-green;
    background: #fff;
    padding: 0px;
    width: 25em;
    overflow-y: scroll;
    max-height: 225px;
    margin-top: -3px;
    margin-left: 0;
    border-radius: 5px;
    border-top-left-radius: 0px;
    z-index: 3;
    .search-result, li {
        padding: 5px 10px;
        color: #444;
        list-style: none;
        font-size: rem-calc(12);
        line-height: 1.3;
        font-weight: normal;
    }
    .search-result:hover {
        color: $anchor-font-color-hover;
        background: $lightest-grey;
        cursor: pointer;
    }
    .loading {
        display: none;
    }
}

.search-results.with-label {
    top: 2.75em;
}

.search-results.loading {
    .loading {
        display: block;
    }
    .search-result {
        display: none;
    }
}

//Fancy input prefixes and postfixes
.input-wrap {
    border: 0;
    padding: 0;
    position: relative;
    .input-prefix {
        padding: 0 .25em;
        line-height: 1.75rem;
        position: absolute;
        top: 1px;
        left: 1px;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        background: $light-grey;
        color: $medium-dark-grey;
        z-index: 2;
    }
    input[type="text"] {
        padding-left: 2.5em;
    }
    .w-postfix {
        padding-right: 22px;
        padding-left: 4px;
    }
}

.prefix, .postfix {
    line-height: 26px;
    position: absolute;
    left: 1px;
    top: 1px;
    padding: 0 5px 0 5px;
    background: #ccc;
    color: #444;
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    z-index: 2;
    width: auto;
}

.postfix {
    left: auto;
    right: 1px;
    top: 1px;
    text-transform: none;
}

.error small, small.error, .error small.error {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    margin-top: .4em;
    font-size: rem-calc(11);
    line-height: 1.3;
}

// Switches

div.switch-with-labels {
    label {
        display: inline-block;
        opacity: .8;
        box-shadow: inset rgba(0, 0, 0, 0.25) 0 1px .5rem;
        &:before {
            position: absolute;
            right: .75em;
            top: .5em;
            font-size: rem-calc(12);
            content: "Nope";
            text-indent: 0;
            color: rgba(0,0,0,.2);
            text-align: right;
            -webkit-font-smoothing: antialiased;
        }
        &:after {
            top: 0.25rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0 1px .25rem;
        }
    }
    &:hover {
        label {
            opacity: 1;
        }
    }
    input:checked + label {
        background: $ordoro-blue;
        opacity: 1;
        &:before {
            content: "Yep";
            text-indent: 0;
            right: auto;
            left: .75em;
            text-align: right;
            color: rgba(255,255,255,.95);
        }

    }
    &.small {
        label {
            width: 6em;
        }
        input:checked + label:after {
            left: 3.8rem;
        }
    }
    &.on-off {
        label {
            width: 4em;
            &:before {
                content: "Off";
            }
        }
        input:checked + label {
            &:before {
                content: "On";
            }
        }
        input:checked + label:after {
            left: 2rem;
        }
    }
    &.x-sm {
        label {
            width: 2.5em;
            height: 1.25rem;

            &:before {
                content: "";
            }

            &:after {
                width: 0.9rem;
                height: 0.9rem;
                top: 0.175rem;
            }
        }

        input:checked + label {
            &:before {
                content: "";
            }

            &:after {
                left: 1.05rem;
            }
        }
    }
}

/*------------------------------------*\
    #NEW REFACTORED STUFF
\*------------------------------------*/

.wrap--date-picker {
    position: relative;
    vertical-align: middle;

    &:after {
        font-family: $alternateIconFontFamily;
        -webkit-font-smoothing: antialiased;
        background: $light-grey;
        content: "\1F4C5";
        position: absolute;
        display: block;
        color: $medium-dark-grey;
        @include text-shadow-white;
        font-size: rem-calc(17);
        left: 1px;
        top: 2px;
        padding: 0 .5em;
        border-radius: rem-calc(3) 0 0 rem-calc(3);
    }
}

.input--date-picker, input[type="text"].input--date-picker {
    width: 9em;
    padding-left: 2.85em;
    line-height: 1;
    height: 1.85rem;

    &:focus {
        border-color: $body-font-color;
    }
}

.label--filter {
    color: #fff;
    font-size: rem-calc(12);
    font-weight: normal;
    line-height: 1.1;
    padding: .75em 0;
}

.label__text--filter {
    display: inline-block;
    width: 88%;
    word-wrap: break-word;
}

.form-list--reconcile {
    .form-list-item {
        margin-bottom: rem-calc(20);
    }
}

.wrap--edit-form {
    border: 1px solid $medium-grey;
    margin-top: 1rem;
}

.form__header--edit, .row .form__header--edit {
    padding: 1rem;
    margin-bottom: rem-calc(15);
    background: $lighter-grey;
    border-bottom: 1px solid $medium-grey;
}

.wrap--edit-form .form-list {
    .form-list-item {
        margin: 0 rem-calc(3) rem-calc(15);

        input[type="text"], .select {
            margin-bottom: 0;
        }
    }
}

.wrap--full-select {
    .select {
        width: 100%;
    }
}

.wrap--edit-order-line {
    padding: 1rem 0 .25rem 0;

    &:nth-child(even) {
        background: $lightest-grey;
    }
}

.form__counter--edit {
    text-align: center;
    line-height: 1.3;
    margin-bottom: 0;
}



