//Settings Section

.settings-wrap,
.row .settings-wrap {
  padding-left: 2.5em;
  margin-bottom: 2em;

  .list-title {
    margin-top: 0;
  }
}

.logo-wrap {
  font-size: rem-calc(14);
}

.row .logo-upload-wrap {
  margin-bottom: 1em;

  hr {
    border-color: $light-grey;
  }
}

.helper {
  font-size: rem-calc(11);
  margin-top: 1em;
  background-color: lighten(#34a0cd, 45%);
  border: 1px solid lighten(#34a0cd, 35%);
  margin-left: 0;

  li {
    margin-left: 1em;
    list-style-type: square;
  }

  p {
    font-size: rem-calc(12);
  }

  &.larger-helper {
    font-size: rem-calc(14);
    line-height: 1.45;

    dt {
      font-size: rem-calc(17);
      margin-bottom: .5em;
    }
  }
}

.helper-li {
  font-size: rem-calc(12);
  @extend .helper;
  padding: 1em;
  margin-top: 0;
}

.helper-error {
  border: 1px solid $error-color;
  background: lighten($error-bg-color, 5%);

  .helper-text {
    color: $error-color;
  }
}

.helper-warning {
  border: 1px solid $warning-border-color;
  color: $warning-txt-color;
  background: $warning-bg-color;
}

.helper--pin {
  padding: .5em 0.75em;
  font-size: rem-calc(14);
  margin: 0;
}

.setup-helper,
.row .setup-helper {
  position: absolute;
  right: 0;
  top: 4em;
}

.settings-list {
  margin-left: 0;

  .settings-list-item {
    list-style: none;
    width: 100%;
    line-height: 1.35;
    vertical-align: middle;
    margin-bottom: 0;
    padding: 1.25em 0;
    border-top: 1px solid $light-grey;

    .settings-list-item-desc {
      color: $medium-grey;
    }

    .warehouse-address,
    .supplier-email,
    .billing-item {
      word-wrap: break-word;
    }

    .default {
      color: $txt-green;

      .warehouse-status-icon:before {
        font-family: $alternateIconFontFamily;
      }
    }

    .default-callout {
      font-weight: normal;
      font-size: .75em;
      margin-top: .5em;
    }

    .not-configured {
      margin-bottom: 1.5em;
    }

    .not-configured-callout {
      font-size: .9em;
      line-height: 1.4;
      font-weight: normal;
      margin-top: .75em;
      margin-bottom: 1em;

      strong {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
      }
    }

    .settings-warehouse-mapping-msg {
      display: none;
    }

    &.archived {

      background: #f7f7f7;

      strong,
      span,
      em {
        color: #bbb;
      }
    }

    &.read-only {

      .settings-list-item-desc {
        background: #999;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(14);
        color: #fff;
        min-width: .55rem;
        display: inline-block;
        padding: 0 rem-calc(5);
        margin-left: .975rem;
        border-radius: rem-calc(3);
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        width: auto;
      }
    }

    &.warning {
      background: lighten($warning-txt-color, 45%);

      .settings-list-item-button {
        color: #aaa;
      }

      &.expanded {
        background: #fff;
      }
    }
  }

  .first {
    border-top: 1px solid $light-grey;
  }

  .add-item {
    padding-top: 1.5em;
    border-bottom: 0;
  }

  .mapping {
    opacity: .5;

    .settings-warehouse-mapping-msg {
      display: block;
      line-height: 1.5;

      .loading {
        margin-top: 1.1em;
      }

      em {
        font-size: .8em;
        color: $medium-grey;
      }
    }

    .settings-list-item-button {
      display: none;
    }
  }

}

.billing-item {
  margin-bottom: 0;
}

.billing-item-type {
  font-size: rem-calc(14);
  margin-bottom: 0;
  color: $medium-dark-grey;
}

.billing-item-cc {
  font-size: rem-calc(17);
  line-height: 1.2;
  margin-bottom: 0;
  color: $body-font-color;
}

.billing-item-desc {
  margin-bottom: 0;
  font-size: rem-calc(23);
  line-height: 1.2;

  &.larger {
    font-size: rem-calc(27);
    line-height: 1.1;
  }
}

.billing-item-fine-print {
  margin-bottom: 0;
  font-size: rem-calc(12);

  &.with-button {
    border-top: 1px dotted $light-grey;
    padding-top: .5em;
    margin-top: .75em;
  }
}

.billing-plan-name {
  color: $txt-green;
  text-transform: uppercase;
  font-family: $aktiv-grotesk-bold;
  font-weight: normal;
}

.billing-plan-features,
ul.billing-plan-features {
  margin-top: 0.25em;
  margin-left: 1em;
  position: relative;
  border: 3px solid $light-grey;
  background: #fff;

  li {
    color: $medium-dark-grey;
    margin-bottom: .5em;
  }

  &:after {
    @include triangle-pointing-left(.5em, $light-grey);
    top: 2.25em;
    left: -.5em;
    font-size: 0.01;
    height: .5em;
    width: .5em;
  }
}

.upgrade-callout {
  margin-bottom: 0;
  display: none;
}

.need-upgrade {
  .feature-text {
    text-decoration: line-through;
    opacity: .8;
  }

  .upgrade-callout {
    display: inline;
  }
}

.settings-list-item-form-wrap {
  display: none;
}

.expanded {
  .settings-list-item-button {
    display: none;
  }

  .settings-list-item-form-wrap {
    display: block;
  }
}

.wrap--modal-speedbump .settings-list-item-form-wrap {
  display: block;
  min-height: 1em;
}

.settings-list-item-form-wrap {
  position: relative;
  padding-top: 1.75em;
  min-height: 24em;

  &.shawty {
    min-height: 16em;
  }

  .form-list {
    margin-left: 0;
    margin-bottom: .5em;

    .form-list-item {
      @extend .bigger;
    }
  }

  fieldset {
    margin-bottom: 0;
  }

  .setup-helper {
    top: 1.75em;
  }

  .helper-info {
    display: none;
  }

  .request-deletion-text {
    p {
      font-size: .875em;
      color: $medium-grey;
    }
  }

  .settings-list-save-button {
    display: block;
    clear: both;
    margin: 0 0 0 0.9375em;
  }

  .nested-settings-list-save-button {
    @extend .settings-list-save-button;
    margin: 0;
  }
}

.add-item {
  .settings-list-item-form-wrap {
    padding-top: .25em;

    .helper-info {
      display: block;
    }

    .request-deletion-text {
      display: none;
    }
  }
}

.shipper-list-tabs,
.row .shipper-list-tabs {
  border-bottom: 1px solid #aaa;
  margin-bottom: 1.5em;

  dd {
    margin-right: 0;

    .shipper-toggle {
      border-right: 0;
      border-bottom: 0;
      margin-bottom: 0;
      top: 1px;
      display: block;
    }

    .shipper-toggle:hover {
      border-color: #aaa;
      background-color: #ddd;
    }

    .shipper-toggle:focus {
      border-color: #aaa;
    }

    .active,
    .active:hover,
    .active:focus {
      background-color: #fff;
      border-bottom: 1px solid #fff;
      opacity: 1;
      height: 61px;
    }
  }

  .last {
    .shipper-toggle {
      border-right: 1px solid #aaa;
    }
  }
}

.shipper-setup-wrap {
  .settings-list-item-form-wrap .setup-helper {
    top: 1em;
  }
}

.postage-wrap {
  margin-bottom: 0;
  min-height: 0;

  .postage-value {
    text-transform: uppercase;
    font-size: rem-calc(44);
    line-height: 1;

    .loading {
      display: none;
      margin-top: .25em;
    }

    .span-wrap {
      display: block;
    }
  }

  .postage-add {
    width: 90%;
    margin-top: 1em;
    padding: 1em 0;
    border-top: 1px dotted $light-grey;

    .prefix {
      height: 2.7em;
      line-height: 2.7;
      background: $lighter-grey;
      color: $medium-dark-grey;
      border: 0;
      border-right: 1px solid $medium-grey;
      padding: 0 .5em;
    }

    .input-wrap {
      input {
        width: 65%;
        font-size: rem-calc(16);
        padding-left: 2em;
        height: 2.5em;
      }

      .error-message {
        margin-left: 0;
        margin-top: 0;
        line-height: 1.5;
      }
    }
  }

  .calculating {
    .loading {
      display: block;
      color: $medium-grey;
      font-size: rem-calc(17);
      text-transform: none;
      line-height: 1.25;
    }

    .span-wrap {
      display: none;
    }
  }

  .currency {
    position: relative;
    font-size: rem-calc(27);
    top: -.35em;
  }
}

.postage-wrap.last {
  border-left: 1px solid $light-grey;
  padding-left: 1.75em;
}

/*------------------------------------*\
    #Refactored stuff
\*------------------------------------*/

.list--settings-wrap {
  margin: 0;
  padding-left: 0;
  padding-right: 0;

  .settings-list-item-form-wrap {
    min-height: 20.5em;
  }

  .settings-list-item-form-cp,
  .settings-list-item-form-usps {
    min-height: 1em;
  }
}

.list--settings {
  margin-bottom: 0;

  dd {
    margin: .25em 0 0 0;
  }
}

.list__item--settings-wrap {
  list-style: none;
  margin-bottom: 0;
  padding: 1.25em 0;
  border-top: 1px solid $light-grey;
}

.list__item--add-item-wrap {
  padding-top: 1.5em;
}

.wrap--logo,
.label__logo {
  height: 2em;

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {
    background: transparent url(../images/usps.svg) top left no-repeat;
    background-size: contain;
    height: 1.75em;
  }

  &.ups {
    background: transparent url(../images/ups.svg) top left no-repeat;
    background-size: contain;
    height: 3em;
  }

  &.fedex {
    background: transparent url(../images/fedex.svg) top left no-repeat;
    background-size: contain;
  }

  &.canada_post {
    background: transparent url(../images/canada-post.svg) top left no-repeat;
    background-size: contain;
  }

  &.amazon {
    background: transparent url(../images/amazon-buy-shipping-logo.png) top left no-repeat;
    background-size: contain;
    height: 2rem;
  }

  &.amazon_shipper {
    background: transparent url(../images/amazon-shipping.png) top left no-repeat;
    background-size: contain;
    height: 3rem;
  }

  &.dhl,
  &.dhl_ecommerce {
    background: transparent url(../images/dhl.svg) top left no-repeat;
    background-size: contain;
  }

  &.pitney_cbds,
  &.newgistics {
    background: transparent url(../images/pitney-bowes.svg) top left no-repeat;
    background-size: contain;
    height: 2.25rem;
  }

  &.quickbooks {
    background: transparent url(../images/quickbooks.svg) top left no-repeat;
    background-size: contain;
    height: 3rem;
    margin-top: rem-calc(5);
  }

  &.stripe {
    background: transparent url(../images/stripe-blue.svg) top left no-repeat;
    background-size: contain;
    height: 3rem;
    margin-top: rem-calc(5);
  }

  &.australia_post {
    background: transparent url(../images/australia-post.svg) top left no-repeat;
    background-size: contain;
  }

  &.onelivex {
    background: transparent url(../images/x-delivery.svg) top left no-repeat;
    background-size: contain;
    height: 1.85rem;
  }

  &.sendle {
    background: transparent url(../images/sendle.svg) top left no-repeat;
    background-size: contain;
    height: 1.85rem;
  }
}

.list--import-count {
  display: inline-block;
  margin: 0 0 .5rem 0;
  padding: .6rem 1rem;
  border: 1px solid $light-grey;
  list-style: none;
}

.list__item--import-count,
.no-list-style .list__item--import-count {
  display: inline-block;
  line-height: 1.75;
  margin: 0 1rem 0 0;
  padding-right: 1rem;
  border-right: 1px solid #ddd;

  &:last-of-type {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}
