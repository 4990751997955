.wrap--free-callout {
  background: #f2f2f2;
  padding: .4rem;
  border-bottom: 1px solid #ddd;

  p {
    font-size: rem-calc(13);
  }
}

.spinning--sm {
  @include spinner(0.2rem, #444, 0.35, 1rem);
  display: inline-block;
}

.spinning--sm-ko {
  @include spinner(0.2rem, #fff, 0.35, 1.25rem);
  display: inline-block;
}

.spin--animation {
  @include animate-spin;
}


.wrap--usps-balance {
  border-top: 1px solid #ddd;
  margin-top: .15rem;
  padding-top: rem-calc(1);
}

.label__callout {
  min-width: .55rem;
  display: inline-block;
  padding: 0 rem-calc(4);
  position: relative;
  border-radius: 3px;
  font-size: rem-calc(10);
  line-height: 1.5;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;

  &.label__callout--lg {
    font-size: rem-calc(14);
    padding: 0 rem-calc(6);
  }

  &.label__callout--wide {
    padding: 0 rem-calc(8);
  }
}

.label__callout--grey {
  background: $medium-grey;
  color: #fff;
}

.label__callout--blue {
  background: $ordoro-blue;
  color: #fff;
}

.label__callout--dk-grey {
  background: #444;
  color: #fff;
}

.label__callout--green {
  background: $ordoro-green;
  color: #fff;
}

.label__callout--yellow {
  background: #b29600;
  color: #fff;
}

.label__callout--amazon,
.label__callout--amazon-fba {
  background: #f29d39;
  color: #fff;
}

.label__callout--amazon-fbm {
  background: #0f7eb5;
  color: #fff;
}

.label__callout--red {
  background: #ff0000;
  color: #fff;
}

.wrap--reload-app-msg {
  padding: .75rem;
  background: $ordoro-blue;
}

//Banner message
.wrap--banner-msg {
  background: $warning-bg-color;
  margin-bottom: 0;
  padding: .75rem 1.5rem;
  position: relative;

  a {
    font-family: $aktiv-grotesk-bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wrap--banner-migration {
  background-color: #222;
  color: yellow;
  padding: .5rem 1.5rem;
  font-family: $aktiv-grotesk-bold;
}

//Allocation stuff

.overallocated {

  .link--line-detail,
  .i--allocation,
  .line-detail {
    color: $alert-color;
  }
}

.partial {

  .link--line-detail,
  .i--allocation,
  .line-detail {
    color: $warning-txt-color;
  }
}

.allocated {

  .link--line-detail,
  .i--allocation,
  .line-detail {
    color: $txt-green;
  }
}

.allocation-attempted {

  .link--line-detail,
  .i--allocation,
  .line-detail {
    color: $medium-dark-grey;
  }
}

.unallocated {

  .link--line-detail,
  .i--allocation,
  .line-detail {
    color: #999;
  }
}


.wrap--order-allocation-callout {
  display: inline-block;
  border: 1px solid transparent;
  padding: rem-calc(1) rem-calc(3);
  line-height: 1;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;

  &.allocated {
    color: $txt-green;
    border-color: $txt-green;
  }

  &.partial {
    color: $warning-txt-color;
    border-color: $warning-txt-color;
  }

  &.overallocated {
    color: $alert-color;
    border-color: $alert-color;
  }

  &.allocation-attempted {
    color: $medium-dark-grey;
    border-color: $medium-dark-grey;
  }
}

// EULA SCROLL SHIT

.wrap--eula-scroll {
  overflow-y: scroll;
  border: 1px dashed #ccc;
  padding: .75rem;
  height: 6rem;
  @include modal__scrollbar;

  p {
    font-size: rem-calc(13);
    line-height: 1.3;
    margin-bottom: .85rem;
  }

}