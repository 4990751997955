//Downtime page

.downtime {
    background: #222;
    body {
        background: transparent;
        padding-top: 4em;
    }
    .downtime-message {
        -webkit-font-smoothing: antialiased;
        dt {
            color: #fff;
            font-family: $aktiv-grotesk-regular;
            font-size: rem-calc(37);
            line-height: 1;
            font-weight: normal;
            margin: .5em 0;
            @include text-shadow-standard;

        }
        dd {
            font-size: rem-calc(17);
            line-height: 1.5;
            color: #777;
            margin-bottom: 3%;
            @include text-shadow-standard;
        }
    }
}

.sad-box {
    display: inline-block;
    background-image: url(../images/sad_box01.gif);
    background-size: cover;
    width: 14em;
    height: 14em;
}
