.illo--order-mgmt {
  .cls-1, .side-panel {
    fill: #777676;
  }

  .cls-2, .cls-9, .side-accent, .side-outline {
    fill: #333;
  }

  .cls-3 {
    fill: #cbcbca;
  }

  .cls-4 {
    fill: #f16e2a;
  }

  .cls-5 {
    fill: #baddee;
  }

  .cls-6 {
    fill: #6ab145;
  }

  .cls-7 {
    fill: #444;
  }

  .cls-8 {
    fill: #fff;
  }

  .cls-9 {
    fill-rule: evenodd;
  }
}