//
// Table styling
// ------------

.table {
  width: 100%;
  margin-bottom: 0;

  thead {
    background: transparent;
    color: #444;
  }
}

.table__th {
  padding: 1rem .75rem;
  line-height: 1.3;
  vertical-align: bottom;
  color: #999;
  border-bottom: 1px solid #999;
  font-size: rem-calc(14);
  font-family: $aktiv-grotesk-bold;

  &:first-of-type {
    padding-left: 0;

    &.align-center {
      padding-left: .75rem;
    }
  }

  &.v-align-middle {
    vertical-align: middle;
  }

  &[data-hint]:after {
    font-size: 1em;
  }

}



.table__th--sm,
.table__td--sm {
  padding: .5rem .75rem;
  font-size: rem-calc(12);

  &.no-top-padding {
    padding-top: 0;
  }
}

.table__th--md,
.table__td--md {
  padding: .5rem .75rem;
  font-size: rem-calc(13);
}


.table__td--sm-side {
  padding: .85rem .75rem;
  font-size: rem-calc(12);
}

.table__th--top-border {
  border-top: 1px solid #999;
}

.table__th--warehouse-inventory {
  padding: .5rem;
  vertical-align: top;
  width: 20%;

  &:first-of-type {
    padding-left: .5rem;
  }
}

.table__th--warehouse-inventory-value {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px dotted #ddd;
  background: #fff;

  &.poh {
    border-left: 1px solid #ccc;
  }

  &:last-of-type {
    border-right: 1px solid #ccc;
  }
}

.table__tbody--lines,
.table__tbody--warehouse-inventory {
  .table__td {
    padding: 1rem .75rem;
    border-bottom: 1px solid #ddd;
    vertical-align: top;

    &.v-align-middle {
      vertical-align: middle;
    }

    &:first-of-type {
      padding-left: 0;
    }
  }

  .table__td--sm {
    padding: .5rem .75rem;
    font-size: rem-calc(12);
  }

  .table__td--product-search {
    background: #f7f7f7;
  }

  .table__tr--line-rejected .table__td {
    color: #ccc;
  }

  &.table__tbody--serial-numbers {
    .table__td:first-of-type {
      padding-left: .75rem;
    }
  }

  &.table__tbody--rs {
    .table__td:first-of-type {
      padding-left: .75rem;
    }
  }

  .table__td--warehouse-inventory {
    border-bottom: 1px dotted #ccc;
    padding: .5rem;

    &:first-of-type {
      padding: .5rem;
      border-right: 1px dotted #ccc;
    }

  }
}

.table--warehouse-inventory {
  background: transparent;

  thead {
    background: transparent;
  }
}


.table__tbody--warehouse-inventory {
  border: 1px solid #ccc;
  background-color: #fff;
}

.table__tr--expanded .thead--multi-warehouse .table__th--warehouse-inventory-value {
  border-bottom: 0;
  width: auto;
}

.label--product-search {
  display: inline-block;
  color: #999;
  margin-right: .75rem;
}

.table__tbody--packing-modal,
.table__tbody--goods-receipt-modal {
  .table__td {
    border-bottom: 0;
    border-top: 1px solid #999;
  }

  tr:first-of-type .table__td {
    border-top: 0;
  }
}

.table__tbody--full-border {
  border: 1px solid #999;
}

.table__tbody--top-border {
  border-top: 1px solid #999;
}

.tr--error {
  .table__td {
    background: rgba(255, 0, 0, 0.15);
    border-bottom: 1px solid #ff0000;

    &:first-of-type {
      padding-left: .75rem;
    }
  }

  .textarea,
  .input--text,
  .fs-n1 {
    opacity: .4;
  }

  .btn--link {
    color: #ff0000;
    -webkit-font-smoothing: antialiased;

    &:hover {
      color: #444;
    }
  }
}

.tr--all-received {
  .table__td {
    background: rgba(62, 153, 44, 0.15);
    border-bottom: 1px solid $ordoro-green;

    &:first-of-type {
      padding-left: 2.75rem;
      position: relative;

      &:before {
        font-family: "iverson-icons";
        content: "\69";
        font-size: rem-calc(23);
        color: $ordoro-green;
        position: absolute;
        top: 1.5rem;
        left: .75rem;
      }
    }
  }

  .textarea,
  .input--text,
  .fs-n1 {
    opacity: .4;
  }
}

.table__td--product-search {
  text-align: center;

  .wrap--product-search {
    display: inline-block;
    width: 35%;
    vertical-align: middle;
  }

  input[type="text"].input--search {
    margin-bottom: 0;
  }
}

.table__tbody--v-align-middle {
  .table__td {
    vertical-align: middle;
  }
}

.table__tbody--totals {
  .table__td {
    padding: 1rem .75rem;
    border-bottom: 1px dotted #ccc;
    background: #eee;
  }

  .table__td--grand-total {
    background: #ddd;
    border-bottom: 0;
  }
}

.table__tbody--label-breakdown {
  .table__td {
    font-size: rem-calc(14);
    padding: 1rem .75rem;
    border-top: 1px solid #ddd;
    vertical-align: top;

    &:first-of-type {
      padding-left: 0;
    }
  }

  tr:first-of-type {
    .table__td {
      border-top: 0;
    }
  }
}


.table__tbody--single-total {
  .table__td {
    padding: 1rem .75rem;
    border-bottom: 1px solid #999;
    border-top: 1px solid #999;
    background: #eee;
  }
}

.table--plans {
  .table__th {
    width: 25%;
    border-bottom: 1px solid #ddd;
    background: #eee;

    &:first-of-type {
      background: transparent;
    }
  }
}

.table__th--plan-col {
  border-left: 1px solid #999;
  text-align: center;
  background: #eee;
  font-size: rem-calc(17);
  color: #444;
  border-top: 1px solid #999;
  padding: .55rem;
  vertical-align: middle;

  &:last-of-type {
    border-right: 1px solid #999;
  }

  &:hover {
    cursor: pointer;
  }
}

.table__tbody--plans {}

.table__td--plans {
  border-bottom: 1px solid #ddd;
  padding: .55rem;
  vertical-align: middle;

}

.table__td--plan-col {
  text-align: center;
  border-left: 1px solid #999;

  &:last-of-type {
    border-right: 1px solid #999;
  }
}

.table__td--btn-wrap {
  padding: 0;
}

.starter-selected .table__th--starter,
.basic-selected .table__th--basic,
.pro-selected .table__th--pro,
.basic-5-selected .table__th--basic-5,
.basic-25-selected .table__th--basic-25,
.basic-49-selected .table__th--basic-49,
.basic-129-selected .table__th--basic-129,
.pro-199-selected .table__th--pro-199,
.pro-299-selected .table__th--pro-299,
.pro-499-selected .table__th--pro-499 {
  background: lighten($ordoro-blue, 42%);
  border: 3px solid $ordoro-blue;
  border-bottom: 1px solid #ddd;
}

.starter-selected .table__td--starter,
.basic-selected .table__td--basic,
.pro-selected .table__td--pro,
.basic-5-selected .table__td--basic-5,
.basic-25-selected .table__td--basic-25,
.basic-49-selected .table__td--basic-49,
.basic-129-selected .table__td--basic-129,
.pro-199-selected .table__td--pro-199,
.pro-299-selected .table__td--pro-299,
.pro-499-selected .table__td--pro-499 {
  border-left: 3px solid $ordoro-blue;
  border-right: 3px solid $ordoro-blue;
}

.starter-selected tr:last-of-type .table__td--starter,
.basic-selected tr:last-of-type .table__td--basic,
.pro-selected tr:last-of-type .table__td--pro,
.basic-5-selected tr:last-of-type .table__td--basic-5,
.basic-25-selected tr:last-of-type .table__td--basic-25,
.basic-49-selected tr:last-of-type .table__td--basic-49,
.basic-129-selected tr:last-of-type .table__td--basic-129,
.pro-199-selected tr:last-of-type .table__td--pro-199,
.pro-299-selected tr:last-of-type .table__td--pro-299,
.pro-499-selected tr:last-of-type .table__td--pro-499 {
  border-bottom: 3px solid $ordoro-blue;
}

.table__td--preset {
  border-bottom: 1px dotted #ccc;
  padding: .5rem .75rem;
  font-size: rem-calc(12);

  &.deselected {
    label {
      color: #ccc;
    }
  }
}

.table__tr--preset:first-of-type {
  .table__td--preset {
    padding-top: 0;
  }
}

.table__tr--preset:last-of-type,
.table__tr--return-orders:last-of-type {

  .table__td--preset,
  .table__td {
    border-bottom: 0;
  }
}

.open .table--carrier-specific {

  .table__tr--preset,
  .table__tr--preset:last-of-type {
    border-bottom: 1px dotted #ccc;
  }
}

.table--order-summary {
  tr td {
    line-height: 1.3;
    vertical-align: top;
    padding-top: .65rem;
    padding-bottom: .5rem;
    word-break: break-word;

    strong {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .list__title--order-data,
  .list__item--order-data {
    line-height: 1.3;
  }
}

.table--zebra-list {

  .table__th {
    font-size: .75rem;
    line-height: 1.2;
  }

  tr td {
    padding: 1rem .75rem;
    vertical-align: top;
    background: #f7f7f7;
    border-bottom: 1px solid #bbb;
    font-size: .75rem;

    &.no-left-padding {
      padding-left: 0;
    }
  }

  tr:nth-child(2n+1) td,
  tr .table__td--warehouse-inventory {
    background: #fff;
  }

}

.table__td--order-checkbox {
  width: 1.5%;
}

.table--product-list {

  .wrap--checkbox-all-products {
    padding: .5rem;
    margin-bottom: 0;
    border: 1px solid #aaa;
    background: #eee;
    border-radius: .1875rem;
    vertical-align: middle;
    @include flexbox;
    @include flex-direction(column);
    @include flex-justify-sa;


    input[type="checkbox"] {
      vertical-align: middle;
    }
  }

  .table__th {

    &:last-of-type {
      width: auto;
    }
  }

  .table__th--order-checkbox {
    width: 1.5%;
    padding-right: 0;
  }

  .table__td--order-checkbox {
    padding: 1rem 0 1rem .5rem;
    width: 1.5%;
  }

  .wrap--product-expand-btn {
    padding-left: 0;
    padding-right: 0;
    min-width: 1.35rem;
    width: 1.35rem;
    position: relative;
  }
}

.wrap--product-list-inner-scroll {

  .wrap--checkbox-all-products {
    padding: .5rem 0;
  }

  .table__td--order-checkbox {
    padding: 1rem .5rem;
    width: 1.5%;
  }

}

.table--has-inventory {
  .table__td--product-list-name {
    width: 15%;
  }

  .table__td--product-list-sku {
    width: 8%;
  }

  .table__td--product-list-supplier {
    width: 10%;
  }

  .table__td--product-list-weight {
    width: 8%;
  }

  .table__td--product-list-dims {
    width: 8%;
  }

  .table__td--product-list-writeback {
    width: 15%;
  }

  .table__td--product-list-warehouses {
    width: 40%;
  }

  .table__td--product-list-expand {}

}

.table--no-inventory {
  .table__td--product-list-name {
    width: 20%;
  }

  .table__td--product-list-sku {
    width: 15%;
  }

  .table__td--product-list-upc {
    width: 10%;
  }

  .table__td--product-list-weight {
    width: 10%;
  }

  .table__td--product-list-dims {
    width: 10%;
  }

  .table__td--product-list-writeback {
    width: 15%;
  }

  .table__td--product-list-warehouses {
    width: 20%;
  }

  .table__td--product-list-expand {}

}


.table__tbody--border-top-transparent {
  border-top: 1px solid transparent;
}

.wrap--product-list-inner-scroll {
  max-height: calc(100vh - 14rem);
  overflow-y: auto;
  margin-right: -.85rem;

  &::-webkit-scrollbar {
    height: .5em;
    width: rem-calc(15);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .3);
    border-left: rem-calc(7) solid #fff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    margin-top: rem-calc(46);
    background: rgba(0, 0, 0, .15);
    border-left: rem-calc(7) solid #fff;
  }
}

.zuck .wrap--product-list-inner-scroll {
  max-height: calc(100vh - 16rem);
}




/* Fix for weird ass FF bug with position:relative in a table-cell that has a background color */

@-moz-document url-prefix() {

  .table--product-list .wrap--product-expand-btn,
  .table--product-list tr:nth-child(2n+1) .wrap--product-expand-btn {
    background-clip: padding-box;
  }

  .wrap--product-list-inner-scroll {
    margin-right: 0;
  }

}

.table-inner-scroll--header {
  position: sticky; // how it stays on top
  top: 0; // needed to be sticky
  background-color: white; // otherwise there is no background
  z-index: 2; // must be above product images and tag badges
  border-bottom: 1px solid #999; // a bottom border is nice but will need to remove top border of first row
  width: fit-content; // needed to expand background to fit the width of the table
  border-right: 1px solid #fff; // to hide tbody side border
}

.table__td--line-break {

  .wrap--address-validation {
    vertical-align: top;
  }

  .line-break {
    display: block;
  }
}

.status-badge {
  border: 0;
  color: #fff;
  background: #999;
  border-radius: rem-calc(2);
  padding: .1rem;
  line-height: 1;
  min-width: 1.35rem;
  font-size: rem-calc(10);
  text-align: center;
  -webkit-font-smoothing: antialiased;

  &.status-badge--batch {
    border-radius: 1rem;
    padding: .2rem;
    min-width: 1rem;
    line-height: 1;
    margin-top: rem-calc(3);
  }

}

.list__item--order-block:first-of-type {

  .status-badge.tooltip--top:before {
    left: 50%;
    top: 155%;
    bottom: auto;
    border-bottom-color: #282E31;
    margin-top: rem-calc(-4);
    border-top: 0;
  }

  .status-badge.tooltip--top:after {
    left: 50%;
    top: 155%;
    bottom: auto;
    margin-left: rem-calc(-18);
  }

}

.wrap--po-addl-info {
  display: none;
}

.table__tr--expanded {

  .wrap--product-addl-info-item,
  .wrap--product-addl-info-list,
  .wrap--po-addl-info {
    display: block;
  }
}

/* PRODUCT WAREHOUSE STYLING */


.table__td--non-editable-qty {

  .list--product-warehouse {
    display: block;
  }
}

.table--zebra-list tr .table__td--inv {
  padding-right: 0;
  padding-left: 0;
}

.table--multi-location {
  background: transparent;
  margin-bottom: 1rem;

  tr.even,
  tr.alt,
  tr:nth-of-type(even) {
    background: transparent;
  }
}

.table__tbody--multi-location {
  tr .table__td {
    border-bottom: 1px solid #ccc;
  }

  tr:last-of-type .table__td {
    border-bottom: 0;
  }

  tr .table__td:first-of-type {
    padding-left: 0;
  }
}

.table--supplier-mappings input[type="text"] {
  &::placeholder {
    color: #999;
    font-family: "Aktiv-Grotesk-Italic";
  }
}



/* OLD Product Detail Page Table */

.writeback-inventory .list__item--writeback-toggle {
  padding-bottom: .75rem;
  margin-bottom: .75rem;
  border-bottom: 1px dotted #ccc;
}

.manual-cart .hide-for-manual {
  display: none;
}

/* NEW Product Detail Page Table */

.wrap--table-panel {
  border: 1px solid #999;
  border-top: 0;
  padding: 1.5rem 1.75rem 2.25rem 1.75rem;
}

/* NEW PO Detail Page Table */
.wrap--goods-receipt,
.wrap--bom-product {
  border: 3px solid #ddd;
  padding: 1.25rem;
  margin-bottom: 2rem;

  .table .table__tbody--lines tr:last-of-type .table__td {
    border-bottom: 0;
    padding-bottom: .25rem;
  }

  &:first-of-type {
    margin-top: 1rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

/* Merge Orders Modal Styling */

.table__tr--merge-orders {
  border: 1px solid #ccc;

  &:hover {
    border: 1px solid #666;
    cursor: pointer;
  }

  &.selected,
  &.selected:hover {
    border: 3px solid $ordoro-blue;
    background: lighten($ordoro-blue, 46%);
    cursor: default;
  }

}

.table__td--merge-orders {
  line-height: 1.3;
  vertical-align: top;
  padding: .85rem;
  word-break: break-word;
  background: transparent;

  &:first-of-type {
    padding-right: 0;
  }
}

/* GLOBAL ERROR MODAL TABLE */

.table__tbody--global-error {
  .table__td {
    padding: 1rem .5rem;
    border-bottom: 1px dotted #ddd;
  }
}

/* Kit Components Serial Number Scanning */

table .tr--product-sn-selected,
table .tr--product-sn-selected:nth-of-type(even) {
  border: 3px solid $ordoro-blue;
  background: lighten($ordoro-blue, 46%);
  cursor: default;
}


/* Batch Docs */
.wrap--batch-doc {
  padding-top: .75rem;
  margin-top: .75rem;
  border-top: 1px dotted #ccc;

  &:nth-child(1) {
    padding-top: 0;
    margin-top: rem-calc(-5);
    border-top: 0;
  }
}

.action-in-progress .wrap--batch-action-status {
  color: #999;
  font-family: "Aktiv-Grotesk-Italic";
}

/* Rate Shopping Fun */

.wrap--table--rs {
  padding: .25rem 1.5rem;
  min-width: 65vw;
}

.table--rs {


  thead .table__th:first-of-type {
    padding-left: .75rem;
  }

  thead .table__th--carrier-logo {
    padding-right: 1rem;
  }

  .table__tbody--lines {
    .table__td {
      font-size: rem-calc(14);
    }

    .table__td--carrier-logo {
      padding-right: 1rem;
    }

  }

  tr:not(.tr--has-error):hover {
    cursor: pointer;

    .table__td {
      background: #eee;
    }
  }

  .tr--focus {
    outline: 2px dashed #999;
  }

  .tr--rate-selected {
    td {
      background-color: #ddd;
    }
  }

}

/* Billing shit */

.tr--account-locked {
  .table__td {
    color: #ff0000;
    background: lighten(#ff0000, 45%);

    &:first-of-type {
      padding-left: .75rem;
    }

    .btn--link {
      color: #ff0000;
    }
  }
}