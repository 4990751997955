//Spinner
.syncing, #orders_main_nav.syncing, #shipments_main_nav.syncing, #products_main_nav.syncing {
    .icon:before {
        @include spinner(0.15em, #5e8244, 1, 1em);
        display: inline-block;
        text-indent: -9999px;
        overflow: hidden;
    }
    /* Spinner */
}

.top-bar-section ul #orders_main_nav.syncing, .top-bar-section ul #shipments_main_nav.syncing, .top-bar-section ul #products_main_nav.syncing {
    background: #8bbe66;
    @include text-shadow-reset;
    a {
        color: #5e8244;
        .main-nav-icon {
            opacity: 1;
        }
    }
}

.top-bar-section ul #orders_main_nav.syncing:hover, .top-bar-section ul #shipments_main_nav.syncing:hover, .top-bar-section ul #products_main_nav.syncing:hover {
    background: #719e50;
    a {
        color: #466430;
        .main-nav-icon {
            color: #466430;
        }
    }
}

.top-bar-section ul #orders_main_nav.syncing, .top-bar-section ul #shipments_main_nav.syncing, .top-bar-section ul #products_main_nav.syncing {
    .selected, .selected:hover {
        color: #466430;
        .main-nav-icon {
            opacity: 1;
        }
    }
}

.syncing .main-nav-icon {
    margin-right: .25em;
}

.activity.syncing {
    .icon:before {
        @include spinner(0.1em, #444, 0.5, 0.85em);
        display: inline-block;
        text-indent: -9999px;
    }
    /* Spinner */
}

.dropzone.uploading {

    .icon-upload {
        overflow: hidden;

        &:before {
            @include spinner(0.1em, #444, 0.5, 0.85em);
            display: inline-block;
            text-indent: -9999px;
        }
    }
}

.empty-subhead.loading, .postage-value .loading, .balance-loading .loading, .search-results .loading, .lead .loading, .supplier-select-wrap .loading-wrap {
    .spinner {
        @include spinner(0.15em, #444, 0.5, 1.1em);
        vertical-align: top;
        display: inline-block;
        margin-right: .25em;
    }
}

.first-import-loading {
    .spinner {
        @include spinner(0.25em, #444, 0.5, 2em);
        vertical-align: top;
        display: inline-block;
    }
}

.modal-utility-group-items .loading {
    .spinner {
        @include spinner(0.2em, #fff, 0.5, 1.15em);
        vertical-align: top;
        display: inline-block;
        margin-right: .25em;
        position: relative;
        top: .2em;
    }
}

.button:before {
    opacity: 0;
    display: block;
    @include transition-all-slower;
    z-index: -1;
}

.submitting:before, .btn--working:before {
    content: "";
    @include spinner(0.15em, #fff, 1, 1em);
    display: block;
    text-indent: -9999px;
    position: absolute;
    top: .75em;
    left: 44%;
    z-index: 1;
}

.tiny.btn--working:before {
    @include spinner(0.25em, #fff, 1, 1em);
    top: .5rem;
    left: 40%;
}

.labels-processing-spinner {
    @include spinner(0.6em, #444, 0.5, 7.5em);
    vertical-align: top;
    left: 0;
}

.w-100.submitting:before {
    left: 48%;
}

/* Prevents craycray flashing scrollbars in Windows Chrome and all Firefox */
.main-nav-wrap .top-bar-section .main-nav li .icon:before, .user-utility .syncing .dropdown-toggle .icon:before {
    overflow: hidden;
}

.loading-wrap {
    display: none;
}

.loading-outer-wrap {
    .hide-for-loading {
        display: none;
    }
    .loading-wrap {
        display: block;
        line-height: 1.25em;
    }
}
