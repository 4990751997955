//FIXME: New form input styles. We'll move the other ones form custom.scss once we've refactored the rest of the app to get rid of foundation.

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"] {
  background: #fff;
  margin: 0;
  border-radius: rem-calc(4);
  border-color: getColor(gray, base);
  box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px;
  height: rem-calc(27);
  min-height: rem-calc(27);
  padding: 0 .3rem;
  font-size: rem-calc(13);

  &:focus {
    background: #fff;
    border-color: #6EB43A;
  }

  &.input--inline {
    display: inline-block;
  }

  &.input--xx-sm {
    height: rem-calc(23);
    min-height: rem-calc(23);
    padding: 0 .2rem;
    font-size: rem-calc(12);
    width: rem-calc(33);
  }

  &.input--dim {
    width: 2.55rem;
  }

  &.input--x-sm {
    width: 2.75rem;
  }

  &.input--sm {
    width: 4rem;
  }

  &.input--x-md {
    width: 5rem;
  }

  &.input--md {
    width: 6rem;
  }

  &.input--lg {
    width: 10rem;
  }

  &.input--25 {
    width: 25%;
  }

  &.input--40 {
    width: 40%;
  }

  &.input--50 {
    width: 50%;
  }

  &.input--75 {
    width: 75%;
  }

  &.input--90 {
    width: 90%;
  }

  &.input--100 {
    width: 100%;
  }

  &.input--xl {
    width: 15rem;
  }

  &.input--h-29 {
    height: rem-calc(29);
  }

  &.input--med-h {
    height: rem-calc(32);

    &.txt--lg {
      font-size: rem-calc(17)
    }
  }

  &.input--tall {
    height: rem-calc(44);

    &.txt--lg {
      font-size: rem-calc(17)
    }
  }

  &.input--error {
    border-color: $error-color;
    color: $error-color;
  }

  &.input--w-prefix {
    border-radius: 0 rem-calc(4) rem-calc(4) 0;
    border-left: 0;
  }

  &.input--w-suffix {
    border-radius: rem-calc(4) 0 0 rem-calc(4);
    border-right: 0;
  }

  &[disabled] {
    border-color: #999;
    background: #eee;
  }

  &.input--w-auto {
    width: auto;
  }
}

.wrap--taller-datepicker {
  input[type="text"].input--w-prefix {
    width: 7rem;
    height: 2.5rem;
    font-size: rem-calc(16);
  }

  .icon-prefix {
    -webkit-font-smoothing: antialiased;
    color: #999;
  }

}


.settings-list-item input.input--tall {
  font-size: rem-calc(17);
}

.wrap--weight-input input[type="text"].input--w-suffix {
  height: auto;
}

.wrap--input-tall input[type="text"] {
  height: rem-calc(40);
  font-size: rem-calc(16);
  padding: 0 .5rem;
  margin-bottom: 0;
}

.wrap__btn--clear-search {
  padding: 0 .25rem;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  display: flex;
}

.input--main-search:focus~ {

  .wrap__btn--clear-search,
  .btn--main-search {
    border-color: #666;
  }
}

.input--main-search:valid~ {
  .wrap__btn--clear-search .btn--clear-search {
    opacity: .7;
  }
}

input[type="text"].input--search,
.input--search,
.app-table td input[type="text"].input--search {
  width: 100%;
  height: rem-calc(33);
  padding-left: 1.75rem !important;
}

input[type="text"].input--lighter,
.input--lighter {
  border-color: #999;

  &:focus {
    background: #fff;
    border-color: #444;
  }
}

input[type="text"].has-prefix,
.has-prefix {
  padding-left: 2.5rem;
}

input[type="text"].has-prefix--sm,
.app-table td input[type="text"].has-prefix--sm,
.has-prefix--sm {
  padding-left: 1.5rem;
}


input[type="text"].has-suffix,
.has-suffix {
  padding-right: 2.5rem;
}

.input__unit {
  vertical-align: middle;
  font-size: rem-calc(13);
  line-height: 1.6875rem;
  margin: 0 rem-calc(4);
}

.error .input__unit {
  color: $error-color;
}

.meta-rule-input-weight input[type="text"] {
  margin-bottom: 0;
}

.select {
  border-color: getColor(gray, base);
  font-size: rem-calc(13);
  height: rem-calc(27);
  border-radius: rem-calc(4);

  &.h-auto {
    height: auto;
  }

  &.select--lt {
    border-color: #aaa;
  }

  &[disabled] {
    border-color: #999;
    background: #eee;
    opacity: .5;
  }

  &:focus {
    border-color: #6EB43A;
  }

  .select--error {
    border-color: #ff0000;
  }
}


.select--modal {
  border-color: getColor(gray, base);
  font-size: rem-calc(13);
  height: rem-calc(27);
}

.select--tall {
  height: rem-calc(40);
}

.select__delete-icon {
  display: flex;
  align-items: end;
  margin-bottom: .5rem;
}

.label--input-prefix,
.list__item--form .label--input-prefix {
  font-family: $aktiv-grotesk-regular;
  color: #999;
  font-size: rem-calc(17);
  line-height: 1;
  position: relative;

  &:before {
    line-height: 1.85;
    position: absolute;
    left: rem-calc(7);
  }
}

.table__td--product-search {
  .label--product-search-zropdown {
    display: inline-block;
    vertical-align: middle;
    margin-right: .75rem;
    color: #999;
    font-family: $aktiv-grotesk-bold;
  }

  .wrap--product-search-zropdown {
    width: 40%;
    display: inline-block;
    vertical-align: middle;
  }
}

.list__item--product-filter-stacked,
.wrap--product-filter-stacked {
  .label--product-search {
    color: #444;
    margin-bottom: rem-calc(5);
    font-size: rem-calc(14);
  }

  .label--input-prefix:before {
    font-size: rem-calc(17);
  }

  .wrap--product-search {
    width: auto;
  }

}

/* FF fix for select height */
@-moz-document url-prefix() {

  .select--modal,
  .select--ff {
    height: auto;
  }
}

.wrap--input-with-prefix {
  position: relative;
}

.input__prefix {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.65;
  border-right: 1px solid #444;
  color: #999;
  font-family: $font-family-headings;
  font-size: rem-calc(13);
  padding: .35rem .5rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;

  &.sm {
    padding: .35rem;
  }
}

.input__suffix {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.65;
  border-left: 1px solid #444;
  color: #999;
  font-family: $font-family-headings;
  font-size: rem-calc(13);
  padding: .35rem .5rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.wrap--input-prefix {
  border: 1px solid #444;
  border-radius: rem-calc(4) 0 0 rem-calc(4);
  padding: 0 .3rem;
}

.textarea {
  border-radius: rem-calc(4);
  padding: .3rem;
  font-size: rem-calc(13);
}

.textarea--mh-auto {
  min-height: auto;
}

.textarea--in-cell {
  opacity: .75;
  width: 65%;

  &.order-detail {
    width: 80%;
    font-size: rem-calc(12);
    line-height: 1.3;
    height: rem-calc(27);
    min-height: 0;
  }

  &:focus {
    opacity: .9;
  }
}

.textarea--customs {
  height: rem-calc(27);
  line-height: 1.3;
  min-height: 0;
  width: 90%;
}

.label--inline {
  min-width: .85rem;
  margin-bottom: 0;
  text-align: center;
}

.label--disabled {
  &:hover {
    cursor: default;
  }
}

.wrap--label-unbold label {
  font-weight: normal;
  font-family: $aktiv-grotesk-regular;
}

.wrap--v-align-inner {

  input[type='checkbox'],
  span {
    vertical-align: middle;
  }
}

.list__item--shipping-shipperIDs label {
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    margin: auto 0 auto .3rem;
  }
}

.meta-labelconfigform-shipper-amazon .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/amazon-mark-01.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(17);
  height: rem-calc(15);
}

.meta-labelconfigform-shipper-amazon_shipper .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/amazon-shipping.png) top left no-repeat;
  background-size: contain;
  width: rem-calc(48);
  height: rem-calc(16);
}

.meta-labelconfigform-shipper-fedex .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/fedex.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(37);
  height: rem-calc(13);
}

.meta-labelconfigform-shipper-ups .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/ups.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(19);
  height: rem-calc(19);
}

.meta-labelconfigform-shipper-ups .inner-wrap__panel--actions:not(.inner-wrap__panel--actions-rs):after {
  display: block;
  content: "UPS, the UPS shield trademark, the UPS Ready mark, the UPS Developer Kit mark and the Color Brown are trademarks of United Parcel Service of America, Inc. All Rights Reserved.";
  font-size: rem-calc(10);
  line-height: 1.3;
  margin-top: rem-calc(5);
  color: #999;
}

.meta-labelconfigform-shipper-fedex .inner-wrap__panel--actions:not(.inner-wrap__panel--actions-rs):after {
  display: block;
  content: "FedEx service marks are owned by Federal Express Corporation and are used by permission.";
  font-size: rem-calc(10);
  line-height: 1.3;
  margin-top: rem-calc(5);
  color: #999;
}

.label-config__carrier-fine-print {
  font-size: rem-calc(10);
  line-height: 1.3;
  margin-top: rem-calc(5);
  color: #999;

  &:first-of-type {
    margin-bottom: rem-calc(10);
  }

}

.meta-labelconfigform-shipper-endicia .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-pitney .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-pitney_merchant .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-pitney_presort .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-visible_usps .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/usps-mark.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(37);
  height: rem-calc(14);
  margin-left: .25rem;
}

.meta-labelconfigform-shipper-canada_post .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/canada-post-mark.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
}

.meta-labelconfigform-shipper-australia_post .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/australia-post-mark.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
}

.meta-labelconfigform-shipper-dhl .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-dhl_ecommerce .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/dhl.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(55);
  height: rem-calc(13);
}

.meta-labelconfigform-shipper-pitney_cbds .list__item--shipping-shipperIDs label:after,
.meta-labelconfigform-shipper-newgistics .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/pitney-bowes-mark.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(16);
  height: rem-calc(16);
}

.meta-labelconfigform-shipper-onelivex .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/x-delivery.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
}

.meta-labelconfigform-shipper-sendle .list__item--shipping-shipperIDs label:after {
  background: transparent url(../images/sendle.svg) top left no-repeat;
  background-size: contain;
  width: rem-calc(55);
  height: rem-calc(15);
}

.wrap--selected-rate-service,
.label__carrier-logo,
.wrap-shipper-addl-options {
  &:before {
    display: inline-block;
    content: '';
    vertical-align: middle;
    margin-right: rem-calc(5);
  }

  &.fedex {
    &:before {
      background: transparent url(../images/fedex.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(37);
      height: rem-calc(13);
    }
  }

  &.amazon {
    &:before {
      background: transparent url(../images/amazon-mark-01.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(17);
      height: rem-calc(15);
    }
  }

  &.amazon_shipper {
    &:before {
      background: transparent url(../images/amazon-shipping.png) top left no-repeat;
      background-size: contain;
      width: rem-calc(48);
      height: rem-calc(16);
    }
  }

  &.ups {
    &:before {
      background: transparent url(../images/ups.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(19);
      height: rem-calc(19);
    }
  }


  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {

    &:before {

      background: transparent url(../images/usps-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(22);
      height: rem-calc(14);
    }
  }

  &.canada_post {
    &:before {
      background: transparent url(../images/canada-post-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.australia_post {
    &:before {
      background: transparent url(../images/australia-post-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.dhl,
  &.dhl_ecommerce {
    &:before {
      background: transparent url(../images/dhl.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(55);
      height: rem-calc(13);
    }
  }

  &.pitney_cbds,
  &.newgistics {
    &:before {
      background: transparent url(../images/pitney-bowes-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(16);
      height: rem-calc(16);
    }
  }

  &.onelivex {
    &:before {
      background: transparent url(../images/x-delivery.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(15);
      height: rem-calc(15);
    }
  }

  &.sendle {
    &:before {
      background: transparent url(../images/sendle.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(55);
      height: rem-calc(15);
    }
  }

}

.label__carrier-logo,
.wrap-shipper-addl-options {
  &:before {
    margin: 0 rem-calc(12);
  }

  &.fedex {
    &:before {
      width: rem-calc(74);
      height: rem-calc(26);
    }
  }

  &.amazon {
    &:before {
      background: transparent url(../images/amazon-mark-01.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(34);
      height: rem-calc(30);
    }
  }

  &.amazon_shipper {
    &:before {
      background: transparent url(../images/amazon-shipping.png) top left no-repeat;
      background-size: contain;
      width: rem-calc(78);
      height: rem-calc(26);
    }
  }

  &.ups {
    &:before {
      background: transparent url(../images/ups.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }


  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {

    &:before {

      background: transparent url(../images/usps-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(44);
      height: rem-calc(28);
    }
  }

  &.canada_post {
    &:before {
      background: transparent url(../images/canada-post-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.australia_post {
    &:before {
      background: transparent url(../images/australia-post-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.dhl,
  &.dhl_ecommerce {
    &:before {
      background: transparent url(../images/dhl.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(110);
      height: rem-calc(26);
    }
  }

  &.pitney_cbds,
  &.newgistics {
    &:before {
      background: transparent url(../images/pitney-bowes-mark.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.onelivex {
    &:before {
      background: transparent url(../images/x-delivery.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(26);
      height: rem-calc(26);
    }
  }

  &.sendle {
    &:before {
      background: transparent url(../images/sendle.svg) top left no-repeat;
      background-size: contain;
      width: rem-calc(99);
      height: rem-calc(27);
    }
  }

}

.wrap-shipper-addl-options {

  &:before {
    margin-left: 0;
  }
}

.td__shipper-logo {
  width: 100%;

  &.endicia,
  &.pitney,
  &.pitney_merchant,
  &.pitney_presort,
  &.visible_usps {
    background: transparent url(../images/usps.svg) center center no-repeat;
    min-height: rem-calc(34);
    background-size: contain;
  }

  &.ups {
    background: transparent url(../images/ups.svg) center center no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    min-height: rem-calc(38);
    width: rem-calc(34);
  }

  &.dhl,
  &.dhl_ecommerce {
    background: transparent url(../images/dhl.svg) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(33);
  }

  &.fedex {
    background: transparent url(../images/fedex.svg) center center no-repeat;
    min-height: rem-calc(33);
    background-size: contain;
  }

  &.canada_post {
    background: transparent url(../images/canada-post.svg) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(33);
  }

  &.amazon {
    background: transparent url(../images/amazon-buy-shipping-logo.png) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(33);
  }

  &.amazon_shipper {
    background: transparent url(../images/amazon-shipping.png) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(34);
  }

  &.pitney_cbds,
  &.newgistics {
    background: transparent url(../images/pitney-bowes.svg) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(34);
  }

  &.australia_post {
    background: transparent url(../images/australia-post.svg) center center no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    min-height: rem-calc(34);
  }

  &.onelivex {
    background: transparent url(../images/x-delivery.svg) center center no-repeat;
    background-size: contain;
    height: rem-calc(34);
    width: 60%;
    margin: auto;
  }

  &.sendle {
    background: transparent url(../images/sendle.svg) center center no-repeat;
    background-size: contain;
    min-height: rem-calc(34);
  }
}


.wrap--nested-form {
  margin: .5rem 0 .5rem 1rem;
  padding-left: 1rem;
  border-left: 1px dotted #ccc;
}

.outer-wrap--batch-preset-bulk-update {
  width: 60%;
}

.inner-wrap--batch-preset-bulk-update {
  border-bottom: 1px dotted #ccc;
  padding-bottom: .5rem;
  margin-bottom: .5rem;

  &:only-child,
  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

}

.wrap--edit-mode {
  margin: rem-calc(3) 0 rem-calc(7) rem-calc(7);
  padding: rem-calc(7) 0 0 rem-calc(12);
  border-left: 1px dotted #ccc;
}

// Bulk Form Overrides

.meta-labelconfigform-form-bulk {
  .label--ship-date {
    display: none;
  }
}

.meta-labelconfigform-bulk-packinglist .list__item--shipping-options {
  label {
    display: none;
  }
}

// Stripe Element Styling

.wrap--stripe-element {
  .StripeElement {
    background: #fff;
    border-radius: rem-calc(4);
    color: #444;
    border: 1px solid #666;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 3px;
    height: rem-calc(27);
    min-height: rem-calc(27);
    padding: 0 .3rem;
    font-size: rem-calc(13);
    @include flexbox;
    @include flex-direction(column);
    @include flex-justify-sa;
  }

  .StripeElement--focus {
    background: #fff;
    border-color: #6EB43A;
  }

  .StripeElement--invalid {
    border-color: #ff0000;
  }
}


// Dim/Weight Modal Styling Test

.wrap--larger-input {
  input[type="number"].input--dim {
    width: 3.35rem;
    height: 2.25rem;
    padding: .4rem 0 .4rem .4rem;
    font-size: rem-calc(23);
    line-height: 2.8;
  }

  .input__unit {
    font-size: rem-calc(17);
    margin: 0 .5rem;
    line-height: 2;
  }
}

// Omnibar


.omni-bar__input {
  box-shadow: none !important;
  font-size: rem-calc(13) !important;
  width: 100% !important;

}

.omni-bar__control {
  border: 1px solid #666 !important;
  border-right: 0 !important;
  border-radius: rem-calc(4) 0 0 rem-calc(4) !important;
  font-size: rem-calc(14);

  &:hover {
    cursor: text;
  }
}

.btn--omni-bar-search {
  border-color: #666;
}

.omni-bar__clear-indicator:hover {
  cursor: pointer;
}

.omni-bar__control--is-focused {
  border: 1px solid #666 !important;
  border-right: 0 !important;
  box-shadow: none !important;
}

.omni-bar__multi-value {
  font-size: rem-calc(13);
  font-family: $font-family-headings;
  -webkit-font-smoothing: antialiased;
  color: #222;

  .omni-bar__multi-value__label {
    color: inherit;
    font-size: inherit;
    line-height: 1;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    color: #999;

    &:hover {
      background-color: #ccc;
      color: #222;
      cursor: pointer;
    }

    svg {
      height: rem-calc(17) !important;
      width: rem-calc(17) !important;
    }
  }
}

.omni-bar_value-type_status {

  &:before {
    content: 'Status:';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }
}

.omni-bar_value-type_status~.omni-bar_value-type_status:before {
  display: none;
}

.omni-bar_value-type_filters {

  &:before {
    content: 'Status:';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }
}

.omni-bar_value-type_filters~.omni-bar_value-type_filters:before {
  display: none;
}

.omni-bar_value-type_cart:before {
  content: 'Sales Channels:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_cart~.omni-bar_value-type_cart:before {
  display: none;
}

.omni-bar_value-type_shipper:before {
  content: 'Carriers:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_shipper~.omni-bar_value-type_shipper:before {
  display: none;
}

.omni-bar_value-type_supplier:before {
  content: 'Suppliers:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_supplier~.omni-bar_value-type_supplier:before {
  display: none;
}

.omni-bar_value-type_warehouse:before {
  content: 'Warehouses:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_warehouse~.omni-bar_value-type_warehouse:before {
  display: none;
}

.omni-bar_value-type_fulfillment_channel:before {
  content: 'Fulfillment:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_fulfillment_channel~.omni-bar_value-type_fulfillment_channel:before {
  display: none;
}

.omni-bar_value-type_allocation:before {
  content: 'Allocation Status:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_allocation~.omni-bar_value-type_allocation:before {
  display: none;
}

.omni-bar_value-type_category:before {
  content: 'Category:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_category~.omni-bar_value-type_category:before {
  display: none;
}

.omni-bar_value-type_similar_address_to:before {
  content: 'Similar Address To:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_similar_address_to~.omni-bar_value-type_similar_address_to:before {
  display: none;
}

.omni-bar__value-container {

  .omni-bar_value-type_batch_reference_id {
    background: #222;
    color: #fff;

    &:before,
    &:first-of-type:before {
      content: 'Batch:';
      display: inline-block;
      font-size: rem-calc(13);
      line-height: 1;
      font-family: $font-family-headings;
      color: #fff;
      padding: rem-calc(5) rem-calc(5);
      background: #222;
      border-radius: 2px 0 0 2px;
    }

    .omni-bar__multi-value__remove {
      color: #999;

      &:hover {
        color: #ccc;
        background: #444;
      }

    }

  }
}

.omni-bar_value-type_batch_reference_id~.omni-bar_value-type_batch_reference_id:before {
  display: none;
}

.omni-bar_value-type_quantity:before {
  content: 'Qty:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_quantity~.omni-bar_value-type_quantity:before {
  display: none;
}

.omni-bar_value-type_tag {

  &:before {
    content: 'Tags (Any):';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }

  .omni-bar__multi-value__label {
    padding-right: rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 0 2px 2px 0;
    color: #eee;

    &:hover {
      background-color: rgba(0, 0, 0, .55);
      color: #fff;
    }
  }
}

.omni-bar_value-type_tag~.omni-bar_value-type_tag:before {
  display: none;
}

.omni-bar_value-type_tag_and {

  &:before {
    content: 'Tags (All):';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }

  .omni-bar__multi-value__label {
    padding-right: rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 0 2px 2px 0;
    color: #eee;

    &:hover {
      background-color: rgba(0, 0, 0, .55);
      color: #fff;
    }
  }
}

.omni-bar_value-type_tag_and~.omni-bar_value-type_tag_and:before {
  display: none;
}

.omni-bar_value-type_tag_only {

  &:before {
    content: 'Tags (Only):';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }

  .omni-bar__multi-value__label {
    padding-right: rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 0 2px 2px 0;
    color: #eee;

    &:hover {
      background-color: rgba(0, 0, 0, .55);
      color: #fff;
    }
  }
}

.omni-bar_value-type_tag_only~.omni-bar_value-type_tag_only:before {
  display: none;
}

.omni-bar_value-type_tag_not {

  &:before {
    content: 'Tags (Does not have any):';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }

  .omni-bar__multi-value__label {
    padding-right: rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 0 2px 2px 0;
    color: #eee;

    &:hover {
      background-color: rgba(0, 0, 0, .55);
      color: #fff;
    }
  }
}

.omni-bar_value-type_tag_not~.omni-bar_value-type_tag_not:before {
  display: none;
}

.omni-bar_value-type_exclude_tags {

  &:before {
    content: 'Excluded Tags:';
    display: inline-block;
    font-size: rem-calc(13);
    line-height: 1;
    font-family: $font-family-headings;
    color: #999;
    padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
    background: #fff;
  }

  .omni-bar__multi-value__label {
    padding-right: rem-calc(7);
  }

  .omni-bar__multi-value__remove {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: rgba(0, 0, 0, .3);
    border-radius: 0 2px 2px 0;
    color: #eee;

    &:hover {
      background-color: rgba(0, 0, 0, .55);
      color: #fff;
    }
  }
}

.omni-bar_value-type_exclude_tags~.omni-bar_value-type_exclude_tags:before {
  display: none;
}

.omni-bar_value-type_deliver_by:before {
  content: 'Deliver By:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_deliver_by~.omni-bar_value-type_deliver_by:before {
  display: none;
}

.omni-bar_value-type_ship_by:before {
  content: 'Ship By:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_ship_by~.omni-bar_value-type_ship_by:before {
  display: none;
}

.omni-bar_value-type_dropshipped:before {
  content: 'Dropshipped:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_dropshipped~.omni-bar_value-type_dropshipped:before {
  display: none;
}

.omni-bar_value-type_ordered:before {
  content: 'Ordered:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_ordered~.omni-bar_value-type_ordered:before {
  display: none;
}

.omni-bar_value-type_created:before {
  content: 'Created:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_created~.omni-bar_value-type_created:before {
  display: none;
}

.omni-bar_value-type_shipped:before {
  content: 'Shipped:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_shipped~.omni-bar_value-type_shipped:before {
  display: none;
}

.omni-bar_value-type_timezone:before {
  content: 'Time Zone:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_timezone~.omni-bar_value-type_timezone:before {
  display: none;
}

.omni-bar_value-type_ship_to_status:before {
  content: 'Address Validation:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_ship_to_status~.omni-bar_value-type_ship_to_status:before {
  display: none;
}

.omni-bar_value-type_ship_to_country:before {
  content: 'Ship To:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar_value-type_ship_to_country~.omni-bar_value-type_ship_to_country:before {
  display: none;
}

.omni-bar_value-type_search {
  background-color: #fff !important;

  .omni-bar__multi-value__remove,
  .omni-bar__multi-value__remove:hover {
    padding-left: rem-calc(2);
    padding-right: rem-calc(2);
    background-color: #eee;
  }
}

.omni-bar_value-type_search:before {
  content: 'Text Search:';
  display: inline-block;
  font-size: rem-calc(13);
  line-height: 1;
  font-family: $font-family-headings;
  color: #999;
  padding: rem-calc(5) 0 rem-calc(5) rem-calc(12);
  background: #fff;
}

.omni-bar__multi-value:first-of-type:before {
  padding-left: 0;
}

.omni-bar__value-container>div:not(.omni-bar__placeholder):not(.omni-bar__multi-value) {
  flex-grow: 1;
  position: relative;
  z-index: 1;

  .omni-bar__input {
    width: 100%;
  }
}

.omni-bar__placeholder {
  z-index: 0;
}

/* OVERRIDES FOR EXPORT MODAL */

.omni-bar__multi-value-modal {
  background-color: #eee;

  &:before {
    color: #666;
  }

}

.list__item--wrap-order-report-filters {

  .omni-bar__multi-value {
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;

    &:before {
      padding-left: 0;
    }

  }



}


/// Fancy new radio and checkboxes

input[type='checkbox'].checkbox--fancy,
input[type='radio'].radio--fancy {
  --active: #34a0cd;
  --active-inner: #fff;
  --focus: 3px rgba(52, 160, 205, .1);
  --border: #aaa;
  --border-hover: #222;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem-calc(22);
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 rem-calc(6) 0 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background .3s, border-color .3s, box-shadow .2s;
  flex-shrink: 0;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }

  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &:not(.switch) {
    width: 22px;
    height: 22px;

    &:after {
      opacity: var(--o, 0);
    }

    &:checked {
      --o: 1;
    }
  }
}

input[type='checkbox'].checkbox--fancy {

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
      color: #222;
    }
  }

  &:indeterminate {
    --b: #34a0cd;
    --bc: #34a0cd;
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
      color: #ff0000;

      &:after {
        display: block;
        font-family: $aktiv-grotesk-regular;
        content: '(Excluded)';
        font-size: rem-calc(10);
        color: #ff0000;
      }

    }
  }

  &:not(.switch--fancy) {
    border-radius: 5px;

    &:after {
      width: 6px;
      height: 12px;
      border: 3px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 3px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }

    &:indeterminate {
      &:after {
        width: 12px;
        height: 3px;
        border: 0;
        left: 4px;
        top: 8px;
        transform: rotate(var(--r, 0deg));
        opacity: 1;
        background-color: #fff;
      }

      &:checked {
        --r: 0deg;
      }
    }
  }

  &.switch--fancy {
    width: 38px;
    border-radius: 11px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: .6;
        }
      }
    }
  }
}

input[type='radio'].radio--fancy {
  border-radius: 50%;

  &:after {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, .7));
  }

  &:checked {
    --s: .65;
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);

    &+.label__text--fancy div .label__pre-text {
      font-family: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
      color: #222;
    }
  }

  &:not(.switch) {

    &:after {
      opacity: var(--o, 0);
      background-color: #34a0cd;
    }

    &:checked {
      background-color: #fff;
    }
  }
}

input[type='checkbox'].checkbox--fancy-sm,
input[type='radio'].radio--fancy-sm {
  height: rem-calc(16);

  &:not(.switch) {
    width: rem-calc(16);
    border-radius: 3px;

    &:after {
      width: 5px;
      height: 10px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 5px;
      top: 1px;
    }
  }

}

.label--fancy {
  line-height: 1;
  color: #444;

  &:hover {
    color: #222;
  }
}

.label__pre-text {
  font-family: $font-family;
  line-height: 1.25;
}

.label--fancy-tag {
  border-width: 1px;
  border-style: dotted;
  padding: 0.5rem;
  border-left-width: rem-calc(10);
  border-left-style: solid;
  margin-right: .75rem;

  &:hover {
    border-style: solid;

    .wrap--tag-buttons {
      opacity: 1;
    }

  }

  .wrap--tag-edit {
    margin: 0;
  }

  &.disabled,
  .disable:hover {
    opacity: .5;
    border-style: dotted;
    border-left-style: solid;
    cursor: default;
  }

}

.wrap--untagged-filter {
  width: 32%;
}

.label--fancy-untagged {
  background-color: #f7f7f7;
}


// Preset Modal Switches

input[type=checkbox].switch--fancier {
  height: 0;
  width: 0;
  visibility: hidden;
  margin-bottom: 0;
}

.switch__background--fancier {
  cursor: pointer;
  margin-bottom: 0;
  text-indent: -9999px;
  width: 100%;
  height: 1.5rem;
  background: #ddd;
  display: block;
  border-radius: rem-calc(1000);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: .25rem;
    left: .25rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-radius: 100%;
    transition: 0.2s;
  }

  &:active:after {
    width: 1.5rem;
  }

}

input[type=checkbox].switch--fancier+.switch__background--fancier {
  margin: 0;
  vertical-align: unset;
  display: block;
}


input[type=checkbox].switch--fancier:checked+.switch__background--fancier {
  background: #34a0cd;
}

.list__item--shipping-shipperIDs .switch__background--fancier:after {
  margin: 0;
  top: .25rem;
  bottom: unset;
}

input[type=checkbox].switch--fancier:checked+.switch__background--fancier:after {
  left: calc(100% - .25rem);
  transform: translateX(-100%);
  margin: 0;
  top: .25rem;
  bottom: unset;
}


// Logo Uploader in Branding Tracking Page Settings

.wrap--tracking-logo-upload {
  .label--logo-upload {
    font-size: rem-calc(17);
    margin-bottom: rem-calc(15);
  }

  .divider--top {
    margin-top: rem-calc(30);
    padding-top: rem-calc(20);
  }

}