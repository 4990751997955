//
// Alerts
// ------

.alert {
  margin: 0;
  line-height: 1.3;
  font-size: rem-calc(14);
  padding: .8rem;

  &.margin-auto {
    margin: 0 auto;
  }

}

.alert--x-sm {
  padding: .25rem;
}

.alert--sm {
  padding: .35rem;
}

.alert--sm-text {
  dl {
    font-size: rem-calc(13);
  }
}

.alert--lg {
  padding: 1.5rem;
}

.alert--rounded {
  border-radius: rem-calc(3);
}

.alert--standard {
  border: 1px solid lighten($ordoro-blue, 5%);
  background: lighten($ordoro-blue, 46%);
  position: relative;
}

.alert--warning {
  border: 1px solid $warning-border-color;
  background: $warning-bg-color;

  .alert__title {
    color: $warning-txt-color;
  }

  &.border-top {
    border: 0;
    border-top: 1px solid $warning-border-color;
  }
}

.alert--warning-lt {
  border: 1px solid $warning-border-color;
  background: #fffdd8;
}

.alert--global {
  position: fixed;
  margin: auto;
  width: 100%;
  text-align: center;
  bottom: -8rem;
  left: 0;
  right: 0;
  z-index: 800;
  @include transition-all-standard;

  &.in {
    bottom: 3.5rem;
  }
}

.alert--global-dk {
  background: rgba(0, 0, 0, .75);
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.alert--zuck-mode {
  border: 3px solid $ordoro-green;
  background: lighten($ordoro-green, 50%);
}

.alert__inner-wrap--global {
  display: inline-block;
  text-align: center;
  font-size: rem-calc(17);
  margin: 0 auto;
  max-width: 80vw;
  padding: 1rem;
  background: rgba(0, 0, 0, .75);
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.alert-close {
  position: absolute;
  top: 0;
  right: .15rem;
  line-height: 1;
}

.alert__close-btn--global {
  position: absolute;
  top: 0;
  bottom: 0;
  right: .75rem;
  margin: auto 0;

}

.alert--savings {
  display: inline-block;
  padding: .5em;
  font-size: rem-calc(13);
  background: #fff;
  border: 1px dotted #ccc;
  color: #999;
  margin-left: .75em;
}

.alert--neutral {
  background: #f5f5f5;
  border: 1px solid #ddd;
  padding: .75rem;

  &.alert--sm {
    padding: .35rem;
  }

  &.alert--x-sm {
    padding: .25rem;
  }
}

.alert--form-callout {
  font-size: rem-calc(12);
  padding: .35rem .5rem;
  background-color: lighten(#34a0cd, 45%);
  border: 1px solid lighten(#34a0cd, 35%);
  margin-bottom: 0;
}

.alert--refresh {
  border: rem-calc(5) solid #f58026;
  padding: 1rem;
}

.alert--unprinted {
  padding: 0 .35rem;
}

.alert--tally {
  border: 1px solid lighten($ordoro-blue, 5%);
  background: lighten($ordoro-blue, 46%);
  padding: .75rem 1.25rem;
  margin: .75rem 0 0 0;
  border-right: 0;
  border-left: 0;

  .list__item:only-of-type {
    list-style: none;
    margin-left: -1.1rem;
  }
}

.wrap--make-warning .alert--tally {
  border: 1px solid $warning-border-color;
  background: $warning-bg-color;
  border-right: 0;
  border-left: 0;
}

.alert--insurance-cost {
  padding: .4rem .8rem;

  .label--insurance-cost {
    display: none;
  }
}

.alert--error {
  border-top: 1px solid lighten(#ff0000, 30%);
  border-bottom: 1px solid lighten(#ff0000, 30%);
  background: lighten(#ff0000, 45%);
  padding-right: .75rem;

  &.full-border {
    border: 1px solid lighten(#ff0000, 30%);
  }

  &.lg-text small.error {
    font-size: rem-calc(13);
  }
}

.wrap--scan-alert {
  min-height: 15vh;
  padding: 3rem 3rem 4.25rem 3rem;
}

.wrap--packing-mode-scan-alert {
  min-height: 15vh;
}

.alert--success {
  border: 1px solid $txt-green;
  background: lighten($ordoro-green, 50%);
}

.alert--support-pin {
  border-right: 0;
  border-left: 0;
  margin-bottom: 1.35rem;
}

.alert--ship-by-date div:nth-child(2) {
  border-top: 1px dotted #ccc;
  padding-top: rem-calc(3);
  margin-top: rem-calc(3);
}


.wrap--external-revision-alert {

  .alert {
    border-radius: .25rem;
  }

}

// IMM Feature Lock Sidebar

.alert--feature-lock {
  background: #fff;
  border: 3px solid #34a0cd;
  border-radius: 0.625rem;
  color: #111;
  padding: 1.85rem;
}

.wrap--inv-mgmt-icon {
  width: 5.35rem;
  margin: 0 auto 1rem auto;
  text-align: center;
}

.icon--pricing path {
  fill: #bbb;
}

.alert--app-disabled {
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: rem-calc(5);
  background: #f7f7f7;

}