//
// Lists
// -----

.list {
  margin: 0;
}

.list--no-style,
.list--form {
  list-style: none;
  margin: 0;
}

.list__item--no-style {
  list-style: none;
  margin: 0;
}

.list__item--error,
.panel .list__item--error {
  color: $error-color;
  margin-bottom: rem-calc(5);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list__item--warning,
.panel .list__item--warning {
  color: $warning-txt-color;
  margin-bottom: rem-calc(5);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list--square,
.list__item--square {
  list-style-type: square;
  margin-left: 1.1rem;

  .list__item--no-style {
    margin-left: -1.1rem;
  }
}

.list--ol {
  margin-left: 1.2rem;

  .list__item {
    padding-left: .3rem;
  }
}

.list__item--mb0-last:last-of-type {
  margin-bottom: 0;
}

.list__item--form {
  margin-bottom: .875rem;

  // FIXME
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  label {
    margin-bottom: 0;
    font-size: rem-calc(13)
  }

  .fs-00 {
    font-size: rem-calc(14);
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list--inline li {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}

.list--inline .list__item--dropdown {
  display: block;
  margin: 0;
}

.list--list-header {
  padding: 1em;
  margin-bottom: 1em;
  border: 1px solid #bbb;
  background: #ddd;
  vertical-align: middle;
}

.list--content-blocks {
  padding-left: 0;
  margin: 0;
  max-height: calc(100vh - 15.5rem);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: .5em;
    width: rem-calc(13);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .3);
    border-left: rem-calc(5) solid #fff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, .15);
    border-left: rem-calc(5) solid #fff;
  }

}

.zuck .list--content-blocks {
  max-height: calc(100vh - 16.5rem);
}

.list--order-blocks {
  padding-left: 2rem;
  margin-left: -2rem;
}

.wrap--expand {
  background: #fff;
  color: #999;
  opacity: 0;
  visibility: hidden;
  font-size: rem-calc(11);
  line-height: 1.3;
  margin-top: .5rem;
  padding: .25rem .5rem;
  display: inline-block;
  transition: $transition-duration;
}

.list__item--content-block {
  display: block;
  list-style: none;
  padding: 1rem 1rem 0 .8rem;
  margin-bottom: 0;
  border-top: 1px solid #bbb;
  border-left: 3px solid transparent;
  background: #f7f7f7;
  position: relative;
  opacity: 1;
  @include transition-all-standard;

  &:nth-child(2n+1) {
    background: #fff;
  }

  &:hover {
    background: lighten($ordoro-blue, 46%);

    .link--order-header {
      border-bottom: 1px dotted #999;

      &:hover {
        color: #000;
        border-bottom: 1px solid #999;
      }
    }

    .wrap--expand {
      opacity: 1;
      visibility: visible;
    }
  }

  &.selected,
  &:nth-child(2n+1).selected {
    border-left: 3px solid lighten($ordoro-blue, 5%);
    background: lighten($ordoro-blue, 46%);

  }

  &.loading {
    padding: 3.5rem 1rem;
    background: transparent;
    border: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list__item--order-revision,
.list__item--order-revision:nth-child(2n+1) {
  background: #fffdcb;

  .warning {
    color: #756301;
  }

  &:hover {
    background: darken(#fffdcb, 2%);
  }

  &.selected,
  &:nth-child(2n+1).selected {
    border-left: 3px solid #FFD324;
    background: darken(#fffdcb, 2%);

  }
}

.wrap--order-revision-callout {
  background-color: rgba(255, 211, 36, .5);
  margin: .5rem 0 0 -3px;
  padding: .15rem;
  text-align: center;
  color: #111;

  .abs-right {
    right: .25rem;
  }

}

.list--loading {

  min-height: 12rem;

  .list__item--content-block {
    opacity: .3;
  }
}

.list--empty-content {
  padding: 2rem 5.75rem 3rem 5.75rem;
  text-align: center;
  color: #666;
  margin-bottom: 0;
}


.list--shipping-options,
.list--dropship {
  list-style: none;
  margin-left: 0;
}

.list--accordion {
  margin-bottom: .35rem;
  padding: .25rem 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  &.lg {
    padding: .75rem 0;
  }

}

.list__title--accordion {
  margin-bottom: 0;
  line-height: 1.3;
  transition: margin-bottom 100ms linear;
}

.list__item--accordion {
  display: none;
}

.open {

  .list__title--accordion {
    margin-bottom: .75rem;
  }

  .list__item--accordion {
    display: block;
  }
}


.wrap--edit-preset-list {

  .list--shipping-options:has(+ .list--accordion) .list__item--shipping-options:last-of-type {
    border-bottom: 0;
  }

  .list__item--shipping-options:has(+ .list--accordion) .list--shipping-options .list__item--shipping-options:last-of-type,
  .list__item--shipping-options:has(+ .list__item--shipping-options) .list--shipping-options .list__item--shipping-options:last-of-type,
  .list__item--accordion .list--shipping-options .list__item--shipping-options:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .list__item--accordion .list--shipping-options .list--shipping-options .list__item--shipping-options:last-of-type,
  .list--shipping-options .list--shipping-options .list__item--shipping-options:last-of-type,
  .list--shipping-options:has(+ .list--accordion) ul .list__item--shipping-options:last-of-type {
    border-bottom: 1px dotted #ccc;
    padding-bottom: 1rem;
  }

  .list__item--shipping-options:has(+ .meta-labelconfigform-container-packagingweight) .list--shipping-options .list__item--shipping-options:last-of-type,
  .list__item--shipping-options:has(+ .meta-labelconfigform-input-shipdate) .list--shipping-options .list__item--shipping-options:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .list__item--shipping-options.margin-bottom-15:has(+ .list__item--shipping-options) {
    margin-bottom: 0 !important;
  }
}

.list--subfilters {
  font-size: rem-calc(13);
  display: inline-block;
  margin-left: 0;
  padding-left: 0;
  background: none;
}

.list__title--filters {
  font-family: $font-family-headings;
  margin-bottom: 0;
}

.list__item--shipping-options,
.list__item--dropship {
  margin-bottom: .65rem;
  font-size: rem-calc(14);
  list-style: none;

  &.warning label,
  &.warning button {
    color: $warning-txt-color;
  }
}

.list__item--shipping-options-rs {
  flex-basis: 100%;
}

.wrap--shipping-rate-dropdown-rs {
  flex: 1 1 0;
  margin-right: .625rem;
}

.wrap--rate-shipper-pkg-info {
  flex: 1 1 0;
  margin-right: .825rem;
  max-width: 15rem;
}

.btn__text--no-overflow {
  max-width: 15rem;
}

.wrap--rate-cost-eta-info {
  text-align: right;
  margin-right: 1rem;
}

.list__item--shipping-options:empty,
.list__item--shipping-options .flex-item:empty {
  display: none;
}

.list__item--shipping-total {
  vertical-align: middle;
  line-height: 1;
}

.list__item--action-item {
  margin-bottom: rem-calc(10);

  &:last-of-type {
    margin-bottom: 0
  }
}

.list--pagination-wrap {
  font-size: rem-calc(13);
  padding-top: 1em;
  margin-bottom: 0;
}

.list--pagination,
.list--content-count {
  margin-right: rem-calc(14);
}

.max-pages-reached {
  .list--pagination {
    margin-right: 0;
  }
}

.wrap--list-header {
  padding-top: .8rem;
  padding-bottom: .5rem;
}

.wrap--list-header-orders {
  padding-bottom: .8rem;
}

.hr--content-block {
  height: 0;
  margin: .5rem rem-calc(14) -1px 0;
  position: relative;
  z-index: 2;

  &.at-bottom {
    margin: -1px rem-calc(14) 0 0;
  }
}

.wrap--product-footer {

  .hr--content-block.at-bottom,
  .list--pagination {
    margin-right: 0;
  }
}

.wrap--product-footer-inner-scroll {
  margin-right: rem-calc(3);
}

.list--tabs {
  margin: 0 0 .5rem 0;
  padding-left: 0;
  display: flex;
}

.wrap--order-header {
  padding: 0;
}

.link--order-header {
  color: #222;
  border-bottom: 1px dotted transparent;
  @include transition-all-standard;
}

.list--order-header:last-of-type {
  margin-left: 1.25rem;
  padding-bottom: 0;
}

.list--order-header:first-of-type {
  margin-left: 0;
}

.list__item--order-checkbox {
  margin-left: -.25rem;
  margin-right: .7rem;
  font-size: .875rem;
  display: inline-block;
  vertical-align: top;
}

.list__title--order-header {
  display: inline-block;
  vertical-align: top;
}

.list__title--order-header,
.list__title {
  font-weight: normal;
  font-family: $font-family-headings;
}

.list__title--order-data,
.list__item--order-data {
  margin-bottom: 0;
  line-height: 1.35;
}

.list__title--product-data,
.list__item--product-data {
  margin-bottom: rem-calc(3);
  line-height: 1.35;
}

.wrap--summary-tags {
  position: relative;
  top: rem-calc(-1);
}

.wrap--summary-tags-min {
  position: absolute;
  top: 0;
  right: -1rem;

  .list__item--tag {
    display: block;
  }

  .tag-name,
  .btn--remove-tag {
    display: none;
  }
}

.table__td--summary-tags {
  width: 3%;

  .list__item--tag {
    display: block;
    width: 100%;
    height: rem-calc(5);
    padding: 0;
    margin-right: 0;
    margin-bottom: 2px;
  }

  .tag-name,
  .btn--remove-tag {
    display: none;
  }

}

.wrap--summary-tags-table {
  line-height: 1;

  .list {
    line-height: 1;
  }

  .list__item--tag {
    margin-top: rem-calc(3);
    margin-right: rem-calc(3);
    padding: 0;
    height: .5rem;
    width: .7rem;
    vertical-align: top;
  }

  .tag-name,
  .btn--remove-tag {
    display: none;
  }
}

.tag-name {
  margin-right: rem-calc(5);
}

.wrap--order-summary,
.table--order-summary {
  margin-left: rem-calc(20);
  line-height: 1.3;
  background: transparent;

  .hide-in-summary {
    display: none;
  }

  .i--validation {
    display: inline-block;
    vertical-align: middle;
  }
}

.table--order-summary {

  .i--validation {
    font-size: rem-calc(13);
  }

  .wrap--address-validation {
    display: inline-block;
  }

  .btn--link:hover {
    .wrap--address-validation {
      text-decoration: none;
    }
  }
}

.wrap--order-timeline-pop-over,
.wrap--po-timeline-pop-over,
.wrap--return-order-timeline-pop-over,
.wrap--mo-timeline-pop-over,
.wrap--batch-timeline-pop-over {
  position: absolute;
  left: -1.2rem;
  top: .25rem;
  opacity: 0;
  visibility: hidden;
  @include transition-all-faster;
}

.wrap--po-timeline-pop-over,
.wrap--return-order-timeline-pop-over,
.wrap--mo-timeline-pop-over,
.wrap--batch-timeline-pop-over {
  top: 1rem;
}

.list__item--content-block:hover {
  .wrap--order-timeline-pop-over {
    opacity: 1;
    visibility: visible;
  }
}

.table__tr:hover {

  .wrap--po-timeline-pop-over,
  .wrap--return-order-timeline-pop-over,
  .wrap--mo-timeline-pop-over,
  .wrap--batch-timeline-pop-over {
    opacity: 1;
    visibility: visible;
  }
}

.wrap--expanded-order {
  padding: 0 1rem 1rem .8rem;
}

.show-in-fancy {
  display: none;
}

.table--order-summary-fancy {
  .show-in-fancy {
    display: inline;
  }
}

.wrap__order-list--selected .table--order-summary-fancy {

  .hide-when-selected {
    display: none;
  }

  .block-when-selected {
    display: block;
  }
}

.selected-shipper--endicia,
.selected-shipper--pitney,
.selected-shipper--pitney_merchant,
.selected-shipper--pitney_presort,
.selected-shipper--visible_usps {
  background: transparent url(../images/usps-mark.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(20);
  height: rem-calc(13);
  margin-right: rem-calc(3);
}

.selected-shipper--fedex {
  background: transparent url(../images/fedex.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(35);
  height: rem-calc(12);
  margin-right: rem-calc(4);
}

.selected-shipper--ups {
  background: transparent url(../images/ups.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(16);
  height: rem-calc(16);
  margin-right: rem-calc(3);
}

.selected-shipper--canada-post {
  background: transparent url(../images/canada-post-mark.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
  margin-right: rem-calc(3);
}

.selected-shipper--australia_post {
  background: transparent url(../images/australia-post-mark.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
  margin-right: rem-calc(3);
}

.selected-shipper--dhl,
.selected-shipper--dhl_ecommerce {
  background: transparent url(../images/dhl.svg) bottom left no-repeat;
  background-size: contain;
  width: rem-calc(50);
  height: rem-calc(13);
  margin-right: rem-calc(3);
}

.selected-shipper--amazon {
  background: transparent url(../images/amazon-mark-01.svg) bottom left no-repeat;
  background-size: contain;
  width: rem-calc(17);
  height: rem-calc(15);
  margin-right: rem-calc(3);
}

.selected-shipper--amazon_shipper {
  background: transparent url(../images/amazon-shipping.png) bottom left no-repeat;
  background-size: contain;
  width: rem-calc(48);
  height: rem-calc(16);
  margin-right: rem-calc(3);
}

.selected-shipper--pitney_cbds,
.selected-shipper--newgistics {
  background: transparent url(../images/pitney-bowes-mark.svg) bottom left no-repeat;
  background-size: contain;
  width: rem-calc(16);
  height: rem-calc(16);
  margin-right: rem-calc(3);
}

.selected-shipper--onelivex {
  background: transparent url(../images/x-delivery.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(15);
  height: rem-calc(15);
  margin-right: rem-calc(3);
}

.selected-shipper--sendle {
  background: transparent url(../images/sendle.svg) center left no-repeat;
  background-size: contain;
  width: rem-calc(50);
  height: rem-calc(15);
  margin-right: rem-calc(3);
}

.list__title--order-data,
.list__title--product-data,
.list__title--tracking-data
 {
  color: #999;
  font-family: $font-family-headings;
  font-size: rem-calc(12);
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 0;

  &.error {
    color: #ff0000;
  }

  &.warning {
    color: $warning-txt-color;
  }
}

.list__title--product-data {
  font-size: rem-calc(13);
}

.list--unselected,
.list--label-success {
  padding: 1.25rem;
  color: #999;
  font-size: rem-calc(14);
}

.list--label-success {
  color: #444;
  padding: 0 1.25rem;
  margin-bottom: 0;
}

.list--label-sizes {
  font-size: rem-calc(14);
}

.list__title--unselected {
  font-weight: normal;
  font-family: $font-family-headings;
  padding: 2.5rem 1rem;
  text-align: center;
}

.list__title--label-success {
  font-family: $font-family-headings;
  font-size: rem-calc(17);
  color: #000;
  margin: 1rem 0 .25rem 0;
}

.list__item--label-success:last-of-type {
  margin-bottom: 0;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #ccc;
}

.list__title--label-sizes {
  color: #999;
  padding-bottom: 0;
}

.list__title--print-options {
  color: #999;
  margin: .5rem 0;
  font-size: rem-calc(14);
}

.list--order-data,
.list--tracking-data,
.list--product-data {
  font-size: rem-calc(12);

  li:only-of-type {
    list-style: none;
    margin-left: -1.1rem;
  }

  .list__item--shipping-info:only-of-type,
  .list__item--tag:only-of-type,
  .list__item--line:only-of-type,
  .list__item--dropdown:only-of-type {
    margin-left: 0;
  }
}

.wrap--no-neg-margin {

  .list--order-data li:only-of-type,
  .list--tracking-data li:only-of-type,
  .list--product-data:only-of-type {
    margin-left: 0;
  }

}

.list--order-date {
  color: #666;
  vertical-align: baseline;
  line-height: 1.2;
  margin-left: 1.25rem;
}

.list--order-status {
  position: absolute;
  top: .2rem;
  right: .25rem;
  vertical-align: middle;
}

.wrap__list--order-data,
.wrap__list--tracking-data {
  border-left: 1px solid #ddd;
  word-break: break-word;

  &:first-of-type {
    padding-left: 1.25rem;
    border-left: 0;
  }

  &:last-of-type {
    border-left: 1px solid #ddd;
  }

  &.partially-shippable {
    color: $warning-txt-color;

    .list__title--order-data {
      color: $warning-txt-color;
    }
  }

  &.unshippable {
    color: $error-color;

    .list__title--order-data {
      color: $error-color;
    }
  }
}

.list--order-summary,
.list--order-shipto,
.wrap--address-validation,
.list--order-data {

  &.partially-shippable,
  &.warning,
  &.warning .btn--address {
    color: $warning-txt-color;

    .list__title--order-data {
      color: $warning-txt-color;
    }
  }

  &.unshippable,
  &.error,
  &.error .btn--address {
    color: $error-color;

    .list__title--order-data {
      color: $error-color;
    }
  }
}

.list--order-billto,
.with-top-divider {
  margin-top: rem-calc(10);
  padding-top: rem-calc(10);
  border-top: 1px dotted #ccc;
}

.icon--order-status {
  color: #bbb;

  &:hover {
    color: #999;
  }
}

.order-status {
  border: 2px solid #ccc;
  color: #aaa;
  border-radius: rem-calc(3);
  display: inline-block;
  padding: .35em;
  font-size: rem-calc(10);
  font-family: $font-family-headings;
}

.label-status {
  margin-bottom: .5em;
  padding-bottom: .5em;
  border-bottom: 1px dotted #ccc;
}

.label-started {
  color: getColor(blue, base);
}

.list__item--tab {
  margin-left: 0;
  list-style: none;
  flex-grow: 1;

  &.active {
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;

    .btn--tab {
      background: #fff;
      border-bottom: 0;
      color: #444;
      font-family: $font-family-headings;
      cursor: default;
    }

    &:first-of-type {
      border-left: 1px solid transparent;
    }

    &:last-of-type {
      border-right: 1px solid transparent;
    }

    &.single {
      border-right: 0;
      border-left: 0;
    }
  }
}

.list--line {
  margin: 0;
  list-style: none;
}

.list__item--line:first-of-type {
  margin-top: .25rem;
}

.list--line-details,
.list--kit-component,
.list--product-warehouse {
  @include flexbox;
  @include flex-justify-sb;
  padding-bottom: .5rem;
  border-bottom: 1px dotted #ccc;
  margin-bottom: .5rem;
}

.list--line .list__item--line:last-of-type .list--line-details {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.list--po-line-details {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.list--kit-component:last-of-type,
.list--product-warehouse:last-of-type,
.list--return-line-details:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.list__title--line-detail,
.list__title--kit-component,
.list__title--product-warehouse {
  text-align: left;
  font-size: rem-calc(12);
  line-height: 1.3;
  margin: 0 rem-calc(5) 0 0;
}

.list__item--line-detail,
.list__item--kit-component,
.list__item--product-warehouse {
  font-size: rem-calc(12);
  margin: 0 rem-calc(5) 0 0;
  line-height: 1.3;
  vertical-align: top;
}

.table__td--inv .list__title--product-warehouse {

  width: 80%;
  margin-right: 0;

  .btn--link {
    width: 100%;
  }
}

.list__item--line-qty {
  margin-right: 0;
  color: #666;
}

.wrap--product-addl-info-item {
  display: none;
  border-top: 1px dotted #ccc;
  padding-top: .5rem;
  margin-top: .55rem;
}

.wrap--product-addl-info-list {
  display: none;
  border: 1px solid #ccc;
  background: #fff;
  padding: .5rem;
  margin: .55rem 0 0 0;
  position: relative;

  &:before {
    @include arrow(top, #ccc, .4rem);
    content: "";
    position: absolute;
    left: .55rem;
    top: -.4rem;
  }

}

.wrap--product-addl-info-right {
  margin-right: -.55rem;

  &:before {
    @include arrow(top, #ccc, .4rem);
    content: "";
    position: absolute;
    left: auto;
    right: .55rem;
    top: -.4rem;
  }
}

.inv-total {
  padding-right: .75rem;
}

.wrap--product-addl-info-inv {
  border-left: 0;
  border-right: 0;
  padding: .5rem 0;

  .list--product-warehouse {
    padding-right: .75rem;
  }


  &:before {
    display: none;
  }

  &.first {
    padding-left: .75rem;
    border-left: 1px solid #ccc;
  }

  &.last {
    padding-right: .75rem;
    border-right: 1px solid #ccc;

    .list--product-warehouse {
      padding-right: 0;
    }
  }

}

// Order Timeline

.wrap--timeline-day {
  border-top: 1px dotted #ccc;
}

.wrap--order-timeline-pop-over {

  .wrap--timeline-day:first-of-type {
    border-top: 0;

    .subheader--timeline-day {
      margin-top: 0;
    }
  }

  .wrap--timeline-day:last-of-type {

    .list--timeline-point:last-of-type {
      margin-bottom: 0;
    }
  }
}

.list--timeline-point {
  margin: 0 0 0 1.5rem;
  padding: .5rem 1.5rem .5rem 1.25rem;
  border-left: 1px solid #ddd;

  &:first-of-type {
    padding-top: .75rem;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }
}

.subheader--timeline-day {
  font-size: rem-calc(12);
  color: #444;
  font-family: $font-family-headings;
  margin: .75rem 0 .25rem 0;
}

.list__title--timeline-point {
  color: #666;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border-radius: 100%;
    border: 3px solid #fff;
    background: #ddd;
    width: .8rem;
    height: .8rem;
    left: -1.65rem;
    top: rem-calc(1);
    display: inline-block;
  }
}

.attr--timeline-point {
  color: #999;
}

.list__item--timeline-point {}

.wrap--insurance-amt {
  margin: rem-calc(5) rem-calc(17);
}

.wrap--tally-list {
  border-top: 1px dotted #ccc;
  margin: rem-calc(13) rem-calc(10) rem-calc(5) rem-calc(10);
  padding-top: rem-calc(13);
}

.wrap--printnode-list {
  margin-left: 1.35rem;
}


// Product List Page

.wrap--product-name-kit {
  .btn--link {
    margin-right: rem-calc(5);
  }
}

.wrap--product-detail-header {
  .list--tags {
    margin-bottom: 1rem;
  }
}

.list--nested-items {
  list-style: none;

  .list__item {
    padding-left: .75rem;
    border-left: 1px dotted #ccc;
  }

  .list__item:nth-child(1) {
    padding-left: 0;
    border-left: 0;
  }

  .list__item:nth-child(2) {
    margin-left: .75rem;
  }

  .list__item:nth-child(3) {
    margin-left: 1.5rem;
  }

  .list__item:nth-child(4) {
    margin-left: 2.25rem;
  }

  .list__item:nth-child(5) {
    margin-left: 3rem;
  }

  .list__item:nth-child(6) {
    margin-left: 3.75rem;
  }

}


// Product List Page - Firefox BS

@-moz-document url-prefix() {

  .list__item--product-warehouse {
    line-height: inherit;
  }

}

// Welcome Page List Styling

.list--app-resource {
  padding: 1.5rem 1.35rem;
  background: #eee;
  border-radius: rem-calc(5);
  border: 2px solid #bbb;
}

.list--app-resource-best {
  border: 3px solid #444;
  position: relative;
  padding-top: 3rem;

  &:before {
    content: "RECOMMENDED";
    font-family: $aktiv-grotesk-bold;
    font-size: rem-calc(12);
    padding: .35rem;
    background: #444;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

}

// Safari Z-index sadness with Rate Shopping Popover

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .only-sf--z1 {
    z-index: 1;
  }
}
