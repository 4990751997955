// Font-face setup, Bourbon mixins, Foundation framework, and basic Ordoro styles
@import "base";

// App-specific styles
@import "dropdowns";
@import "date-picker";
@import "tables";
@import "modals";
@import "header";
@import "footer";
@import "dashboard";
@import "graphs";
@import "product-detail";
@import "user-settings";
@import "order-detail";
@import "dropzone";
@import "non-pro";
@import "downtime";

// Final Foundation Overrides
@import "final-overrides";

// New refactored Concise Stuff

// Core concise imports
@import 'concise.css/core/globals/globals';
@import 'concise.css/core/globals/functions';
@import 'concise.css/core/globals/mixins';
@import 'concise.css/custom/globals';
@import 'concise.css/core/layout/base';
@import 'concise.css/addons/concise-ui/components/tooltips';

// Custom styles
@import 'concise-custom/globals';
@import 'concise-custom/magic';
@import 'concise-custom/mixins';
@import 'concise-custom/buttons';
@import 'concise-custom/dropdowns';
@import 'concise-custom/navigation';
@import 'concise-custom/site-wide';
@import 'concise-custom/grid';
@import 'concise-custom/modals';
@import 'concise-custom/iverson-icons';
@import 'concise-custom/lists';
@import 'concise-custom/forms';
@import 'concise-custom/tags';
@import 'concise-custom/tables';
@import 'concise-custom/dashboard';
@import 'concise-custom/rules';
@import 'concise-custom/helpers';
@import 'react-datepicker';
@import 'concise-custom/date-picker';
@import 'concise-custom/alerts';
@import 'concise-custom/panels';
@import 'concise-custom/progress-indicator';
@import 'concise-custom/spinner';
@import 'concise-custom/packing-mode';
@import 'concise-custom/pricing-plans';
@import 'concise-custom/detail-page';
@import 'concise-custom/svgs';
@import 'concise-custom/user-settings';

#iverson-page {
  @import 'concise-custom/resets';
  @import 'concise-custom/custom';
}

#dropdown-root {
  @import 'concise-custom/resets';
  @import 'concise-custom/custom';
}

// WHITE LABEL DEMO STUFF
.white-label--firstmile {
  @import 'concise-custom/white-label/firstmile';
}

.white-label--visible-scm {
  @import 'concise-custom/white-label/visible-scm';
}

.zuck #iverson-page .inner-wrap__panel--actions {
  max-height: calc(100vh - 33.5rem);
}

.zuck #iverson-page .inner-wrap__panel--actions-rs {
  max-height: calc(100vh - 36rem);
}

.zuck #iverson-page .wrap--batch-info-panel .inner-wrap__panel--actions {
  max-height: calc(100vh - 17rem);
}

.zuck #iverson-page .panel--actions .wrap--batch-info-panel .inner-wrap__panel--actions {
  max-height: calc(100vh - 25rem);
}


.zuck #iverson-page .meta-labelconfigform-shipper-ups .inner-wrap__panel--actions {
  max-height: calc(100vh - 33.5rem);
}

.zuck #iverson-page .inner-wrap__abode {
  max-height: calc(100vh - 21.5rem);
}

.zuck #iverson-page .inner-wrap__panel--errors {
  max-height: 4rem;
}

@import 'concise-custom/responsive';

.dark-mode {
  @import 'concise-custom/dark-mode';
}
