//Firefox hacks

@-moz-document url-prefix() {
    .product-basic-info-value .postfix, .product-basic-info-equation.edit-mode .postfix {
        right: .8em;
    }
    .manual-order-price-wrap .product-basic-info-value .prefix {}

    /* Select box inconsistency */
    .select {
        height: auto;
        padding: .3em .1em;
    }
    .pagination-data .number-per-page-wrap select {
        margin-top: 0;
        padding: 0;
    }
    .select.bigger-select, .select.full-width {
        padding: .15em .25em;
    }
    .select.warehouse-select {
        height: 1.35em;
    }
    input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"] {
        padding: 0 .5rem;
    }

    .table-cell-status-icon {
        margin-left: .2em;
    }
    .print-label-error-wrap .icon-error {
        top: -3.25rem;
    }

    @media #{$large} {
        .print-label-error-wrap .icon-error {
            top: -3rem;
        }
    }


}
