.progress-indicator {
    width: 0;
    height: rem-calc(3);
    background: $ordoro-blue;
    position: relative;

    &.start--linear {
        @include animation(start--linear 10s ease-in);
        @include animation-fill-mode(both);
    }

    &.end--linear {
        @include animation(end--linear 150ms linear);
        @include animation-fill-mode(both);
    }


}

.progress-indicator__outer {
    opacity: 0;
    width: 100%;
    height: rem-calc(3);
    background: #ddd;
    position: relative;
    overflow: hidden;
    @include transition-all-fast;

    &.start--indeterminate {
        opacity: 1;

        .progress-indicator__inner {
            @include animation(start--indeterminate 1.25s ease-in-out infinite);
        }
    }

    &.end--indeterminate {
        opacity: 0;
    }

}

.progress-indicator__inner {
    height: rem-calc(3);
    background: $ordoro-blue;
    position: absolute;
    top: 0;
}

.progress-circle {
    border: 8px solid #ddd;
    border-right-color: $ordoro-green;
    border-bottom-color: $ordoro-green;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    text-align: center;
}
