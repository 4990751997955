//
// Arrows
// ------

@mixin arrow($direction, $color, $size) {
  display: block;
  height: 0;
  width: 0;

  @if $direction =='top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }

  @else if $direction =='right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }

  @else if $direction =='bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  }

  @else if $direction =='left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  }

  @else if $direction =='top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  }

  @else if $direction =='top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  }

  @else if $direction =='bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  }

  @else if $direction =='bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}

//FLEX BOX

@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-justify-sb {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@mixin flex-justify-sa {
  -ms-flex-pack: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

@mixin flex-direction($value) {
  @if $value ==row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  }

  @else if $value ==column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  @else if $value ==column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  }

  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;

  @if $value ==nowrap {
    -ms-flex-wrap: none;
  }

  @else {
    -ms-flex-wrap: $value;
  }

  flex-wrap: $value;
}

@mixin flex-grow($int) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

@mixin flex-wrap-margin($value) {
  margin: rem-calc(-$value) 0 rem-calc($value) rem-calc(-$value);

  li,
  .flex-item {
    margin: rem-calc($value) 0 0 rem-calc($value);
  }
}

//Fancy Scroll Bar

@mixin modal__scrollbar {

  &::-webkit-scrollbar {
    height: .5em;
    width: .5em;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .4);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, .2);
  }
}

// Center all

@mixin center-everything {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

// Background Pulse for Onboarding divs


@mixin speedbump-pulse {
  @include animation(speedbump-pulse 7s infinite);
}

@include keyframes(speedbump-pulse) {

  0%,
  20%,
  26%,
  40%,
  50% {
    transform: translate(0, 0);
  }

  22%,
  24% {
    transform: translate(3px, 0);
  }

  21%,
  23%,
  25% {
    transform: translate(-3px, 0);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.speedbump-pulse {
  @include speedbump-pulse;
}

// Linear global progress indicator

@include keyframes(start--linear) {
  0 {
    width: 0%;
  }

  10% {
    width: 20%;
  }

  100% {
    width: 50%;
  }
}

@include keyframes(end--linear) {
  0 {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

// Interderminate global progress indicator

@include keyframes(start--indeterminate) {
  0% {
    left: -10%;
    width: 0;
  }

  40% {
    width: 20%;
  }

  60% {
    width: 20%;
  }

  100% {
    width: 0;
    left: 110%;
  }
}


//
// Pulse
// ------


@include keyframes(pulse) {
  0% {
    @include transform(scale(1));
    opacity: 0.5;
  }

  19% {
    @include transform(scale(2.5));
    opacity: 0.0;
  }

  20% {
    @include transform(scale(0));
    opacity: 0.0;
  }

  45% {
    @include transform(scale(.1));
    opacity: 0;
  }

  70% {
    @include transform(scale(0.2));
    opacity: 0;
  }

  100% {
    @include transform(scale(0.5));
    opacity: 0.1;
  }
}


//
// Side-to-Side
//

@include keyframes(side-to-side-right) {
  0% {
    right: 0;
  }

  50% {
    right: -1rem;
  }

  100% {
    right: 0;
  }
}

@include keyframes(side-to-side-left) {
  0% {
    left: 0;
  }

  50% {
    left: -1rem;
  }

  100% {
    left: 0;
  }
}

@mixin side-to-side-right {
  @include animation(side-to-side-right 1s ease-in-out infinite);
}

@mixin side-to-side-left {
  @include animation(side-to-side-left 1s ease-in-out infinite);
}

.side-to-side-right {
  position: relative;
  margin-right: 1.1rem;
  @include side-to-side-right;
}

.side-to-side-left {
  position: relative;
  margin-left: 1.1rem;
  @include side-to-side-left;
}