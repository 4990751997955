//
// Header
// ----------

.header__logo {
  max-width: 100%;
  height: auto;
  width: auto \9;
  -ms-flex: 0 0 auto;
  display: inline-block;
  opacity: 1;
  vertical-align: middle;
}

.header--main {
  background: getColor(green, base);
  padding: 0;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header--pagination {
  font-size: rem-calc(23);
}

.page__title {
  font-size: rem-calc(37);
  line-height: 1;
  margin: 0;
  font-family: $font-family;

}

.panel__header--actions {
  font-size: rem-calc(17);
  padding: .75rem 1rem;
  text-align: center;
  background: #eee;
}

//
// Navigation
// ----------

.wrap--nav-lg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 .9375rem;
  width: 100%;
}

.main-nav,
.wrap--utility-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  vertical-align: middle;
}

.utility-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin: 0 .6rem 0 0;
}

.main-nav__link {
  color: #fff;
  display: -ms-flexbox;
  height: 1.3rem;
  -ms-flex-align: stretch;
  text-transform: capitalize;
  opacity: .9;
  font-size: rem-calc(14);
  line-height: 1.5;
  font-family: $font-family-headings;
  padding: .95rem .6rem;
  position: relative;
  box-sizing: content-box;
  text-align: center;

  &:hover,
  &:focus {
    color: #fff;
    background: darken(getColor(green, base), 3%);
    opacity: 1;
  }

  &.active {
    font-family: $font-family-headings;
    background: lighten(getColor(green, base), 3%);
    color: #fff;
    opacity: 1;
  }

  &.wrap--logo {
    width: 1.75rem;
    height: auto;
    max-height: 3.25rem;
    padding: 0;
    margin-right: .35rem;
    display: flex;
    opacity: 1;
    justify-content: space-around;

    svg {
      width: 100%;
    }

    .logo-mark__fill {
      fill: #355a24;
    }

    &:hover {
      .logo-mark__fill {
        fill: #2f5022;
      }

      background: transparent;
    }
  }

  &.needs-attention:after {
    top: .8rem;
    right: .4rem;
    background-color: yellow;
  }
}

.main-nav__count,
.main-nav__count:hover {
  background: #467425;
  color: #fff;
  margin-left: rem-calc(1);
  min-width: .55rem;
  display: inline-block;
  padding: 0 rem-calc(3);
  position: relative;
  border-radius: 3px;
  font-size: rem-calc(12);
  line-height: 1.5;
  border: 0;
  text-align: center;
  text-shadow: none;
}

.wrap--main-nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.utility-nav__item {
  display: inline-block;
  -ms-flex-align: stretch;
  flex-grow: 1;
  list-style: none;

  &:last-of-type {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

.utility-nav__link {
  color: #fff;
  font-size: rem-calc(14);
  padding: .6rem;
  height: 2rem;
  opacity: .9;
  box-sizing: content-box;
  line-height: 2.2;
  display: inline-block;
  font-family: $font-family;

  &:hover {
    color: #fff;
    opacity: 1;
    background: darken(getColor(green, base), 3%);
    cursor: pointer;
  }

  &:focus {
    color: #fff;
    opacity: .9;
    background: transparent;
  }
}

.utility-nav__link--settings {
  padding: .55rem .6rem;
  line-height: 2;
}

.wrap--nav-sm {
  display: none;
}

.list--nav-sm {
  background: darken(getColor(green, base), 5%);
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.link--nav-sm {
  background: transparent;
  border-top: 1px solid getColor(green, base);
  color: #fff;
  display: block;
  font-size: rem-calc(14);
  padding: .5rem 1rem;

  &:hover {
    background: darken(getColor(green, base), 8%);
    color: #fff;
  }
}

.needs-attention {
  font-family: $font-family-headings;
  position: relative;

  &:after {
    content: "";
    border-radius: 100%;
    background-color: getColor(blue, base);
    display: block;
    width: rem-calc(6);
    height: rem-calc(6);
    position: absolute;
    top: 0;
    right: rem-calc(-6);
  }
}

.unread-activity {
  font-family: $font-family-headings;
  position: relative;

  &:after {
    content: "";
    border-radius: 100%;
    background-color: yellow;
    display: block;
    width: rem-calc(6);
    height: rem-calc(6);
    position: absolute;
    top: .8rem;
    right: 1rem;
  }
}

.wrap--header-inner {
  margin: .8rem 0 1.5rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;

  &.detail-page {
    margin-bottom: .5rem;
  }
}

.wrap--sub-nav {
  margin: 0;
  @include flexbox;
  @include flex-grow(1);
  @include flex-justify-sb;
  @include flex-wrap(wrap-reverse);
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding-top: .25rem;
}

.wrap--sub-nav-panel {
  border: 1px solid #999;
  padding: .4rem 1.75rem 0 1.75rem;
}

.list--sub-nav {
  @include flexbox;
  @include flex-wrap(wrap);
  margin-bottom: 0;
}

.list__title--sub-nav {
  font-size: rem-calc(14);
  line-height: 2.7;
  margin: 0 1.15rem 0 0;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  text-transform: uppercase;
}

.list__item--sub-nav {
  margin: 0 1.35rem 0 0;
  line-height: 1;

  &.wider {
    margin-right: 2rem;
  }

  &:last-of-type {
    margin: 0;
  }
}

.sub-nav__link {
  color: getColor(gray, base);
  font-size: rem-calc(14);
  display: inline-block;
  line-height: 1.3;
  vertical-align: middle;
  padding: .5rem 0 .75rem 0;
  border-bottom: 3px solid transparent;

  &:hover {
    color: #999;
    border-bottom: 3px solid #ddd;

    .new-items {
      color: darken(getColor(gray, base), 25%);
    }
  }

  &.active {
    font-family: $font-family-headings;
    color: getColor(green, dark);
    border-bottom: 3px solid getColor(green, dark);
  }
}


/* Side Nav */

.list--side-nav {
  padding: 0 0 .65rem .9375rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list__title--side-nav {
  line-height: 1.3;
  font-size: rem-calc(14);
  word-break: break-word;
}

.list__item--side-nav {
  padding: 0;
  margin: 0;
  font-size: rem-calc(14);
}

.link--side-nav {
  padding: 0 .5rem 0 .75rem;
  background: transparent;
  margin: .65rem 0;
  line-height: 1.3;
  border-left: 3px solid transparent;
  color: #666;
  display: block;
  word-break: break-word;
  margin-left: -.9375rem;
  text-align: left;
  width: 100%;

  &:hover {
    background: transparent;
    cursor: pointer;
    color: #999;
    border-color: #ddd;
  }
}

.link--side-nav-active,
.link--side-nav-active:hover,
.link--side-nav-active:focus {
  background-color: transparent;
  color: getColor(green, dark);
  border-left: 3px solid getColor(green, dark);

}

.inner-wrap--side-nav {
  padding: 1rem 0 0 0;
  margin-bottom: 1rem;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  background: #f7f7f7;
}

.list__side-nav {
  list-style: none;
  margin: 0;
}


/* Icon alignment override (for now) */
*,
::after,
::before {
  vertical-align: initial;
}

/* IE11 alignment hack */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  *,
  ::after,
  ::before {
    vertical-align: auto;
  }
}

.outer-wrap--callout-trial {
  display: flex;
}

.wrap--callout-trial {
  display: flex;
  @include flex-column;
  @include flex-justify-sa;

  .label__callout--trial {
    font-size: rem-calc(13);
    text-transform: none;
    background: #355a24;
    border: 2px solid #355a24;
    color: yellow;
    padding: .25rem .55rem;
    border-radius: rem-calc(3) 0 0 rem-calc(3);
    line-height: 1.35;
  }

}