//Table Customizations

//Basic table styles
.app-table, .table--analytics {
    opacity: 1;
    width: 100%;
    th {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        line-height: 1.5;
        padding: 1px 5px 1px 0;
        vertical-align: middle;
        -webkit-font-smoothing: antialiased;
    }
    tbody {}
    td {
        word-wrap: break-word;
        vertical-align: top;
        line-height: 1.3;
        padding-left: .5em;
        p, dl, ul {
            font-size: rem-calc(11);
        }
        input[type="text"] {
            padding: .5em .25em;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    .checkbox-column {
        padding: 10px 5px 9px 8px;
    }
    input[type="checkbox"] {
        margin-bottom: 0;
    }
    .column-name.first {
        border-left: 0;
    }
    .cell-wrapper {
        position: relative;
    }
    /* ADDITIONAL TABLE INFO STYLING */
    .additional-table-info {
        margin-bottom: 0;
        width: auto;
        float: none;
        dt {
            font-weight: normal;
            line-height: 1.2;
            margin-bottom: 0;
            height: auto;
        }
        dd {
            margin: 5px 0 0 0;
            line-height: 1.3;
        }
    }

    .product-attribute {
        text-transform: uppercase;
        color: $medium-grey;
        margin-bottom: 0em;
        display: block;
        .icon {
            margin-right: 3px;
        }
        .icon:before {
            top: 2px !important;
        }
    }

    .with-top-border {
        padding-top: rem-calc(5);
        border-top: 1px solid $light-grey;
    }
    .warning_item {
        background: $error-bg-color;
        color: $error-color;
        padding: 0.35em;
    }
    .product-name-cell {
        .additional-table-info {
            max-width: 10em;
            word-wrap: break-word;
        }
    }
    .shipment-tracking-cell {
        .track-order {
            width: 90%;
            display: block;
        }
        .icon-label {
            position: absolute;
            right: .25em;
            top: .25em;
            color: $medium-dark-grey;
        }
    }
    .quantity {
        float: right;
        color: $medium-dark-grey;
        vertical-align: top;
        line-height: 1.3;
    }
    .item {
        width: 80%;
        vertical-align: top;
        line-height: 1.3;
    }
    .po_lines {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
    }
    #main .weight {
        margin: -3px 0 7px 0;
    }
    #main .shipping_parameter {
        margin: 0 0 3px 0;
    }
    #main .shipping_parameter.first {
        padding-top: 10px;
        border-top: 1px solid #ddd;
    }
    #main .main_content .additional-table-info .po-supplier-address {
        margin-bottom: 0;
    }
    .on-hand {
        text-align: right;
        min-width: 1.5em;
    }
    .warehouse-location {
        width: 75%;
    }
    .input-wrap {
        display: block;
    }
}

.sales-channel-table {
    td {
        ul {
            font-size: rem-calc(12);
            margin-left: 0;
        }
    }
}

// Invalid address styling


.app-table {
     .additional-table-info {
        .address-status-callout {
            display: none;
        }
    }

    .wrap--edit-btn {
        margin-top: 0;
    }

    .address-info {

        &.error {
            .address-status-callout {
                display: inline;
            }

            dt, dd {
                color: $error-color;
            }
        }

        &.warning {
            .address-status-callout {
                display: inline;
            }

            dt, dd {
                color: $warning-txt-color;
            }
        }
    }
}

//Sets up zebra-striping and expand/collapse of table cells
.zebra {
    tbody td {
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }
    tbody:nth-child(2n+1) td {
        background-color: $secondary-color;
        border-bottom: 1px solid $light-grey;
    }
    .shipping-sneak-peek, .shipping-rule-explanation {
        td {
            visibility: hidden;
            height: 0;
            padding: 0 rem-calc(5) 0 rem-calc(8);
            background: $success-bg-color;
            color: $txt-green;
            line-height: 0;
            border-bottom: 1px solid $secondary-color;
            @include transition-all-standard;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            line-height: 0;
        }
    }

    .shipping-rule-explanation {
        td {
            background: $light-grey;
            color: $body-font-color;
        }
    }
    tbody:nth-child(2n+1) {
        .shipping-sneak-peek {
            td {
                border-bottom: 1px solid #fff;
            }
        }
        .shipping-rule-explanation {
            td {
                border-bottom: 1px solid $medium-grey;
            }
        }
    }
    .collapsed {
        .shipping-sneak-peek, .shipping-rule-explanation {
            td {
                .icon, .status-data {
                    display: none;
                    visibility: hidden;
                    height: 0;
                }
                .status-data {
                    margin-left: .25em;
                }
            }
        }
        td {
            @include transition-all-standard;
        }
        .additional-table-info-item {
            display: none;
        }
        .toggle-tip {
            position: absolute;
            background: #F0F8FB;
            color: $medium-grey;
            display: block;
            width: 7.25em;
            height: auto;
            top: -.75em;
            text-align: center;
            padding: .5em;
            opacity: 0;
            border-radius: 3px;
            @include transition-all-standard;
            .toggle-arrow {
                display: block;
                width: 5%;
                margin: 0 auto;
            }
            .up-arrow {
                display: none;
            }
            .up-arrow:after {
                @include triangle-pointing-up(5px, $medium-grey);
                line-height: 0;
            }
            .down-arrow {
                display: block;
                margin-bottom: .7em;
            }
            .down-arrow:after {
                @include triangle-pointing-down(5px, $medium-grey);
                line-height: 0;
            }
        }
        .toggle-tip-text {
            font-size: .85em;
            line-height: 1.2;
            display: block;
            margin: 0 0 .25em 0;
        }
        .toggle-tip-text:before {
            content: "Expand Row";
            text-transform: uppercase;
        }
    }
    .collapsed.with-hover:hover, tbody:nth-child(2n+1).collapsed.with-hover:hover {
        td {
            cursor: pointer;
            background: #F0F8FB;
        }
        .toggle-tip {
            left: -7.5em;
            opacity: 1;
        }
    }
    .expanded {
        .shipping-sneak-peek, .shipping-rule-explanation {
            td {
                visibility: visible;
                height: auto;
                padding: rem-calc(12) rem-calc(5) rem-calc(12) rem-calc(8);
                border-top: 1px solid #b7d99f;
                border-bottom: 1px solid #70b822;
                line-height: 1.3;
                .icon, .status-data {
                    display: inline;
                    visibility: visible;
                    height: auto;
                }
            }
        }
        .shipping-rule-explanation {
            td {
                border-top: 1px solid $medium-grey;
                border-bottom: 1px solid $medium-grey;
            }
        }
        td, td:hover {}
        .additional-table-info-item {
            display: block;
            margin-left: 0;
            width: 90%;
            line-height: 1.3;
        }

        .list__item--line-item {
            border-bottom: 1px dotted $light-grey;
            padding-bottom: rem-calc(5);
            margin-top: rem-calc(5);

            &:last-of-type {
                border-bottom: 0;
            }
        }

        .order-more {
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            width: 85%;
            margin-left: 1em;
        }
        .toggle-tip {
            .up-arrow {
                display: block;
            }
            .down-arrow {
                display: none;
            }
        }
        .toggle-tip-text {
            margin: .8em 0 0 0;
        }
        .toggle-tip-text:before {
            content: "Collapse Row";
            text-transform: uppercase;
        }
    }
    .expanded.shippable .shipping-sneak-peek, tbody:nth-child(2n+1).expanded.shippable .shipping-sneak-peek {
        td {
            background: $success-bg-color;
            color: $txt-green;
            border-top: 1px solid #b7d99f;
            border-bottom: 1px solid #70b822;
            .order-status-data:before {
                content: $shippable-message;
                padding-left: .3em;
            }
        }
    }
    .expanded.warning .shipping-sneak-peek, tbody:nth-child(2n+1).expanded.warning .shipping-sneak-peek, .expanded.partially-shippable .shipping-sneak-peek, tbody:nth-child(2n+1).expanded.partially-shippable .shipping-sneak-peek {
        td {
            background: $warning-bg-color;
            color: $warning-txt-color;
            border-top: 1px solid #fffa7f;
            border-bottom: 1px solid #d2cb00;
            .order-status-data:before {
                content: $partially-shippable-message;
                padding-left: .3em;
            }
        }
    }
    .expanded.no-go .shipping-sneak-peek, tbody:nth-child(2n+1).expanded.no-go .shipping-sneak-peek, .expanded.unshippable .shipping-sneak-peek, tbody:nth-child(2n+1).expanded.unshippable .shipping-sneak-peek {
        td {
            background: $error-bg-color;
            color: $error-color;
            border-top: 1px solid #ffb5b5;
            border-bottom: 1px solid #ff0000;
            .order-status-data:before {
                content: $unshippable-message;
                padding-left: .3em;
            }
        }
    }
    .expanded.shipped {
        .shipping-sneak-peek {
            td {
                display: none;
            }
        }
    }
    .expanded.rule-triggered .shipping-rule-explanation, tbody:nth-child(2n+1).rule-triggered .shipping-rule-explanation {
        td {
            background: $light-grey;
        }
    }
}

.app-table.processing {
    opacity: .6;
}

//Shippability list styling
.app-table {
    .shippability-list {
        margin-bottom: .25em;
        border-bottom: 1px dotted $light-grey;

        &:last-of-type {
            border-bottom: 0;
            margin-bottom: 0;
        }

        li {
            list-style: none;
            padding: .3em;
            margin-left: -0.3em;
            margin-bottom: .25em;
            @include transition-all-standard;
            position: relative;
            border-bottom: 1px dotted $light-grey;

            &:last-of-type {
                border-bottom: 0;
            }

            .shippability-status-icon {
                margin-right: .3em;
                vertical-align: top;
                line-height: 1.2;
            }

            .icon-dropship {
                color: $txt-green;
            }
        }
        a.item, .item-link {
            color: inherit;
            opacity: .9;

            &:hover {
                color: $medium-dark-grey;
                opacity: 1;
            }
        }

    }
    .unshippable-list {
        li {
            color: $error-color;
        }
        li:hover {
            background: $error-bg-color;
        }
    }
    .partially-shippable-list {
        li {
            color: $warning-txt-color;
        }
        li:hover {
            background: $warning-bg-color;
        }
    }
    .shippable-list {
        li {
            color: $success-txt-color;
            .dropship-info {
                color: $medium-grey;
            }
        }
        li:hover {
            background: $success-bg-color;
        }
    }
    .shipped-list {
        li {
            color: $medium-grey;
        }
        li:hover {
            background: $lighter-grey;
        }
    }
}

//Product detail sync status list styling
.sync-failure {
    .sync-status {
        li {
            color: $error-color;
        }
    }
}

.synced {
    .sync-status {
        li {
            color: $success-txt-color;
        }
    }
}

//Empty table styling
.app-table {
    .empty {
        td {
            padding: 2em 1em;
            .empty-subhead {
                font-size: rem-calc(27);
                line-height: 1.3;
                color: $body-font-color;
                strong {
                    color: $txt-green;
                }
                .inline-text-button {
                    padding: 0;
                    margin-bottom: 0;
                    color: $anchor-font-color;
                }
                .inline-text-button:hover {
                    color: $anchor-font-color-hover;
                }
            }
            .empty-loader {
                font-size: rem-calc(23);
            }
        }
    }
}

//Table refresh callout
.alert-panel {
    border: 5px solid $ordoro-orange;
    padding: 1em;
    margin-left: 0;
    text-align: center;
    @include clearfix;
    li {
        list-style: none;
        .alert-message {
            font-size: 1.2em;
            margin-right: .8em;
            position: relative;
            top: .4em;
        }
    }
}

.dashboard-panel {
    text-align: left;
}

//Create order modal table
.create-order-table, .customs-table {
    margin-bottom: 0;
    fieldset {
        margin-bottom: 0;
    }
    td {
        vertical-align: middle;
        border-bottom: 1px solid $light-grey;
        input[type="text"] {
            height: rem-calc(27);
            padding: .5em .25em;
        }
    }
    .create-order-product-sku-cell {
        .word-breaker {
            max-width: 5em;
        }
    }
    .totals {
        td {
            font-size: rem-calc(11);
            input[type="text"] {
                width: 75%;
                padding: .5em .25em;
                margin-bottom: 0;
            }
        }
        .create-order-tax-cell {
            border-bottom: 1px solid #444;
        }
        .create-order-total-cell {
            border-bottom: 0;
        }
    }
}

.customs-table, .shipment-errors-table {
    background: transparent;
    margin-bottom: 1em;
    tr:nth-of-type(even) {
        background: transparent;
    }
    td {
        border-bottom: 1px solid $medium-grey;
        padding-top: 1.25em;
        padding-bottom: 1.25em;
    }
    .customs-error-row {
        td {
            background: $error-bg-color;
            color: $error-color;
            font-size: rem-calc(13);
            text-align: center;
            padding: 1em 2.5em;
            -webkit-font-smoothing: antialiased;
        }
    }
    .totals {
        td {
            background: rgba(0, 0, 0, 0.1);
            color: #111;
            padding: .85em 1em;
            font-size: rem-calc(13);
            @include text-shadow-white;
            border-left: 1px dotted $medium-grey;
        }
        .first, .last {
            border-left: 0;
        }
    }
}

.customs-table-wrap {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 23.5rem);
    margin-bottom: 1.5em;
    border-bottom: 2px dashed #444;
    .customs-table {
        margin-bottom: 0;
    }
}

.shipment-errors-table {
    margin-bottom: 2em;
    thead {
        background: rgba(0, 0, 0, 0.7);
    }
    tr:nth-of-type(even) {
        background: transparent;
    }
    td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.7);
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        font-size: rem-calc(12);
    }
    .error-description-cell {
        color: #ff0000;
    }
    .column-name {
        border-left: 1px solid $light-grey;
    }
}

//Bulk data import table
.data-import-table {
    table-layout: fixed;
    margin-bottom: 1em;
    .data-import-status-cell, .data-import-delete-cell {
        width: 2em;
    }
    .data-import-data-cell {
        width: 15em;
    }
    .th-last {
        border-left: 1px solid #555;
    }
    th.error {
        background: $error-color;
        color: #fff;
        .th-select {
            background: darken(#ff0000, 10%);
            border-color: #fff;
        }
        .inline-icon-button {
            color: $light-grey;
            &:hover {
                color: #fff;
            }
        }
        .column-name {
            padding-bottom: 0;
        }
    }
    .th-error-msg {
        display: block;
        font-size: rem-calc(12);
        padding: .25em .75em;
        margin-bottom: 0;
        color: #fff;
        border-left: 1px solid #555;
        word-wrap: break-word;
    }
    .icon-star {
        color: $warning-border-color;
    }
    .icon-success {
        color: $success-txt-color;
    }
    tr td {
        padding: 1em .5em;
    }
    .error {
        tr td {
            background: $error-bg-color !important;
            color: $error-color;
            border-top: 2px solid $error-color;
            border-bottom: 0;
        }
        .import-error-msg td {
            background: $error-color !important;
            color: #fff;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            border-bottom: 2px solid #fff;
            -webkit-font-smoothing: antialiased;
            padding: .5em;
        }
    }
}

.table--analytics {
    margin-top: .9375rem;

    th {
        font-size: rem-calc(13);

        .column-name {
            padding-left: .7rem;
        }
    }

    td {
        font-size: rem-calc(13);
        border-bottom: 1px solid $lighter-grey;
        padding: 1rem .7rem;
        color: $medium-dark-grey;

    }

    tbody:last-of-type {
        tr:last-of-type {
            td {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.table__rank {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    color: $medium-grey;
}

.table--revenue-statement {

    tr:first-of-type {
        td {
            color: #000;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
        }

        strong {
            color: #000;
        }
    }

    tr:last-of-type {
        td {
            border-bottom: 2px solid $light-grey;
        }
    }

}

.app-table-wrap {
    overflow: hidden;
    overflow-x: scroll;
}

.fancy-scroll {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: .5em;
}

.table--inner-scroll {

    border-bottom: 2px dashed $body-font-color;

    tbody {
        overflow-x: hidden;
        overflow-y: scroll;
        display: block;
        max-height: 23.75em;

        &::-webkit-scrollbar {
            height: .5em;
            width: .5em;
            margin: .75em 0;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0,0,0,.3);
        }

        &::-webkit-scrollbar-track {
            margin-top: .35em;
            border-radius: 10px;
            background: rgba(0,0,0,0);
        }
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    td {
        border-bottom: 1px solid #ddd;
        vertical-align: middle;
    }

    tbody tr:last-of-type {
        td {
            border-bottom: 0;
        }
    }

    th:last-of-type {
        width: 3.5%;
    }

    .bigger-select {
        font-size: 1em;
        height: 2em;
        max-width: 95%;
    }
    
    &.horizontal, &.vertical {
        &::-webkit-scrollbar {
            height: .85em;
            width: .85em;
            margin-top: .35em;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0,0,0,.3);
        }

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(0,0,0,.1);
        }
    }
}

//Specific table header widths
#order_checkbox_column, #shipment_checkbox_column {
    /* width: 18px; */
    width: 3%;
}

#product_checkbox_column, #po_checkbox_column {
    width: 2%;
}

#labels .checkbox_column {
    /* width: 18px; */
    width: 0.5%;
}

#shipment_id_column, #po_date_column, #po_status_column, #customs_value, .shipment-detail-quantity-in-shipment {
    /* width: 142px; */
    width: 15%;
}

#customs_weight {
    width: 16%;
}

#customs_quantity {
    width: 9%;
}

#customs_country_of_origin {
    width: 19%;
}

#width_column {}

#order_date_column, #order_total_column, #supplier_column, #label_error_count {
    /*width: 75px;*/
    width: 10%;
}

#product_action_column, #order_ship-type_column, #shipment_order_date_column, #product_available_column {
    width: 12%;
}

#order_bill-to_column, #order_ship-to_column, #order_address_info, #shipment_order_date_cell, #ship_to_column, #supplier_column, #product_on-hand_column, #shipment_tracking_info, #package_dimensions_column {
    /* width: 122px;*/
    width: 16%;
}

#product_name_column, #product_sku_column, #order_detail_on_hand_column, #order_detail_quantity_ordered_column, #order_detail_price_column {
    width: 13%;
}

#po_id_column, #shipment_action_column, #shipping_method_column, #product_upc_column {
    width: 18%;
}

#weight_column, #shipment_date_column, #create_order_price_column, #updated_column, #product_weight_column {
    /* width: 93px;*/
    width: 10%;
}

#order_status_column, #order_cart_column, #product_dropship_options, #product_total-committed_column, #product_supplier_column, #status_column {
    width: 13%;
}

#shipment_address_info {
    width: 12%;
}

#product_reordered_column, #shipment_status_column {
    width: 8%;
}

#order_id_column {
    width: 20%;
}

#create_order_delete_column {
    width: 2%;
}

.data-import-status-cell, .data-import-delete-cell {
    width: 3%;
}

#create_order_product_name_column {
    width: 25%;
}

#create_order_sku_column {
    width: 12%;
}

#order_detail_sku_column {
    width: 30%;
}

#customs_description, #order_detail_product_name_column {
    width: 42%;
}

.product-detail-import-thead {
    width: 45%;
}

.product-detail-channel-thead, .product-detail-writeback-thead {
    width: 50%;
}

.column_name, .column-name {
    display: inline-block;
    padding: 0.75em 0.3125em 0.75em 0.5em;
    border-left: 1px solid #555;
    &.th-select-column-name {
        width: 87%;
    }
}

/* Hide/Show help article icon for weird ass new inventory management terms */
.hide-for-product-th {display: none;}
#product_on-hand_column, #product_available_column, #product_total-committed_column {
    .hide-for-product-th {display: inline-block;}
}

/*------------------------------------*\
    #Refactored stuff
\*------------------------------------*/

.th-width-1, .td-width-1 {width: 1%;}
.th-width-3, .td-width-3 {width: 3%;}
.th-width-5, .td-width-5 {width: 5%;}
.th-width-8, .td-width-8 {width: 8%;}
.th-width-10, .td-width-10 {width: 10%;}
.th-width-11, .td-width-11 {width: 11%;}
.th-width-12, .td-width-12 {width: 12%;}
.th-width-15, .td-width-15 {width: 15%;}
.th-width-20, .td-width-20 {width: 20%;}
.th-width-25, .td-width-25 {width: 25%;}
.th-width-30, .td-width-30 {width: 30%;}
.th-width-35, .td-width-35 {width: 35%;}
.th-width-40, .td-width-40 {width: 40%;}
.th-width-45, .td-width-45 {width: 45%;}
.th-width-50, .td-width-50 {width: 50%;}
.th-width-55, .td-width-55 {width: 55%;}
.th-width-60, .td-width-60 {width: 60%;}
.th-width-65, .td-width-65 {width: 65%;}
.th-width-70, .td-width-70 {width: 70%;}
.th-width-75, .td-width-75 {width: 75%;}
.th-width-80, .td-width-80 {width: 80%;}
.th-width-85, .td-width-85 {width: 85%;}


.list--with-quantity {
    margin: 0;
}

.list__item--with-quantity {
    list-style: none;
    padding: .3em;
    margin-left: -0.3em;
    margin-bottom: .25em;
    @include transition-all-standard;
    position: relative;
    border-bottom: 1px dotted $light-grey;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        border-bottom: 0;
    }
}

.list__item--kit-component {
    padding-left: 0;
    font-size: .9em;
}

.table--po-items {
    td {
        vertical-align: middle;
        border-bottom: 1px solid #ddd;
    }
}
