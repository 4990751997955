.list--settings {

  .list__item--settings {
    list-style: none;
    width: 100%;
    line-height: 1.35;
    vertical-align: middle;
    margin-bottom: 0;
    padding: 1.25em 0;
    border-top: 1px solid $light-grey;

    .settings-list-item-desc {
      color: $medium-grey;
    }

    .warehouse-address,
    .supplier-email,
    .billing-item {
      word-wrap: break-word;
    }

    .default {
      color: $txt-green;

      .warehouse-status-icon:before {
        font-family: $alternateIconFontFamily;
      }
    }

    .default-callout {
      font-weight: normal;
      font-size: .75em;
      margin-top: .5em;
    }

    .not-configured {
      margin-bottom: 1.5em;
    }

    .not-configured-callout {
      font-size: .9em;
      line-height: 1.4;
      font-weight: normal;
      margin-top: .75em;
      margin-bottom: 1em;

      strong {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
      }
    }

    .settings-warehouse-mapping-msg {
      display: none;
    }

    &.archived {

      background: #f7f7f7;

      strong,
      span,
      em {
        color: #bbb;
      }
    }

    &.read-only {

      .settings-list-item-desc {
        background: #999;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(14);
        color: #fff;
        min-width: .55rem;
        display: inline-block;
        padding: 0 rem-calc(5);
        margin-left: .975rem;
        border-radius: rem-calc(3);
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
        width: auto;
      }
    }

    &.warning {
      background: lighten($warning-txt-color, 45%);

      .settings-list-item-button {
        color: #aaa;
      }

      &.expanded {
        background: #fff;
      }
    }
  }

  .first {
    border-top: 1px solid $light-grey;
  }

  .add-item {
    padding-top: 1.5em;
    border-bottom: 0;
  }

  .mapping {
    opacity: .5;

    .settings-warehouse-mapping-msg {
      display: block;
      line-height: 1.5;

      .loading {
        margin-top: 1.1em;
      }

      em {
        font-size: .8em;
        color: $medium-grey;
      }
    }

    .settings-list-item-button {
      display: none;
    }
  }

}