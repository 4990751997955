//
// Spinner
// ---------

.wrap--panel-processing {
    min-height: 10em;
    padding: 3.25rem 1.25rem 1.25rem 1.25rem;
    text-align: center;
}

.panel-processing {
    @include spinner(0.45em, #444, 0.35, 3em);
}

.spinner--lg {
  @include spinner(0.5rem, #444, 0.35, 3rem);
  @include animate-spin;
}

.spinner--md {
  @include spinner(0.3rem, #444, 0.6, 1.5rem);
  @include animate-spin;
}

.spinner--sm {
    @include spinner(0.2rem, #444, 0.6, 1rem);
    @include animate-spin;
}

.spinner--x-sm {
    @include spinner(0.15rem, #444, 0.6, .8rem);
    @include animate-spin;
}

.list-processing {
    @include spinner(0.3em, #444, 0.35, 2em);
    display: inline-block;
}



/* Order list loading overlay */

.wrap--outer-list-loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.wrap--full-cover {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrap--list-loading {
    text-align: center;
    background: rgba(0,0,0,.25);
    height: 8rem;
    width: 8rem;
    border-radius: rem-calc(10);
    @include center-everything;
    z-index: 2
}

.spinner--list-loading {
    @include spinner(.5rem, #fff, 1, 3.75rem);
    @include animate-spin;
    @include center-everything;
}

.spinner--img-loading {
  @include spinner(.5rem, #444, 0.35, 2em);
  @include animate-spin;
  @include center-everything;
}
