//Header Customizations

header {
    margin-bottom: .5em;
}

.header-wrap {
    position: relative;
}

.customer-name-wrap {
    width: 60%;
    margin-bottom: rem-calc(14);
}

.customer-name {
    font-family: $aktiv-grotesk-regular;
    font-weight: normal;
    font-size: rem-calc(32);
    margin: .5rem 0;
    line-height: 1.2;
    .ordoro-logo-wrap {
        display: block;
    }
    .ordoro-logo {
        margin-bottom: 0.3125em;
        line-height: 0;
        width: 3em;
        display: block;
        float: left;
        img {
            width: 100%;
        }
    }
    .company {
        color: $black;
        text-rendering: optimizeLegibility;
        word-wrap: break-word;
        &:hover {
            color: $anchor-font-color-hover;
            text-decoration: none;
        }
    }

}

//Plan callout button
.plan-callout-button {
    margin: .45em 0 0 .5em;
    display: none;
    float: left;
    text-decoration: none;
    border: 2px solid $light-grey;
    border-radius: 3px;
    padding: 0 .35em;
    font-size: rem-calc(10);
    line-height: 1.35;
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    color: $medium-grey;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    letter-spacing: .05em;
    @include transition-all-standard;
    &:hover {
        border: 2px solid #aaa;
        color: $medium-dark-grey;
    }
    &.free-plan {
        display: block;
        &:after {
            content: "Free";
        }
    }
    &.basic-plan {
        display: block;
        &:after {
            content: "Basic";
        }
    }
    &.pro-plan {
        display: block;
        &:after {
            content: "Pro";
        }
    }
}

//Days left to activitate box

.activate-now {
    clear: right;
    margin: 2.5em 0 .75em 0;
    border: 2px solid $ordoro-green;
    background: $lightest-green;
    padding: .5em 1em;
    min-width: 250px;
    dd {
        font-size: emCalc(11px);
        margin-bottom: 0;
    }
}

//News callout
.news-callout {
    font-size: rem-calc(12px);
    margin: 3.8em 0 .75em 0;
    padding: .75em 2.25em .75em .75em;
    background-color: lighten(#34a0cd, 45%);
    border: 1px solid lighten(#34a0cd, 35%);
    margin-left: 0;
    clear: right;
    position: relative;
    p {
        font-size: rem-calc(12px);
        margin-bottom: 0;
    }
    a {
        color: darken(#34a0cd, 5%);
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        &:hover {
            color: lighten(#34a0cd, 5%);
            border-bottom: 1px dotted lighten(#34a0cd, 10%);
        }
    }
    .close-button {
        position: absolute;
        top: .2em;
        right: .2em;
        opacity: .6;
        &:hover {
            opacity: .8;
        }
    }
    .icon-news:before {
        color: darken(#34a0cd, 5%);
    }
}

//Global Error
.global-error {
    display: none;
    .popover {
        display: block;
        left: 15em;
        top: 1em;
        .inner {
            background: #fff;
            color: #fff;
        }
        .title {
            background: #ff0000;
            color: #fff;
            @include text-shadow-standard;
            border-bottom: 1px solid #fff;
        }
        .content {
            background: #ff0000;
            color: #fff;
            @include text-shadow-standard;
        }
        .close-popover {
            position: absolute;
            right: 0.75em;
            top: 0.75em;
            opacity: .4;
            .icon {
                color: #000;
            }
        }
        .close-popover:hover {
            opacity: .6;
        }
    }
}

.global-error.in {
    display: block;
}

// Zuck mode
.zuck-mode {
    background: #444;
    color: $light-grey;
    margin-bottom: 0;
    dt {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(14);
        line-height: 2.5;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 0;
        @include text-shadow-standard;

    }
    .icon {
        color: $warning-color;
        opacity: .7;
    }
}

// Endicia sadness

.wrap--endicia-suspended {
    background: $error__bg;
    border: 3px solid $error;
    padding: .75em;
    margin-bottom: 0;
}

.wrap--endicia-header {
    border: 0;
    border-bottom: 1px solid $error;
}

.list--endicia-suspended {
    color: $error;
    font-size: rem-calc(14);
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
}

.link--endicia-suspended {
    color: darken(#ff0000, 15%);
    border-bottom: 1px dotted darken(#ff0000, 15%);

    &:hover, &:focus {
        color: darken(#ff0000, 5%);
        border-bottom: 1px solid darken(#ff0000, 5%);
    }
}
