// We're including the foundation settings here so we can override the variables below. This should help deal with sadness when we upgrade versions in the future.

@import "settings";

/*------------------------------------*\
    #OLD_LEGACY_VARIABLES
\*------------------------------------*/

// We use these as default colors throughout
$ordoro-green: #6EB43A;
$ordoro-orange: #f58026;
$ordoro-blue: #34a0cd;
$primary-color: $ordoro-green;
$secondary-color: #e9e9e9;
$alert-color: #ff0000;
$success-color: #5da423;
$txt-green: #3E992C;
$overlay-green: #66a338;
$lightest-grey: #f2f2f2;
$lighter-grey: #eee;
$light-grey: #ccc;
$medium-grey: #999;
$medium-dark-grey: #666;
$success-txt-color: $txt-green;
$success-bg-color: #D4E8C5;
$error-color: #ff0000;
$error-bg-color: #ffd1d1;
$warning-color: #ffff00;
$warning-txt-color: #b29600;
$warning-bg-color: #fffcb2;
$warning-border-color: #FFD324;
$yellow: #ffff00;
$lightest-green: #f2f8ee;
$transparent: transparent;


$Avant-Garde-Book: "AvantGardeGothicITCW01B 731069", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$Avant-Garde-Medium: "AvantGardeGothicITCW01M 731087", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$Avant-Garde-Demi: "AvantGardeGothicITCW01D 731075", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;

$Helvetica: "Helvetica", Helvetica, Arial, sans-serif;
$tertiaryFontWeight: normal;
$tertiaryFontStyle: normal;
$iconFontFamily: "Symbolicons-Line";
$alternateIconFontFamily: "Symbolicons-Block";
$tertiaryIconFontFamily: "SS-Pika";

//Colors for graphs

/* Line colors */
$lc-01: rgba(110,180,58,1);
$lc-02: rgba(52,160,205,1);
$lc-03: rgba(121,41,205,1);
$lc-04: rgba(205,50,41,1);
$lc-05: rgba(205,159,52,1);
$lc-06: rgba(58,93,192,1);
$lc-07: rgba(192,58,155,1);

/* Area colors */
$ac-01: rgba(110,180,58,.2);
$ac-02: rgba(52,160,205,.2);
$ac-03: rgba(121,41,205,.2);
$ac-04: rgba(205,50,41,.2);
$ac-05: rgba(205,159,52,.2);
$ac-06: rgba(58,93,192,.2);
$ac-07: rgba(192,58,155,.2);


/*------------------------------------*\
    #COLOR-VARIABLES
\*------------------------------------*/

// Ordoro green
$ordoro-green: #6EB43A;
$ordoro-green--lighter: lighten($ordoro-green, 5%);
$ordoro-green--darker: darken($ordoro-green, 5%);

// Ordoro orange
$ordoro-orange: #f58026;
$ordoro-orange--lighter: lighten($ordoro-orange, 5%);
$ordoro-orange--darker: darken($ordoro-orange, 5%);

// Ordoro blue
$ordoro-blue: #34a0cd;
$ordoro-blue--lighter: lighten($ordoro-blue, 5%);
$ordoro-blue--darker: darken($ordoro-blue, 5%);

// Greys
$grey--base: #999;
$grey--mid-light: #ccc;
$grey--light: #eee;
$grey--x-light: #f2f2f2;
$grey--mid-dark: #666;
$grey--dark: #444;
$grey--x-dark: #222;

// Successes
$success: #5da423;
$success__txt: #3E992C;
$success__bg: #D4E8C5;

// Warnings
$warning: #ffff00;
$warning__txt: #b29600;
$warning__bg: #fffcb2;
$warning__border: #FFD324;

// Errors
$error: #ff0000;
$error__txt: $error;
$error__bg: #ffd1d1;

// Misc
$yellow: #ffff00;
$green__txt: $success__txt;
$blue__tooltip: rgba($ordoro-blue, .9);


/*------------------------------------*\
    #FONT-VARIABLES
\*------------------------------------*/

$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;

$avant-garde-book: "Avant-Garde-Book", $helvetica;
$avant-garde-medium: "Avant-Garde-Medium", $helvetica;
$avant-garde-demi: "Avant-Garde-Demi", $helvetica;

$symbolicons-line: "Symbolicons-Line";
$symbolicons-block: "Symbolicons-Block";
$ss-pika: "SS-Pika";
$ss-social: "SS-Social";

$aktiv-grotesk-regular: "Aktiv-Grotesk-Regular", "HelveticaNeue", "Helvetica Neue", "Arial", sans-serif;
$aktiv-grotesk-italic: "Aktiv-Grotesk-Italic", "HelveticaNeue-Italic", "Helvetica Neue Italic", "Arial Italic", sans-serif;
$aktiv-grotesk-bold: "Aktiv-Grotesk-Bold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "Arial Bold", sans-serif;
$aktiv-grotesk-bold-italic: "Aktiv-Grotesk-Bold-Italic", "HelveticaNeue-BoldItalic", "Helvetica Neue Bold Italic", "Arial Bold Italic", sans-serif;

$icon-font--primary: $symbolicons-line;
$icon-font--secondary: $symbolicons-block;
$icon-font--tertiary: $ss-pika;
$icon-font--social: $ss-social;


/*------------------------------------
------------------------------------*\
    #FOUNDATION_SETTINGS_OVERRIDES
\*------------------------------------
------------------------------------*/

// We use these to control various global styles
$body-bg: #fff;
$body-font-color: #444;
$body-font-family: $aktiv-grotesk-regular;
$body-font-weight: normal;
$body-font-style: normal;

// Media Query Ranges
$large-range: 86.25em, 90em;
$xlarge-range: 101.053em, 120em;

// We use these to control header font styles
$header-font-family: $aktiv-grotesk-regular;
$header-font-weight: normal;
$header-font-color: #000;
$header-top-margin: 0.5rem;

// We use these to style anchors
$anchor-text-decoration: none;
$anchor-font-color: $ordoro-orange;
$anchor-font-color-hover: $ordoro-blue;


// We use this to set the background color for the breadcrumb container.
$crumb-bg: lighten(#fff, 0%);

// We use these to set the padding around the breadcrumbs.
$crumb-padding: rem-calc(6 0 9);

// $crumb-side-padding: rem-calc(12);

// We use these to control border styles.
// $crumb-function-factor: -10%;
$crumb-border-size: 0;

// We use these to set various text styles for breadcrumbs.
$crumb-font-size: rem-calc(16);

// $crumb-font-color: $primary-color;
$crumb-font-color-current: $body-font-color;

// $crumb-font-color-unavailable: #999;
$crumb-font-transform: none;


// We use these to build padding for buttons.
$button-tny: rem-calc(3);
$button-sml: rem-calc(7);
$button-med: rem-calc(10);
$button-lrg: rem-calc(14);

$button-group-border-width: 2px;

// We use these to style the labels in different ways
$form-label-pointer: pointer;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: bold;

$form-label-font-color: $body-font-color;
$form-label-bottom-margin: rem-calc(3);

$input-font-color: $body-font-color;
$input-font-size: rem-calc(12);

$input-focus-bg-color: $lightest-grey;
$input-border-color: $medium-dark-grey;
$input-focus-border-color: $txt-green;

$input-border-radius: 4px;

$input-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
$input-include-glowing-effect: false;

$fieldset-border-style: solid;
$fieldset-border-width: 0px;
$fieldset-border-color: #ddd;
$fieldset-padding: 0;
$fieldset-margin: 0 0 rem-calc(18) 0;

$input-error-message-padding: rem-calc(6) rem-calc(4);

$input-error-message-font-size: rem-calc(12);
$input-error-message-font-weight: normal;
$input-error-message-font-style: regular;
$input-error-message-font-color: $alert-color;
$input-error-message-font-color-alt: #333;

$inline-list-children-display: inline-block;

// We use these to control the style of the reveal overlay.
$reveal-overlay-bg: rgba(#000, 0.8);
$reveal-overlay-bg-old: #444;

// We use these to control the style of the modal itself.
$reveal-modal-bg: #fff;
$reveal-position-top: -85%;
$reveal-default-width: 80%;
$reveal-modal-padding: rem-calc(25);
$reveal-box-shadow: 0 3px 60px rgba(#000, 0.5);

// We use these to style the reveal close button
$reveal-close-font-size: rem-calc(22);
$reveal-close-top: rem-calc(4);
$reveal-close-side: rem-calc(6);

$reveal-border-width: 0;

$side-nav-link-color: $medium-grey;
$side-nav-link-color-active: #000;
$side-nav-link-color-hover: $ordoro-blue;

$side-nav-font-family: $Avant-Garde-Medium;
$side-nav-active-font-family: $Avant-Garde-Medium;

$table-bg: #fff;
$table-even-row-bg: #fff;

$table-border-size: 0;

// These control the table head styles
$table-head-bg: #333;
$table-head-font-size: rem-calc(12);
$table-head-font-color: #fff;
$table-head-font-weight: normal;
$table-head-padding: 0px;

// These control the row padding and font styles
$table-row-padding: rem-calc(10) rem-calc(5) rem-calc(10) rem-calc(6);
$table-row-font-size: rem-calc(11);
$table-row-font-color: #444;
$table-line-height: rem-calc(14);

// These are for controlling the display and margin of tables
$table-margin-bottom: rem-calc(20);
