//Dropdowns

.dropdown-wrap {
    position: relative;
    li {
        list-style: none;
    }
    .dropdown-toggle {
        @include border-top-radius(0px);
        @include transition-all-standard;
    }
    .dropdown-menu {
        display: none;
        background: #fff;
        position: absolute;
        top: 2.8em;
        left: 0;
        right: auto;
        z-index: 900;
        min-width: 160px;
        max-width: 220px;
        margin: 0;
        border-radius: 4px;
        border-top-left-radius: 0px;
        @include dropdown-shadow;
        @include transition-all-standard;
        border: 1px solid $ordoro-blue;
        font-size: 11px;
        li {
            float: none;
            display: block;
            margin-right: 0;
            -webkit-font-smoothing: antialiased;
            a, .dropdown-list-button {
                color: $body-font-color;
                padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(10);
                text-align: left;
                font-size: 11px;
                font-weight: normal;
                background: transparent;
                border: 0;
                border-top: 1px dotted transparent;
                border-bottom: 1px dotted transparent;
                display: block;
                @include transition-all-standard;
                margin-bottom: 0;
                width: 100%;
            }

            a:hover, .dropdown-list-button:hover {
                color: $anchor-font-color-hover;
                background: $lighter-grey;
                cursor: pointer;
            }

            a:focus, .dropdown-list-button:focus {
                outline: none;
                color: #111;
                border-top: 1px dotted $ordoro-blue;
                border-bottom: 1px dotted $ordoro-blue;
            }

        }

        .list__item--support-pin {
            padding: rem-calc(5);
            background: darken($ordoro-blue, 5%);
            color: #fff;
        }

        .tag-item {
            margin-bottom: 0;
            margin-right: 0;
            color: $body-font-color;
            background: transparent;
            border-bottom: 1px solid #aaa;
            @include transition-all-standard;

            input[type="checkbox"] {
                margin:  0 .25em 0 .6em;
                display: inline-block;
                vertical-align: top;
            }

            .inline-text-button {
                font-size: rem-calc(12);
            }

            .tag-text-wrap {
                display: inline-block;
                vertical-align: top;
                line-height: 1.2;
                width: 10em;
                word-wrap: break-word;
                font-size: rem-calc(12);

                .icon:before {
                    top: 2px;
                }
            }

            .tag-filter-text-wrap {
                width: 80%;
            }
        }

        .tag-item:hover {
            box-shadow: inset rgba(255,255,255,.2) 0 0 10em;
            .wrap--tag-functions {
                opacity: 1;
                @include transition-all-standard;
            }
        }

        .tag-item:last-of-type {
            border-bottom: 0;
        }

        .last-tag-item {
            margin-bottom: 0;

            .dropdown-list-button {
                font-size: rem-calc(12);
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
                padding: rem-calc(10);
                background: #fff;
                text-align: center;
            }
        }
        .empty {
            padding: 1.5em .6em;
            text-align: center;
            line-height: 1.3;
            margin-bottom: 0;
            border-bottom: 1px solid #aaa;
        }
    }

    .dropdown-menu.right {
        left: auto;
        right: 0;
        border-radius: 3px;
        border-top-right-radius: 0px;
    }

    .tag-menu {
        width: 17em;
        max-width: 20em;
        max-height: 30em;
        top: 3.25em;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            height: .6em;
            width: .6em;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: rem-calc(3);
            background: rgba(0,0,0,.3);
        }

        &::-webkit-scrollbar-track {
            border-radius: rem-calc(3);
            background: rgba(0,0,0,.1);
            margin: .25em 0;
        }
    }

    .utility-nav-toggle {
        border-left: 1px solid $light-grey;
        border-right: 1px solid transparent;
    }

    .filters {
        font-size: rem-calc(14);
        .dropdown-toggle {
            padding-bottom: .3em;
            font-size: rem-calc(14);
            line-height: 1.5;
        }

        .dropdown-menu {}
    }
}

.main-action-dropdown {
    .dropdown-toggle:after {
        margin-left: rem-calc(3);
    }
    .dropdown-menu {
        top: 2.5em;
        font-size: rem-calc(12);
        label {
            font-size: rem-calc(12);
        }
    }
    .supplier-menu {
        width: 20em;
        max-width: 20em;
        .first {
            padding: .5em .5em 0 1.25em;
        }
        li {
            font-size: 12px;
            .dropdown-list-button {
                line-height: 1.5em;
                padding: .5em .3em .5em 1.25em;
            }
        }
        .border-top {
            border-top: 1px solid $light-grey;
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            .dropdown-list-button {
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
            }
        }
    }
}

.dropdown-wrap.open {
    .dropdown-toggle {
        @include border-top-radius(4px);
        background: $ordoro-blue;
        border-left-color: $ordoro-blue;
        border-right-color: $ordoro-blue;
        color: #fff;
        @include text-shadow-standard;
        .icon {
            color: #fff;
        }
    }
    .dropdown-menu {
        display: block;
        visibility: visible;
    }
    .filters .dropdown-toggle {
        padding-bottom: 0.625em;
    }
}

.activity {
    .dropdown-menu {
        width: 20em;
        max-width: 20em;
        word-wrap: break-word;
        top: 3em;
        .activity-item {
            padding: 15px 12px;
            border-bottom: 1px solid $light-grey;
            display: block !important;
            .icon {
                display: none;
            }
            .activity-status {
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
                color: $body-font-color;
                line-height: 1.3;
                margin-bottom: .25em;
            }
            .activity-date-wrapper {
                color: $medium-grey;
                font-size: .9em;
            }
            a {
                color: $anchor-font-color-hover;
                padding: 5px 0;
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
            }
            a:hover {
                background: transparent;
                color: #000;
            }
        }
        .no-go {
            background: $error-bg-color;
            .icon {
                color: $error-color;
            }
            .activity-status {
                color: $error-color;
            }
            .activity-date-wrapper {
                color: $error-color;
            }
        }
    }
}

/*------------------------------------*\
    #NEW REFACTORED STUFF
\*------------------------------------*/

.btn--dropdown-toggle {
    margin: 0;
    padding: .45em .75em;
}

.btn--filter-toggle {
    border-radius: rem-calc(3);
    background: $medium-dark-grey;
    border: 1px solid $body-font-color;
    color: $lighter-grey;
    font-size: rem-calc(14);
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;

    &:after {
        @include triangle-pointing-down(.4em, $lighter-grey);
        @include dropdown-triangle;
        margin-left: .35em;
        margin-top: .25em;
        line-height: 0;
    }

    &:hover, &:focus {
        background: $body-font-color;
        color: #fff;
        cursor: pointer;

        &:after {
            @include triangle-pointing-down(.4em, #fff);
            @include dropdown-triangle;
        }
    }
}

.list__item--dropdown-wrap {
    position: relative;

}

.list--dd-menu {
    display: none;
    margin: 0;
    position: absolute;
    border-radius: rem-calc(3);
    border-top-left-radius: 0;
    @include dropdown-shadow;
    @include transition-all-standard;
}

.open {

    .btn--filter-toggle {
        background: $body-font-color;
        color: #fff;

         &:after {
            @include triangle-pointing-up(.4em, #fff);
            @include dropdown-triangle;
            border-top: 0;
        }
    }

    .list--dd-menu {
        display: block;
    }
}

.list--filters {
    top: 1.65em;
    left: 0;
    right: auto;
    z-index: 900;
    min-width: 14em;
    margin: 0;
    background: $body-font-color;
    border: 0;
    color: #fff;
}

.list__item--filter {
    border-bottom: 1px dotted $medium-dark-grey;
    margin: 0 .75em;
    list-style: none;
    display: block;

    &:last-of-type {
        border-bottom: 1px dotted $medium-dark-grey;
    }
}

.list__item--filter-select-all {
  border-bottom: 2px solid #fff;

  .label--filter {
    color: #ccc;
    text-transform: uppercase;
  }

}

/*------------------------------------*\
    #NEW REFACTORED STUFF
\*------------------------------------*/

.label--tag {
    position: relative;
    line-height: 1;
    padding: .6em 0;
    @include transition-all-standard;

    &:hover {
        background: $lightest-grey;
    }

    &.disabled:hover {
        background: #fff;
        cursor: default;
    }
}

.label--untagged:hover {
    background: darken($light-grey, 5%);
}

.label--untagged.disabled:hover {
    background: $light-grey;
    cursor: default;
}

.label--tag.disabled {
    .tag-text-wrap {
        opacity: .5;
    }
}

.wrap--tag-functions {
    @include transition-all-standard;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: .6em;
    display: inline-block;
}

.wrap--tag-edit {
    display: inline-block;
    margin-right: .5em;
}
