//ORDER DETAIL PAGE

.order-details-content-wrap, .row .order-details-content-wrap {
    padding-left: 2.5em;
    margin-bottom: 2.5em;
    border-left: 1px solid $light-grey;
    min-height: 23em;
    .list-title {
        margin-top: 0;
    }
}

/* Header region */
.detail-header-wrap {
    margin: .5em 0;
}

.detail-id-callout-list {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    background: #fff;
    padding: 0;
    .detail-id-callout {
        font-size: rem-calc(27);
        color: #000;
        font-weight: normal;
        margin-bottom: 0;
        a {
            color: $medium-grey;
            &:hover {
                color: $medium-dark-grey;
                text-decoration: underline;
            }
        }
        span {
            color: $light-grey;
            margin: 0 .2em 0 0;
        }
    }
    dd {
        display: inline-block;
        line-height: 1.5;
        font-size: rem-calc(14);
        color: $medium-grey;
        vertical-align: bottom;
        strong {
            color: #000;
        }
        .icon:before {
            top: 2px;
        }
    }
    .shipment-date {
        padding: .25em .5em;
        background: $lighter-grey;
    }
}

.order-details-divider {
    border-color: $light-grey;
    border-width: 2px 0 0 0;
    margin-left: 1em;
    margin-right: 1em;
}

.address-dl {
    .address-dl-label {
        font-family: $aktiv-grotesk-regular;
        font-weight: normal;
        text-transform: uppercase;
        font-size: rem-calc(13);
        color: #000;
        line-height: 1;
        margin-bottom: .35em;
    }
    .address-dl-name {
        font-size: rem-calc(17);
        line-height: 1.2;
        margin-bottom: .1em;
        strong {
            color: #000;
        }
    }
    dd {
        font-size: rem-calc(14);
        margin-bottom: .15em;
        line-height: 1.3;
    }
}

.order-ship-to {
    border-right: 1px dotted $light-grey;
}

.bill-to-notes {
    font-size: rem-calc(12);
    margin-top: 1em;
    padding: .75em;
    background-color: lighten(#34a0cd, 45%);
    color: $medium-dark-grey;
    .icon.xx-sm:before {
        top: 2px;
        color: $medium-grey;
    }
}

.detail-meta-data {
    border-right: 1px solid $light-grey;
    border-top: 0;
    padding-bottom: .75em;
    dl {
        border-top: 1px dotted $light-grey;
        margin-top: .75em;
        padding-top: .75em;
        padding-left: .75em;
        margin-bottom: 0;
        &.first {
            border-top: 0;
            margin-top: 0;
        }
    }
    dt, dt label {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(12);
        line-height: 1.2;
        margin-bottom: .35em;
        color: $medium-dark-grey;
    }
    dd {
        list-style: none;
        font-size: rem-calc(11);
        margin-bottom: .1em;
        line-height: 1.35;
        word-wrap: break-word;
        strong {
            color: #000;
        }
        .make-medium-grey {
            color: $medium-grey;
        }
        &.larger {
            font-size: rem-calc(14);
        }
    }
    .address-status-callout {
        display: none;
    }
    .address-error {

        .address-status-callout {
            display: block;
            padding: .35em .5em;
            margin-right: 1.1em;
            background: $error-bg-color;
        }
        dd {
            color: $error-color;

            strong {
                color: $error-color;
            }
        }
        .address-status-callout + dd {
            padding-top: .35em;
        }
    }
    .inline-text-button {
        font-size: rem-calc(11);
    }
    .detail-meta-data-list-header {
        background: $lightest-grey;
        border-bottom: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        padding: .75em;
        position: relative;
        dt {
            font-size: rem-calc(14);
            color: $body-font-color;
            margin-bottom: 0;
            text-align: center;
            .inline-icon-button {
                position: absolute;
                left: -.35em;
                opacity: .5;
                &:hover {
                    opacity: .8;
                }
            }
            .xx-sm.icon:before {
                top: 2px;
            }
        }
    }
    .bill-to-notes-wrap {
        padding-right: .75em;
        border-top: 0;
        padding-top: 0;
        strong {
            color: $medium-dark-grey;
        }
    }
    .product-image img {
        padding-right: 10px;
    }
}

/* Shippability */

.detail-shippability-wrap {
    padding: .75em;
    margin-right: 1.1em;
}

.shippable .detail-shippability-wrap {
    background: $success-bg-color;
    color: $txt-green;
    .order-shippability-data:before {
        content: $shippable-message;
        padding-left: .3em;
    }
}

.partially-shippable .detail-shippability-wrap {
    background: $warning-bg-color;
    color: $warning-txt-color;
    .order-shippability-data:before {
        content: $partially-shippable-message;
        padding-left: .3em;
    }
}

.unshippable .detail-shippability-wrap {
    background: $error-bg-color;
    color: $error-color;
    .order-shippability-data:before {
        content: $unshippable-message;
        padding-left: .3em;
    }
}

.detail-order-product-name-cell {
    @include transition-all-standard;
    .shippability-popover {
        position: relative;
    }
    .xxx-sm:before {
        top: 2px;
    }
    .additional-details {
        margin-left: 1.5em;
    }
    .additional-details-input {
        margin-left: 1.5em;
        color: $body-font-color;
    }
}

.detail-order-search-product-cell {
    padding: 1.5em;
}

.detail-product-name {
    -webkit-font-smoothing: antialiased;
}

.line-unshippable {
    .detail-product-name, .shippability-popover-message, .additional-details {
        color: $error-color;
    }
    &:hover {
        background: $error-bg-color;
    }
    .shippability-popover-header:before {
        content: $unshippable-popover-header;
    }
    .shippability-popover-message:before {
        content: $unshippable-popover-message;
    }
}

.line-partially-shippable {
    .detail-product-name, .shippability-popover-message, .additional-details {
        color: $warning-txt-color;
    }
    &:hover {
        background: $warning-bg-color;
    }
    .shippability-popover-header:before {
        content: $partially-shippable-popover-header;
    }
    .shippability-popover-message:before {
        content: $partially-shippable-popover-message;
    }
}

.line-shippable {
    .detail-product-name, .shippability-popover-message, .additional-details, .icon-dropship, .popover-wrapper:hover .icon-dropship {
        color: $success-txt-color;
    }
    &:hover {
        background: $success-bg-color;
        color: $success-txt-color;
    }
    .shippability-popover-header:before {
        content: $shippable-popover-header;
    }
    .shippability-popover-message:before {
        content: $shippable-popover-message;
    }
}

.line-shipped {
    .detail-product-name, .shippability-popover-message, .additional-details {
        color: $medium-grey;
    }
    &:hover {
        background: $lighter-grey;
        .icon {
            color: $medium-grey;
        }
    }
    .shippability-popover-header:before {
        content: $shipped-popover-header;
    }
    .shippability-popover-message:before {
        content: $shipped-popover-message;
    }
}

/* Staff comments */
.comment-block {
    border-bottom: 1px dotted $light-grey;
    padding-bottom: .75em;
    margin-bottom: .75em;
    p {
        font-size: rem-calc(14);
        margin-bottom: .35em;
    }
    .comment-byline {
        font-size: rem-calc(12);
        margin-bottom: 0;
        color: $medium-dark-grey;
    }
}

/* Shipments region */

.shipment-id-list {
    padding: 1em 0 .5em 0;
    .detail-id-callout {
        font-family: $aktiv-grotesk-regular;
        font-weight: normal;
        font-size: rem-calc(17);
        margin-top: 0;
        color: #000;
        vertical-align: bottom;
    }
    .detail-id-delete {
        margin-left: .55em;
        .icon.xx-sm:before {
            top: 1px;
        }
    }
    .shipment-count {
        position: absolute;
        left: -2.75em;
        top: 0;
        border-radius: 100%;
        background: $ordoro-blue;
        color: #fff;
        padding: .25em .5em;
        line-height: 1;
        text-align: center;
        font-size: rem-calc(11);
    }
    li {
        font-size: rem-calc(12);
        line-height: 1;
    }
    &:first-child {
        padding-top: 0;
    }
}

.additional-details {
    font-size: rem-calc(11);
}

.shipment-warehouse-options {
    font-size: rem-calc(12);
    padding: 1em .75em;
    margin-top: 2.25em;
    margin-bottom: .5em;
    .inline-text-button-wrap {
        padding-top: .75em;
        border-top: 1px dotted $light-grey;
        color: $medium-grey;
        margin-top: 1em;
        &:first-child {
            padding-top: 0;
            margin-top: 0;
            border: 0;
        }
        .icon.xx-sm:before {
            top: 2px;
        }
    }
}

.select.warehouse-select {
    width: auto;
    height: 1.65em;
    font-size: 1em;
    line-height: 1;
    margin-bottom: 0;
    vertical-align: middle;
}

.shipment-button-group {
    border-left: 1px solid $light-grey;
    padding: .25em 0;
    margin: 1.25em 0 .25em 0;
    color: $medium-dark-grey;
    li {
        border-bottom: 1px dotted $light-grey;
        padding-bottom: .5em;
        margin-left: 1em;
        font-size: rem-calc(11);
        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.table-subheader {
    border-top: 1px dotted $light-grey;
    padding-top: 1.25em;
    padding-right: 1em;
    margin-top: 0;
    .shipment-weight {
        text-transform: none;
        font-family: $aktiv-grotesk-regular;
        font-weight: normal;
        font-size: rem-calc(13);
        color: $body-font-color;
    }
}

.order-items-table, .shipment-items-table {
    margin-bottom: 0;
    tr {
        td {
            vertical-align: top;
        }
        &:last-child td {
            border-bottom: 0;
        }
    }
}

.shipment-items-wrap {
    border-bottom: 1px solid $medium-grey;
    padding-bottom: .5em;
    margin-bottom: 2em;
    &.open {
        .shipment-items-table {
            display: table;
        }
    }
}

.add-shipment-button-wrap {
    border-top: 2px solid $light-grey;
    padding: 1.5em 0;
}

.on-hand-wrap {
    color: $medium-dark-grey;
    margin-left: .5em;
    vertical-align: middle;
    line-height: 1.2;
    font-size: rem-calc(11);
    text-align: center;
}

/* Timeline */

.timeline {
    .timeline-day-header {
        font-size: rem-calc(14);
        background: $lightest-grey;
        border-bottom: 1px solid $light-grey;
        border-top: 1px solid $light-grey;
        color: $body-font-color;
        padding: .35em .7em;
        margin-bottom: 0;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
    }
    .timeline-day-group-wrap {
        margin-bottom: 0;
        &:last-of-type .timeline-day-group {
            padding-bottom: 0;
            li:last-child {
                position: relative;
                top: .1em;
            }
        }
    }
    .timeline-day-group {
        border-left: 2px solid $light-grey;
        margin: 0 0 0 6.25em;
        padding: 0;
        font-size: rem-calc(13);
        padding-bottom: 1.5em;
        .timeline-day-item {
            list-style: none;
            padding: 1.5em 0;
            position: relative;
            border-bottom: 1px dotted $light-grey;
            margin-left: -6.25em;
            span {
                display: inline-block;
                line-height: 1.3;
                vertical-align: middle;
            }
        }
        li:last-child {
            border: 0;
            padding-bottom: 0;
        }
    }
    .timeline-day-time {
        width: 4.5em;
        margin-right: .25em;
        margin-left: .5em;
        color: #aaa;
        text-align: right;
    }
    .timeline-icon-wrap {
        background: #fff;
        border: 3px solid $light-grey;
        padding: .25em;
        border-radius: 50%;
        margin-right: 1em;
        width: 1.25em;
        height: 1.25em;
        text-align: center;
        .icon.xx-sm:before {
            top: .2em;
            color: $body-font-color !important;
        }
    }
    .timeline-day-content-wrap {
        max-width: 55em;
    }
}
