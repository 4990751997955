//DASHBOARD

/*------------------------------------*\
    #ONBOARDING
\*------------------------------------*/


.onboarding-wrap {
  padding: 1.25em 2em;

  .onboarding-header {
    color: #000;
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }

  dt.onboarding-header {
    font-size: rem-calc(27);
  }

  .import-instructions {
    padding: .5em 0 0 120px;
    background: transparent url(../images/iconSprite-setup.png) -18px -5px no-repeat;
    min-height: 120px;

    dt {
      font-family: $aktiv-grotesk-bold;
      text-transform: uppercase;
      color: #000;
      font-weight: normal;
    }

    dd {
      font-weight: bold;
      margin-bottom: .5em;
    }

    .success {
      color: $txt-green;

      .text {
        text-decoration: line-through;
      }

      .icon {}
    }
  }

  .dropshipping-callout {
    background-position: -18px -231px;
  }
}

/*------------------------------------*\
    #DASHBOARD (old shit)
\*------------------------------------*/


.vital-stats-panel {
  padding: 0;
  border-color: $light-grey;

  .vital-stats-header {
    font-family: $aktiv-grotesk-bold;
    font-size: rem-calc(12);
    text-transform: uppercase;
    font-weight: normal;
    padding: 1em 1.25em;
    margin-bottom: 0;
    background: $lighter-grey;
    color: $medium-grey;
    border-bottom: 1px solid $light-grey;
  }

  .vital-stats-list-wrap {
    padding: 0.9375em;
    margin-bottom: 0;

    .vital-stats-list {
      margin-bottom: 0;

      dt {
        font-family: $aktiv-grotesk-regular;
        font-weight: normal;
        font-size: rem-calc(14);
        text-transform: uppercase;
        color: #000;
        margin-bottom: 0;
      }

      .stat {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(44);
        line-height: 1.1;
        color: #000;
        text-rendering: optimizeLegibility;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .big-stat {
        font-size: rem-calc(52);
      }

      .small-stat {
        font-size: rem-calc(27);
        line-height: 1.2em;
      }

      .currency {
        color: $txt-green;
      }
    }
  }

  .sales-stats-wrap {
    border-bottom: 1px solid $light-grey;
  }

  .last {
    border-left: 1px solid $light-grey;
  }
}


/*------------------------------------*\
    #ANALYTICS (new shit)
\*------------------------------------*/


.wrap--analytics {
  padding-left: 2.5em;
}

.panel--analytics {
  border-radius: rem-calc(3);
  padding: 0;
  border: 1px solid $light-grey;
  background: #fff;
  margin-bottom: 2em;
  position: relative;
}

.panel__header--analytics,
.panel--analytics .panel__header--analytics {
  background: $lighter-grey;
  color: $medium-grey;
  border-bottom: 1px solid $light-grey;
}

.subheader--analytics,
.panel .subheader--analytics {
  color: $body-font-color;
  @include text-shadow-white;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}

.list--analytics-filters {
  border-radius: rem-calc(3);
  padding: .75em .75em .85em .75em;
  background: $lighter-grey;
  border: 1px solid $light-grey;
  color: $body-font-color;
  margin: 0 0 .5rem 0;
  -webkit-font-smoothing: antialiased;

  input[type="checkbox"] {
    vertical-align: top;
  }
}

.list--analytics-period {
  display: inline-block;
  margin: 0 0 0 .75em;
}

.list__item--date {
  float: right;
}

.list__item--active-filter,
.list--inline .list__item--active-filter {
  font-size: rem-calc(11);
  border: 1px solid $medium-grey;
  background: #fff;
  position: relative;
  top: rem-calc(-2);
  padding: 0 .35em;
  color: $medium-dark-grey;

  &:first-of-type {
    border: 0;
    padding: 0;
    top: 0;
  }
}

.text--active-filter {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.list--totals {
  margin: 1.75em 0 0 0;
  padding-left: 1em;
}

.list__item--graph-total,
.panel .list__item--graph-total {
  list-style: none;
  margin: 0 0 .75em 0;
  padding-bottom: .75em;
  border-bottom: 1px solid $lighter-grey;
  -webkit-font-smoothing: antialiased;

  &:first-of-type {
    margin-top: 1em;
  }

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.list__item--graph-popover {
  float: left;
  list-style: none;
  margin: .75rem 0 0 0;
  padding: .75rem 0 0 0;
  border-top: 1px solid $lighter-grey;
  width: 11rem;

  &:nth-child(even) {
    margin-left: 1rem;
  }

  &:nth-child(1),
  &:nth-child(2) {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

.list--top-states {
  counter-reset: top-states;
  margin-left: 0;
}

.list__item--top-state {
  list-style: none;

  &:before {
    content: counter(top-states)".";
    counter-increment: top-states;
    font-size: rem-calc(14);
    line-height: 1;
    vertical-align: top;
    font-weight: bold;
    color: $medium-grey;
    margin-right: rem-calc(5);
  }
}

.legend {
  width: rem-calc(11);
  height: rem-calc(11);
  line-height: 1;
  background: $light-grey;
  vertical-align: top;
  margin-right: .35em;

  &.legend-01 {
    background-color: $lc-01;
  }

  &.legend-02 {
    background-color: $lc-02;
  }

  &.legend-03 {
    background-color: $lc-03;
  }

  &.legend-04 {
    background-color: $lc-04;
  }

  &.legend-05 {
    background-color: $lc-05;
  }

  &.legend-06 {
    background-color: $lc-06;
  }

  &.legend-07 {
    background-color: $lc-07;
  }
}

.list--graph-text {
  margin: 0;
  display: inline-block;
  vertical-align: top;
}

.list__item--graph-title {
  color: $body-font-color;

  font-size: rem-calc(14);
  line-height: 1;
}

.list__item--graph-value {
  color: $medium-grey;
  line-height: 1;
  margin-bottom: 0;
  font-size: rem-calc(17);
}

.list--horiz-bar-graph {
  margin-bottom: 0;
  margin-top: .8em;

  &:first-of-type {
    margin-top: 1em;
  }

  &:last-of-type {
    margin-bottom: 1em;
  }
}

.list__title--horiz-bar-graph {
  margin-bottom: 0;
  line-height: 1.1;
  font-size: rem-calc(14);
}

.text--value-bar-chart {
  color: $medium-grey;
  font-weight: normal;
}

.list__item--horiz-bar-graph {
  display: inline-block;
  font-size: rem-calc(14);
  line-height: 1;
  margin-bottom: 0;
  vertical-align: middle;
  color: $medium-grey;
}

.list--no-data {
  display: inline-block;
  padding: 2em;
  background: rgba(0, 0, 0, .7);
  margin-top: 4em;
  -webkit-font-smoothing: antialiased;

  .list__title {
    color: #fff
  }

  .list__item {
    color: $light-grey;
    margin-bottom: 0;
  }
}

.wrap--dashboard-stats {
  .list--horiz-bar-graph-none {
    display: inline-block;
    margin-top: .5rem;
    padding: 1rem 2rem;
    background: #fff;
    border: 2px dashed #ddd;

    .list__title {
      color: #666;
    }

    .list__item {
      color: #999;
      margin-bottom: rem-calc(3);
    }

  }

  .list--sparkline-none {
    margin: 0;
  }

}

.overlay {
  position: absolute;
}

.overlay--no-data {
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScyMCcgdmlld0JveD0nMCAwIDUgMTAnPgoJPHJlY3Qgd2lkdGg9JzExMCUnIHg9Jy01JScgeT0nLTUlJyBoZWlnaHQ9JzExMCUnIGZpbGw9J3RyYW5zcGFyZW50Jy8+Cgk8bGluZSB4MT0nLTInIHkxPScxJyB4Mj0nNycgeTI9JzEwJyBzdHJva2U9JyMwMDAwMDAnIG9wYWNpdHk9Jy4xNScgc3Ryb2tlLXdpZHRoPScwLjc1Jy8+Cgk8bGluZSB4MT0nLTInIHkxPSc2JyB4Mj0nNycgeTI9JzE1JyBzdHJva2U9JyMwMDAwMDAnIG9wYWNpdHk9Jy4xNScgc3Ryb2tlLXdpZHRoPScwLjc1Jy8+Cgk8bGluZSB4MT0nLTInIHkxPSctNCcgeDI9JzcnIHkyPSc1JyBzdHJva2U9JyMwMDAwMDAnIG9wYWNpdHk9Jy4xNScgc3Ryb2tlLXdpZHRoPScwLjc1Jy8+Cjwvc3ZnPg==');
}

.wrap--table-panel {

  .loading .wrap--graph {
    border-right: 1px solid #f7f7f7;
  }

  .overlay--no-data {
    margin: 1rem 0;
  }

}

.popover-wrapper--trial-mode {
  margin-top: .65em;
  position: relative;

  .popover {
    top: -1em;
    left: -15.5em;
    position: absolute;

    .inner {
      background: $warning-border-color;
    }

    .content {
      background: $warning-bg-color;
      color: $warning-txt-color;
      padding: .5em;

      p {
        color: $warning-txt-color;
      }
    }

    .arrow {
      @include triangle-pointing-right(0.55em, $warning-border-color);
      z-index: 1001;
      right: -.45em;
    }
  }
}

.wrap--dashboard-content .wrap--list-loading {
  position: relative;
  clear: both;
}