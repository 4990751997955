@import "ordoro-variables";

//Global things

//Page headers
.list-title {
  line-height: 1.2;
}

.list-title--secondary {
  font-size: rem-calc(17);
  border: 1px solid $lighter-grey;
  padding: .35em .5em;
  background: rgba(0, 0, 0, .025);
  display: inline-block;
  color: $body-font-color;
  font-family: $aktiv-grotesk-bold;
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  top: -.1em;
}

.subheader {
  text-transform: uppercase;
  font-size: rem-calc(16);
  color: #000;
  font-family: $aktiv-grotesk-bold;
  font-weight: normal;
}

.subheader--data-import {}

.smaller-subheader {
  font-size: rem-calc(14);
}

//List Customizations
.content-list {

  dt {
    font-size: rem-calc(17);
  }

  dd {
    font-size: rem-calc(14);
    margin-left: 0;
    color: $medium-dark-grey;

    strong {
      color: $body-font-color;
    }
  }

  li {
    font-size: rem-calc(14);
    margin: 0 0 1em 1.5em;
  }
}

.setup-help-list {
  margin-left: 0;

  li {
    font-size: rem-calc(12);
    line-height: 1.2;
    margin: 0 0 .5em 1.5em;
  }
}

.smaller-content-list {
  margin-left: 0;

  li {
    font-size: rem-calc(12);
    line-height: 1.2;
    margin: 0 0 .25em 1.25em;
  }
}

.big-content-list {
  dt {
    font-size: rem-calc(17);
  }

  li {
    font-size: rem-calc(17);
    margin: 0 0 .3em 1.25em;
    color: #000;
  }

  dd {
    font-size: rem-calc(17);
    margin-left: 0;
    color: $medium-dark-grey;
  }

}

.no-list-style {
  margin-left: 0;
  list-style: none;

  li {
    list-style: none;
    line-height: 1.2;
    margin: 0 0 .5em 0;
  }
}

.error-list {

  li,
  dd {
    list-style: none;
    padding: .5em;
    font-size: rem-calc(14);
    line-height: 1.3;
    margin-bottom: 1.25em;
    background: $error-bg-color;
    color: $error-color;
    border: 1px solid #ff0000;
  }
}

.list--error {
  border: 2px solid $error-color;
  color: $error-color;
  padding: 1em;
  background: $error-bg-color;
}

.list--warning {
  border: 2px solid $warning-color;
  color: $warning-color;
  padding: 1em;
  background: $warning-bg-color;
}

.inline-list-with-borders {
  li {
    margin-left: 0;
    margin-left: .75em;
    padding-left: .75em;
    border-left: 1px dotted $light-grey;

    &:first-child {
      border: 0;
      padding-left: 1em;
    }
  }
}

//Pagination
.pagination-data {
  li {
    font-size: rem-calc(12);
    display: inline-block;
    vertical-align: middle;
    list-style: none;
  }

  .number-per-page-wrap {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid $light-grey;

    label {
      font-size: 1em;
      color: $body-font-color;
    }

    select {
      font-size: 1em;
      line-height: 1;
      width: 5em;
      margin: 0 .4em 0;
      vertical-align: middle;
    }
  }
}

ul.pagination {
  li {
    font-size: rem-calc(12);
    color: $light-grey;

    &:hover {
      background: transparent;
    }
  }

  .inline-text-button {
    padding: 2px 3px;
    font-weight: normal;
    font-size: rem-calc(12);
  }

  .inline-text-button:hover {
    background: $secondary-color;
    text-decoration: none;
    color: $anchor-font-color-hover;
  }

  .no-bg:hover,
  .no-bg:focus {
    background: transparent;
    text-decoration: none;
  }

  .previous-wrap {
    border-right: 1px solid $light-grey;
    margin-right: .35em;
    padding: .5em;
    line-height: 1;
  }

  .next-wrap {
    padding: .5em .5em .5em .2em;
    margin-left: 0;
    line-height: 1;
  }

  li.selected {
    .inline-text-button {
      color: $body-font-color;
      font-family: $aktiv-grotesk-bold;
    }
  }
}

//Random typographic things
.currency {
  color: $txt-green;
}

.currency-symbol {
  position: relative;
  font-size: .4em;
  top: .4rem;
  vertical-align: top;
}

p.lead {
  font-family: $aktiv-grotesk-bold;
  font-size: 1.25em;
  line-height: 1.25;
  color: #000;

  &.not-bold {
    font-family: $aktiv-grotesk-regular;
  }

  &.larger {
    font-size: 1.5em;
  }
}

//Popovers
.popover-wrapper {
  @include transition-all-standard;

  .icon {
    color: $medium-grey;
    @include transition-all-standard;
  }

  .popover {
    position: absolute;
    z-index: 1000;
    width: 30em;
    display: none;

    .inner {
      background: $ordoro-blue;
      position: relative;
      padding: 5px;
      border-radius: 5px;
      @include popover-shadow;
    }

    .title {
      color: $ordoro-blue;
      background: #fff;
      margin: 0;
      font-size: rem-calc(16);
      text-transform: uppercase;
      padding: .5em .75em;
      border-bottom: 1px solid $ordoro-blue;
    }

    .chart-popover-title {
      text-transform: none;
    }

    .content {
      padding: .5em 1em .5em 1em;
      background: #fff;
    }

    .comment-block {
      border-bottom: 1px dotted $light-grey;
      margin-bottom: .75em;
      padding-bottom: .75em;

      p {
        margin: 1em 0 0 0;
        font-size: rem-calc(12);
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .smaller-popover {
    width: 15em;

    p {
      font-size: rem-calc(12);
    }
  }

  .panel-popover {
    display: block;
    width: 17em;
    margin-top: .25em;
    margin-left: -1em;
  }

  .label-popover {
    position: relative;
    display: block;
    width: 15em;
    top: -2em;
    left: -3.5em;
    margin-bottom: 1.5em;

    .arrow {
      @include triangle-pointing-left(0.75em, $ordoro-blue);
      z-index: 1001;
      left: -.55em;
      top: 7.85em;
    }
  }

  .graph-popover {
    display: block;
    width: 11em;

    .arrow {
      @include triangle-pointing-down(0.55em, $ordoro-blue);
      z-index: 1001;
      left: 4.85em;
      bottom: -.35em;
    }

    .content {
      padding: .5em .75em;
    }

    p {
      font-size: rem-calc(13);
      color: $medium-dark-grey;
      margin-bottom: 0;
      line-height: 1.3;
    }

    .currency {
      font-size: rem-calc(17);
      color: $txt-green;
    }
  }

  .popover--line-graph {
    display: block;
    width: 26rem;
    -webkit-font-smoothing: antialiased;

    .title {
      padding: .25em .75em;
      background: $ordoro-blue;
      color: #fff;
    }

    .arrow {
      @include triangle-pointing-left(0.55em, $ordoro-blue);
      z-index: 1001;
      top: 45%;
      left: -.35em;
    }

    .content {
      padding: 1em .75em .75em .75em;
    }

    &.popover--product-graph {
      width: 15rem;
    }
  }

  .list--product-graph {

    .list__item {
      margin-bottom: rem-calc(15);
    }

    .list__item:first-of-type {

      padding-bottom: rem-calc(15);
      border-bottom: 1px dotted #ccc;

      .list__item--graph-title {
        font-size: rem-calc(17);
      }

      .list__item--graph-value {
        color: $medium-grey;
        line-height: 1;
        margin-bottom: 0;
        font-size: rem-calc(23);
      }
    }

    .list__item:last-of-type {
      margin-bottom: 0;
    }

  }

  .popover--states {
    display: block;
    width: 11em;
    -webkit-font-smoothing: antialiased;

    .arrow {
      @include triangle-pointing-left(0.55em, $ordoro-blue);
      z-index: 1001;
      top: 45%;
      left: -.35em;
    }

    .content {
      padding: .5em .75em;
    }

  }
}

.label-popover-wrapper {
  top: -.25em;
  right: -1em;
  display: none;
  opacity: .85;

  &:hover {
    opacity: 1;
  }

  .popover {
    top: -5.35em;
    left: 3.85em;

    .inner {
      background: $warning-border-color;
    }

    .content {
      background: $warning-bg-color;
      color: $warning-txt-color;
      padding: .5em;

      p {
        color: $warning-txt-color;
      }
    }

    .arrow-down {
      @include triangle-pointing-down(0.55em, $warning-border-color);
      z-index: 1001;
      left: 2em;
      bottom: -.35em;
    }

    .arrow-left {
      @include triangle-pointing-left(0.55em, $warning-border-color);
      z-index: 1001;
      left: -0.45em;
      top: 35%;
    }
  }
}

.endicia-callout .label-popover-wrapper {
  top: -0.55em;
  right: -0.55em;
  width: 7em;
  display: block;
}

.needs-insurance .label-popover-wrapper {
  display: inline-block;
  top: 1px;
  right: .5em;

  .popover {
    top: -1.25em;
    left: 1.3em;
    width: 21em;
  }
}

.graph-popover-wrapper {
  margin-left: -5.35em;
  margin-top: -4em;
}

.wrapper--popover-line-graph {
  margin-left: 1.65em;
  margin-top: 0;
}

.wrapper--popover-states {
  margin-left: 1.5em;
  margin-top: .5em;
}

.popover-wrapper:hover {
  .icon {
    color: $anchor-font-color-hover;
  }

  .popover {
    display: block;
  }
}

.comment-cell {
  .comment-popover-wrapper {
    position: absolute;
    top: -3px;
    right: .3em;
  }

  .additional-table-info dt {
    width: 90%;
  }
}

.to-the-right {
  top: -1.75em;
  left: 2em;

  .arrow {
    @include triangle-pointing-left(0.75em, $ordoro-blue);
    z-index: 1001;
    left: -.55em;
    top: 1.5em;
  }
}

.to-the-left {
  top: -1.75em;
  right: 2em;

  .arrow {
    @include triangle-pointing-right(0.75em, $ordoro-blue);
    z-index: 1001;
    right: -.55em;
    top: 1.5em;
  }
}

.below {
  .arrow {
    @include triangle-pointing-up(0.55em, $ordoro-blue);
    z-index: 1001;
    left: 2em;
    top: -.35em;
  }
}

/* shippability popovers */
.shippability-popover {
  .popover {
    width: 26em;

    .inner {
      padding: 3px;
    }

    .title {
      @include text-shadow-white;
      font-size: rem-calc(12);

      .icon.xx-sm:before {
        top: 2px;
      }
    }

    .content {
      padding: .75em 1em;
    }

    p {
      margin: 0;
      line-height: 1.3;
    }
  }

  .to-the-right {
    top: -1.25em;
    left: 103%;
  }
}

.unshippable-popover,
.line-unshippable {
  .popover {
    .inner {
      background: $error-color;
    }

    .title {
      color: $error-color;
      border-bottom-color: $error-color;
      background: $error-bg-color;
    }

    .arrow {
      @include triangle-pointing-left(0.75em, $error-color);
    }
  }
}

.partially-shippable-popover,
.line-partially-shippable {
  .popover {
    .inner {
      background: $warning-txt-color;
    }

    .title {
      color: $warning-txt-color;
      border-bottom-color: $warning-txt-color;
      background: $warning-bg-color;
    }

    .arrow {
      @include triangle-pointing-left(0.75em, $warning-txt-color);
    }
  }
}

.shippable-popover,
.line-shippable {
  .popover {
    .inner {
      background: $success-txt-color;
    }

    .title {
      color: $success-txt-color;
      border-bottom-color: $success-txt-color;
      background: $success-bg-color;
    }

    .arrow {
      @include triangle-pointing-left(0.75em, $success-txt-color);
    }
  }
}

.shipped-popover,
.line-shipped {
  .popover {
    .inner {
      background: $body-font-color;
    }

    .title {
      color: $body-font-color;
      border-bottom-color: $body-font-color;
      background: $light-grey;

      .status-icon:before {
        color: $body-font-color;
      }
    }

    .arrow {
      @include triangle-pointing-left(0.75em, $body-font-color);
    }
  }
}

.action-button-tooltip {
  display: none;
  background: #ff0000;
  color: #fff;
  @include text-shadow-standard;
  padding: 1px .5em;
  position: absolute;
  top: -9px;
  right: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px, rgba(255, 255, 255, 0.2) 0 2px 0 inset;
  line-height: 1.2;

  .exclamation {
    font-size: emCalc(14px);
    line-height: 1;
  }

  .popover {
    width: 20em;

    .inner {
      background: #ff0000;
      opacity: .9;
    }

    .content {
      background: #000;
      color: #fff;
      padding: .75em;

      p {
        font-size: rem-calc(14);
      }
    }
  }
}

.no-go .action-button-tooltip {
  display: block;
}

.no-go .action-button-tooltip:hover {
  display: pointer;
}

//Foundation alerts
.alert-box.success {
  background: $success-bg-color;
  color: $success-txt-color;
  border-color: $success-txt-color;
}

//Tags
.tags-inline-list {
  width: auto;
  font-size: rem-calc(9);
  line-height: 1.2;
  margin: .25em 0 0 0;

  li,
  .tag-wrap {
    margin: 0 .5em .5em 0;
    padding: 2px 3px;
    border: 1px solid $medium-grey;
    font-size: rem-calc(9);
    vertical-align: middle;

    .tag-name {
      color: #333;
      vertical-align: middle;
    }
  }

  .first {
    border: 0;
    margin-top: .2em;
  }

}

.wrap--manual-order-tags {
  .tags-inline-list {

    li {
      font-size: rem-calc(10);
      line-height: 1.35;
      padding: rem-calc(2) rem-calc(4);
    }

    .no-tags {
      font-size: rem-calc(12);
      border: 0;
      padding: 0;
      margin: 0;
      color: $medium-grey;
    }
  }
}

.detail-tags {
  li {
    font-size: rem-calc(9);

    .tag-name {
      vertical-align: baseline;
    }
  }

  .first {
    padding-left: 0;
    margin-top: 0;
    font-size: rem-calc(13);
  }

  .non-tag {
    border: 0;

    .inline-text-button {
      font-size: rem-calc(11);
      margin-top: 1px;
    }
  }
}

.tags-selected {
  position: absolute;
  right: 1.25em;
  top: -2.1em;
}

/* Tag colors */
.tag01,
.tag01:hover,
button.tag01,
button.tag01:hover {
  background-color: #c4e1b2;
}

.tag01 .label--tag {
  border-left: rem-calc(10) solid #c4e1b2;
}

.tag02,
.tag02:hover,
button.tag02,
button.tag02:hover {
  background-color: #f5f5b0;
}

.tag02 .label--tag {
  border-left: rem-calc(10) solid #f5f5b0;
}

.tag03,
.tag03:hover,
button.tag03,
button.tag03:hover {
  background-color: #fee2ca;
}

.tag03 .label--tag {
  border-left: rem-calc(10) solid #fee2ca;
}

.tag04,
.tag04:hover,
button.tag04,
button.tag04:hover {
  background-color: #b8b0b0;
}

.tag04 .label--tag {
  border-left: rem-calc(10) solid #b8b0b0;
}

.tag05,
.tag05:hover,
button.tag05,
button.tag05:hover {
  background-color: #f5b0b1;
}

.tag05 .label--tag {
  border-left: rem-calc(10) solid #f5b0b1;
}

.tag06,
.tag06:hover,
button.tag06,
button.tag06:hover {
  background-color: #c1fcfc;
}

.tag06 .label--tag {
  border-left: rem-calc(10) solid #c1fcfc;
}

.tag07,
.tag07:hover,
button.tag07,
button.tag07:hover {
  background-color: #cef3e5;
}

.tag07 .label--tag {
  border-left: rem-calc(10) solid #cef3e5;
}

.tag08,
.tag08:hover,
button.tag08,
button.tag08:hover {
  background-color: #b9d2c9;
}

.tag08 .label--tag {
  border-left: rem-calc(10) solid #b9d2c9;
}

.tag09,
.tag09:hover,
button.tag09,
button.tag09:hover {
  background-color: #d6ecf5;
}

.tag09 .label--tag {
  border-left: rem-calc(10) solid #d6ecf5;
}

.tag10,
.tag10:hover,
button.tag10,
button.tag10:hover {
  background-color: #f7dbe7;
}

.tag10 .label--tag {
  border-left: rem-calc(10) solid #f7dbe7;
}

.tag11,
.tag11:hover,
button.tag11,
button.tag11:hover {
  background-color: #ebe7d1;
}

.tag11 .label--tag {
  border-left: rem-calc(10) solid #ebe7d1;
}

.tag12,
.tag12:hover,
button.tag12,
button.tag12:hover {
  background-color: #dfffd4;
}

.tag12 .label--tag {
  border-left: rem-calc(10) solid #dfffd4;
}

.label--untagged {
  border-left: rem-calc(10) solid transparent;
  background: $light-grey;
}

//Flex Video Container
.flex-video {
  border: 1px solid $medium-dark-grey;
}

//Panels

.panel {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
  }
}

.white-panel {
  background: #fff;
}

.neutral-callout {
  border: 5px solid $ordoro-blue;
}

.error-panel {
  border: 5px solid $anchor-font-color;
  background: transparent;

  li {
    list-style: none;
    font-size: rem-calc(14);

    .icon {
      width: 1%;
      line-height: 1;
    }

    .status-data {
      width: 95%;
      margin-left: 3%;
      line-height: 1.5;
    }
  }
}

.smaller-panel {
  padding: .75em;
}

.panel--free-callout {
  border: 3px solid $ordoro-blue;
  padding: .65em;
  font-size: rem-calc(13);
  text-align: center;
  background: #fff;

  p {
    font-size: rem-calc(13);
  }
}

//Progress indicator (For CSV import only. Putting this here until I get to refactoring this stuff.)

.list--progress {
  background: $lightest-grey;
  list-style: none;
  position: relative;
  margin: .25em 0 1em 0;
  height: 1.5em;
  border: 1px solid $ordoro-blue;
}

.list__title--progress {
  font-size: rem-calc(11);
  line-height: 1.2;
  vertical-align: top;
  position: relative;
  float: left;
  display: block;
  font-family: $aktiv-grotesk-bold;
  font-weight: normal;
  width: 13%;
  height: 100%;
  margin-bottom: 0;
  padding: .45em .75em;
  background: $ordoro-blue;
  color: #fff;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  z-index: 2;

  &:after {
    @include triangle-pointing-right(.65em, $ordoro-blue);
    right: -.5em;
    top: .4em;
    line-height: 0;
  }

}

.list__item--progress {
  display: block;
  float: left;
}

.progress-bar {
  background: $lightest-grey;
  border: 0;
  height: 100%;
  width: 86%;
  padding: 0;

  .meter {
    position: relative;
    background: lighten($ordoro-blue, 25%);
    @include transition-all-standard;

    &:after {
      content: "";
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: 1.5em 1.5em;
      @include linear-gradient(-45deg, rgba(255, 255, 255, .25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .25) 50%, rgba(255, 255, 255, .25) 75%, transparent 75%, transparent);
      @include candystriper;
    }
  }
}

.on-6 {
  border: 1px solid $ordoro-green;

  .list__title--progress {
    background: $ordoro-green;

    &:after {
      @include triangle-pointing-right(.65em, $ordoro-green);
      right: -.5em;
      top: .4em;
      line-height: 0;
    }
  }

  .progress-bar {
    width: 87%;

    .meter {
      background: lighten($ordoro-green, 20%);

      &:after {
        display: none;
      }
    }
  }
}



//Shippability message variables
$shippable-popover-header: "Shippable Item";
$shippable-popover-message: "This item is ready to hit the road.";
$partially-shippable-popover-header: "Partially Shippable Item";
$partially-shippable-popover-message: "This item can be partially shipped because you have less than the requested quantity.";
$unshippable-popover-header: "Unshippable Item";
$unshippable-popover-message: "This item cannot be shipped because it's out of stock.";
$shipped-popover-header: "Shipped Item";
$shipped-popover-message: "It's going... it's going... it's gone!";

$shippable-message: "All of the items in this order are ready to ship.";
$partially-shippable-message: "This order can be partially shipped because some of the requested items are out of stock.";
$unshippable-message: "This order cannot be shipped because the requested items are out of stock.";

//Helpers
.hidden {
  display: none;
}

.make-block {
  display: block !important;
}

.make-inline-block {
  display: inline-block !important;
}

.text-indent {
  text-indent: -9999px;
  display: block;
}

.clearfix {
  @include clearfix;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.clearfix:before,
.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.error-message {
  color: $error-color;
}

.success-txt {
  color: $success-txt-color;
}

.make-medium-grey,
.panel .make-medium-grey {
  color: $medium-grey;
}

.make-medium-dark-grey {
  color: $medium-dark-grey;
}

.make-uppercase {
  text-transform: uppercase;
}

.make-lowercase {
  text-transform: lowercase;
}

.make-helvetica {
  font-family: $aktiv-grotesk-regular;
}

.make-smaller {
  font-size: .8em;
}

.make-larger {
  font-size: 125%;
}

.make-bold {
  font-family: $aktiv-grotesk-bold;
}

.make-normal {
  font-family: $aktiv-grotesk-regular;
}

.make-relative {
  position: relative;
}

.make-absolute {
  position: absolute;
}

.make-middle,
.app-table .make-middle {
  vertical-align: middle !important;
}

.middle-aligned {
  vertical-align: middle;
}

.make-border-box {
  @include box-sizing(border-box);
}

.centered-text {
  text-align: center;
}

.right-aligned-text {
  text-align: right;
}

.left-aligned-text {
  text-align: right;
}

.rounded {
  border-radius: 5px;
}

.word-breaker {
  word-break: break-word;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.op-30 {
  opacity: .3;
}

.op-50 {
  opacity: .5;
}

.op-60 {
  opacity: .6;
}

.op-75 {
  opacity: .75;
}

.text-shadow-white {
  @include text-shadow-white;
}

.border-top-light {
  border-top: 1px solid $light-grey;
}

.border-top-dotted {
  border-top: 1px dotted $light-grey;
}

.border-bottom-light {
  border-bottom: 1px solid $light-grey;
}

.border-left-dotted {
  border-left: 1px dotted $light-grey;
}

.border-right-dotted {
  border-right: 1px dotted $light-grey;
}

.small-screen-show {
  display: none;
}

/*------------------------------------*\
    #REFACTORED BITS
\*------------------------------------*/

.panel__header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
}

/* LISTS - Bulleted or Numbered */

.list {}

.list__item {
  font-size: rem-calc(17);
  line-height: 1.4em;
  margin-bottom: .65em;
}

.list__item--sm {
  font-size: rem-calc(14);
}

/* LISTS - Inline */

.list--inline {
  margin-left: 0;
  margin-bottom: .5em;
}

.list__title--inline {
  font-weight: normal;
  display: inline;
  font-size: rem-calc(14);
}

.list__item--inline {
  display: inline;
  margin-left: .75em;
  font-size: rem-calc(14);
}

.list__item--inline-block {
  display: inline-block;
  margin-left: .15em;
  font-size: rem-calc(14);
}

.callout-ribbon {
  border: 2px solid $light-grey;
  padding: 0 .35em;
  border-radius: 3px;
  font-size: rem-calc(17);
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: rem-calc(.5);
  color: $medium-grey;
  font-family: $aktiv-grotesk-bold;
  font-weight: normal;
}

.callout-ribbon--beta,
.callout-ribbon--trial-mode {
  border-color: $warning-border-color;
  color: darken($warning-border-color, 10%);
}

.callout-ribbon--trial-mode:hover {
  cursor: pointer;
  background: $warning-bg-color;
}

// Line-height
.lh-0 {
  line-height: 0;
}

.lh-sm {
  line-height: 1;
}

.lh-md {
  line-height: 1.2;
}

.lh-lg {
  line-height: 1.5;
}

.lh-xl {
  line-height: 1.8;
}

.bg-light-grey {
  background-color: $light-grey;
}
