//
// Dashboard
// ------

/* Replacing Foundation's "medium-centered" class so we can do some CSS3 transitions */
.outer-wrap--dashboard {
  width: 100%;
  @include transition-all-faster;
}

.inner-wrap--dashboard {
  margin-right: auto;
  margin-left: auto;
  float: none;
  width: 85%;
  @include transition-all-faster;
}

.panel--dashboard {
  border-radius: rem-calc(3);
  padding: 0;
  border: 1px solid $light-grey;
  background: #fff;
  margin-bottom: 2em;
  position: relative;

  dl,
  li {
    color: #444;
  }
}

.panel__header--dashboard {
  background: $lighter-grey;
  color: $medium-grey;
  border-bottom: 1px solid $light-grey;
}

.panel__body {
  padding: 1rem 1.25rem;
}

.panel__body--centered {
  margin: 0 auto;
  text-align: center;
}

.subheader--dashboard,
.panel .subheader--dashboard {
  color: $body-font-color;
  @include text-shadow-white;
  margin: 0;
  vertical-align: middle;
}

.panel--dashboard-action-items {
  border-color: #34a0cd;

  .panel__header--dashboard {
    background: rgba(52, 160, 205, 0.2);
    border-bottom: 1px solid #34a0cd;
  }
}

.panel--setup-guide {
  position: relative;
  border: 2px solid #888;

  .panel__header--setup-guide {
    background: #e7e7e7;
    border-bottom: 2px solid #888;
  }

  .panel__body {
    padding: 0.875rem 0;
  }

  .btn--primary {
    white-space: normal;
  }
}

.panel--dashboard-quickship {
  border-color: #999;

  .panel__header--dashboard {
    background: #f7f7f7;
    border-bottom: 1px solid #999;
  }
}


.badge--setup-step {
  width: 1.35rem;
  height: 1.35rem;
  border: 2px solid #aaa;
  color: #aaa;
  border-radius: 100%;
  line-height: 1.35;
  display: flex;
  justify-content: center;
  margin: auto auto .3rem auto;
}



.release__callout {
  min-width: .55rem;
  display: inline-block;
  padding: 0 rem-calc(4);
  position: relative;
  border-radius: 3px;
  font-size: rem-calc(11);
  line-height: 1.5;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;
}

.release__callout--new-feature {
  background: $ordoro-green;
  color: #fff;
}

.release__callout--update {
  background: $ordoro-blue;
  color: #fff;
}

.release__callout--bugfix {
  background: darken(#ff0000, 20%);
  color: #fff;
}

.panel--warning {
  border: 1px solid $warning__border;

  .panel__header--dashboard {
    background: $warning__border;
    color: #fff;
    border-bottom: 1px solid $warning-txt-color;
  }
}

.wrap--dashboard-content .panel--dashboard.panel--warning {
  margin-bottom: 2em;
}

.list--task-completed {
  opacity: .35;

  .img--setup-guide {
    filter: grayscale(100%);
  }

}

.img--setup-guide {
  height: 5.5rem;
}

.img--setup-guide-check {
  height: 3rem;
}

.img--setup-guide-check-sm {
  height: 1.5rem;
}

.wrap--import-orders-callout {
  border-right: 2px solid #999;
}

/* INLINE SVGs */

.wrap__setup--icon {
  text-align: center;
  margin-bottom: rem-calc(20);
}

.icon--setup {
  height: 5.5rem;
}

.icon--setup-sales-channel {

  .cls-1,
  .cls-2 {
    fill: none;
  }

  .cls-1 {
    stroke: #333;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }

  .cls-3 {
    fill: #34a0cd;
  }

  .cls-4 {
    fill: #ccc;
  }

  .cls-5 {
    fill: #333;
  }

  .cls-6 {
    clip-path: url(#clip-path);
  }

  .cls-7 {
    clip-path: url(#clip-path-2);
  }

  .cls-8 {
    fill: #1e7894;
  }

  .cls-9 {
    fill: #baddee;
  }

  .cls-10 {
    fill: #fff;
  }
}

.icon--setup-carrier {

  .cls-1,
  .cls-6 {
    fill: #333;
  }

  .cls-2 {
    fill: #c0a78b;
  }

  .cls-3 {
    fill: #e6e7e8;
  }

  .cls-4 {
    fill: #b0916e;
  }

  .cls-5 {
    fill: #fff;
  }
}

.icon--setup-warehouse {

  .cls-1 {
    fill: #e6e7e8;
  }

  .cls-2 {
    fill: #333;
  }

  .cls-3 {
    fill: #b0916e;
  }

  .cls-4 {
    fill: #ffd324;
  }
}


/* DASHBOARD ANALYTICS SPECIFIC STYLING */

.wrap__outer--dashboard-feature-lock {
  position: relative;
}


.list--analytics-locked {
  padding: 3.5rem 2rem;

  .list__item {
    color: #fff;
  }
}

.list--analytics-unconfigured {
  background: rgba(255, 255, 255, .85);
  border: 2px solid $ordoro-blue;
  box-shadow: rgba(0, 0, 0, .15) 0 rem-calc(20) rem-calc(20);

  .list__item,
  .list__title {
    color: #222;
  }
}

.wrap__inner--dashboard-feature-lock {
  filter: grayscale(1);
  opacity: .75;

  .panel .subheader--analytics {
    color: #999;
  }

  .wrap--graph,
  .wrap--product-img {
    opacity: .5;
  }

}

.wrap--initial-svg {
  padding: 0 3.25rem;
  margin-bottom: 1.5rem;
}

.value--grey-bar {
  color: #ddd;
  background-color: #ddd;
}


.dashed {
  stroke-dasharray: 20;

}

.path-01 {
  stroke-dasharray: 20;
  stroke-dashoffset: 0;
  animation: dash 5s linear infinite;
}

.path-01b {
  stroke-dasharray: 20, 100;
  stroke-dashoffset: 0;
  animation: dash 5s linear infinite;
}


@keyframes dash {

  to {
    stroke-dashoffset: 1000;
  }
}

.blob--blue {
  scale: .75;
  opacity: .1;
  animation: jello 1.5s ease-in-out infinite;
  transform-origin: center;
}

@keyframes jello {

  0% {
    scale: .75;
    opacity: .1;
    fill: #34a0cd;
  }

  11.1% {
    scale: .75;
    opacity: .15;
    fill: #34a0cd;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
    opacity: .2;
    fill: #F58026;

  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
    opacity: .15;
    fill: #F58026;
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
    opacity: .1;
    fill: #34a0cd;

  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
    opacity: .1;
    fill: #34a0cd;
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
    opacity: .1;
    fill: #34a0cd;
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
    opacity: .1;
    fill: #34a0cd;
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    opacity: .1;
    fill: #34a0cd;
  }

  100% {
    opacity: .1;
    fill: #34a0cd;
  }

}