//Buttons
button {
    line-height: normal;
}

button:focus {
    outline: none;
}

//Action buttons
.button {
    @include button($button-med, $ordoro-orange, 5px, false, false, false);
    border: 2px solid #dc6b00;
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px, rgba(255, 255, 255, 0.2) 0 2px 0 inset;
    @include text-shadow-standard;
    @include transition-all-fast;
    -webkit-font-smoothing: antialiased;
    line-height: 1.35em;
    vertical-align: top;
    -webkit-backface-visibility: hidden;
}

.button:hover, .button:focus, .button:active {
    color: #fffbda;
    text-decoration: none;
    border-color: #ce6806;
    background: $ordoro-orange;
    box-shadow: rgba(0, 0, 0, 0.4) 0 1px 4px, rgba(0, 0, 0, 0.15) 0 2px 30px inset;
}

input[type="submit"].button {
    -webkit-border-radius: 5px;
    &:focus {
        outline: none;
    }
}

.button.smaller, .form-list .button.smaller {
    padding: .5em 1em;
    font-size: rem-calc(12);
}

.button.xlarge {
    padding: 20px 50px 21px;
    font-size: rem-calc(23);
}

.button.blue {
    background: $ordoro-blue;
    border-color: #2a8db7;
}

.button.blue:hover {
    color: #fffbda;
}

.button.grey {
    border-color: $medium-dark-grey;
    background: $medium-grey;
}


.btn--search {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0, rgba(255, 255, 255, 0.2) 0 2px 0 inset;
}
 .btn--search:hover, .btn--search:focus, .btn--search:active {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0, rgba(0, 0, 0, 0.15) 0 2px 15px inset;
    cursor: pointer;
 }

button.disabled, button.disabled:hover, button.disabled:focus, button[disabled], button[disabled]:hover, button[disabled]:focus, .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
    background: $lighter-grey;
    border-color: $medium-grey;
    @include shadow-reset;
    color: #bbb;
    @include text-shadow-reset;
    opacity: .5;
    cursor: default;
}

.main-action.disabled, .main-action.disabled:hover {}

.button.submitting, .button.submitting:hover, .button.submitting:active, .button.submitting:focus, .button.submitting[disabled], .button.submitting[disabled]:hover, .button.submitting[disabled]:active, .button.submitting[disabled]:focus, .button.btn--working, .button.btn--working:hover, .button.btn--working:active {
    @extend .button.grey;
    cursor: default;
    @include shadow-reset;
    @include text-shadow-reset;
    color: $medium-grey;
    @include button-submitting;
    opacity: 1;
}

//Dropdown buttons
.dropdown-toggle, .utility-nav-button {
    @include button(0, #fff, false, false, false, false);
    padding: 10px;
    border: 0;
    font-weight: normal;
    margin-bottom: 0;
    @include transition-all-standard;
    font-size: rem-calc(11);
}

.dropdown-toggle:after {
    @include triangle-pointing-down(4px, $body-font-color);
    @include dropdown-triangle;
    margin-left: .02em;
    margin-top: .4em;
    line-height: 0;
}

.advanced-toggle:after {
    @include triangle-pointing-down(4px, $medium-grey);
    @include dropdown-triangle;
    margin-left: .25em;
    margin-top: .4em;
    line-height: 0;
}

.dropdown-toggle.medium:after {
    @include triangle-pointing-down(5px, $body-font-color);
    @include dropdown-triangle;
    margin-left: 0.3em;
}

.dropdown-toggle:hover, .utility-nav-button:hover, .dropdown-toggle:focus, .utility-nav-button:focus {
    background: transparent;
    color: $anchor-font-color-hover;
    outline: none;
    .icon {
        color: $anchor-font-color-hover;
    }
}

.dropdown-toggle:active, .utility-nav-button:active {
    @include shadow-reset;
}

.dropdown-toggle:hover:after {
    @include triangle-pointing-down(4px, $anchor-font-color-hover);
    @include dropdown-triangle;
}

.dropdown-toggle.medium:hover:after {
    @include triangle-pointing-down(5px, $anchor-font-color-hover);
    @include dropdown-triangle;
}

.disabled.dropdown-toggle:after, .disabled.dropdown-toggle:hover:after {
    @include triangle-pointing-down(4px, #bbb);
    @include dropdown-triangle;
}

.disabled.dropdown-toggle.medium:after, .disabled.dropdown-toggle.medium:hover:after {
    @include triangle-pointing-down(5px, #bbb);
    @include dropdown-triangle;
}

.syncing .dropdown-toggle:after {
    border-top: 4px solid $txt-green;
}

.syncing .dropdown-toggle:hover:after {
    border-top: 4px solid $anchor-font-color-hover;
}

.open .dropdown-toggle:after, .open .dropdown-toggle:hover:after {
    @include triangle-pointing-up(4px, #fff);
    @include dropdown-triangle;
    border-top: 0;
    line-height: 0;
}

.open .dropdown-toggle.medium:after, .open .dropdown-toggle.medium:hover:after {
    @include triangle-pointing-up(5px, #fff);
    @include dropdown-triangle;
    border-top: 0;
    line-height: 0;
}

.open .advanced-toggle:after {
    @include triangle-pointing-up(4px, $medium-grey);
    @include dropdown-triangle;
    border-top: 0;
    line-height: 0;
}

.open .advanced-toggle:hover:after {
    @include triangle-pointing-up(4px, $anchor-font-color-hover);
    @include dropdown-triangle;
}

.open .shipment-items-toggle:after {
    @include triangle-pointing-up(4px, $body-font-color);
    @include dropdown-triangle;
    border-top: 0;
    line-height: 0;
}

.open .shipment-items-toggle:hover:after {
    @include triangle-pointing-up(4px, $anchor-font-color-hover);
    @include dropdown-triangle;
}

//Secondary action buttons
.secondary-action {
    @include button($button-med, $ordoro-orange, 0px, false, false, false);
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 1.3em;
    border: 2px solid transparent;
    border-right: 0;
    border-left: 0;
    color: $body-font-color;
    background: transparent;
    @include shadow-reset;
    @include text-shadow-reset;
    margin-bottom: 0;
    border-right: 0;
    &.small {
        padding: 0.5rem .875rem;
        font-size: 0.8125rem;
    }
}

.secondary-action:hover, .secondary-action:focus {
    background: transparent;
    border: 2px solid transparent;
    border-right: 0;
    border-left: 0;
    color: $anchor-font-color-hover;
    @include shadow-reset;
    @include text-shadow-reset;
}

.secondary-action.disabled, .secondary-action.disabled:hover, .secondary-action.disabled:focus, .secondary-action[disabled], .secondary-action[disabled]:hover, .secondary-action[disabled]:focus {
    color: #bbb;
    background: transparent;
    border: 2px solid transparent;
    border-right: 0;
    border-left: 0;
    @include shadow-reset;
    @include text-shadow-reset;
}

.with-divider {
    border-left: 1px solid $light-grey !important;
}

/* Secondary buttons for forms */
.secondary-action.secondary-form-action, .secondary-action.table-form-secondary-action {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-transform: none;
    line-height: 1.6;
    padding-left: 1em;
    color: $medium-dark-grey;
}

.secondary-action.secondary-form-action:hover, .secondary-action.secondary-form-action:focus, .secondary-action.table-form-secondary-action:hover, .secondary-action.table-form-secondary-action:focus {
    color: $body-font-color;
    text-decoration: underline;
}

.secondary-action.table-form-secondary-action {
    font-weight: normal;
    font-size: .8em;
    margin: .8em 0 0 0;
    padding: 0;
    line-height: 1;
    vertical-align: middle;
}

.product-basic-info .secondary-action.table-form-secondary-action {
    margin-top: .4em;
    vertical-align: top;
}

.wrap--manual-order-tags .dropdown-wrap .secondary-action {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-transform: none;
    font-size: rem-calc(12);
    line-height: 1.5;
    border: 0;
    border-bottom: 1px dotted $light-grey;
    background: transparent;
    border-radius: 0;
    padding: 0 0 .5em 0;
    margin-bottom: .75em;
    display: block;
    text-align: left;
    width: 100%;

    &:hover {
        color: $ordoro-blue;
    }
}

.wrap--manual-order-tags .dropdown-wrap.open {
    .secondary-action {
        border: 0;
        border-bottom: 1px dotted $light-grey;
        border-radius: 0;
        background: transparent;
        color: $body-font-color;
        @include text-shadow-reset;

        &:after {
            @include triangle-pointing-up(4px, $body-font-color);
            border-top: 0;
            line-height: 0;
        }

        &:hover {
            color: $ordoro-blue;

            &:after {
                @include triangle-pointing-up(4px, $ordoro-blue);
                border-top: 0;
                line-height: 0;
            }
        }
    }

    .dropdown-menu {
        top: 2em;
    }
}

//Inline buttons
.inline-text-button {
    background: transparent;
    border: 0;
    color: $medium-grey;
    overflow: visible;
    padding: 0;
    text-align: left;
    display: inline;
    @include shadow-reset;
    font-weight: normal;
    font-size: 1em;
}

.inline-text-button:hover, .inline-text-button:focus {
    text-decoration: underline;
    background: transparent;
    color: $medium-dark-grey;
    @include shadow-reset;
    cursor: pointer;
}

.inline-text-button:focus {
    text-decoration: none;
}

.no-underline:hover, .no-underline:focus {
    text-decoration: none;
}

.inline-text-button.hyperlink {
    color: $linkColor;
    padding: 0;
    margin-bottom: 0;
}

.inline-text-button.hyperlink:hover, .inline-text-button.hyperlink:focus {
    color: $linkHoverColor;
}

.highlighter {
    color: yellow;
    &:hover, &:focus {
        color: lighten(#ffff00, 35%);
    }
}

.darker {
    color: $body-font-color;
}

.darker:hover {
    color: $anchor-font-color-hover;
    text-decoration: none;
}

.darker.with-underline:hover {
    text-decoration: underline;
}


.inline-remove {
    background: #fff;
    padding: 0 2px;
    margin-bottom: 0;
    border: 1px solid $medium-grey;
    display: inline-block;
    font-size: rem-calc(9);
    line-height: 1;
    vertical-align: middle;
}

.inline-remove:hover {
    background: #fff;
    text-decoration: none;
}

.inline-remove-dark {
    background: $medium-grey;
    color: #fff;
    font-weight: bold;
    border-color: $medium-dark-grey;
    -webkit-font-smoothing: antialiased;

    &:hover {
        background: $medium-dark-grey;
        color: #fff;
    }
}

.inline-text-button.disabled, .inline-text-button.disabled:hover, .inline-text-button.disabled:focus, .inline-text-button[disabled], .inline-text-button[disabled]:hover, .inline-text-button[disabled]:hover {
    background: transparent;
    border: 0;
}

.inline-text-button.no-underline:hover, .inline-text-button.no-underline:focus {
    text-decoration: none;
}

.settings-list-item-button {
    color: $light-grey;
}

.settings-list-item-button:hover, .settings-list-item-button:focus {
    color: $ordoro-blue;
    text-decoration: none;
    .icon {
        text-decoration: none;
    }
    .button-text {
        text-decoration: underline;
    }
}

.app-table .inline-text-button {
    margin-bottom: 0;
}

.order-more {
    font-weight: bold;
    width: 85%;
    margin-left: 3em;
}

.modal-subnav-button {
    margin-bottom: 0;
    font-size: rem-calc(12);
    font-weight: normal;
    color: $body-font-color;
    vertical-align: baseline;
}

.modal-subnav-button:hover, .modal-subnav-button:focus {
    color: $anchor-font-color-hover;
    text-decoration: none;
}

.go-back-wrapper {
    font-size: rem-calc(12);
    margin: .35em 0 .5em 0;
    .inline-text-button {
        font-size: rem-calc(12);
        vertical-align: baseline;
    }
}

//Modal buttons
.close-button, .inline-icon-button {
    background: transparent;
    margin-bottom: 0;
    border: 0;
    padding: 0;
    color: $medium-grey;
    text-align: left;
    display: inline-block;
    @include shadow-reset;
    @include transition-all-standard;
    overflow: visible;
}

.close-button:hover, .inline-icon-button:hover, .close-button:focus, .inline-icon-button:focus {
    color: $medium-dark-grey;
    background: transparent;
    cursor: pointer;
}

.close-button:active, .inline-icon-button:active {
    @include shadow-reset;
}

.non-modal-close-button-wrap {
    position: absolute;
    top: .6em;
    right: 1.6em;
}

.csv-row-delete {
    position: absolute;
    right: -1em;
    margin-top: -.85em;
    padding: .75em;
    opacity: 0;
}

tbody:hover .csv-row-delete {
    opacity: 1;
}


.tag-button, button.tag-button {
    border: 1px solid #fff;
    color: $body-font-color;
    font-weight: normal;
    font-size: emCalc(12px);
    line-height: 1.2;
    @include text-shadow-white;
    padding: 0 .5em;
    margin-bottom: 0;
    text-align: center;
    width: 100%;
    min-width: 5em;
    height: 5em;
    @include transition-all-standard;
}

.tag-button:hover, button.tag-button:hover {
    color: $body-font-color;
    @include tag-color-shadow-hover;
}

.tag-button.selected, button.tag-button.selected {
    border: 1px solid $body-font-color;
    font-weight: bold;
    @include tag-color-shadow-selected;
}

//List page utility buttons (uses inline-text-button)
.list-utility {
    margin-top: .25em;
    li {
        font-size: rem-calc(12);
        .inline-text-button {
            font-weight: normal;
            padding: 0 .8em;
            margin-bottom: 0;
            line-height: 1.25;
            @include transition-all-standard;
            font-size: rem-calc(12);
            .icon {
                margin-right: .25em;
            }
            .down-arrow, .up-arrow {
                margin-left: 0;
            }
            .down-arrow:before, .up-arrow:before {
                top: 2px;
            }
        }
        .inline-text-button:hover {
            text-decoration: none;
        }
        .last {
            padding-right: 0;
        }
        .needs_sync {
            color: $ordoro-blue;
        }
        .needs_sync:hover, .needs_sync:focus {
            color: #000;
        }
    }
}

//Table sort arrows
.sort-arrows {
    @include button(0, #fff, false, false, false, false);
    background: transparent;
    font-size: 1rem;
    display: block;
    float: right;
    border: 0;
    @include text-shadow-standard;
    @include transition-all-standard;
    margin-bottom: 0;
    padding: .8em .1em 0 0;
    .sort-arrow {
        display: block;
        opacity: .5;
        @include transition-all-standard;
    }
    .up-arrow {
        @include triangle-pointing-up(0.3em, #fff);
        position: static;
        margin-bottom: .2em;
    }
    .down-arrow {
        @include triangle-pointing-down(0.3em, #fff);
        position: static;
    }
}

.sort-arrows:hover, .sort-arrows:focus {
    background: transparent;
    outline: none;
    .sort-arrow {
        opacity: .8;
    }
}

.sort-arrows.asc {
    .up-arrow {
        opacity: 1;
    }
    .down-arrow {
        opacity: .4;
    }
}

.sort-arrows.asc:hover {
    .up-arrow {
        opacity: 1;
    }
    .down-arrow {
        opacity: .8;
    }
}

.sort-arrows.desc {
    .down-arrow {
        opacity: 1;
    }
    .up-arrow {
        opacity: .4;
    }
}

.sort-arrows.desc:hover {
    .down-arrow {
        opacity: 1;
    }
    .up-arrow {
        opacity: .8;
    }
}

//Expand all button
.expand-all-button {
    position: absolute;
    left: -2.35em;
    padding: .5em;
    top: .3em;
    opacity: 0;
    @include transition-all-standard;
    .up-arrow {
        display: none;
        vertical-align: middle;
    }
    .up-arrow:after {
        @include triangle-pointing-up(4px, $medium-grey);
        line-height: 0;
    }
    .down-arrow {
        display: inline-block;
        vertical-align: middle;
    }
    .down-arrow:after {
        @include triangle-pointing-down(4px, $medium-grey);
        line-height: 0;
    }
    &:hover, &:focus {
        .up-arrow:after {
            @include triangle-pointing-up(4px, $medium-dark-grey);
            line-height: 0;
        }
        .down-arrow:after {
            @include triangle-pointing-down(4px, $medium-dark-grey);
            line-height: 0;
        }
    }
    &.all-expanded {
        .up-arrow {
            display: inline-block;
        }
        .down-arrow {
            display: none;
        }
    }
}

.app-table thead:hover {
    .expand-all-button {
        opacity: 1;
    }
}

//Toggle button groups

.toggle-button-list {
    margin: 0;
    dt {
        font-size: 0.75em;
        &.normal-size {
            font-size: 1em;
        }
    }
    dd {
        float: left;
        margin: 0 rem-calc(15) rem-calc(15) 0;
        &:last-of-type {
            margin-right: 0;
        }
    }
    .other-options {
        margin: 1.5em 0 0 0.5em;
        font-size: rem-calc(13);
        color: $medium-grey;
    }
}


//Shipper toggle buttons (used in shipping label modal)


.shipper-toggle {
    border: 1px solid #aaa;
    text-indent: -9999px;
    position: relative;
    padding: 10px 20px;
    height: 60px;
    @include transition-all-standard;
    opacity: .8;
}

.shipper-toggle:hover, .shipper-toggle:focus {
    background-color: #fff;
    border-color: $ordoro-blue;
    cursor: pointer;
    opacity: 1;
}

.shipper-toggle.selected, .shipper-toggle.selected:hover {
    background-color: #fff;
    border-color: #444;
    opacity: 1;
    cursor: default;
    @include img-shadow-big;
}

.endicia .shipper-toggle, .pitney .shipper-toggle {
    background: #f7f7f7 url(../images/usps.svg) center center no-repeat;
    background-size: 90%;
    width: 155px;
}

.ups .shipper-toggle {
    background: #f7f7f7 url(../images/ups.svg) center center no-repeat;
    background-size: 60%;
    width: 69px;
}

.fedex .shipper-toggle {
    background: #f7f7f7 url(../images/fedex.svg) center center no-repeat;
    background-size: 80%;
    width: 128px;
}

.canada_post .shipper-toggle {
    background: #f7f7f7 url(../images/canada-post.svg) center center no-repeat;
    background-size: 80%;
    width: 155px;
}

.plan-toggle {
    border: 1px solid #aaa;
    background: $lightest-grey;
    text-align: center;
    padding: .75em;
    color: $body-font-color;
    @include transition-all-standard;
    opacity: .8;
    width: 13.75em;
    position: relative;
}

.plan-toggle:hover, .plan-toggle:focus {
    background-color: #fff;
    color: $ordoro-blue;
    border-color: $ordoro-blue;
    opacity: 1;
    cursor: pointer;
}

.plan-toggle.selected, .plan-toggle.selected:hover {
    background-color: #fff;
    border-color: $txt-green;
    opacity: 1;
    @include img-shadow-big;
    .plan-type, .plan-desc, .plan-fine-print {
        color: $txt-green;
    }
}

.plan-type {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
    text-transform: uppercase;
    display: block;
    font-size: rem-calc(17);
    line-height: 1.2;
    vertical-align: top;
}

.plan-desc {
    font-size: rem-calc(14);
    display: block;
    opacity: .8;
    margin-bottom: .5em;
}
.plan-fine-print {
    font-size: rem-calc(11);
    line-height: 1.2;
    display: block;
    opacity: .6;
    text-align: left;
    border-top: 1px solid $light-grey;
    padding-top: .75em;
    margin: .75em 0 0 0;
    li {
        margin: 0 0 .5em 1.25em;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}


//Misc button stuff
.main-action-button-group {
    margin-bottom: 1em;
}

.button-group-wrap {
    display: block;
    margin-left: 0;
    li {
        list-style: none;
        display: inline-block;
        margin-right: 3px;
    }
}

.product-actions {
    margin-left: -0.9375rem;
    li {
        margin-right: 0;
    }
}

.button-wrap {
    position: relative;
}

/*------------------------------------*\
    #NEW REFACTORED STUFF
\*------------------------------------*/

.btn--analytics-period {
    background: none;
    border-radius: rem-calc(3);
    font-size: rem-calc(12);
    font-weight: bold;
    border: 1px solid $medium-grey;
    padding: .35em .75em .25em .75em;
    text-align: center;
    color: $medium-grey;
    outline: none;
    margin-bottom: 0;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    @include transition-all-standard;

    &:hover {
        background: $medium-grey;
        border: 1px solid $medium-dark-grey;
    }

    &.active, .active:hover {
        background: $body-font-color;
        border: 1px solid $body-font-color;
        color: #fff;
        cursor: default;
    }
}
