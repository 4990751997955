//
// Utilities
// ---------

.screenshot {
  border: 2px solid #444;
}

/* Color */

.text--black {
  color: #000;
}

.text--white {
  color: #fff;
}

.text--blue {
  color: $ordoro-blue;
}

.text--md-dk-grey,
.panel .text--md-dk-grey {
  color: #444;
}

.text--md-grey,
.panel .text--md-grey {
  color: #666;
}

.text--lt-grey,
.panel .text--lt-grey {
  color: #999;
}

.text--warning {
  color: $warning-txt-color;
}

.text--warning-orange {
  color: #FFD324;
}

.text--green {
  color: $txt-green;
}

.error,
.panel .error,
.text--error {
  color: #ff0000;
}

.align-center {
  text-align: center;
}

.margin-auto {
  margin-right: auto;
  margin-left: auto;
}

.margin-auto-all {
  margin: auto;
}

.center-everything {
  @include center-everything;
}

.padding-0 {
  padding: 0;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.v-align-middle,
.detail-page .v-align-middle,
.list__item--dropdown .v-align-middle,
.list__item--dropdown input[type="checkbox"].v-align-middle,
.list__item--dropdown input[type="radio"].v-align-middle {
  vertical-align: middle;
}

.wrap--input-v-align-middle {

  input[type="checkbox"],
  [type="radio"] {
    vertical-align: middle;
  }
}

.v-align-base {
  vertical-align: baseline;
}

.v-align-bottom {
  vertical-align: bottom;
}

.v-align-top {
  vertical-align: top;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  @include flexbox;
}

.flex--justify {
  @include flexbox;
  @include flex-justify-sb;
  @include flex-wrap(wrap);
}

.flex--center {
  @include flexbox;
  @include flex-justify-sa;
}

.flex--justify-nowrap {
  @include flexbox;
  @include flex-justify-sb;
  @include flex-wrap(nowrap);
}

.flex--col {
  @include flexbox;
  @include flex-direction(column);
}

.flex--justify-col {
  @include flexbox;
  @include flex-direction(column);
  @include flex-justify-sa;
}

.flex--jc-left {
  justify-content: left;
}

.flex--jc-center {
  justify-content: center;
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.wrap--input-seven-eighths {
  @include flex-basis(83%);
}

.wrap--input-two-thirds {
  @include flex-basis(64%);
}

.wrap--input-half {
  @include flex-basis(48%);
}

.wrap--input-third {
  @include flex-basis(31%);
}

.wrap--input-quarter {
  @include flex-basis(23%);
}

.wrap--input-eighth {
  @include flex-basis(14%);
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  @include flex-grow(0);
}

.flex-grow,
.flex-grow-1 {
  @include flex-grow(1);
}

.flex-grow-2 {
  @include flex-grow(2);
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-wrap-margin-10 {
  @include flex-wrap-margin(10);

  .btm-offset-7 {
    margin-bottom: rem-calc(-7);
  }

}

.hide,
small.hide {
  display: none;
}

.flex-wrap-margin-12 {
  @include flex-wrap-margin(12);
}

.flex-wrap-margin-15 {
  @include flex-wrap-margin(15);
}

.align-items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.abs-right {
  right: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.txt-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.th--inner-ellipsis {
  border-bottom: 1px dotted #ddd;
  margin-left: rem-calc(7);
  margin-right: rem-calc(7);
  position: relative;
  top: rem-calc(-7);
}

.hidden,
.wrap--detail-page .hide-on-detail-page {
  display: none;
}

.border-left--light,
.border-left--light:hover {
  border-left: 1px solid #ddd;
}

.border-left--dark,
.border-left--dark:hover {
  border-left: 1px solid #999;
}

.border-right--light,
.border-right--light:hover {
  border-right: 1px solid #ddd;
}

.border-bottom--light,
.border-bottom--light:hover {
  border-bottom: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-top {
  border-top: 1px solid #ccc;
}

.border-top--light {
  border-top: 1px solid #ddd;
}

.divider--left {
  margin-left: .75rem;
  padding-left: .75rem;
  border-left: 1px dotted #ccc;

  &.lg {
    margin-left: 1rem;
    padding-left: 1rem;
  }

  &.mid-dark {
    border-left: 1px solid #ccc;
  }

  &.dark {
    border-left: 1px solid #999;
  }
}

.divider--right {
  margin-right: .75rem;
  padding-right: .75rem;
  border-right: 1px dotted #ccc;

  &.sm {
    margin-right: .45rem;
    padding-right: .45rem;
  }

  &.lg {
    margin-right: 1rem;
    padding-right: 1rem;
  }

  &.dark {
    border-right: 1px solid #999;
  }
}

.divider--bottom {
  margin-bottom: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px dotted #ccc;

  &.sm {
    margin-bottom: .25rem;
    padding-bottom: .25rem;
  }

  &.mid {
    border-bottom: 1px solid #ccc;
  }

  &.dark {
    border-bottom: 1px solid #999;
  }
}

.table__td .list {
  .divider--bottom:only-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.divider--top {
  margin-top: .75rem;
  padding-top: .75rem;
  border-top: 1px dotted #ccc;

  &.sm {
    margin-top: .25rem;
    padding-top: .25rem;
  }

  &.mid {
    border-top: 1px solid #ccc;
  }

  &.dark {
    border-top: 1px solid #999;
  }
}

.divider--top-stubby:before {
  border-top: 2px solid #ddd;
  content: "";
  display: block;
  margin: 0.65rem 0;
  width: 1rem;
}

.divider--left,
.divider--right,
.divider--top,
.divider--bottom {
  &.divider--darken {
    border-color: #999;
  }
}

.op-0 {
  opacity: 0;
}

.op-30 {
  opacity: .3;
}

.op-50 {
  opacity: .5;
}

.op-75 {
  opacity: .75;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-18 {
  width: 18%;
}

.w-20 {
  width: 20%;
}

.min-w-20 {
  min-width: 20%;
}

.w-25 {
  width: 25%;
}

.mw-25 {
  min-width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.mw-40 {
  min-width: 40%;
}

.w-50 {
  width: 50%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-5rem {
  width: 5rem;
}

.w-7rem {
  width: 7rem;
}

.w-20rem {
  width: 20rem;
}

.w-30rem {
  width: 30rem;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.textarea-h-2rem {
  height: 2rem;
  min-height: 0;
}

.clearfix:before,
.clearfix:after {
  height: 0;
}

.margin-left-0-imp {
  margin-left: 0 !important;
}

// Line-height

.lh-0 {
  line-height: 0;
}

.lh-sm,
.btn--link.lh-sm:active,
.btn--link.lh-sm:focus {
  line-height: 1;
}

.lh-1-1 {
  line-height: 1.1;
}

.lh-sm-md,
.btn--link.lh-sm-md:active,
.btn--link.lh-sm-md:focus {
  line-height: 1.2;
}

.lh-1-25 {
  line-height: 1.25;
}

.lh-md,
.btn--link.lh-md:active,
.btn--link.lh-md:focus {
  line-height: 1.35;
}

.lh-x-md {
  line-height: 1.4;
}

.lh-lg {
  line-height: 1.5;
}

.lh-xl {
  line-height: 1.8;
}

.lh-xxl {
  line-height: 2;
}

.lh-inherit,
.btn--link.lh-inherit:active,
.btn--link.lh-inherit:hover {
  line-height: inherit;
}

// Typography Modular Scale

.fs-n2 {
  font-size: rem-calc(11);
}

.fs-n1 {
  font-size: rem-calc(12);
}

.fs-n0 {
  font-size: rem-calc(13);
}

.fs-00 {
  font-size: rem-calc(14);
}

.fs-01 {
  font-size: rem-calc(17);
}

.fs-02 {
  font-size: rem-calc(23);
}

.fs-03 {
  font-size: rem-calc(27);
}

.fs-04 {
  font-size: rem-calc(37);
}

.fs-05 {
  font-size: rem-calc(44);
}

.fs-06 {
  font-size: rem-calc(59);
}

.fs-07 {
  font-size: rem-calc(71);
}

.fs-08 {
  font-size: rem-calc(95);
}


.unbold {
  font-family: $font-family;
}

.capitalize {
  text-transform: capitalize;
}

.no-underline {
  text-decoration: none;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.word-break {
  word-break: break-all;
}

.ws-normal {
  white-space: normal;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.border-box {
  box-sizing: border-box;
}

// Help with Chrome's wacky text copy/pasting stuff

.wk--no-copypasta {
  -webkit-user-select: none;
}

.wk--copypasta {
  -webkit-user-select: text;
}

.wk--copypasta-hover {
  &:hover {
    -webkit-user-select: text;
  }
}

// Override for Foundation crap

.column--reset {
  padding-left: 0;
  padding-right: 0;
  float: none;
}




.no-border {
  border: 0;
}

.margin-left-n03 {
  margin-left: rem-calc(-3);
}

.margin-top-n02 {
  margin-top: rem-calc(-2);
}

.wrap--no-inner-left-margin .margin-left-n03 {
  margin-left: 0;
}