//
// Dropdowns
// ---------

.wrap--dropdown {
  position: relative;
  margin-bottom: 0;
  padding-left: 0;
}

.wrap--product-detail-tags.list {
  margin-left: rem-calc(-10);
}

.wrap--product-search {
  width: 65%;
}

#purchase-order-detail-contents .wrap--product-search,
.outer-wrap--product-search-100 .wrap--product-search {
  width: 100%;
}

.wrap--inner-dropdown {
  display: none;
  position: relative;

  &:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    left: .75rem;
    top: -.15rem;
  }
}

// Shitty ass Safari 16.x hack to help product list inner scroll not be as shitty
@supports (-webkit-hyphens:none) {
  .list--product-top-bar .wrap--inner-dropdown {
    z-index: 200000000;
  }

  #iverson-page .table--product-list .table__th--order-checkbox input[type='checkbox'] {
    margin: auto;
  }
}

.utility-nav .wrap--inner-dropdown {
  z-index: 3;
}

.wrap--inner-product-search {
  &:before {
    content: "";
    left: 1.7rem;
    top: -0.4rem;
  }
}

.wrap--sort-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: rem-calc(-5);
}

.list--orders-top-action-bar .wrap--sort-label {
  vertical-align: baseline;
}

.list--dropdown {
  background-color: #fff;
  border: 2px solid #444;
  border-radius: rem-calc(4);
  box-shadow: rgba(0, 0, 0, .2) 0 .25rem 1rem;
  color: getColor(text, primary);
  display: block;
  left: -.35rem;
  list-style: none;
  margin-top: .25rem;
  margin-left: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  transition: all $transition-duration;
  min-width: 15.5em;
  z-index: 3;
  max-height: calc(100vh - 14.75rem);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: .5em;
    width: rem-calc(13);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: rgba(0, 0, 0, .3);
    border-right: rem-calc(3) solid #fff;
    border-left: rem-calc(3) solid #fff;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, .15);
    border-right: rem-calc(3) solid #fff;
    border-left: rem-calc(3) solid #fff;
    margin-top: rem-calc(3);
    margin-bottom: rem-calc(3);
  }

  &.min-width-auto {
    min-width: auto;
  }
}

.list--dropdown-no-inner-scroll {
  overflow-y: initial;
  overflow-x: initial;
  max-height: auto;
}

.list--modal-dropdown {
  position: relative;
  top: .15rem;
  margin-bottom: rem-calc(20);
}

.wrap--inner-dropdown-right {

  &:before {
    @include arrow(top, #444, .5em);
    content: "";
    position: absolute;
    left: auto;
    right: .35em;
    top: -.15em;
  }

  .list--dropdown {
    left: auto;
    right: -.35em;
  }
}

.utility-nav .list--dropdown {
  max-height: calc(100vh - 5.75rem);
}

.list--product-search {
  margin-top: 0;
  left: .75rem;
}

.list--activity-dropdown {
  min-width: 19rem;

  .list__item:last-of-type {
    border-bottom: 0;
  }
}

.list--action-dropdown {
  background-color: #fff;
  border: 1px solid #444;
  border-radius: rem-calc(0) rem-calc(4) rem-calc(4) rem-calc(4);
  color: getColor(text, primary);
  box-shadow: rgba(0, 0, 0, .2) 0 .25em 1em;
  display: none;
  left: 0;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  transition: all $transition-duration;
  min-width: 150%;
  z-index: 1;
}

.wrap--other-actions {
  .list--action-dropdown {
    min-width: 130%;
  }
}

.open {

  .wrap--inner-dropdown,
  .list--action-dropdown {
    display: block;
  }

  &.btn--dropdown,
  .btn--dropdown {
    color: #999;

    &:after,
    &:hover:after,
    &:focus:after {
      @include arrow(top, #999, .35em);
      border-top: 0;
      content: "";
      display: inline-block;
      vertical-align: middle;
    }
  }

  .btn--primary-dropdown,
  .btn--primary-dropdown:hover,
  .btn--primary-dropdown:focus {
    background-color: darken(getColor(base, primary), 5%);

    &:after {
      @include arrow(top, lighten(yellow, 5%), .35em);
      border-top: 0;
      content: "";
      display: inline-block;
      vertical-align: middle;
    }
  }

  .btn--action {
    border-color: #444;
    border-bottom: 0;
    border-radius: rem-calc(4) rem-calc(4) 0 0;
    position: relative;
    z-index: 2;
  }

  .btn--ko-dropdown {
    color: #fff;

    &:after {
      @include arrow(top, #fff, .35em);
      border-top: 0;
      content: "";
      display: inline-block;
    }
  }

  .utility-nav__link,
  .utility-nav__link:hover,
  .utility-nav__link:focus {
    background: lighten(getColor(green, base), 3%);
    opacity: 1;
  }
}

.wrap--dropdown-right {
  .list--action-dropdown {
    border-radius: rem-calc(4) rem-calc(0) rem-calc(4) rem-calc(4);
    left: auto;
    right: 0;
  }
}

.list__item--dropdown {
  font-size: rem-calc(12);
  display: block;
  padding: 0;
  margin-bottom: 0;
  vertical-align: top;
  border-bottom: 1px solid #ccc;

  input[type="checkbox"] {
    vertical-align: baseline;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}


.list__item--dropdown-empty {
  padding: .75rem;
  text-align: center;
}

.label--selectable,
.list__link--dropdown {
  background: transparent;
  border: 0;
  color: #444;
  display: block;
  font-family: $aktiv-grotesk-bold;
  font-size: rem-calc(12);
  line-height: 1.3em;
  padding: .75rem .5rem;
  text-align: left;
  width: 100%;

  &.disabled {

    &:hover,
    &:focus:hover {
      color: #444;
    }
  }

  &.selected,
  &:hover,
  &:focus:hover,
  &.list__link--dropdown-arrow-focus {
    cursor: pointer;
    background: #f7f7f7;
    color: getColor(blue, base);
    text-decoration: none;
  }

  &.list__link--dropdown-arrow-focus {
    outline: 2px dashed $ordoro-blue;
  }

  &:focus {
    background: inherit;
    color: inherit;
  }

  &.top-divider {
    border-top: 1px solid #ccc;
  }

  .label--selectable__text {
    display: flex;
    align-items: center;
  }

}

.label--selectable-pt-pb-0 {
  padding-top: 0;
  padding-bottom: 0;

  label {
    padding-top: .75rem;
    padding-bottom: .75rem;
    max-width: 12rem;
    line-height: 1.25;

    &.mw-unset {
      max-width: unset;

    }
  }

  .wrap--tag-buttons {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  &.label--selectable-left-indent {
    label {
      margin-left: .35rem;
      padding-left: .5rem;
      border-left: 1px dotted #ccc;
    }
  }

}

.list__item--dropdown-header {
  background: #444;
  color: #fff;
  padding: .5rem;
  -webkit-font-smoothing: antialiased;
  border-bottom: 0;

  label {
    color: #fff;
  }
}

.list__item--dropdown-header-pkg-type {
  padding: 0;
  background-color: #ccc;

  label {
    color: #111;
  }


  &:first-of-type {
    margin-top: rem-calc(3);
  }

  .label--selectable,
  .list__link--dropdown {

    color: #111;

    &.selected,
    &:hover,
    &:focus:hover,
    &.list__link--dropdown-arrow-focus {
      background: #ddd;
      color: #111;
      text-decoration: none;
    }

    &.list__link--dropdown-arrow-focus {
      outline: 2px dashed $ordoro-blue;

      label {
        color: #111;
      }

    }
  }
}

.list__link--dropdown {
  padding-left: .75rem;

  // Fix this when we remove foundation dependencies (:focus)
  &.disabled,
  &.disabled:hover,
  &[disabled],
  &[disabled]:hover,
  &:focus {
    cursor: default;
    background: #fff;
  }

}

.list__item--dropdown-last {
  .list__link--dropdown {
    padding: .75rem 0;
    text-align: center;
  }
}

.list__link--preset-save,
.list__link--preset-save:focus,
.list__link--logout,
.list__link--logout:focus {
  text-align: center;
  background: #eee;

  &:hover,
  &:focus:hover {
    color: #111;
    background: #ddd;
  }
}

.list__link--batch-list,
.list__link-batch-list:focus {
  text-align: center;
  background: #f0f8fb;

  &:hover,
  &:focus:hover {
    color: #111;
    background: darken(#f0f8fb, 5%);
  }
}

.list__link--preset {
  width: 75%;

  &:hover,
  &:focus:hover {
    background: transparent;
  }
}

.wrap--tag-buttons,
.wrap--preset-buttons {
  opacity: 0;
  transition: all $transition-duration;
}

.disabled .wrap--tag-buttons {
  opacity: 0;

  &:hover {
    opacity: 0;
  }
}

.wrap--preset-buttons {
  padding: .75rem .75rem .75rem 0;
}

.label--tag {
  border-width: 0 0 0 rem-calc(10);
  border-style: solid;
  border-color: transparent;
  font-size: rem-calc(12);

  &:hover {
    .wrap--tag-buttons {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: .5;

    &:hover .wrap--tag-buttons {
      opacity: 0;
      visibility: hidden;
      cursor: default;
    }
  }
}

.label--untagged {
  background: #eee;

  &.disabled {
    opacity: .5;

    &:hover {
      background: #eee;
    }
  }
}

.wrap--preset-item:hover {
  .wrap--preset-buttons {
    opacity: 1;
  }
}

.list--activity-item {
  font-size: rem-calc(12);
  margin-bottom: 0;
  line-height: 1.3;
  padding: .7rem;
}

.list__title--activity {
  margin-bottom: rem-calc(3);
}

.list__item--activity {
  color: #666;
  margin-bottom: rem-calc(3);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.activity-message {
  margin-right: rem-calc(5);
}

.result-link,
.log-link {
  color: #666;

  &:hover {
    color: #444;
  }
}

.activity-error {

  .list__title--activity {
    color: #ff0000;

    &.flex {
      font-family: $aktiv-grotesk-regular;
    }
  }

  .activity-message {
    margin-right: 0;
    background: #ffeaeb;
    color: darken(#ff0000, 8%);
    border: 1px dashed #ccc;
    padding: .4rem;
    max-height: 5rem;
    overflow-y: scroll;
    word-break: break-word;

    &::-webkit-scrollbar {
      height: .45em;
      width: rem-calc(12);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: rgba(0, 0, 0, .3);
      border-right: rem-calc(3) solid #ffeaeb;
      border-left: rem-calc(3) solid #ffeaeb;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: #ffeaeb;
      border-right: rem-calc(3) solid #ffeaeb;
      border-left: rem-calc(3) solid #ffeaeb;
      margin-top: rem-calc(3);
      margin-bottom: rem-calc(3);
    }
  }
}

.list__item--activity-error {
  .activity-message {
    word-break: break-all;
  }

  .btn--link {
    width: 100%;
    padding: .25rem;
    text-align: center;
    border: 1px solid #ccc;
    border-top: 0;
  }
}

.wrap--support-pin {
  padding: .7rem;
  margin-left: 0;
  line-height: 1.3;
  list-style: none;
  background: #444;
  color: #fff;
}

.btn--clear-list {
  opacity: 0;
  visibility: hidden;
}

.wrap--activity-header {
  padding: .5rem .7rem;
  margin-left: 0;
  font-size: rem-calc(14);
  line-height: 1;
  list-style: none;
  background: #ddd;
  color: #444;

  &:hover {
    .btn--clear-list {
      opacity: 1;
      visibility: visible;
    }
  }
}

.btn--clear-list {
  opacity: 0;
  visibility: hidden;
}


.wrap--label-text {
  display: inline-block;
  margin-left: .25em;
  width: 80%;
}

.outer-wrap--dropdown-presets {
  margin-right: .6rem;
  margin-bottom: .35rem;
  display: flex;

}

.wrap--dropdown-presets {
  text-align: right;
  margin-right: .55rem;
  margin-left: auto;

  .list--dropdown {
    max-height: calc(100vh - 23rem);
  }
}


/* Reverse Filtering */

.btn--filter-reverse {
  display: none;
  opacity: 0;

}

.wrap--reversable-filters {
  .btn--filter-reverse {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: .35rem;
  }

  .label--selectable {
    position: relative;
    padding-right: rem-calc(30);

    &:hover {
      .btn--filter-reverse {
        opacity: 1;
        display: inline-block;

      }
    }
  }
}

/* FANCY DROPDOWNS WITH SEARCH FILTERS */


.wrap-inner--dropdown-with-filter {
  position: relative;

  &:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    left: .75rem;
    top: -.15rem;
  }
}

.wrap--product-dropdown-with-filter {
  .list--dropdown-with-filter {
    left: 0;
    width: 100%;
  }
}

.modal__content .list--dropdown-with-filter {
  max-height: unset;
}

.wrap-outer--tag-dropdown-with-filter {
  .wrap-inner--dropdown-with-filter:before {
    display: none;
  }

  .wrap-middle--dropdown-with-filter {
    position: absolute;
    z-index: 3;
    border-top: 2px solid #444;
    border-radius: .25rem .25rem 0 0;
    margin-top: .35rem;

    &:before {
      @include arrow(top, #444, .45rem);
      content: "";
      position: absolute;
      left: .75rem;
      top: -.5rem;
    }
  }

  .list--dropdown {
    position: unset;
    border-top: 0;
    border-radius: 0 0 .25rem .25rem;
    margin-top: 0;
    left: 0;
    overflow-x: auto;
    max-height: auto;
  }

}


.wrap--dropdown-presets .wrap-middle--dropdown-with-filter {
  right: 2.75rem;

  &:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    left: auto;
    right: .75rem;
    top: -.5rem;
  }
}


.wrap--text-input--dropdown-with-filter {
  background: #444;
  border-bottom: 2px solid #888;
  border: 2px solid #444;
  border-top: 0;
  padding: .75rem;
}

// ZROPDOWN FUN

#dropdown-root {
  .wrap--base-dropdown {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 700;
  }

  .wrap--dropdown-list {
    position: relative;
    margin-top: 0;
    margin-left: 0;
    padding: 0;
    background-color: #fff;
    border: 2px solid #444;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0.25rem 1rem;
    color: #444;
    width: min-content;
  }

  .wrap--base-dropdown-rs {
    .list--dropdown-open {
      margin-right: .25rem;
    }
  }

  // Down (normal)
  .wrap--dropdown-list--left.wrap--dropdown-list--down:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    right: .75rem;
    top: -.5rem;
  }

  // Up
  .wrap--dropdown-list--left.wrap--dropdown-list--up:after {
    @include arrow(bottom, #444, .45rem);
    content: "";
    position: absolute;
    right: .75rem;
    bottom: -.5rem;
  }

  // Down (right adjust)
  .wrap--dropdown-list--right.wrap--dropdown-list--down:before {
    @include arrow(top, #444, .45rem);
    content: "";
    position: absolute;
    left: .75rem;
    top: -.5rem;
  }

  // Up (right adjust)
  .wrap--dropdown-list--right.wrap--dropdown-list--up:after {
    @include arrow(bottom, #444, .45rem);
    content: "";
    position: absolute;
    left: .75rem;
    bottom: -.5rem;
  }

  .list--dropdown {
    border: none;
    border-radius: 0;
    box-shadow: none;
    transition: none;
    max-height: unset;
    min-height: 50px;
    margin: 0;
    position: relative;
    width: min-content;
    left: 0;

    &.list--dropdown-open {
      overscroll-behavior: contain;
    }

    &>ul {
      margin: 0;
    }
  }

  .dropdown-list--footer {
    border-top: 1px solid #ccc;
  }
}

.zropdown__header--rs {
  background: #fff;
}

.zropdown__header--rs-inner {
  padding: 1.5rem;
  border-bottom: 2px solid #999;
  min-width: 65vw;
  overflow: auto;
}