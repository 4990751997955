.header--main {
    background: transparent url('https://ee27567f00fe81db073a-0605e6fdbd6fca55fb84b19713876532.ssl.cf1.rackcdn.com/firstmile-nav-bg-dark.png') 0 0 repeat;
}

.main-nav__link {

    color: #fff;
    -webkit-font-smoothing: antialiased;
    opacity: 1;

    &:hover, &:focus {
        color: lighten( #84c447, 10%) ;
        background: rgba(0,0,0,.3);
    }

    &.active {
        background: rgba(255,255,255,.2);
        color: #fff;
        opacity: 1;
    }

    &.wrap--logo {
        background:
        url('https://ee27567f00fe81db073a-0605e6fdbd6fca55fb84b19713876532.ssl.cf1.rackcdn.com/firstmile-logo-01.png') 0 0 no-repeat;
        background-size: contain;
        width: 2rem;
        height: 2rem;
        margin: .6rem .35rem .6rem 0;

        opacity: 1;
    }
}

.main-nav__count {
    background: #84c447;
    color: #1f3138;
}

.utility-nav__link:hover {
    background: rgba(0,0,0,.3);
}

.open .utility-nav__link, .open .utility-nav__link:hover {
    background: rgba(255,255,255,.2);
    color: #fff;
    opacity: 1;
}

.btn--primary, .btn--primary:focus {
    background: rgb(132, 196, 71);
    border-color: rgb(132, 196, 71);
    color: #fff;

    &:hover {
        background: #008eff;
        border-color: #008eff;
        color: #fff;
    }
}

.btn--primary-alt, .btn--primary-alt:focus {
    background: #008eff;
    border-color: #008eff;

    &:hover {
        background: rgb(132, 196, 71);
        border-color: rgb(132, 196, 71);
        color: #fff;
    }
}

.btn--primary-ol, .btn--primary-ol:focus {
    background: #fff;
    border-color: #999;
    color: #666;
}

.btn--primary-ol:hover, .btn--primary-ol:focus:hover {
    background: #f7f7f7;
    color: #444;
    border-color: #666;
}

.btn--disabled, .btn--disabled:hover, .btn--disabled:focus, .btn--disabled:focus:hover {
    background: #eee;
    border-color: #999;
    color: #bbb;
    opacity: .5;
    cursor: default;
}

.btn--selected, .btn--selected:hover, .btn--selected:focus, .btn--selected:focus:hover {
    background: transparent;
    border-color: transparent;
    color: $ordoro-blue;
    cursor: default;
}

.btn--processing, .btn--processing:hover, .btn--processing:focus, .btn--processing:focus:hover, .btn--processing:disabled, .btn--processing:hover:disabled {
    border-color: $medium-dark-grey;
    background: $medium-grey;
    color: $medium-grey;
    cursor: default;
    position: relative;
    opacity: 1;

    &:before {
        @include spinner(0.15rem, #fff, 0.85, 1rem);
        @include animate-spin;
        @include center-everything;
        content: "";
    }

    &.btn--nav-sync:before {
        @include spinner(0.15rem, #fff, 0.85, 0.85rem);
        @include animate-spin;
        @include center-everything;
        content: "";
    }
}

.btn--processing-xxl, .btn--processing-xxl:hover, .btn--processing-xxl:focus, .btn--processing-xxl:focus:hover, .btn--processing-xxl:disabled, .btn--processing-xxl:hover:disabled {
    border-color: $medium-dark-grey;
    background: $medium-grey;
    color: $medium-grey;
    cursor: default;
    position: relative;
    opacity: 1;

    &:before {
        @include spinner(0.3rem, #fff, 0.85, 2.25rem);
        @include animate-spin;
        @include center-everything;
        content: "";
    }
}


.sub-nav__link.active {
    color: #000;
    border-bottom: 3px solid #000;
}