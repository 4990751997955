//Modals

//Modal Customizations

.reveal-modal-bg, .reveal-modal {
    @include transition-all-standard;
    display: block;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
}

.reveal-modal-bg.in, .reveal-modal.in {
    opacity: 1;
    visibility: visible;
    z-index: 98;
    opacity: 1;
}

.reveal-modal {
    border-radius: 5px;
    top: -125%;
    min-height: 22em;
    padding: rem-calc(27);
    max-height: calc(100vh - 80px);

    p {
        font-size: rem-calc(14);
    }
    .success-message {
        font-size: rem-calc(17);
        line-height: 1.5;
    }
}

.reveal-modal.in {
    top: 2.5em;
}

.reveal-modal.x-sm {
    width: 40%;
}

.reveal-modal.sm {
    width: 50%;
}

.reveal-modal.md {
    width: 60%;
}

.reveal-modal.lg {
    width: 70%;
}

.reveal-modal.x-lg {
    width: 90%;
}

.no-min-height {
    min-height: 1em;
    padding-bottom: rem-calc(37);
}

.modal-header {
    font-family: $aktiv-grotesk-regular;
    font-weight: normal;
    margin: 0 0 .5em;
    line-height: 1.2;
}

/* MODAL STYLES */
.modal-backdrop .modal {
    min-height: 410px;
    margin-top: 0;
    margin-left: -395px;
    text-align: left;
    -webkit-box-shadow: 0 3px 60px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 3px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 60px rgba(0, 0, 0, 0.5);
}

.modal-backdrop .modal.fade.in {
    top: 65px;
}

.modal-backdrop, .modal-backdrop.fade.in {
    opacity: 1;
    filter: alpha(opacity = 100);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00000080,endColorstr=#00000080);
    /*IE fallback for lack of rgba support */
}

/* .modal-backdrop.fade seems to cover everything up unless I set the indexes thusly */
.modal-backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11000;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-in-out;
}

.modal-backdrop.fade {
    z-index: -1;
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
}

.modal-backdrop.fade.in {
    z-index: 10000;
    background: rgba(0, 0, 0, 0.7);
    visibility: visible;
}

.modal .reminder {
    margin-right: 80px;
}

.modal .reminder.last {
    margin-right: 0;
}

.modal .reminder dt a {
    text-align: left;
    line-height: 24px;
    padding: 15px 20px;
}

.modal .reminder dt a span {
    margin: -15px 25px 0 -20px;
    font-size: 60px;
    padding: 28px 18px 14px 18px;
}

/* Craycray CSS animation spinner */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: translate(0, 0) rotate(0deg);
    }
    50% {
        -webkit-transform: translate(1px, -1px) rotate(180deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(360deg);
    }
}


@-moz-keyframes spin {
    0% {
        -moz-transform: translate(0, 0) rotate(0deg);
    }
    50% {
        -moz-transform: translate(0px, 0px) rotate(180deg);
    }
    100% {
        -moz-transform: translate(0, 0) rotate(360deg);
    }
}


@-ms-keyframes spin {
    0% {
        -ms-transform: translate(0, 0) rotate(0deg);
    }
    50% {
        -ms-transform: translate(1px, -1px) rotate(180deg);
    }
    100% {
        -ms-transform: translate(0, 0) rotate(360deg);
    }
}


.progress .percentage {
    position: absolute;
    z-index: 11002;
    left: -37px;
    width: 100px;
    top: 19px;
    display: block;
    text-align: center;
    color: rgba(108, 179, 62, 0.7);
}

.percentage span {
    font-size: 12px;
    position: relative;
    top: -2px;
}

.start_app {
    font-size: 20px;
}

.modal-scroll {
    max-height: 500px;
    overflow-y: scroll;
}

//Sync cart modal
.sync-cart-list {
    .sync-cart-list-item {
        width: 48%;
        .panel {
            padding: .75em .75em .5em .75em;
            dt {
                margin-bottom: .5rem;
                font-size: rem-calc(14);
            }
            dd {

                margin-bottom: .65rem;
                margin-right: 1rem;
                line-height: 1.1;
                background: transparent;

                label {
                    font-size: rem-calc(13);
                }
            }
            .icon {
                display: none;
            }
            .icon:before {
                top: 2px;
            }
            .autosynced {
                .icon {
                    display: inline-block;
                }
            }
        }
    }
    .disabled {
        label {
            color: $medium-grey;
        }
        dt label:hover {
            color: $medium-dark-grey;
        }
        dd label {
            cursor: default;
        }
    }
    dd.disabled label {
        cursor: default;
    }
    .even {
        float: left;
        clear: both;
    }
    .odd {
        float: right;
        clear: none;
    }
}

.lots_of_items {
    .sync-cart-list {
        max-height: calc(100vh - 23rem);
        overflow-y: auto;
    }
}

//Create Order modal
.create-order-form {
    height: 31em;
    overflow-y: scroll;
    fieldset {
        margin-bottom: 0;
        legend {
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            font-size: rem-calc(18);
            color: $txt-green;
            margin-bottom: .75em;
            width: 100%;
            label {
                font-size: .65em;
                font-weight: normal;
                margin-bottom: 0;
            }
        }
        .button {
            margin-bottom: 0;
        }
    }
    .search-wrap input[type="text"] {
        font-size: .75rem;
        padding-left: 1.65rem;
    }
    .create-order-form-wrapper {
        .form-list {
            font-size: rem-calc(12);
            .form-list-item {
                margin-bottom: .75em;
                label {
                    font-size: rem-calc(12);
                    line-height: 1.5;
                    margin-bottom: 0;
                }

                .label--tag {
                    line-height: 1.2;
                }

                input[type="text"] {
                    font-size: 1em;
                    height: 2.5em;
                    margin-bottom: 0;
                }

                .search-wrap input[type="text"] {
                    font-size: .75rem;
                    padding-left: 1.65rem;
                }
            }
        }
    }
}

.order-summary-wrap {
    font-size: .75em;
    h5 {
        font-size: rem-calc(14);
    }
}

.order-summary-block {
    border-top: 1px solid $light-grey;
    padding: .75em 0 0 0;
    font-size: rem-calc(12);
    dt, .inline-text-button {
        line-height: 1;
    }
    dd {
        margin-bottom: 0;
        line-height: 1.3;
    }
    .content-list {
        margin-left: 0;
        li {
            font-size: rem-calc(12);
        }
    }
}

//Create label modal

.no-warehouse-wrap, .wrap--modal-speedbump {
    padding: 1.6875rem;
    background: #fff;
    border-radius: 5px;

    // Hide the is default stuff since we assume it will be default
    li.form-list-item.row:last-child {
        display: none;
    }
}

.no-usps-account-wrap {
    .icon {
        opacity: .3;
        line-height: .7;
    }
    p {
        font-size: rem-calc(17);
    }
}

.labels-processing-wrap {
    position: absolute;
    opacity: 1;
    @include transition-all-standard;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 7em;
    background: #fff;
    min-height: 42em;
    border-radius: 5px;
    .labels-processing-spinner-wrap {
        position: relative;
        margin: 0 auto 1em auto;
        height: 7.5em;
        width: 7.5em;
    }
    .labels-processing-spinner {
        position: absolute;
    }
    .icon {
        opacity: .4;
        font-size: 3.25em;
        line-height: 1.25;
        position: relative;
        top: 0.625em;
    }
    h1 {
        margin-bottom: 0;
        line-height: 1.2;
    }
    p {
        font-size: rem-calc(17);
    }
}

.create-label-modal {
    padding: 0;
    height: auto;
    background: transparent;
    .close-button {
        z-index: 102;
    }
}

.create-label-outer-wrap, .customs-declarations-wrap, .label-errors-wrap {
    position: absolute;
    top: 0;
    left: 0;
    padding: rem-calc(27);
    z-index: 99;
    background: $light-grey;
    width: 100%;
    @include transition-all-standard;
    border-radius: 5px;
    min-height: calc(100vh - 10rem);
    overflow: hidden;
}

.create-label-outer-wrap {
    z-index: 100;
    background: #fff;
}

.gtfo {
    left: -98%;
    border-radius: 5px 0 0 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 3px 7px;
}

.label-errors-wrap {
    background: $error-bg-color;
    color: rgba(0, 0, 0, 0.7);
    h2 {
        color: rgba(0, 0, 0, 0.7);
    }
    &.some-shipment-errors {
        background: $warning-bg-color;
        color: rgba(0, 0, 0, 0.7);
        h2 {
            color: rgba(0, 0, 0, 0.7);
        }
    }
}

.create-label-wrapper {
    max-height: calc(100vh - 16rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: .5rem;
    -webkit-transform: translate3d(0,0,0);

    &::-webkit-scrollbar {
        height: .5em;
        width: .5em;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0,0,0,.4);
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,.2);
    }

    label, input[type="text"], .unit {
        font-size: .75em;
    }
    label {
        margin-bottom: .2em;
        &.larger {
            font-size: 1em;
            margin-bottom: 0;
        }
    }

    .form-list {
        .form-list-item {
            input[type="text"] {
                padding: .2em;
                height: 2.1em;
                margin-bottom: 0;
                vertical-align: top;
            }

            .input-wrap {
                vertical-align: middle;

                input[type="text"] {
                    height: 2.3em;
                    padding-left: 1.5em;
                    width: 6em;
                }
            }
        }

    }
    .advanced-options {
        padding: 10px 0;
        margin-bottom: 1.5em;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        .advanced-options-lists-wrap {
            display: none;
        }
        .advanced-toggle {
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            font-size: rem-calc(14);
            text-transform: uppercase;
            padding: 0;
            border: 0;
            color: $medium-grey;
            background: none;
        }
        .advanced-toggle:hover {
            color: $anchor-font-color-hover;
        }
    }
    .advanced-options.open {
        padding-bottom: 0;
        .advanced-options-lists-wrap {
            display: block;
            margin-bottom: .5em;
        }
    }

}

.endicia-callout {
    padding: .5em;
    margin-top: -.5em;
    margin-bottom: 0;
    background-color: lighten(#34a0cd, 45%);
    border: 1px solid lighten(#34a0cd, 35%);
    .endicia-label {
        background: $warning-bg-color;
        color: $warning-txt-color;
        border: 1px solid $warning-border-color;
        text-align: center;
        font-size: rem-calc(11);
        padding: 0 .25em;
    }
}

.wrap--validator-link {display: none;}

.wrap--callout {
    &.error {
        .modal__callout {
            display: block;
            margin: .25em 0 .8em 0;
            padding-bottom: .8em;
            line-height: 1;
            text-transform: uppercase;
            color: $error-color;
            border-bottom: 1px dotted rgba(255,0,0,.7);
        }

        .callout__message:after {
            content: "Invalid Address";
        }

        .wrap--validator-link {
            display: block;
            line-height: 1.3;
            margin-top: 1em;
            padding: .8em;
            background: $body-font-color;
            color: #fff;
        }
    }

    &.warning {
        .modal__callout {
            display: block;
            margin: .25em 0 .8em 0;
            padding-bottom: .8em;
            line-height: 1;
            text-transform: uppercase;
            color: $warning-txt-color;
            border-bottom: 1px dotted rgba(178,150,0,.7);
        }

        .callout__message:after {
            content: "Warning";
        }
    }
}

.modal-utility-list {
    border: 3px solid darken($ordoro-blue, 5%);
    padding: 0;
    margin: 0;
    background: #222;
    color: $medium-grey;
    dt {
        color: #fff;
        @include text-shadow-standard;
        font-size: rem-calc(14);
        padding: .8em 0;
        margin-bottom: 0;
        background: $ordoro-blue;
        text-align: center;
        -webkit-font-smoothing: antialiased;
    }
    dd {
        font-size: rem-calc(13);
        margin-left: 0;
    }
    .modal-utility-group {
        .modal-utility-group-items {
            list-style: none;
            margin-left: 0;
            li {
                padding: 0 1em 1em 1em;
                margin-bottom: 0;
                font-size: rem-calc(13);
                word-wrap: break-word;
                -webkit-font-smoothing: antialiased;

                .message--address {
                    line-height: 1.3;
                    display: block;

                }
            }

            .upgrade-rates {
                background: $body-font-color;
                color: #fff;
                padding: 1em .5em;
                strong {
                    line-height: 1.2;
                    margin-bottom: .5em;
                }
            }

            .all-padding {
                padding: 1em;

            }

            .good {
                padding: 0 1em;
            }

            .warning {
                .message--address {
                    color: $warning-txt-color;
                }
            }

            .error {
                .message--address {
                    color: $error-color;
                }
            }

            .modal-utility-item {
                -webkit-font-smoothing: antialiased;
                padding: 0;
                margin: 0;
                border-bottom: 1px dotted #444;
                .shipping-rate-button {
                    padding: 1em;
                    margin-bottom: 0;
                    width: 100%;
                    font-size: 1em;
                    border: 0;
                    background: transparent;
                    text-align: left;
                    color: $medium-grey;
                    @include transition-all-standard;
                    -webkit-font-smoothing: antialiased;
                    @include shadow-reset;
                    .shipping-type {
                        font-family: $aktiv-grotesk-bold;
                        font-weight: normal;
                    }
                    .currency, .shipping-cost {
                        color: #fff;
                        font-family: $aktiv-grotesk-bold;
                        font-weight: normal;
                    }
                    &:last-of-type {
                        border-bottom: 0;
                    }
                }
                .shipping-rate-button:hover {
                    background: #444;
                    color: #fff;
                }
            }
            .suggested-address {
                -webkit-font-smoothing: antialiased;
                margin-left: 0;
                margin-bottom: 1em;
                padding: .75em;
                border: 1px dashed $medium-dark-grey;
                li {
                    color: #fff;
                    padding: 0;
                    margin-bottom: 0;
                }
            }

            .first {
                padding-top: 1em;
            }
            .error {
                color: $error-color;
            }
            .error-message {
                font-size: rem-calc(12);
                padding: .5em 0;
                margin: 0 1em;
                border-top: 1px dotted #444;
            }
            .main-error {
                background: #ff0000;
                color: #fff;
                padding: .5em .75em;
                margin: 0 1em 1.25em 1em;
                font-family: $aktiv-grotesk-bold;
                font-weight: normal;
                line-height: 1.25;
                a {
                    color: #ffff00;
                    opacity: 1;
                    &:hover {
                        opacity: .85;
                        text-decoration: underline;
                    }
                }
            }
            .loading {
                margin-top: 1em;
            }
            .content-list {
                margin-left: 0;
                li {
                    padding: .3em 0;
                    font-size: rem-calc(13);
                    list-style: square;
                }
            }
        }
    }
    .link-wrap {
        padding: 1em;
        margin-bottom: 0;
        color: #666;
        font-size: .75em;
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        background: rgba(255, 255, 255, 0.1);
        text-align: center;
        a {
            color: $medium-grey;
        }
        a:hover {
            color: #fff;
            text-decoration: underline;
        }
    }

}

.create-label-sidebar {
    .content-list {
        margin-bottom: 0;
        li {
            margin: 0 0 .5em 1em;
        }
    }
}

.create-label-callout {
    background-color: #fff;
    border: 1px solid #999;
    text-align: center;
    dt {
        line-height: 1.3;
        margin-bottom: .65em;
    }
    dd {
        font-size: rem-calc(12);
    }
}

//Print label section in the modal

.print-label-error-wrap {
    padding-top: 5em;
    .print-label-error-icon-wrap {
        position: relative;
        margin: 0 auto;
    }
    .icon {
        position: relative;
    }
    .icon-shipments {
        color: $error-color;
        line-height: 1;
    }
    .icon-error {
        top: -2.85rem;
    }

    h3 {
        color: $error-color;
    }
}

.print-group {
    background: #ecf6fa;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    margin-top: 1em;
    margin-bottom: 0;
    padding: 0;
    .print-group-title {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        font-size: rem-calc(17);
        display: inline-block;
        margin-left: 1em;
        @include text-shadow-white;
        .label-big-count {
            font-family: $aktiv-grotesk-bold;
            font-weight: normal;
            font-size: rem-calc(59);
            line-height: 1;
            margin-right: .15em;
            color: #000;
        }
        .print-item-description {
            line-height: 1;
            text-transform: uppercase;
            vertical-align: top;
        }
        .middle-aligned {
            line-height: 3.75;
        }
    }
    .print-group-options {
        display: inline-block;
        background-color: #fff;
        margin-bottom: 0;
        padding: 2.35em 1.75em;
        min-width: 13em;
        font-size: rem-calc(14);
        position: relative;
        ul {
            list-style: none;
            margin-left: 0;
            font-size: rem-calc(14);
            li {
                margin-bottom: .75em;
            }
        }
    }
}

.regular-label-group {
    margin-top: 1em;
    .print-group-title {
        margin-top: 3.5em;
    }
    .print-group-options {
        padding: 1.25em 1.75em;
        &:after {
            top: 41%;
        }
    }
}

.additional-documents-group {
    .print-group-title {
        margin-top: 1.25em;
        .icon.md {
            line-height: .8;
        }
        .print-item-description {
            width: 15em;
            margin-top: .5em;
        }
        small {
            font-family: $Helvetica;
            text-transform: none;
        }
    }
}

.multipage-label-group {
    .print-group-title {
        margin-top: 1.1em;
    }
}

.printer-icon {
    display: inline-block;
    width: 1.75em;
    height: 1.75em;
    margin-right: .6em;
}

.zebra-printer {
    background: transparent url(../images/printer-zebra-ol.png) center center no-repeat;
    background-size: 100%;
}

.desktop-printer {
    background: transparent url(../images/printer-desktop-ol.png) center center no-repeat;
    background-size: 100%;
}

.printer-text {
    vertical-align: top;
    line-height: 1.8;
}

.label-customization-block {
    @extend .order-summary-block;
    &:first-of-type {
        border-top: 0;
        padding-top: .5em;
    }
    padding-top: rem-calc(17);
    margin-bottom: 0;
    font-size: 1em;
    dt {
        font-size: rem-calc(12);
        margin-bottom: .75em;
        text-transform: uppercase;
        color: $medium-grey;
    }
    dd {
        font-size: rem-calc(12);
        label, .unit, input[type="text"], .select {
            font-size: 1em;
        }
        margin-bottom: rem-calc(17);
    }
}


//Activate now modal

.wrap--modal-scroll {
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transform: translate3d(0,0,0);

    &::-webkit-scrollbar {
        height: .5em;
        width: .5em;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0,0,0,.4);
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,.2);
    }
}

.activate-form {
    legend {
        font-family: $aktiv-grotesk-bold;
        font-weight: normal;
        margin-bottom: 1.1em;
        width: 100%;
        &.with-top-border {
            padding-top: 1em;
            margin-top: 1em;
            border-top: 1px solid $light-grey;
        }
    }
    input[type="text"] {
        font-size: .75em;
    }
    label {
        font-size: .8em;
    }
    .plan-desc {
        font-size: .85em;
        color: #000;
    }
}

.error-block {
    padding: 2%;
    background: $error-bg-color;
    border: 2px solid $error-color;
    .error-text {
        font-size: .85em;
        line-height: 1.3;
        margin-bottom: 0;
        color: $error-color;
        @include text-shadow-white;
    }
}

.coupon-wrap {
    .icon-success {
        display: none;
    }
}

.coupon-success {
    label {
        color: $txt-green;
    }
    .icon-success {
        display: inline-block;
        position: relative;
        top: -4px;
    }
    .icon-success:before {
        color: $txt-green;
        top: 0;
    }
}

//Edit Address Modal

.modal--edit-address {
    .wrap--validator, .header--ship-to {
        display: none;
    }

    .header--bill-to {
        display: inline;
    }

    .form--edit-address {
        width: 100%;
    }

    &.md {
        .wrap--validator {
            display: block;
        }

        .header--bill-to {
            display: none;
        }

        .header--ship-to {
            display: inline;
        }

        .form--edit-address {
            width: 66.66667%;
        }
    }
}

//Shipping Insurance

.wrap--insurance {
    border-top: 1px dotted $light-grey;
    padding: .75em 0;
    margin-bottom: 0;
}

.list--insurance {
    margin-bottom: 0;
}

.list__item--insurance {
    display: none;
    vertical-align: middle;
    margin: 0 0 0 .8em;

    &:first-of-type {
        margin: 0;
        display: inline-block;
        position: relative;
    }

    label, .select, input[type="text"] {
        vertical-align: middle;
        margin-bottom: 0;
    }
}

.needs-insurance {
    .list__item--insurance {
        &:first-of-type {
            padding: .55em 1.85em .55em .55em;
            background: $warning-bg-color;
            color: $warning-txt-color;
            border: 1px solid $warning-border-color;
        }
    }
}

.selected {
    .list__item--insurance {
        display: inline-block;
        &:first-of-type {
            padding: .55em;
            background-color: lighten(#34a0cd, 45%);
            border: 1px solid lighten(#34a0cd, 35%);
            position: relative;

            &:after {
                @include triangle-pointing-right(0.4em, #c3e3f0);
                z-index: 1001;
                top: 25%;
                right: -.4em;
                content: "";
            }
        }
    }
    .label-popover-wrapper {
        display: none;
    }
    .fine-print--shipsurance {
        display: block;
    }
}

.fine-print--shipsurance {
    display: none;
    font-size: rem-calc(10);
    color: $medium-grey;
    margin-left: 17em;
    margin-top: .75em;
}

// Shipping rules
.list--rules-warning {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-size: rem-calc(14);
    padding: rem-calc(10) rem-calc(12);
    background: $warning-bg-color;
    color: $warning-txt-color;
    border: 1px solid $warning-border-color;

    .list__item--rules-warning {
        display: inline-block;
        margin-bottom: 0;

    }
}


// USPS Postage callout

.list--usps-balance  {
    border: 1px solid $ordoro-blue;
    background: #fff;
    padding: rem-calc(10) rem-calc(12);
}

.list__title--usps-balance, .list__item--usps-balance {
    line-height: 1.25;
    font-size: rem-calc(14);
}

.callout--postage {
    line-height: 1.25;
}

.list__item--insufficient, .wrap--balance-loading {
    display: none;
}

.wrap--add-postage {
    display: inline-block;
    padding-left: .7em;
    margin-left: .45em;
    border-left: 1px solid #ccc;
}

.insufficient-funds {
    border: 1px solid $error-color;

    .list__item--insufficient {
        display: inline-block;
        font-size: rem-calc(14);

        &.make-smaller {
            line-height: 1.2;
        }
    }

    .list__title--usps-balance, .callout--postage, .icon-warning:before {
        color: $error-color;
    }

    .wrap--add-postage {
        margin-left: rem-calc(22);
        display: block;
        border-left: 0;
        padding-left: 0;
    }
}

.balance-loading {
    .wrap--balance-loading {
        display: block;
    }

    .callout--postage {
        display: none;
    }
}
