//Responsive styling fun

/* large screens -- 1380px wide */
@media #{$large} {

  html,
  body {
    font-size: 18px;
  }

  .row {
    max-width: 66.66666666666667em;
  }

  .reveal-modal {
    .row {
      max-width: 100%;
    }

    &.x-lg {
      width: 65em;
    }
  }

  .syncing .main-nav-icon:before {}

  .main-nav-wrap .top-bar-section .main-nav li a {
    height: 65px;
  }

  .main-nav-wrap .top-bar-section .main-nav li a:not(.button) {
    line-height: 65px;
  }

  .tags-inline-list {
    font-size: rem-calc(12);

    .first {
      margin-top: .2em;
    }
  }

  .tags-selected {
    font-size: rem-calc(10);
    margin-top: 0;
  }

  /* Dropdowns */
  .dropdown-wrap {
    .dropdown-menu {
      font-size: rem-calc(13);
      top: 2em;

      li {
        float: none;
        display: block;

        a,
        .dropdown-list-button {
          padding: 8px 8px 8px 13px;
          font-size: 13px;
          font-weight: normal;
          background: transparent;
          border: 0;
          display: block;
          @include transition-all-standard;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }

    .tag-menu {
      top: 3em;
    }
  }

  .main-action-dropdown {
    .dropdown-menu {
      font-size: rem-calc(13);
      top: 2.5em;

      label {
        font-size: rem-calc(13);
      }
    }

    .supplier-menu {
      width: 20em;
      max-width: 20em;

      li {
        font-size: rem-calc(14);
      }
    }
  }

  /* Table stuff */
  .app-table {
    .additional-table-info {
      dd {
        margin-top: 9px;
      }
    }
  }

  /* Icon sizes and vertical alignment */
  .icon.xxx-sm {
    font-size: 12px !important;
    line-height: 0;
  }

  .icon.xx-sm {
    font-size: 16px !important;
    line-height: 0;
  }

  .icon.x-sm {
    font-size: 20px !important;
    line-height: 0;
  }

  .icon.sm {
    font-size: 26px !important;
    line-height: 0;
  }

  .icon.md {
    font-size: 32px !important;
    line-height: 0;
  }

  .icon.lg {
    font-size: 50px !important;
    line-height: 0;
  }

  .print-label-error-wrap .icon-error {
    top: -2.5rem;
  }

  .loading,
  .syncing {
    .icon-before {
      line-height: .4em;
    }
  }

  #orders_main_nav.syncing,
  #shipments_main_nav.syncing,
  #products_main_nav.syncing {
    .icon:before {
      line-height: .3em;
    }
  }

  .icon-search.x-sm:before {
    top: .25em;
  }

  .product-detail-kit-component-name-cell .icon-search.x-sm:before,
  .detail-order-product-name-cell .icon-search.x-sm:before {
    top: 4px;
  }

  .search-wrap .input-prefix.with-label .icon-search:before {
    top: 0.65em !important;
  }

  .list-utility li .inline-text-button .down-arrow,
  .list-utility li .inline-text-button .up-arrow {
    margin-left: -.25em;
  }

  /* Product Detail Page */
  .product-basic-info-value,
  .product-basic-info-equation.edit-mode {
    .postfix {
      top: .5em;
    }
  }

  .manual-order-price-wrap {
    .product-basic-info-value {
      .prefix {
        top: .5em;
      }
    }
  }

  .product-basic-info-equation.edit-mode {
    .prefix {
      left: .5em;
      top: -0.1em;
    }
  }

  /* Global helper stuff */
  .margin-top-0 {
    margin-top: 0 !important;
  }

  .margin-left-1 {
    margin-left: 1px !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .padding-top-0 {
    padding-top: 0 !important;
  }

  .padding-bottom-0 {
    padding-bottom: 0 !important;
  }

}

@media #{$xlarge-up} {

  html,
  body {
    font-size: 19px;
  }

  .row {
    max-width: 82em;
  }

  .main-nav-wrap .top-bar-section .main-nav li a {
    height: 68px;
  }

  .main-nav-wrap .top-bar-section .main-nav li a:not(.button) {
    line-height: 68px;
  }

  /* Dropdowns */
  .dropdown-wrap {
    .dropdown-menu {
      font-size: rem-calc(14);
      top: 2em;

      li {
        float: none;
        display: block;

        a,
        .dropdown-list-button {
          padding: 8px 8px 8px 14px;
          font-size: 14px;
          font-weight: normal;
          background: transparent;
          border: 0;
          display: block;
          @include transition-all-standard;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }

    .tag-menu {
      top: 2.75em;
    }
  }

  .main-action-dropdown {
    .dropdown-menu {
      font-size: 14px;
      top: 2.5em;

      label {
        font-size: 14px;
      }
    }

    .supplier-menu {
      width: 20em;
      max-width: 20em;

      li {
        font-size: 14px;
      }
    }
  }

  /* Table stuff */
  .app-table {
    .additional-table-info {
      dd {
        margin-top: 10px;
      }
    }
  }

  /* Icon sizes and vertical alignment */
  .icon.xxx-sm {
    font-size: 13px !important;
    line-height: 0;
  }

  .icon.xx-sm {
    font-size: 17px !important;
    line-height: 0;
  }

  .icon.x-sm {
    font-size: 21px !important;
    line-height: 0;
  }

  .icon.sm {
    font-size: 27px !important;
    line-height: 0;
  }

  .icon.md {
    font-size: 33px !important;
    line-height: 0;
  }

  .icon.lg {
    font-size: 51px !important;
    line-height: 0;
  }

  .loading,
  .syncing {
    .icon-before {
      line-height: .4em;
    }
  }

  #orders_main_nav.syncing,
  #shipments_main_nav.syncing,
  #products_main_nav.syncing {
    .icon:before {
      line-height: .3em;
    }
  }

  .product-detail-kit-component-name-cell .icon-search.x-sm:before {
    top: 4px;
  }

  .search-wrap .input-prefix.with-label .icon-search:before {
    top: 0.65em !important;
  }

  .list-utility li .inline-text-button .down-arrow,
  .list-utility li .inline-text-button .up-arrow {
    margin-left: -.25em;
  }

  /* Product Detail Page */
  .product-basic-info-value,
  .product-basic-info-equation.edit-mode {
    .postfix {
      top: .5em;
    }
  }

  .manual-order-price-wrap {
    .product-basic-info-value {
      .prefix {
        top: .5em;
      }
    }
  }

  .product-basic-info-equation.edit-mode {
    .prefix {
      left: .5em;
      top: -0.1em;
    }
  }

  /* Global helper stuff */
  .margin-top-0 {
    margin-top: 0 !important;
  }

  .margin-left-1 {
    margin-left: 1px !important;
  }

  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .padding-top-0 {
    padding-top: 0 !important;
  }

  .padding-bottom-0 {
    padding-bottom: 0 !important;
  }

  .wrap--map {
    margin-left: 0;
  }

}

@media only screen and (min-width: 55em) and (max-width: 70em) {
  .plan-toggle {
    width: 12em;

    .plan-type,
    .plan-desc,
    .plan-fine-print {
      margin-left: auto;
      margin-right: auto;
    }
  }

}

@media only screen and (min-width: 48em) and (max-width: 55em) {
  .toggle-button-list {
    dd {
      margin-right: 1em;

      &:last-of-type {
        margin-right: 0;
      }
    }

  }

  .plan-toggle {
    width: 9em;
    padding: .5em;

    .plan-desc,
    .plan-fine-print {
      font-size: .75em;
    }
  }
}

//First pass at small screen responsiveness. This is just to make things look passable. We'll need to redo this later.

@media only screen and (max-width: 48em) {

  /* General Stuff */
  .small-screen-hide {
    display: none !important;
  }

  .small-screen-show {
    display: inherit !important;
  }

  .header-wrap,
  section.row {
    padding: 0 3%;
  }

  footer {
    padding: 1em 3% 0 3%;
  }

  .user-utility-wrap {
    margin-right: 3%;
  }

  .pagination-data {
    padding-left: .2em;
  }

  .pagination {
    float: none !important;
    margin-top: .5em;
    padding-top: .5em;
    border-top: 1px dotted $light-grey;
  }

  .reveal-modal {
    width: 90% !important;
    min-height: 1px !important;
    height: auto;
  }

  .create-label-modal {
    overflow: auto;
  }

  /* header stuff */
  .customer-name-wrap {
    width: 100%;
  }

  /* Navigation stuff */
  .main-nav-icon,
  .syncing .main-nav-icon {
    margin-right: 0;
  }

  .main-nav-wrap .top-bar-section .main-nav li .selected:after,
  .main-nav-wrap .top-bar-section .main-nav li .selected:after:not(.button) {
    bottom: -.3em;
    left: 30%;
  }

  .count {
    top: -1em;
  }

  .list-utility li .inline-text-button .down-arrow,
  .list-utility li .inline-text-button .up-arrow {
    margin-left: 0;
  }

  /* Dashboard stuff */
  .vital-stats-panel {
    .sales-stats-wrap {
      .sales-divider {
        margin-left: -0.9375em;
        margin-right: -0.9375em;
      }
    }

    .sales-stats-wrap {
      border-bottom: 0;
      margin-bottom: 0 !important;
    }

    .last {
      border-left: 0;
      border-top: 1px solid $light-grey;
    }
  }

  /* List page stuff */
  .search-wrap.margin-top-15,
  .date-range.margin-top-10 {
    margin-top: 5px !important;
  }

  .subnav {
    li {
      font-size: .8em;
      line-height: 1.75;

      a {
        border-left: 0;
        padding: 0 1.5em .5em 0;
        margin-left: 0;
      }

      .selected:after {
        display: none;
      }
    }
  }

  .dropdown-wrap {
    .filters {
      font-size: .8em;
    }
  }

  .tags-selected {
    position: static;

    .tag {
      font-size: .75em;
    }
  }

  .list-actions {
    font-size: .8em;

    .secondary-action {
      padding: .625em .5em .6875em .5em;
    }

    .list-utility li .inline-text-button {
      padding: 0 .25em;
    }
  }

  .product-actions {
    margin-left: 0;
  }

  /* Responsive tables */
  .app-table {

    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .row-wrap {
      border: 1px solid $medium-grey;
      margin: 0 0 1em 2em;
      position: relative;
    }

    .collapsed:hover {
      td {
        background: #fff;
      }

      .toggle-tip {
        display: none;
      }
    }

    .row-wrap:nth-child(2n+1).collapsed:hover {
      td {
        background: $secondary-color;
      }
    }

    td {
      border: 0;
      border-bottom: 1px solid $light-grey  !important;
      position: relative;
      padding: 1em 1em 1em 40%;
      min-height: 3.3em;
      font-size: rem-calc(13);

      p,
      ul,
      dl {
        font-size: rem-calc(13);
        line-height: 1.3;
      }

      &:last-of-type {
        border-bottom: 0 !important;
      }
    }

    td:before {
      position: absolute;
      top: 1em;
      left: 1em;
      width: 30%;
      padding-right: .5em;
      white-space: nowrap;
      border-right: 1px dotted $light-grey;
      color: $medium-dark-grey;
      font-family: $aktiv-grotesk-bold;
      font-weight: normal;
      text-transform: uppercase;
      text-align: right;
      word-wrap: break-word;
    }

    .checkbox-cell {
      left: -2.5em;
      top: -.5em;
      width: 2em;
      position: absolute;
      background: transparent !important;
      border-bottom: 0 !important;
      padding-left: 0;
    }

    .warning,
    .no-go {
      td:nth-of-type(2) {}
    }

    .preloader-wrap {
      border: 0;
      margin: 0;

      td {
        border-bottom: 0;
        padding: 0;
      }

      td:nth-of-type(1) {
        width: auto;
        left: 0;
      }
    }

    .shipping-sneak-peek td:nth-of-type(1) {
      width: auto;
      top: 0;
      left: 0;
      background: inherit !important;
      border-top: 0;
      padding-left: 4% !important;
      min-height: 0;
    }

    /* flips popover the correct way for comments */
    .to-the-right,
    .to-the-left {
      left: auto;
      right: 0;
      width: 20em;

      .arrow {
        display: none;
      }
    }

    .on-hand {
      font-size: 1em;
      line-height: 1.3;
    }
  }

  #orders-list {
    .app-table {
      td:nth-of-type(1):before {
        display: none;
      }

      td:nth-of-type(2):before {
        content: "Status";
      }

      td:nth-of-type(3):before {
        content: "Order ID";
      }

      td:nth-of-type(4):before {
        content: "Date";
      }

      td:nth-of-type(5):before {
        content: "Address";
      }

      td:nth-of-type(6):before {
        content: "Ship Type";
      }

      td:nth-of-type(7):before {
        content: "Cart";
      }

      td:nth-of-type(8):before {
        content: "Total";
      }

      td:nth-of-type(9):before {
        content: "Tags";
      }

      td:nth-of-type(9) {
        border-bottom: 0 !important;
      }
    }
  }

  #shipments-list {
    .app-table {
      td {
        padding-left: 45%;
      }

      td:before {
        width: 35%;
      }

      td:nth-of-type(1) {
        padding-left: 0;
      }

      td:nth-of-type(1):before {
        display: none;
      }

      td:nth-of-type(2):before {
        content: "Status";
      }

      td:nth-of-type(3):before {
        content: "Shipment ID";
      }

      td:nth-of-type(4):before {
        content: "Weight";
      }

      td:nth-of-type(5):before {
        content: "Order Date";
      }

      td:nth-of-type(6):before {
        content: "Ship Date";
      }

      td:nth-of-type(7):before {
        content: "Req. Ship";
      }

      td:nth-of-type(8):before {
        content: "Address";
      }

      td:nth-of-type(9):before {
        content: "Tracking";
      }

      td:nth-of-type(9) {
        border-bottom: 0 !important;
      }
    }
  }

  #products-list {
    .app-table {
      td:nth-of-type(1):before {
        display: none;
      }

      td:nth-of-type(2):before {
        content: "Product";
      }

      td:nth-of-type(3):before {
        content: "SKU";
      }

      td:nth-of-type(4):before {
        content: "Weight";
      }

      td:nth-of-type(5):before {
        content: "Supplier";
      }

      td:nth-of-type(6):before {
        content: "Physical On Hand";
      }

      td:nth-of-type(7):before {
        content: "Total Available";
      }

      td:nth-of-type(8):before {
        content: "Total Committed";
      }

      td:nth-of-type(9):before {
        content: "Reordered";
      }

      td:nth-of-type(9) {
        border-bottom: 0 !important;
      }
    }
  }

  .non-pro #products-list {
    .app-table {
      td:nth-of-type(5):before {
        content: "UPC";
      }

      td:nth-of-type(6):before {
        content: "Pkg Dimensions";
      }

      td:nth-of-type(7),
      td:nth-of-type(8),
      td:nth-of-type(9) {
        display: none;
      }

      td:nth-of-type(6) {
        border-bottom: 0 !important;
      }
    }
  }

  #po-list {
    .app-table {
      td:nth-of-type(1):before {
        display: none;
      }

      td:nth-of-type(2):before {
        content: "Status";
      }

      td:nth-of-type(3):before {
        content: "PO ID";
      }

      td:nth-of-type(4):before {
        content: "Ship To";
      }

      td:nth-of-type(5):before {
        content: "Supplier";
      }

      td:nth-of-type(6):before {
        content: "Updated";
      }

      td:nth-of-type(6) {
        border-bottom: 0 !important;
      }
    }
  }

  .order-items-table {
    .order-detail-line-item {
      td:nth-of-type(1):before {
        content: "Product";
      }

      td:nth-of-type(2):before {
        content: "SKU";
      }

      td:nth-of-type(3):before {
        content: "Qty Ordered";
      }

      td:nth-of-type(4):before {
        content: "Price";
      }

      td:nth-of-type(5):before {
        display: none;
      }

      td:nth-of-type(5) {
        border-bottom: 0 !important;
      }
    }

    .totals {
      td {
        padding-left: 1em;
      }
    }

    .with-input {
      min-height: 4.5em;
    }

    .detail-order-search-product-cell {
      padding-left: 1em;
      margin-bottom: 1.5em;
      border-bottom: 0 !important;
      width: 100%;
    }
  }

  .shipment-items-table {
    td {
      padding-left: 50%;
    }

    td:before {
      width: 40%;
    }

    td:nth-of-type(1):before {
      content: "Product";
    }

    td:nth-of-type(2):before {
      content: "SKU";
    }

    td:nth-of-type(3):before {
      content: "Qty Ordered";
    }

    td:nth-of-type(4):before {
      content: "Qty in Shipment";
    }

    td:nth-of-type(5):before {
      content: "Comments";
    }

    td:nth-of-type(5) {
      border-bottom: 0 !important;
    }

    .shipment-detail-line-comments-cell {
      .textarea-x-sm {
        min-height: 6em;
        font-size: rem-calc(12);
      }
    }
  }

  .product-suppliers-table {
    .supplier-line-item {
      &:nth-child(2n+1) td {
        background: #fff;
      }

      &:last-of-type {
        margin-bottom: .5em;
      }

      td {
        padding-left: 50%;
      }

      td:before {
        width: 40%;
      }

      td:nth-of-type(1):before {
        content: "Supplier";
      }

      td:nth-of-type(2):before {
        content: "Supplier SKU";
      }

      td:nth-of-type(3):before {
        content: "Unit Price";
      }

      td:nth-of-type(4):before {
        content: "Is Default";
      }

      td:nth-of-type(5):before {
        display: none;
      }

      td:nth-of-type(5) {
        border-bottom: 0 !important;
      }
    }

    .supplier-search-wrap {
      margin-bottom: 0;

      .product-detail-search-supplier-cell {
        padding: 0 0 1.5em 0;
        margin-bottom: 0;
        background: #fff;

        .bigger-select {
          width: 100%;
          height: 2.5em;
          @include box-sizing(border-box);
        }
      }
    }
  }

  .product-warehouse-table {
    .warehouse-line-item {
      &:nth-child(2n+1) td {
        background: #fff;
      }

      td {
        padding-left: 50%;
      }

      td:before {
        width: 40%;
      }

      td:nth-of-type(1):before {
        content: "Warehouse";
      }

      td:nth-of-type(2):before {
        content: "Location";
      }

      td:nth-of-type(3):before {
        content: "On Hand";
      }

      td:nth-of-type(4):before {
        content: "Low Stock";
      }

      td:nth-of-type(4) {
        border-bottom: 0 !important;
      }
    }
  }

  .sales-channel-table {
    tbody:nth-child(2n+1) td {
      background: #fff;
    }

    td {
      padding-left: 50%;

      .make-smaller {
        font-size: 1em;
      }
    }

    td:before {
      width: 40%;
    }

    td:nth-of-type(1):before {
      content: "Sales Channel";
    }

    td:nth-of-type(2):before {
      content: "Writeback";
    }

    td:nth-of-type(2) {
      border-bottom: 0 !important;
    }
  }

  .product-kit-table {
    .product-kit-item {
      &:nth-child(2n+1) td {
        background: #fff;
      }

      &:last-of-type {
        margin-bottom: .5em;
      }

      td {
        padding-left: 50%;
      }

      td:before {
        width: 40%;
      }

      td:nth-of-type(1):before {
        content: "Component";
      }

      td:nth-of-type(2):before {
        content: "SKU";
      }

      td:nth-of-type(3):before {
        content: "On Hand";
      }

      td:nth-of-type(4):before {
        content: "Qty Needed";
      }

      td:nth-of-type(5):before {
        display: none;
      }

      td:nth-of-type(5) {
        border-bottom: 0 !important;
      }
    }

    .product-kit-search-wrap {
      margin-bottom: 0;

      .product-detail-kit-component-search-cell {
        padding: 0 0 1.5em 0;
        border-bottom: 0 !important;
        margin-bottom: 0;
        background: #fff;
      }
    }
  }

  .settings-wrap,
  .row .settings-wrap {
    padding-left: .9375em;
    min-height: 1em;
    border-bottom: 1px solid $light-grey;
    border-left: 0;
    margin-bottom: 1em;
  }

  .settings-list-item-form-wrap {
    min-height: 1px;
  }

  .postage-wrap.last {
    padding-left: .9375em;
    border-left: 0;
  }

  .shipper-list-tabs,
  .row .shipper-list-tabs {
    dd {
      float: none;
    }

    .shipper-toggle {
      width: 100%;
      border-left: 0;
    }

    .usps .shipper-toggle {
      background-position: center 13px;
    }

    .ups .shipper-toggle {
      background-position: center -73px;
    }

    .fedex .shipper-toggle {
      background-position: center -162px;
    }

    .last .shipper-toggle {
      border-right: 0;
    }
  }

  .toggle-button-list {
    dd {
      float: none;
      width: 100%;
    }
  }

  .plan-toggle {
    margin-bottom: .75em;
    width: 100%;

    .plan-type-wrap {
      float: left;
      width: 40%;
      margin-top: .5em;
    }

    .plan-fine-print {
      float: left;
      width: 55%;
      margin-top: 0;
      padding-top: 0;
      padding-left: 1em;
      border-top: 0;
      font-size: rem-calc(12);
    }
  }

  .activate-form legend.with-top-border {
    border-top: 0;
  }

  /* Detail page stuff */
  .detail-sidebar,
  .detail-content-area-wrap {
    width: 100%;
  }

  .detail-meta-data {
    border-right: 0;
    border-bottom: 2px solid $medium-dark-grey;
    margin-bottom: 1.5em;

    dt {
      font-size: rem-calc(17);
    }

    dd,
    .inline-text-button {
      font-size: rem-calc(14);
    }

    .detail-meta-data-list-header {
      dt {
        text-align: left;
        font-weight: normal;
        font-size: rem-calc(23);
      }
    }
  }

  .shipment-id-list {
    margin-left: 0.5em !important;

    .detail-id-callout {
      font-size: rem-calc(23);
    }

    li {
      font-size: rem-calc(14);
    }
  }

  /* Tabs stuff */
  .tab-subnav,
  .row .tab-subnav {
    .right {
      float: none !important;
      margin-bottom: 1em;
      text-align: center;
    }
  }

  .wrap--map {
    min-height: 16.5em;
    margin-left: 0;
  }

}

@media only screen and (max-width: 40.063em) {
  #page2 {
    .reg-panel {
      margin-top: 0;
    }
  }

  .downtime {
    .downtime-message {
      text-align: center;

      dt {
        margin-top: .85em;
        font-size: rem-calc(27);
      }
    }
  }

  .wrap--graph {
    min-height: 15em;
    margin-bottom: 0;
    margin-right: .9375rem;
    padding-bottom: 1em;
    border-bottom: 1px solid $light-grey;
    border-right: 0;
  }

  .wrap--map {
    min-height: 10em;
  }

  .wrap--analytics {
    padding-left: 0.9375rem;
  }

  .list__item--date {
    float: none;
    margin-top: 1em;
    margin-left: 0;
  }



}

@media only screen and (max-width: 30em) {
  /* General Stuff */



  .super-small-screen-hide {
    display: none !important;
  }

  /* Header stuff */
  .customer-name-wrap {
    text-align: center;

    .customer-name {
      margin: 1.25em 0 .5em 0;

      a {
        font-size: .85em;
      }
    }
  }

  .user-utility-wrap {
    right: auto;
    left: 0;
    margin: -0.25em 0 0 3%;
  }

  /* Navigation Stuff */
  .list-navigation-rule {
    margin: 0 0.9375em 1em !important;
  }

  .main-nav-wrap .top-bar-section .main-nav li a {
    height: 50px;
    padding: 0 1.25em;
  }

  .main-nav-wrap .top-bar-section .main-nav li a:not(.button) {
    line-height: 50px;
  }

  .count,
  .count:hover {
    top: -1.25em;
    padding: 2px;
  }

  .dropdown-toggle,
  .utility-nav-button {
    padding: .35em .8em;
  }

  /* List page stuff */
  .list-actions .main-action-button-group {
    width: 100% !important;
  }

  .dropdown-wrap {
    .filters {
      margin-top: -.4em;
    }

    .dropdown-menu {
      top: 2.25em;
    }
  }

  .tags-inline-list,
  .tags-list {
    font-size: rem-calc(12);

    li {
      font-size: rem-calc(11);
    }

    .first {
      margin-top: .1em;
    }
  }

  .tags-selected .tag {
    font-size: 1em;
  }

  .filter-wrap {
    float: none !important;
    margin-left: 0;
    margin-top: .5em;
    padding-top: .5em;
    border-top: 1px dotted #ccc;

    .dropdown-menu {
      &.right {
        right: auto;
        left: 0;
        top: 2.5em;
        max-width: 100%;
        width: 100%;
      }
    }

    .tag-item {
      padding: .75em 0;
    }
  }

  /* Dashboard stuff */
  .onboarding-wrap {
    padding: 1em;

    .onboarding-header {
      font-size: rem-calc(23);
    }

    .white-panel {
      padding: 1em;
    }

    dt.onboarding-header {
      font-size: rem-calc(17);
    }

    .import-instructions {
      padding-left: 0;
      background: none;
      min-height: 1px;
    }
  }

  .vital-stats-panel {
    .vital-stats-list-wrap {
      .vital-stats-list {

        .big-stat,
        .stat {
          font-size: rem-calc(37);
        }

        .small-stat {
          font-size: rem-calc(23);
        }
      }
    }
  }

  /* Icon sizes and vertical alignment */
  .icon.xxx-sm {
    font-size: 8px !important;
    line-height: 0;
  }

  .icon.xx-sm {
    font-size: 12px !important;
    line-height: 0;
  }

  .icon.x-sm {
    font-size: 16px !important;
    line-height: 0;
  }

  .icon.sm {
    font-size: 22px !important;
    line-height: 0;
  }

  .icon.md {
    font-size: 28px !important;
    line-height: 0;
  }

  .icon.lg {
    font-size: 46px !important;
    line-height: 0;
  }

  .loading,
  .syncing {
    .icon-before {
      line-height: .4em;
    }
  }

  #orders_main_nav.syncing,
  #shipments_main_nav.syncing,
  #products_main_nav.syncing {
    .icon:before {
      line-height: .3em;
    }
  }

  .inline-list-with-borders {
    li {
      display: block;
      float: none;
      list-style-type: square;
      border: 0;
      padding-left: 1em;
    }
  }

  /* Modal stuff */
  .reveal-modal-bg {
    overflow-y: scroll;
  }

  .reveal-modal {
    padding: .75em;
  }

  .w-100-sml {
    width: 100%;
  }

  .sync-cart-list {

    .even,
    .odd {
      width: 100%;
      float: none;
    }

    .sync-cart-list-item {
      .panel {
        .icon:before {
          top: -3px;
        }

        dd {
          display: block;
          margin-left: -0.35em;

          &.autosynced {
            margin-left: 0;
          }
        }
      }
    }
  }

  .sync-modal-buttons {
    .left {
      float: none !important;
      width: 100%;

      &:first-of-type {
        margin-bottom: 1em;
      }
    }
  }

  .tab-subnav,
  .row .tab-subnav {
    .tab-toggle {
      padding: .75em;

      .tab-text {
        display: none;
      }
    }

    .active {
      .tab-text {
        display: inline;
        font-size: rem-calc(14);
      }
    }
  }

  /* Detail page timeline */
  .timeline {
    .timeline-day-content-wrap {
      width: 60%;
    }
  }

  .login {
    padding-top: 1em;
  }

  .wrap--map {
    min-height: 8em;
  }
}

/* Screen height responsiveness */

@media only screen and (max-height: 768px) {
  .wrap--modal-speedbump {
    min-height: 1em;
  }
}

@media only screen and (max-height: 500px) {
  .reveal-modal {
    min-height: 1em;
  }
}
