//
// Custom styles for detail pages
// ------

.wrap--product-img {
  max-width: 9rem;
  margin-right: 1.5rem;
}

.img--product-thumb {
  object-fit: cover;
  width: rem-calc(48);
  max-width: rem-calc(48);
  height: rem-calc(48);
  max-height: rem-calc(48);
  border: 1px solid #222;
}

.wrap--lg-img-popover {
  .img--product-thumb {
    width: rem-calc(72);
    max-width: rem-calc(72);
    height: rem-calc(72);
    max-height: rem-calc(72);
  }
}

.wrap--xl-img-popover {
  .img--product-thumb {
    width: rem-calc(95);
    max-width: rem-calc(95);
    height: rem-calc(95);
    max-height: rem-calc(95);
  }
}

.img--product-thumb-sm {
  width: rem-calc(36);
  max-width: rem-calc(36);
  height: rem-calc(36);
  max-height: rem-calc(36);
}



.table__td--product-list-name,
.table__th--product-list-name {
  .img--product-thumb {
    width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
  }

  &.img-enabled {
    padding-left: .5rem;
  }

  .wrap--product-addl-info-list {
    .img--product-thumb {
      width: 1.5rem;
      max-width: 1.5rem;
      height: 1.5rem;
      max-height: 1.5rem;
    }
  }

}

.wrap__inner--product-addl-info-list {
  border-bottom: 1px dotted #ccc;
  padding-bottom: .5rem;
  margin-bottom: .5rem;

  .list--product-warehouse {
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }

}

.wrap--product-popover {
  display: none;
  box-shadow: rgba(0, 0, 0, .30) 0 rem-calc(25) rem-calc(45);
  border: 3px solid #222;
}

.wrap--product-thumb-popover {
  position: relative;
}

.wrap--product-thumb-popover:hover {
  .wrap--product-popover {
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: rem-calc(75);
    background: #fff;
    min-width: max-content;
  }
}

.img--product-popover {
  max-width: 50vw;
  max-height: 65vh;
}

.img--product-popover-loading {
  width: 25vw;
  height: 25vw;

  img {
    display: none;
  }
}