em {
    font-family: $aktiv-grotesk-italic;
    font-style: normal;
}

strong, label, dl dt {
    font-family: $aktiv-grotesk-bold;
    font-weight: normal;
}

.unbold-label {
    font-family: $aktiv-grotesk-regular;
    font-weight: normal;
}

em strong, strong em {
    font-family: $aktiv-grotesk-bold-italic;
    font-style: normal;
    font-weight: normal;
}


//Print stylesheet
@import "print";

//Responsive
@import "responsive";

//Browser specific styles (aka "hacks")
@import "ie";
@import "ff";

// Override Foundation's box-sizing declaration
input, select, .app-table th {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

//No-JS
@import "no-js";
