/* NOSCRIPT AND LESS-THAN-IE8 STYLES */

.no-js h2 {
    font-size: 31px;
    color: #000;
    margin: 50px 0 22px 0;
    width: 650px;
}

.no-js p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
    width: 651px;
}
