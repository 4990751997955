//Footer

footer {
    padding-top: 1em;
    border-top: 1px solid $medium-grey;
    p {
        font-size: rem-calc(11);
        color: $medium-grey;
        .footer-phone {
            margin-right: rem-calc(6);
            padding-right: rem-calc(8);
            border-right: 1px solid $light-grey;
        }
    }
}
