// Max width for container
$container-width: 100%;

//
// Typography
// ----------

   $font-family:          "Aktiv-Grotesk-Regular", "Helvetica", "Arial", sans-serif;
   $font-family-headings: "Aktiv-Grotesk-Bold", "Helvetica", "Arial", sans-serif;
// $font-family-print:    "Georgia", "Times New Roman", "Times", serif;
// $font-family-mono:     "Consolas", monospace;
// $font-base-size:       16;

//
// Color Palette
// -------------

// Colors

$colors: (
    base: (
      "primary":   #f58026,
      "selection": #d6d6d6,
      "lines":     #e0e0e0
    ),

    text: (
        "primary":   #444,
        "secondary": #999,
        "heading":   #000,
        "ui":        white
    ),

    background: (
        "dark":  #282E31,
        "light": #f5f5f5,
        "body":  white
    ),

    state: (
        "muted":   #aaa,
        "primary": #4591aa,
        "success": #45ca69,
        "warning": #ffb800,
        "error":   #ca4829
    ),

    blue: (
        "darker":  #495b61,
        "dark":    #447281,
        "base":    #34a0cd,
        "light":   #5ab0cc,
        "lighter": #74cbe8
    ),

    green: (
        "darker":  #3b6e6e,
        "dark":    #3E992C,
        "base":    #6EB43A,
        "light":   #2dbaba,
        "lighter": #69d1d1
    ),

    cream: (
        "darker":  #c47858,
        "dark":    #e29372,
        "base":    #ecac91,
        "light":   #f9c2ab,
        "lighter": #fdd5c3
    ),

    red: (
        "darker":  #653131,
        "dark":    #b73333,
        "base":    #da3c3c,
        "light":   #f25a5a,
        "lighter": #fa8181
    ),

    gray: (
        "darker":  #333333,
        "dark":    #4d4d4d,
        "base":    #666666,
        "light":   #808080,
        "lighter": #999999
    )
);
