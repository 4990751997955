//Chart stuff
.chart-wrap {
  min-height: 18em;
}

//LINE CHART

.graph {
  font-size: rem-calc(11);
  @include text-shadow-reset;
}

.axis path,
.axis line {
  fill: none;
  stroke-width: 0;
  shape-rendering: crispEdges;
}


.line {
  fill: none;
  stroke: $ordoro-green;
  stroke-width: 2px;
}

.y.axis .tick line {
  stroke-width: 1;
  stroke: $light-grey;
  opacity: .6;
  fill: none;
}

.tick text {
  fill: $medium-dark-grey;
  margin-top: 3px;
  font-size: rem-calc(11);
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.area {
  fill: rgba(110, 180, 63, 0.15);
}

.x.axis .tick line text {
  padding-top: 30px;
}

.circle {
  fill: $ordoro-green;
  stroke-width: 1;
  stroke: $ordoro-green;

  &:hover {
    fill: $ordoro-blue;
    stroke-width: 4;
    stroke: $ordoro-blue;
  }
}

.q0-10 {
  fill: #fff;
  stroke: #aaa;
}

.q1-10 {
  fill: rgba(52, 160, 205, .1);
  stroke: #aaa;
}

.q2-10 {
  fill: rgba(52, 160, 205, .2);
  stroke: #aaa;
}

.q3-10 {
  fill: rgba(52, 160, 205, .3);
  stroke: #aaa;
}

.q4-10 {
  fill: rgba(52, 160, 205, .4);
  stroke: #aaa;
}

.q5-10 {
  fill: rgba(52, 160, 205, .5);
  stroke: #aaa;
}

.q6-10 {
  fill: rgba(52, 160, 205, .6);
  stroke: #999;
}

.q7-10 {
  fill: rgba(52, 160, 205, .7);
  stroke: #999;
}

.q8-10 {
  fill: rgba(52, 160, 205, .8);
  stroke: #999;
}

.q9-10 {
  fill: rgba(52, 160, 205, .9);
  stroke: #666;
}

.q10-10 {
  fill: rgba(52, 160, 205, 1);
  stroke: #444;
}



.states {
  path {
    @include transition-all-standard;

    &:hover {
      fill: #444;
      stroke: #111;
    }
  }
}


/*------------------------------------*\
    #ANALYTICS (new shit)
\*------------------------------------*/

.wrap--graph {
  min-height: 20.5em;
  border-right: 1px solid $light-grey;
  padding-right: 0;

  svg {
    display: block;
  }
}

.wrap--map {
  margin: 1.5em 0 1.5em -1em;
}

.subdue-for-loading {
  @include transition-all-standard;
  opacity: 1;
}

.graph-loading {
  display: none;
}

.loading {
  .wrap--graph {
    text-align: center;

    svg,
    .overlay--no-results {
      display: none;
    }

    &:after {
      display: inline-block;
      content: "";
      @include spinner(0.5em, #444, 0.5, 3.5em);
      text-align: center;
      margin: 7.5em 0 0 0;
      vertical-align: middle;
    }
  }

  .graph-loading {
    display: block;
    margin: 1em 0;
    text-align: center;

    &:after {
      display: inline-block;
      content: "";
      @include spinner(0.35em, #444, 0.5, 2em);
      text-align: center;
      margin: 0;
      vertical-align: middle;
    }

  }

  .subdue-for-loading {
    opacity: .15;
  }

  .hide-for-loading {
    display: none;
  }
}

.wrap--dashboard-stats .loading .graph-loading {
  margin: 1.875rem 0;
}

//Line charts
.wrap--line-graph {
  margin: 1em 0;

  .x.axis .tick line,
  .y.axis .tick line {
    stroke-width: 1;
    stroke-dasharray: 3, 3;
    stroke: $light-grey;
    fill: none;
  }

  .line {
    stroke-width: 1px;
  }

  .lc-01 {
    stroke: $lc-01;
  }

  .lc-02,
  .shipping-stats .lc-01 {
    stroke: $lc-02;
  }

  .lc-03 {
    stroke: $lc-03;
  }

  .lc-04 {
    stroke: $lc-04;
  }

  .lc-05 {
    stroke: $lc-05;
  }

  .lc-06 {
    stroke: $lc-06;
  }

  .lc-07 {
    stroke: $lc-07;
  }

  .area {
    fill: transparent;
  }

  .ac-01 {
    fill: $ac-01;
  }

  .ac-02,
  .shipping-stats .ac-01 {
    fill: $ac-02;
  }

  .ac-03 {
    fill: $ac-03;
  }

  .ac-04 {
    fill: $ac-04;
  }

  .ac-05 {
    fill: $ac-05;
  }

  .ac-06 {
    fill: $ac-06;
  }

  .ac-07 {
    fill: $ac-07;
  }

  .region {
    fill: rgba(0, 0, 0, 0);

    &:hover {
      fill: rgba(0, 0, 0, .15);
    }
  }
}

//State Heat Map

//Bar Graphs

.wrap--bar-graph {
  background: $lighter-grey;
  width: 87%;
  height: 1em;
  margin-right: .15em;
}

.fill--bar-graph {
  background: $ordoro-blue;
  height: 100%;
  display: block;
  @include transition-all-standard;
}

.panel--full-width-graph {
  .wrap--graph {
    min-height: auto;
    border-right: 0;
    margin: 0;
  }
}